<template>
  <div class="absolute right-0 z-10 flex justify-end w-fit" style="top: 15%">
    <div
      class="rounded-l-md bg-orange-400 shadow-lg animate-pulse px-2 py-1 hover:animate-none m-auto"
    >
      <div class="flex space-x-2">
        <input
          :value="true"
          type="checkbox"
          class="border"
          v-model="comptoirSessionActive[0].dsvCheck"
          @change="setdsvcheck(comptoirSessionActive[0].dsvCheck)"
        />
        <label>DA SILVA</label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dsvcheck: false,
      tempConsigne: [],
    };
  },
  computed: {
    ...mapGetters(["actionArticle", "dataConsigne", "comptoirSessionActive"]),
  },
  methods: {
    ...mapActions(["addactionArticle", "suppactionArticle", "setdsvcheck"]),
    async addConsigne() {
      if (this.comptoirSessionActive[0].dsvCheck == false) {
        console.log("false");
        for (const element of this.comptoirSessionActive[0].consigneData) {
          console.log(element);
          this.addactionArticle({
            _id: element._id,
            Ref_fournisseur: "C : " + element.Ref_fournisseur,
            Prix_euro: element.Montant_consigne,
            Prix_vente: element.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: 1,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: element.Prix_euro,
            prixfinal: element.Prix_euro,
            Code_marque: element.Code_marque,
            Description: element.Description,
          });
        }
        this.tempConsigne = [];
      }
    },
    async suppConsigne() {
      this.tempConsigne = [];
      if (this.comptoirSessionActive[0].dsvCheck == true) {
        console.log("true");
        this.tempConsigne.push(this.comptoirSessionActive[0].consigneData);
        for (const element of this.actionArticle) {
          if (
            element.Ref_fournisseur.startsWith("C : ") &&
            element.Code_marque == "DA SILVA"
          ) {
            console.log(element);
            element._id = "dasilva" + Math.floor(Math.random() * 10001);
            this.suppactionArticle(element._id);
          }
        }
      }
    },
  },
};
</script>
