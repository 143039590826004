<template>
  <div>
    <!-- menu -->
    <menuCompta />

    <!-- Caisse ouverte -->
    <div v-if="caisse">
      <!-- Modal fermeture caisse -->
      <div
        v-if="showFermeture"
        class="fixed inset-0 flex items-center justify-center z-10"
      >
        <div
          class="absolute inset-0 bg-black opacity-50"
          @click="(showFermeture = false), (currentIndex = 0)"
        ></div>
        <div
          class="bg-white p-4 rounded z-20 overflow-auto"
          style="max-height: 50rem; max-width: 80rem"
        >
          <div class="flex justify-between">
            <h4 class="text-xl font-bold mb-4">Fermeture de la caisse</h4>
            <p v-show="currentIndex === 1">
              Caisse n° {{ numberYesterday }} du {{ getFRDate(dateYesterday) }} :
              {{ totalYesterday }} €
            </p>
            <button
              class="material-icons-round"
              @click="(showFermeture = false), (currentIndex = 0)"
            >
              close
            </button>
          </div>

          <!-- Contenu modal -->
          <div class="container">
            <div
              class="content px-12"
              :class="{ 'slide-left': showNextContent }"
            >
              <!-- Page 1 -->
              <div
                v-show="currentIndex === 0"
                class="mode-grid-cols-5 flex justify-center mb-6 items-center"
                style="min-width: 40rem; min-height: 20rem"
              >
                <div
                  v-for="(mode, index) in modePaiement"
                  :key="index"
                  class="flex space-x-4"
                >
                  <div class="flex flex-col">
                    <button class="flex space-x-4 rounded-md shadow p-2">
                      <img
                        class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                        :src="mode.image"
                      />
                      <div class="flex flex-col">
                        <h4 class="text-sm m-auto">
                          {{ mode.action }} actions
                        </h4>
                        <p class="m-auto text-xl">{{ mode.total }} €</p>
                      </div>
                      <input
                        type="checkbox"
                        class="w-6 h-6 focus:outline-2 outline-sky-300 m-auto"
                        value="true"
                        v-model="selectPaiement[index]"
                      />
                    </button>
                    <h4 class="text-sm m-auto">{{ mode.type }}</h4>
                  </div>
                </div>
              </div>

              <!-- Page 2 -->
              <div
                v-show="currentIndex === 1"
                class="mt-4 bg-gray-50 rounded-md p-1"
              >
                <div class="flex justify-between">
                  <div class="w-32"></div>
                  <h4 class="text-2xl font-medium">
                    Total caisse : {{ getTotalAmount }} €
                  </h4>
                  <div>
                    <div v-for="(mode, index) in modePaiement" :key="index">
                      <div
                        v-if="mode.type == 'Espèce'"
                        class="flex space-x-4 bg-white rounded-md shadow p-2"
                      >
                        <img
                          class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                          :src="mode.image"
                        />
                        <p class="m-auto text-xl">{{ mode.total }} €</p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr
                  class="my-4"
                  style="
                    height: 1px;
                    color: #a9a9a9;
                    background-color: #a9a9a9;
                    border: none;
                  "
                />

                <!-- Total monnaie -->
                <div class="flex justify-center text-xl font-medium">
                  <label>Totale monnaie : </label>
                  <input
                    type="number"
                    class="w-24 text-xl p-1 focus:outline-2 outline-sky-300 my-auto mx-2 border border-black rounded-md"
                    v-model="totalCashHandInput"
                    :disabled="allValuesZero"
                    min="0"
                  />
                  €
                </div>
                <div class="flex mt-4 space-x-4">
                  <hr
                    style="
                      float: left;
                      width: 50%;
                      height: 1px;
                      color: #a9a9a9;
                      background-color: #a9a9a9;
                      border: none;
                    "
                  />
                  <label style="display: inline-block" class="h-0 -mt-4"
                    >ou</label
                  >
                  <hr
                    style="
                      float: right;
                      width: 50%;
                      height: 1px;
                      color: #a9a9a9;
                      background-color: #a9a9a9;
                      border: none;
                    "
                  />
                </div>

                <h4 class="flex justify-center mt-4 text-xl font-medium">
                  Total monnaie : {{ setTotalCash }} €
                </h4>
                <h4
                  class="flex justify-end cursor-pointer mb-6 text-blue-700 underline hover:text-blue-800"
                  @click="resetTabCash()"
                >
                  Réinitialiser
                </h4>

                <div class="mode-grid-cols mt-4">
                  <div v-for="(line, index) in setTotalByPiece" :key="index">
                    <div
                      class="flex space-x-8 bg-gray-50 border rounded-md p-1"
                    >
                      <img
                        :src="
                          require(`@/assets/icons_payments/${line.imgName}.jpg`)
                        "
                        class="w-10 h-10"
                      />
                      <input
                        type="number"
                        class="w-24 text-xl p-1 focus:outline-2 outline-sky-300 m-auto border border-black rounded-md"
                        v-model="qtyPerSpecies[index]"
                        @input="filterInput"
                        min="0"
                        :ref="'inputQty' + index"
                        :disabled="totalCashHandInput !== 0"
                        @keydown.enter="
                          $refs['inputQty' + (index + 1)][0].focus()
                        "
                      />
                      <h2 class="m-auto">=> {{ line.total }} €</h2>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Page 3 -->
              <div
                v-show="currentIndex === 2"
                class="flex justify-center mb-6 items-center"
                style="min-width: 40rem; min-height: 20rem"
              >
                <!-- Montant laissé -->
                <div class="flex justify-center text-xl font-medium">
                  <label>Montant laissé :</label>
                  <input
                    type="number"
                    class="w-24 text-xl p-1 focus:outline-2 outline-sky-300 my-auto mx-2 border border-black rounded-md"
                    v-model="amountLeft"
                    min="0"
                  />
                  €
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-between mt-4">
            <button
              v-if="currentIndex >= 1"
              @click="(showNextContent = false), currentIndex--"
              class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Précédent
            </button>
            <button v-else></button>
            <div class="flex justify-end space-x-4">
              <button
                @click="(showFermeture = false), (currentIndex = 0)"
                class="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-200"
              >
                Fermer
              </button>
              <button
                v-if="currentIndex <= 1"
                @click="nextIndex()"
                class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Suivant
              </button>
              <button
                v-if="currentIndex == 2"
                @click="createCaisse()"
                class="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 text-xl"
              >
                <PulseLoader v-if="isLoading" color="#5045e4" />
                <p v-else>Valider</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Moyen de paiement -->
      <div class="flex justify-between mb-1">
        <h5 class="text-2xl font-medium">
          Caisse du {{ getFRDate(dateCaisse) }}
        </h5>
        <button
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="fermetureCaisse()"
        >
          <img
            class="text-xl mr-2 w-9 h-9"
            src="@/assets/icons_payments/caisseFermée.png"
          />
          <span>Fermer la caisse</span>
        </button>
      </div>

      <!-- Filtrer par client ou numéro -->
      <button
        v-if="!expandFilter"
        class="flex space-x-10 justify-between bg-gray-100 rounded-md shadow border px-2 py-0.5 w-fit"
        @click="expandFilter = true"
      >
        <p>Filtrer par</p>
        <span class="material-icons-round my-auto">expand_more</span>
      </button>
      <button
        v-else
        class="flex space-x-10 justify-between bg-gray-100 rounded-t px-2 py-0.5 w-fit"
        @click="expandFilter = false"
      >
        <p>Filtrer par</p>
        <span class="material-icons-round my-auto">expand_less</span>
      </button>

      <div
        v-if="expandFilter"
        class="flex space-x-12 bg-gray-100 rounded-tr-md rounded-b-md shadow mb-2 p-3 w-fit"
      >
        <div class="flex flex-col">
          <label for="clientInput" class="text-sm">Nom du client</label>
          <input
            list="clientInput"
            type="text"
            v-model="clientFiltre"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
          />
          <datalist id="clientInput">
            <option
              v-for="(c, index) in Clients"
              :key="index"
              :value="c"
            ></option>
          </datalist>
        </div>
        <div class="flex flex-col">
          <label for="numeroCInput" class="text-sm">Numéro de BC</label>
          <input
            list="numeroCInput"
            type="text"
            v-model="numeroCFiltre"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
          />
          <datalist id="numeroCInput">
            <option
              v-for="(num, index) in getNumerosBC"
              :key="index"
              :value="num"
            ></option>
          </datalist>
        </div>
        <div class="flex flex-col">
          <label for="numeroFInput" class="text-sm">Numéro de facture</label>
          <input
            list="numeroFInput"
            type="text"
            v-model="numeroFFiltre"
            class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
          />
          <datalist id="numeroFInput">
            <option
              v-for="(num, index) in getNumerosFact"
              :key="index"
              :value="num"
            ></option>
          </datalist>
        </div>
        <button
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="filtrerTabs(clientFiltre, numeroCFiltre, numeroFFiltre)"
        >
          <span class="material-icons-outlined text-xl mr-2"> filter_alt </span>
          <span>Filtrer</span>
        </button>
      </div>

      <div class="flex justify-center mt-4">
        <div
          v-for="(mode, index) in modePaiement"
          :key="index"
          class="mode-space-x"
        >
          <div class="flex flex-col">
            <button
              class="flex space-x-4 rounded-md shadow p-2"
              :class="{
                'bg-sky-100': mode.clicked,
                'bg-white': !mode.clicked,
              }"
              @click="sortByPayment(mode.type, index)"
            >
              <img
                class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
                :src="mode.image"
              />
              <div class="flex flex-col">
                <h4 class="text-sm m-auto">{{ mode.action }} actions</h4>
                <p class="m-auto text-xl">{{ mode.total }} €</p>
              </div>
            </button>
            <h4 class="text-sm m-auto">{{ mode.type }}</h4>
          </div>
        </div>
      </div>

      <div class="flex justify-center my-2">
        <div class="ml-32 text-xl">
          <button class="flex space-x-4 bg-white rounded-md shadow p-2">
            <img
              class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
              src="@/assets/icons_payments/Caisse.png"
            />
            <div class="flex flex-col">
              <h4 class="text-sm m-auto">Total encaissé :</h4>
              <p class="m-auto text-green-700">{{ totalEncaisse }} €</p>
            </div>
          </button>
        </div>

        <div class="ml-32 text-xl">
          <button class="flex space-x-4 bg-white rounded-md shadow p-2">
            <img
              class="w-12 border border-black rounded-md m-auto rounded-md focus:outline-2 outline-sky-300"
              src="@/assets/icons_payments/FactureEuro.png"
            />
            <div class="flex flex-col">
              <h4 class="text-sm m-auto">Total réel :</h4>
              <p class="m-auto text-green-700">{{ totalReel }} €</p>
            </div>
          </button>
        </div>
      </div>

      <!-- Bon de commande -->
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg border border-orange-400"
        style="max-height: 25em"
      >
        <table class="w-full text-sm text-left text-gray-500">
          <caption
            class="text-xl bg-gray-100 w-full font-semibold text-orange-400 pl-4 py-1"
          >
            Bon de commande
          </caption>
          <thead class="text-xs bg-bluevk text-white uppercase bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3">Date</th>
              <th scope="col" class="px-6 py-3">Numéro</th>
              <th scope="col" class="px-6 py-3">Client</th>
              <th scope="col" class="px-6 py-3">Créé par</th>
              <th scope="col" class="px-6 py-3">Total TTC</th>
              <th scope="col" class="px-6 py-3">Mode de réglement</th>
              <!-- <th scope="col" class="px-6 py-3">
                <div class="flex space-x-2">
                  <input
                    type="checkbox"
                    class="w-6 h-6 focus:outline-2 outline-sky-300"
                    v-model="selectAllC"
                    @change="selectAllCmd"
                  />
                  <p>Sélect.</p>
                </div>
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              class="tab-list odd:bg-white even:bg-gray-100 text-black"
              v-for="(item, index) in tabCmd"
              :key="index"
            >
              <td class="px-6 py-4">{{ getFRDate(item.Date) }}</td>
              <td class="px-6 py-4">{{ item.Numero }}</td>
              <td class="px-6 py-4">{{ item.Client }}</td>
              <td class="px-6 py-4">{{ item.Name }}</td>
              <td class="px-6 py-4 text-xl">
                {{
                  parseFloat(item.TotalPrice + item.TotalPrice * 0.2).toFixed(2)
                }}
                €
              </td>
              <td class="px-6 py-4">
                <div
                  v-for="reglement in item.reglement"
                  :key="reglement"
                  class="flex space-x-2"
                >
                  <p>{{ reglement.placeholder }} :</p>
                  <p>{{ reglement.model }} €</p>
                  <p>
                    {{ reglement.date }}
                  </p>
                </div>
              </td>
              <!-- <td class="px-6 py-4">
                <input
                  type="checkbox"
                  class="w-6 h-6 focus:outline-2 outline-sky-300"
                  :value="item"
                  v-model="selectedCmd"
                  :checked="selectAllC"
                />
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end mt-2 mb-4">
        <div class="flex flex-col">
          <h4>Total TTC :</h4>
          <h4 class="text-2xl hover:text-3xl">{{ totalCmd }} €</h4>
        </div>
      </div>

      <!-- Facture -->
      <div
        class="relative overflow-x-auto shadow-md sm:rounded-lg mt-12 border border-orange-400"
        style="max-height: 25em"
      >
        <table class="w-full text-sm text-left text-gray-500">
          <caption
            class="text-xl bg-gray-100 w-full font-semibold text-orange-400 pl-4 py-1"
          >
            Facture
          </caption>
          <thead class="text-xs bg-bluevk text-white uppercase bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3">Date</th>
              <th scope="col" class="px-6 py-3">Numéro</th>
              <th scope="col" class="px-6 py-3">Client</th>
              <th scope="col" class="px-6 py-3">Créée par</th>
              <th scope="col" class="px-6 py-3">Total TTC</th>
              <th scope="col" class="px-6 py-3">Mode de réglement</th>
              <!-- <th scope="col" class="px-6 py-3">
                <div class="flex space-x-2">
                  <input
                    type="checkbox"
                    class="w-6 h-6 focus:outline-2 outline-sky-300"
                    v-model="selectAllF"
                    @change="selectAllFact"
                  />
                  <p>Sélect.</p>
                </div>
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              class="tab-list odd:bg-white even:bg-gray-100 text-black"
              v-for="(item, index) in tabFact"
              :key="index"
            >
              <td class="px-6 py-4">{{ getFRDate(item.Date) }}</td>
              <td class="px-6 py-4">{{ item.Numero }}</td>
              <td class="px-6 py-4">{{ item.Client }}</td>
              <td class="px-6 py-4">{{ item.Name }}</td>
              <td class="px-6 py-4 text-xl">
                {{
                  parseFloat(item.TotalPrice + item.TotalPrice * 0.2).toFixed(2)
                }}
                €
              </td>
              <td class="px-6 py-4">
                <div
                  v-for="reglement in item.reglement"
                  :key="reglement"
                  class="flex space-x-2"
                >
                  <p>{{ reglement.placeholder }} :</p>
                  <p>{{ reglement.model }} €</p>
                  <p>
                    {{ reglement.date }}
                  </p>
                </div>
              </td>
              <!-- <td class="px-6 py-4">
                <input
                  type="checkbox"
                  class="w-6 h-6 focus:outline-2 outline-sky-300"
                  :value="item"
                  v-model="selectedFact"
                  :checked="selectAllF"
                />
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end mt-2 mb-4">
        <div class="flex flex-col">
          <h4>Total TTC :</h4>
          <h4 class="text-2xl hover:text-3xl">{{ totalFact }} €</h4>
        </div>
      </div>
    </div>

    <!-- Caisse fermée -->
    <div v-else class="flex justify-center items-center m-auto">
      <div
        class="flex flex-col bg-white border rounded-md shadow p-4 space-y-4"
      >
        <label class="m-auto">Caisse fermée !</label>
        <button
          class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none shadow"
          @click="ouvrirLaCaisse"
        >
          Ouvrir la caisse
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useToast } from "vue-toastification";
import { createEcheancier } from "@/hooks/caisse/echeancier";
import menuCompta from "@/components/CaisseCompta/menuCompta.vue";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyCaisse",
  components: {
    PulseLoader,
    menuCompta,
  },
  data() {
    return {
      isLoading: false,
      tabCmd: [],
      totalCmd: 0.0,
      tabFact: [],
      totalFact: 0.0,
      selectedCmd: [],
      selectedFact: [],
      selectAllC: false,
      selectAllF: false,
      totalEncaisse: 0.0,
      totalReel: 0.0,
      clickCount: 0,
      modePaiement: [],
      showFermeture: false,
      selectPaiement: [true, true, true, true, true, true, true],
      tabCash: [
        { imgName: "500euro", amount: 500, total: 0.0, quantity: 0 },
        { imgName: "200euro", amount: 200, total: 0.0, quantity: 0 },
        { imgName: "100euro", amount: 100, total: 0.0, quantity: 0 },
        { imgName: "50euro", amount: 50, total: 0.0, quantity: 0 },
        { imgName: "20euro", amount: 20, total: 0.0, quantity: 0 },
        { imgName: "10euro", amount: 10, total: 0.0, quantity: 0 },
        { imgName: "5euro", amount: 5, total: 0.0, quantity: 0 },
        { imgName: "2euro", amount: 2, total: 0.0, quantity: 0 },
        { imgName: "1euro", amount: 1, total: 0.0, quantity: 0 },
        { imgName: "50cent", amount: 0.5, total: 0.0, quantity: 0 },
        { imgName: "20cent", amount: 0.2, total: 0.0, quantity: 0 },
        { imgName: "10cent", amount: 0.1, total: 0.0, quantity: 0 },
        { imgName: "5cent", amount: 0.05, total: 0.0, quantity: 0 },
        { imgName: "2cent", amount: 0.02, total: 0.0, quantity: 0 },
        { imgName: "1cent", amount: 0.01, total: 0.0, quantity: 0 },
      ],
      qtyPerSpecies: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      currentIndex: 0,
      showNextContent: false,
      amountLeft: 0.0,
      totalCashHandInput: 0.0,
      totalYesterday: "",
      dateYesterday: "",
      numberYesterday: 0,
      dateCaisse: "",
      tabCmdTemp: [],
      tabFactTemp: [],
      clientFiltre: "",
      numeroCFiltre: "",
      numeroFFiltre: "",
      Clients: [],
      expandFilter: false,
    };
  },
  computed: {
    ...mapGetters(["caisse", "caisseContenu", "user"]),
    setTotalByPiece() {
      var total = 0.0;
      var quantity = 0;
      return this.tabCash.map((row, index) => {
        total = row.amount * this.qtyPerSpecies[index];
        total = parseFloat(total).toFixed(2);
        quantity = row.quantity + this.qtyPerSpecies[index];
        quantity = parseInt(quantity);
        return { ...row, total, quantity };
      });
    },
    setTotalCash() {
      var totalCash = 0.0;
      for (const [index, cash] of this.tabCash.entries()) {
        totalCash =
          parseFloat(totalCash) + cash.amount * this.qtyPerSpecies[index];
      }
      return parseFloat(totalCash).toFixed(2);
    },
    allValuesZero() {
      return this.qtyPerSpecies.some((item) => item !== 0);
    },
    getTotalAmount() {
      var total = 0.0;
      if (this.totalCashHandInput != 0.0) {
        total =
          parseFloat(this.totalCashHandInput) - parseFloat(this.totalYesterday);
      } else {
        total = parseFloat(this.setTotalCash) - parseFloat(this.totalYesterday);
      }
      return total.toFixed(2);
    },
    getNumerosBC() {
      var tabNum = [];
      this.tabCmd.forEach((element) => {
        if (this.clientFiltre) {
          if (this.clientFiltre == element.Client) {
            tabNum.push(element.Numero);
          }
        } else {
          tabNum.push(element.Numero);
        }
      });
      return tabNum;
    },
    getNumerosFact() {
      var tabNum = [];
      this.tabFact.forEach((element) => {
        if (this.clientFiltre) {
          if (this.clientFiltre == element.Client) {
            tabNum.push(element.Numero);
          }
        } else {
          tabNum.push(element.Numero);
        }
      });
      return tabNum;
    },
  },
  methods: {
    ...mapActions([
      "ouvrirCaisse",
      "fermerCaisse",
      "clearcaisse",
      "suppcaisse",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    getCmdFact() {
      axios
        .post(`${process.env.VUE_APP_API}/caisse/getAllBox`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.dateCaisse = response.data.Date;

          this.tabCmd = response.data.Commandes;
          console.log(this.tabCmd);
          this.tabFact = response.data.Factures;
          console.log(this.tabFact);
          this.totalCmd = response.data.totalCmd;
          this.totalFact = response.data.totalFact;

          this.tabCmdTemp = this.tabCmd;
          this.tabFactTemp = this.tabFact;
          this.getTotals(response.data);
          this.filtrerClientTab();
        });
    },
    getTotals(data) {
      axios
        .post(`${process.env.VUE_APP_API}/caisse/getTotalPaid`, {
          data: data,
        })
        .then((response) => {
          console.log(response.data);
          this.modePaiement = [
            {
              type: "LCR",
              image: require("@/assets/icons_payments/LCR.png"),
              total: response.data.LCR.somme,
              action: response.data.LCR.action,
              clicked: false,
            },
            {
              type: "Espèce",
              image: require("@/assets/icons_payments/ESP.png"),
              total: response.data.ESP.somme,
              action: response.data.ESP.action,
              clicked: false,
            },
            {
              type: "Virement",
              image: require("@/assets/icons_payments/Virement.png"),
              total: response.data.VIR.somme,
              action: response.data.VIR.action,
              clicked: false,
            },
            {
              type: "Carte Bancaire",
              image: require("@/assets/icons_payments/CB.png"),
              total: response.data.CB.somme,
              action: response.data.CB.action,
              clicked: false,
            },
            {
              type: "Chèque Comptant",
              image: require("@/assets/icons_payments/CHQ.png"),
              total: response.data.CHQ.somme,
              action: response.data.CHQ.action,
              clicked: false,
            },
            {
              type: "Reste dû",
              image: require("@/assets/icons_payments/resteDu.png"),
              total: response.data.Reste.somme,
              action: response.data.Reste.action,
              clicked: false,
            },
            {
              type: "Rendu",
              image: require("@/assets/icons_payments/rendu.png"),
              total: response.data.Rendu.somme,
              action: response.data.Rendu.action,
              clicked: false,
            },
          ];
          this.totalEncaisse = response.data.totalPaye;
          this.totalReel = response.data.totalReel;
        });
    },
    getLastCaisse() {
      axios
        .post(`${process.env.VUE_APP_API}/caisse/getLast`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          console.log(response.data);
          this.totalYesterday = response.data.total;
          this.dateYesterday = response.data.date;
          this.numberYesterday = response.data.number;
        });
    },
    // selectAllCmd() {
    //   for (const [index, line] of this.tabCmd.entries()) {
    //     if (this.selectAllC == true) {
    //       this.selectedCmd[index] = line;
    //     } else {
    //       this.selectedCmd = [];
    //     }
    //   }
    // },
    // selectAllFact() {
    //   for (const [index, line] of this.tabFact.entries()) {
    //     if (this.selectAllF == true) {
    //       this.selectedFact[index] = line;
    //     } else {
    //       this.selectedFact = [];
    //     }
    //   }
    // },
    filtrerClientTab() {
      var cmdFiltre = this.tabCmd.map(function (item) {
        return item.Client;
      });
      var factFiltre = this.tabFact.map(function (item) {
        return item.Client;
      });
      var tabFusionne = cmdFiltre.concat(factFiltre);
      this.Clients = [...new Set(tabFusionne)];
    },
    sortByPayment(reglement, index) {
      this.clickCount++;
      this.tabCmdTemp = this.tabCmd;
      this.tabFactTemp = this.tabFact;

      if (this.clickCount == 1) {
        var commandesReglement = this.tabCmd.filter(function (cmd) {
          for (const tab of cmd.reglement) {
            if (tab.placeholder == reglement) {
              return tab.placeholder === reglement;
            }
          }
        });

        var facturesReglement = this.tabFact.filter(function (cmd) {
          for (const tab of cmd.reglement) {
            if (tab.placeholder == reglement) {
              return tab.placeholder === reglement;
            }
          }
        });

        this.tabCmd = commandesReglement;
        this.tabFact = facturesReglement;

        this.modePaiement.forEach((button, buttonIndex) => {
          button.clicked = buttonIndex === index;
        });
      } else if (this.clickCount >= 2) {
        this.getCmdFact();
        this.clickCount = 0;
      }
    },
    filtrerTabs(client, numeroBC, numeroFact) {
      this.tabCmd = this.tabCmdTemp;
      this.tabFact = this.tabFactTemp;
      if (client && numeroBC && numeroFact) {
        this.tabCmd = this.tabCmd.filter(function (cmd) {
          return cmd.Client === client && cmd.Numero === numeroBC;
        });
        this.tabFact = this.tabFact.filter(function (cmd) {
          return cmd.Client === client && cmd.Numero === numeroFact;
        });
      } else if (client && numeroBC) {
        this.tabCmd = this.tabCmd.filter(function (cmd) {
          return cmd.Client === client && cmd.Numero === numeroBC;
        });
      } else if (client && numeroFact) {
        this.tabFact = this.tabFact.filter(function (cmd) {
          return cmd.Client === client && cmd.Numero === numeroFact;
        });
      } else if (client) {
        this.tabCmd = this.tabCmd.filter(function (cmd) {
          return cmd.Client === client;
        });
        this.tabFact = this.tabFact.filter(function (cmd) {
          return cmd.Client === client;
        });
      } else if (numeroBC) {
        this.tabCmd = this.tabCmd.filter(function (cmd) {
          return cmd.Numero === numeroBC;
        });
      } else if (numeroFact) {
        this.tabFact = this.tabFact.filter(function (cmd) {
          return cmd.Numero === numeroFact;
        });
      }
    },
    filterInput(event) {
      event.preventDefault();
      const inputValue = event.target.value;
      const filteredValue = inputValue.replace(/[,/.]/g, "");
      event.target.value = parseInt(filteredValue);
      if (inputValue == "") {
        event.target.value = parseInt(0);
      }
    },
    nextIndex() {
      if (this.currentIndex == 0) {
        if (this.selectPaiement.every((value) => value === true)) {
          this.showNextContent = false;
          this.currentIndex++;
        } else {
          this.toast.warning("Veuillez valider toutes les transactions !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.showNextContent = false;
        this.currentIndex++;
      }
    },
    resetTabCash() {
      this.qtyPerSpecies = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    },
    fermetureCaisse() {
      this.showFermeture = true;
      if (this.tabCmdTemp.length > 0) {
        this.clickCount = 0;
        this.tabCmd = this.tabCmdTemp;
      }
      if (this.tabFactTemp.length > 0) {
        this.clickCount = 0;
        this.tabFact = this.tabFactTemp;
      }
    },
    async createCaisse() {
      var numberCaisse = 0;
      var totalCaisse = 0.0;
      if (this.totalCashHandInput != 0.0) {
        totalCaisse = this.totalCashHandInput;
      } else {
        totalCaisse = this.setTotalCash;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/caisse/getLastNumber`, {
          uid: this.user.proprietaire,
        })
        .then((response) => {
          console.log("numéro : ", response.data);
          numberCaisse = response.data + 1;
          this.isLoading = true;
        });
      axios
        .post(`${process.env.VUE_APP_API}/caisse`, {
          date: this.dateCaisse,
          user: this.user.username,
          plateform: this.user.proprietaire,
          number: numberCaisse,
          dataBC: this.tabCmd,
          dataFact: this.tabFact,
          payment: [
            {
              total: this.modePaiement[0].total,
              approve: this.selectPaiement[0],
              image: this.modePaiement[0].image,
              type: this.modePaiement[0].type,
              action: this.modePaiement[0].action,
            },
            {
              total: this.modePaiement[1].total,
              approve: this.selectPaiement[1],
              image: this.modePaiement[1].image,
              type: this.modePaiement[1].type,
              action: this.modePaiement[1].action,
            },
            {
              total: this.modePaiement[2].total,
              approve: this.selectPaiement[2],
              image: this.modePaiement[2].image,
              type: this.modePaiement[2].type,
              action: this.modePaiement[2].action,
            },
            {
              total: this.modePaiement[3].total,
              approve: this.selectPaiement[3],
              image: this.modePaiement[3].image,
              type: this.modePaiement[3].type,
              action: this.modePaiement[3].action,
            },
            {
              total: this.modePaiement[4].total,
              approve: this.selectPaiement[4],
              image: this.modePaiement[4].image,
              type: this.modePaiement[4].type,
              action: this.modePaiement[4].action,
            },
            {
              total: this.modePaiement[5].total,
              approve: this.selectPaiement[5],
              image: this.modePaiement[5].image,
              type: this.modePaiement[5].type,
              action: this.modePaiement[5].action,
            },
            {
              total: this.modePaiement[6].total,
              approve: this.selectPaiement[6],
              image: this.modePaiement[6].image,
              type: this.modePaiement[6].type,
              action: this.modePaiement[6].action,
            },
          ],
          cash: this.setTotalByPiece,
          totalCash: totalCaisse,
          totalToday: this.getTotalAmount,
          totalCashed: this.totalEncaisse,
          totalReal: this.totalReel,
          amountLeft: {
            date: this.dateCaisse,
            amount: parseFloat(this.amountLeft).toFixed(2),
          },
        })
        .then(async () => {
          this.showNextContent = false;
          this.showFermeture = false;
          this.currentIndex = 0;
          this.isLoading = false;
          await this.toast.success("Caisse enregistrée !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          createEcheancier(this.tabCmd.concat(this.tabFact), {
            date: this.dateCaisse,
            user: this.user.username,
            plateform: this.user.proprietaire,
            number: numberCaisse,
            dataBC: this.tabCmd,
            dataFact: this.tabFact,
            payment: [
              {
                total: this.modePaiement[0].total,
                approve: this.selectPaiement[0],
                image: this.modePaiement[0].image,
                type: this.modePaiement[0].type,
                action: this.modePaiement[0].action,
              },
              {
                total: this.modePaiement[1].total,
                approve: this.selectPaiement[1],
                image: this.modePaiement[1].image,
                type: this.modePaiement[1].type,
                action: this.modePaiement[1].action,
              },
              {
                total: this.modePaiement[2].total,
                approve: this.selectPaiement[2],
                image: this.modePaiement[2].image,
                type: this.modePaiement[2].type,
                action: this.modePaiement[2].action,
              },
              {
                total: this.modePaiement[3].total,
                approve: this.selectPaiement[3],
                image: this.modePaiement[3].image,
                type: this.modePaiement[3].type,
                action: this.modePaiement[3].action,
              },
              {
                total: this.modePaiement[4].total,
                approve: this.selectPaiement[4],
                image: this.modePaiement[4].image,
                type: this.modePaiement[4].type,
                action: this.modePaiement[4].action,
              },
              {
                total: this.modePaiement[5].total,
                approve: this.selectPaiement[5],
                image: this.modePaiement[5].image,
                type: this.modePaiement[5].type,
                action: this.modePaiement[5].action,
              },
              {
                total: this.modePaiement[6].total,
                approve: this.selectPaiement[6],
                image: this.modePaiement[6].image,
                type: this.modePaiement[6].type,
                action: this.modePaiement[6].action,
              },
            ],
            cash: this.setTotalByPiece,
            totalCash: totalCaisse,
            totalToday: this.getTotalAmount,
            totalCashed: this.totalEncaisse,
            totalReal: this.totalReel,
            amountLeft: {
              date: this.dateCaisse,
              amount: parseFloat(this.amountLeft).toFixed(2),
            },
          });
          this.clearcaisse();
          this.fermerCaisse();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    ouvrirLaCaisse() {
      this.ouvrirCaisse();
      this.$router.push("/actionclient");
    },
  },
  mounted() {
    this.getCmdFact();
    this.getLastCaisse();
  },
};
</script>

<style>
.tab-list:hover:nth-child(odd) {
  background-color: rgb(177, 203, 255);
  /* opacity: 0.6; */
}

.tab-list:hover:nth-child(even) {
  background-color: rgb(177, 203, 255);
  /* opacity: 0.6; */
}

.mode-space-x {
  margin-left: 2rem;
}

.mode-grid-cols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}

.mode-grid-cols-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.container {
  overflow: hidden;
}

.content {
  display: flex;
  transition: transform 0.3s ease-out;
}

.slide-left {
  transform: translateX(-100%);
}
</style>
