script
<template>
  <div>
    <div
      v-if="showFile"
      :class="`modal ${
        !showFile && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="closeImport()"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Prix net à importer</p>
            <div class="z-50 cursor-pointer modal-close" @click="closeImport()">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div
            class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
            v-show="showFileContent"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-vk"
                    >
                      Référence
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-vk"
                    >
                      Prix_achat
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-vk"
                      v-for="(confCond, indexCC) in configurationConditionList"
                      :key="indexCC"
                    >
                      <div class="flex justify-between">
                        <span class="my-auto">{{ confCond.Name }}</span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                  <tr v-for="(readCondi, index) in readFile" :key="index">
                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                    >
                      {{ readCondi[0] }}
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
                    >
                      <!-- <span class="w-1/4">{{ readCondi[1] }}</span> -->
                      <input
                        type="text"
                        class="w-56"
                        v-model="readFile[index][1]"
                      />
                    </td>

                    <td
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
                    >
                      <input
                        type="text"
                        class="w-56"
                        v-model="readFile[index][2]"
                      />
                    </td>

                    <td
                      v-for="(confCond, indexCC) in configurationConditionList"
                      :key="indexCC"
                      class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
                    >
                      <input
                        type="text"
                        class="w-56"
                        v-model="readFile[index][3 + indexCC]"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="flex justify-end mt-4 mb-2"
            v-show="showFileContent && !importLoading"
          >
            <button
              class="p-2 bg-blue-500 text-white rounded"
              @click="importerFile()"
            >
              Importer
            </button>
          </div>
          <div v-show="importLoading">
            <div class="animate-pulse">Importation en cours...</div>
          </div>
          <div v-show="showFinished">
            <div>Importation réussie !</div>
            <div class="flex justify-end">
              <button
                class="p-2 bg-blue-500 text-white rounded"
                @click="closeImport()"
              >
                Fermer
              </button>
            </div>
          </div>

          <!-- End Body -->
        </div>
      </div>
    </div>

    <!-- modal choix client -->
    <div
      v-if="showClientPrix"
      :class="`modal ${
        !showClientPrix && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showClientPrix = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 mx-auto bg-white rounded shadow-lg modal-container max-h-128 max-w-8xl"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between space-x-2 pb-3">
            <p class="text-2xl font-bold">
              Choisir les clients à appliquer les prix net de la colonne
              {{ detailPosition }}
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showClientPrix = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <select
            class="focus:outline-2 focus:outline-sky-300 text-black p-2 border border-gray-800 rounded-md shadow"
            v-model="detailClientPrixNet[detailPosition][0].applyForAll"
            @change="
              saveChangeClient(
                detailClientPrixNet[detailPosition][0]._id,
                detailClientPrixNet[detailPosition][0].applyForAll
              )
            "
          >
            <option :value="true">
              Appliqués les prix net à tous les clients
            </option>
            <option :value="false">Personnalisé</option>
          </select>
          <div
            class="flex mt-6 space-x-4"
            v-if="detailClientPrixNet[detailPosition][0].applyForAll == false"
          >
            <div class="w-full">
              <input
                v-model="inputClient"
                placeholder="Clients"
                list="list"
                class="w-full focus:outline-2 focus:outline-sky-300 text-black p-2 border border-gray-800 rounded-md shadow"
                @click="inputClient = ''"
                @keyup.enter="ajouterClient(detailPosition)"
              />
              <datalist id="list">
                <option
                  v-for="(c, index) in Clients"
                  :key="index"
                  :value="c.Name"
                ></option>
              </datalist>
            </div>
            <button
              title="Valider"
              @click="ajouterClient(detailPosition)"
              class="material-icons-outlined m-auto bg-green-400 hover:bg-green-300 text-white rounded-md shadow"
            >
              navigate_next
            </button>
          </div>

          <div
            v-if="detailClientPrixNet[detailPosition][0].applyForAll == false"
          >
            <div
              class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
            >
              <div
                class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
              >
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Client
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        _id
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    class="bg-white"
                    v-if="detailClientPrixNet[detailPosition].length > 0"
                  >
                    <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
                    <tr
                      v-for="(cl, index) in detailClientPrixNet[detailPosition]"
                      :key="index"
                    >
                      <td
                        v-if="cl.clientID != ''"
                        class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                      >
                        {{ cl.clientName }}
                      </td>

                      <td
                        v-if="cl.clientID != ''"
                        class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
                      >
                        {{ cl.clientID }}
                      </td>

                      <td
                        v-if="cl.clientID != ''"
                        class="w-12 px-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="flex flex-row items-center space-x-1">
                          <button
                            class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                            @click="deleteClient(cl)"
                          >
                            <span
                              class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                              style="font-size: small"
                            >
                              clear
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- <div
            class="flex justify-end mt-4 mb-2"
            v-if="fournisseurTab.choixPrixNet[0].applyForAll == false"
          >
            <button
              class="p-2 bg-blue-500 text-white rounded"
              @click="saveChange(), (showClientPrix = false)"
            >
              Valider
            </button>
          </div> -->

          <!-- End Body -->
        </div>
      </div>
    </div>

    <div class="flex justify-between">
      <div
        id="retour"
        class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit h-fit hover:bg-white border border-blue-400 cursor-pointer"
        @click="back()"
      >
        <img src="../assets/RetourIcon.png" class="w-6" />
        <span style="color: #5976e8" class="m-auto">Retour</span>
      </div>

      <div class="text-2xl font-medium ml-2">
        <h2 class="space-x-2">
          <span>Prix net</span>
          <span class="text-green-600"> {{ fournisseurTab.CompanyName }} </span>
          <span class="text-orange-600">
            {{ fournisseurTab.Prefixe_tarif }}
          </span>
        </h2>
      </div>
      <div class="flex justify-end mt-2 space-x-2">
        <div>
          <label
            id="dropzone-file"
            @click="exportTemplate()"
            title="Télécharger un template Excel"
            class="cursor-pointer bg-orange-600 hover:bg-orange-500 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
          >
            <span class="m-auto">Template</span>
            <span class="material-icons-outlined m-auto">download</span>
          </label>
        </div>
        <div>
          <label
            id="dropzone-file"
            title="Importer Excel"
            class="cursor-pointer bg-emerald-700 hover:bg-emerald-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
          >
            <span class="m-auto">Importer Excel</span>
            <span class="material-icons-outlined m-auto">file_upload</span>
            <input
              id="dropzone-file"
              type="file"
              class="hidden"
              accept=".xlsx"
              @change="readExcel()"
            />
          </label>
        </div>
        <div v-if="!loadingExport">
          <label
            id="dropzone-file"
            @click="exportExcel()"
            title="Exporter Excel"
            class="cursor-pointer bg-sky-700 hover:bg-sky-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
          >
            <span class="m-auto">Exporter Excel</span>
            <span class="material-icons-outlined m-auto">file_download</span>
          </label>
        </div>
        <div v-else>
          <button
            class="bg-sky-700 hover:bg-sky-600 text-white shadow-md px-2 py-1 flex justify-between rounded-md"
          >
            <PulseLoader color="#ffff" class="mr-2" />
            <span>En cours </span>
          </button>
        </div>
      </div>
    </div>

    <searchInput
      placeholder="Rechercher dans prix net fournisseur"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
      class="mt-2"
    />

    <div>
      <input
        v-on:keyup.enter="getOneArticle()"
        v-model="articleSearch"
        placeholder="Entrer un code article"
        class="focus:outline-2 outline-sky-300 mt-4 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-l rounded-r focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
      />
      <ul
        v-show="showArticleList"
        class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <!-- Table pour trouver un produit -->
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Réf./Equipe.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Descr./Fourn.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Stock
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Prix
              </th>
              <th
                class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                <button class="cursor-pointer" @click="showArticleList = false">
                  <span
                    class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25"
                    >close</span
                  >
                </button>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white" v-if="searchTab.length > 0">
            <tr
              v-for="(csArticle, index) in searchTab"
              :key="index"
              @click="handlerArticle(csArticle.article)"
              class="bg-blue-100 hover:bg-opacity-25 cursor-pointer"
              style="max-height: 10px"
            >
              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ csArticle.article.Ref_fournisseur }}
                </div>
                <div class="text-sm leading-5 text-gray-900">
                  {{ csArticle.article.Code_marque }}
                </div>
              </td>

              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-500">
                  {{ csArticle.article.Description }}
                </div>
                <div
                  class="text-sm leading-5 bg-blue-500 text-white p-1 rounded w-fit"
                >
                  {{ csArticle.article.Prefixe_tarif }}
                </div>
              </td>

              <td class="px-6 border-b border-gray-200 whitespace-nowrap">
                <div class="flex flex-col">
                  <span v-if="csArticle.stockData.length > 0">
                    {{ csArticle.stockData[0].stock }}
                  </span>
                  <span v-else>0</span>
                  <span
                    v-if="
                      csArticle.stockData.length > 0 &&
                      csArticle.stockData[0].reservedClient &&
                      csArticle.stockData[0].reservedClient != ''
                    "
                  >
                    Réservé: {{ csArticle.stockData[0].reservedClient }}
                  </span>
                </div>
              </td>

              <td
                class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              >
                <!-- <span v-if="csArticle.article.Prix_ppc"
                        >{{ financial(csArticle.article.Prix_ppc) }} €</span
                      >
                      <span v-else
                        >{{ financial(csArticle.article.Prix_euro) }} €</span
                      > -->
                <div class="flex flex-row space-x-2 items-center">
                  <span class="text-xs line-through"
                    >{{ financial(csArticle.prix.prix_brut) }} €</span
                  >
                  <span>{{ financial(csArticle.prix.prix_net) }} €</span>
                  <span
                    class="p-1 text-xs bg-orange-500 text-white rounded shadow animate-pulse"
                    v-if="csArticle.prix.promo"
                    >Promos</span
                  >
                </div>
              </td>
              <td
                class="w-12 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
              ></td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
              ></th>
            </tr>
          </thead>
        </table>
        <!-- Fin Table pour trouver un produit -->
      </ul>
      <div>
        <div class="flex flex-col mt-2">
          <div
            class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full"
            >
              <table class="min-w-full" style="height: 70vh" ref="table">
                <thead class="tableHEADTR">
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Réf./Equipe
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Descr./Fourn.
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Code/Famille
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      PV
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Prix net
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      v-for="(confCond, indexCC) in configurationConditionList"
                      :key="indexCC"
                    >
                      <div class="flex justify-between">
                        <span class="my-auto">{{ confCond.Name }}</span>

                        <span
                          :title="'Sélectionner les clients ' + confCond.Name"
                          class="material-icons-outlined m-auto bg-cyan-500 hover:bg-cyan-400 text-white rounded-full p-1 mr-5 cursor-pointer w-6 h-6"
                          style="font-size: 17px"
                          @click="setTabClient(confCond.Position)"
                          >checklist</span
                        >
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white tableBODY" style="height: 70vh">
                  <tr
                    v-for="(articleAct, index) in tabArticles"
                    :key="index"
                    class="tableHEADTR text-blue-700 font-medium"
                  >
                    <td
                      class="flex flex-col px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <span>{{ articleAct.Ref_fournisseur }}</span>
                      <span>{{ articleAct.Code_marque }}</span>
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5">
                        {{ articleAct.Description }}
                      </div>
                      <div class="">
                        <span class="text-orange-600">{{
                          articleAct.Prefixe_tarif
                        }}</span>
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5">
                        {{ articleAct.Code_remise }}
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="text-sm leading-2">
                        <span class="cursor-pointer"
                          ><input
                            disabled
                            class="focus:outline-2 outline-sky-300 p-1 w-2/5 rounded-md"
                            type="Number"
                            v-model="articleAct.Prix_euro"
                          />
                          €</span
                        >
                      </div>
                    </td>

                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex space-x-2">
                        <div class="font-medium">
                          <input
                            type="Number"
                            class="focus:outline-2 outline-sky-300 p-1 w-2/5 border border-gray-500 rounded-md font-medium"
                            v-model="paPrixNet[index]"
                            @keydown.enter="
                              $refs['conditionC' + 0 + index][0].focus()
                            "
                          />
                          €
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                      v-for="(confCond, indexCC) in configurationConditionList"
                      :key="indexCC"
                    >
                      <div class="m-auto">
                        <input
                          class="w-4/6 focus:outline-2 outline-sky-300"
                          type="Number"
                          :ref="'conditionC' + indexCC + index"
                          v-model="addConfigCondition[indexCC]"
                          v-on:keyup.enter="
                            followIndexAndCreate(
                              index,
                              indexCC,
                              articleAct,
                              paPrixNet[index]
                            )
                          "
                        />
                        <span class="m-auto">€</span>
                      </div>
                    </td>

                    <td
                      class="w-12 px-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-col items-center space-y-1">
                        <button
                          class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                          @click="clearTabArticles(index)"
                        >
                          <span
                            class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                            style="font-size: small"
                          >
                            clear
                          </span>
                        </button>
                        <button
                          class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                          @click="
                            createPrixNetFourn(
                              articleAct,
                              paPrixNet[index],
                              index
                            )
                          "
                        >
                          <span
                            class="material-icons-round bg-green-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                            style="font-size: small"
                          >
                            done
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>

                  <!-- tabPrixNetFourn -->
                  <tr
                    ref="tablebody"
                    v-for="(tabSaved, index) in tabPrixNetFourn"
                    :key="index"
                    class="tableHEADTR"
                  >
                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <span>{{ tabSaved.Ref_fournisseur }}</span>
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <!-- <div class="">
                        <span class="text-orange-600">{{
                          tabSaved.Prefixe_tarif
                        }}</span>
                      </div> -->
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5"></div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    ></td>

                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="m-auto">
                        <input
                          type="Number"
                          :ref="'pa' + index"
                          class="focus:outline-2 outline-sky-300 p-1 w-2/5 border border-gray-500 rounded-md text-gray-900 font-medium"
                          v-model="tabSaved.Prix_achat"
                          @change="
                            updatePrixNetFourn(
                              tabSaved,
                              tabSaved.Prix_achat,
                              tabSaved.conditions
                            )
                          "
                          @keydown.enter="
                            $refs['condition' + 0 + index][0].focus()
                          "
                        />
                        <span class="m-auto">€</span>
                      </div>
                    </td>

                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                      v-for="(cond, indexC) in tabSaved.conditions"
                      :key="indexC"
                    >
                      <div class="m-auto">
                        <input
                          class="w-4/6 focus:outline-2 outline-sky-300"
                          type="Number"
                          :ref="'condition' + indexC + index"
                          v-model="tabSaved.conditions[indexC]"
                          @change="
                            updatePrixNetFourn(
                              tabSaved,
                              tabSaved.Prix_achat,
                              tabSaved.conditions
                            )
                          "
                          @keydown.enter="
                            $refs['condition' + (indexC + 1) + index][0].focus()
                          "
                        />
                        <span class="m-auto">€</span>
                      </div>
                    </td>

                    <td
                      class="w-12 px-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center space-x-1">
                        <span
                          class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                          style="cursor: pointer"
                          @click="
                            tabPrixNetFourn.splice(index, 1),
                              deletePrixNetFourn(tabSaved)
                          "
                        >
                          <span
                            class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                            style="font-size: small"
                          >
                            clear
                          </span>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <pageNumber
                :pageStart="pageStart"
                :pageNext="pageNext"
                :tabList="tabPrixNetFournLength"
                :pageTab="pageTab"
                :pageTotal="Math.ceil(tabPrixNetFournLength / 10)"
                :clickFunction="
                  (arg1, arg2) => getPrixNetFournisseur(arg1, arg2)
                "
                :currentPage="currentPage"
                :typeList="'prix net'"
                @update:start="pageStart = $event"
                @update:tab="pageTab = $event"
                @update:next="pageNext = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import { searchArticles } from "@/hooks/searchEngine/articles";
import searchInput from "@/components/searchInput.vue";
import pageNumber from "@/components/pageNumber.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";
import {
  createClientPrixNet,
  getClientPrixNet,
  saveClientPrixNet,
  removeClientPrixNet,
} from "@/hooks/articles/prixNetFournisseur";
import { success, warning, error } from "@/hooks/tools/toast";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showArticleList: false,
      tabArticles: [],
      articleSearch: "",
      showPA: [],
      showMarge: [],
      paPrixNet: [],
      t1PrixNet: [],
      t2PrixNet: [],
      t3PrixNet: [],
      file: null,
      readFile: null,
      showFile: false,
      showFileContent: true,
      showClientPrix: false,
      importLoading: false,
      showFinished: false,
      inputClient: "",
      tabClient: [],
      Clients: [],
      searchTab: [],
      tabPrixNetFourn: [],
      applyPrixNet: false,
      clientPNT1: [],
      clientPNT2: [],
      clientPNT3: [],
      tabPrixNetFournDetail: [],
      loadingExport: false,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      tabPrixNetFournLength: 0,

      addConfigCondition: [],
      configurationConditionList: [],

      fournisseurTab: {},
      detailClientPrixNet: [],
      detailPosition: null,
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "plateform",
      "fournisseurID",
      "comptoirSessionActive",
    ]),
  },
  components: {
    searchInput,
    pageNumber,
    PulseLoader,
  },
  methods: {
    ...mapActions([
      "searchTranslatedDirect",
      "getfournisseurID",
      "getfournisseurIDprixnet",
      "getfournisseurIDprixnetsaved",
    ]),
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    back() {
      this.$router.back();
    },
    // Récupérer les prix net
    async getAllPrixNet() {
      await axios
        .post(`${process.env.VUE_APP_API}/prixNetFournisseur/getAllDetail`, {
          plateform: this.user.proprietaire,
          Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        })
        .then((response) => {
          console.log("response prixNet : ", response);
          this.tabPrixNetFournDetail = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Récupérer les prix net
    getPrixNetFournisseur(skipValue, limitValue) {
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/prixNetFournisseur/getall`, {
          plateform: this.user.proprietaire,
          Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log("response prixNet : ", response);
          this.tabPrixNetFourn = response.data.data;
          this.tabPrixNetFournLength = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Créer les prix net
    async createPrixNetFourn(data, pa, index) {
      for (let [index, cond] of this.addConfigCondition.entries()) {
        this.addConfigCondition[index] = parseInt(cond);
      }
      var ligne = {
        plateform: this.user.proprietaire,
        Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        Ref_fournisseur: data.Ref_fournisseur,
        Prix_achat: pa,
        conditions: this.addConfigCondition,
      };
      await axios.post(`${process.env.VUE_APP_API}/prixNetFournisseur`, ligne);
      this.tabPrixNetFourn.unshift(ligne);
      this.clearTabArticles(index);
    },
    followIndexAndCreate(index, indexCC, articleAct, prixAchat) {
      if (indexCC == this.configurationConditionList.length - 1) {
        this.createPrixNetFourn(articleAct, prixAchat, index);
      } else {
        this.$refs["conditionC" + (indexCC + 1) + index][0].focus();
      }
    },
    // Modifier les prix net
    async updatePrixNetFourn(data, pa, cond) {
      await axios.post(`${process.env.VUE_APP_API}/prixNetFournisseur/update`, {
        plateform: this.user.proprietaire,
        Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        Ref_fournisseur: data.Ref_fournisseur,
        Prix_achat: pa,
        // T1: t1,
        // T2: t2,
        // T3: t3,
        conditions: cond,
      });
    },
    // Supprimer les prix net
    deletePrixNetFourn(tab) {
      axios.post(`${process.env.VUE_APP_API}/prixNetFournisseur/delete`, {
        plateform: this.user.proprietaire,
        Prefixe_tarif: tab.Prefixe_tarif,
        Ref_fournisseur: tab.Ref_fournisseur,
      });
    },
    clearTabArticles(index) {
      this.tabArticles.splice(index, 1);
      this.paPrixNet[index] = "";
      this.t1PrixNet[index] = "";
      this.t2PrixNet[index] = "";
      this.t3PrixNet[index] = "";
    },
    async getOneArticle() {
      this.showArticleList = true;
      if (this.articleSearch != "") {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        this.searchTab = await searchArticles(
          this.articleSearch,
          plateform_mere,
          this.comptoirSessionActive[0].Client[0].Name,
          this.comptoirSessionActive[0].Client[0].Group.Name
        );
        this.articleSearch = "";
      } else {
        this.showArticleList = false;
        this.articleSearch = "";
      }
    },
    async handlerArticle(data) {
      if (data.Prefixe_tarif == this.fournisseurTab.Prefixe_tarif) {
        var existArticle = false;
        var existArticleSaved = false;
        for (let i = 0; i < this.tabArticles.length; i++) {
          if (
            this.tabArticles[i].Ref_fournisseur == data.Ref_fournisseur &&
            this.tabArticles[i].Prefixe_tarif == data.Prefixe_tarif
          ) {
            existArticle = true;
            break;
          }
        }
        for (let i = 0; i < this.tabPrixNetFourn.length; i++) {
          if (
            this.tabPrixNetFourn[i].Ref_fournisseur == data.Ref_fournisseur &&
            this.tabPrixNetFourn[i].Prefixe_tarif == data.Prefixe_tarif
          ) {
            existArticleSaved = true;
            break;
          }
        }
        if (existArticle == false && existArticleSaved == false) {
          this.tabArticles.unshift(data);
        } else if (existArticle == true) {
          this.toast.warning("Référence déjà choisie !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else if (existArticleSaved == true) {
          this.toast.warning("Référence déjà enregistrée !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          //     document.getElementById(
          //   `prixNetSaved${indexSaved}`
          // ).style.animationDuration = "10s";
        }
      } else {
        this.toast.warning("Préfixe tarif incorrect !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }

      this.showArticleList = false;
      this.articleSearch = "";
    },
    async saveChange() {
      await axios.put(
        `${process.env.VUE_APP_API}/fournisseur/modifyFournisseur/${this.fournisseurTab._id}`,
        {
          data: this.fournisseurTab,
        }
      );
      await this.getFournisseur();
    },
    async saveChangeClient(id, data) {
      await saveClientPrixNet({ _id: id, applyForAll: data });
      await this.getTabClient(this.detailPosition);
    },
    async deleteClient(cl) {
      await removeClientPrixNet(cl);
      await this.getTabClient(this.detailPosition);
      // this.detailClientPrixNet[this.detailPosition] = await getClientPrixNet({
      //   plateform: this.user.proprietaire,
      //   Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
      //   Code_marque: this.fournisseurTab.CompanyName,
      //   Position: this.detailPosition,
      // });
    },
    getClientsPro() {
      axios
        .get(`${process.env.VUE_APP_API}/client/${this.user.proprietaire}`)
        .then((response) => {
          this.Clients = response.data;
        });
    },
    async ajouterClient(tab) {
      const clientSelectionne = this.Clients.find(
        (client) => client.Name === this.inputClient
      );

      if (clientSelectionne) {
        await this.createTabClient(
          tab,
          false,
          clientSelectionne._id,
          clientSelectionne.Name
        );
      }

      this.inputClient = "";
    },
    async setTabClient(tab) {
      console.log("COUNT SET TAB CLIENT : ", 1);
      this.detailPosition = tab;

      await this.getTabClient(tab);
      this.getClientsPro();

      this.showClientPrix = true;
      this.inputClient = "";
    },
    async getTabClient(tab) {
      let response = [];
      response = await getClientPrixNet({
        plateform: this.user.proprietaire,
        Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        Code_marque: this.fournisseurTab.CompanyName,
        Position: tab,
      });

      if (response.length == 0) {
        // this.detailClientPrixNet[tab].applyForAll = true;
        await this.createTabClient(tab, true, "", "");
        response = await getClientPrixNet({
          plateform: this.user.proprietaire,
          Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
          Code_marque: this.fournisseurTab.CompanyName,
          Position: tab,
        });
      }

      this.detailClientPrixNet[tab] = response;
    },
    async createTabClient(tab, applyForAll, clientID, clientName) {
      const message = await createClientPrixNet({
        plateform: this.user.proprietaire,
        Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        Code_marque: this.fournisseurTab.CompanyName,
        Position: tab,
        applyForAll: applyForAll,
        clientID: clientID,
        clientName: clientName,
      });
      if (message != "") {
        success(message);
      }
      this.detailClientPrixNet[tab] = await getClientPrixNet({
        plateform: this.user.proprietaire,
        Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        Code_marque: this.fournisseurTab.CompanyName,
        Position: tab,
      });
    },
    async getConfigurationCondition() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.configurationConditionList = condition.data;
      for (const conf of this.configurationConditionList) {
        this.addConfigCondition.push("");
      }
    },
    async readExcel() {
      this.file = event.target.files ? event.target.files[0] : null;
      this.readFile = await readXlsxFile(this.file);
      this.readFile.splice(0, 1);
      this.showFile = true;
    },
    closeImport() {
      this.showFile = false;
      this.readFile = null;
      this.file = null;
      this.showFinished = false;
      this.showFileContent = true;
    },
    importerFile() {
      this.importLoading = true;
      axios
        .post(`${process.env.VUE_APP_API}/prixNetFournisseur/importExcel`, {
          data: this.readFile,
          plateform: this.user.proprietaire,
          Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        })
        .then((response) => {
          console.log("response excel :");
          console.log(response.data);
          // response.data.forEach((element) => {
          //  this.tabPrixNetFourn.unshift(element);
          // });
          this.getPrixNetFournisseur(1, 10);
          this.importLoading = false;
          this.showFinished = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportTemplate() {
      const dataToExport = [
        {
          Ref_fournisseur: "",
          Prix_achat: "",
          ...this.configurationConditionList.reduce((acc, current) => {
            acc[current.Name] = "";
            return acc;
          }, {}),
        },
      ];

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(dataToExport);
      XLSX.utils.book_append_sheet(wb, ws, "prixNet");

      XLSX.writeFile(wb, "PrixNetFournisseurTemplate" + ".xlsx");
    },
    async exportExcel() {
      this.loadingExport = true;
      await this.getAllPrixNet();

      let wb = XLSX.utils.book_new();
      console.log(this.tabPrixNetFournDetail);
      let ws = XLSX.utils.json_to_sheet(this.tabPrixNetFournDetail);
      XLSX.utils.book_append_sheet(wb, ws, "Prix Net");

      XLSX.writeFile(
        wb,
        "PrixNetFournisseur_" +
          this.user.proprietaire +
          "_" +
          this.fournisseurTab.CompanyName +
          ".xlsx"
      );
      this.loadingExport = false;

      this.toast.success("Le fichier a été téléchargé", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getPrixNetFournisseur(skipValue, limitValue);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/prixNetFournisseur/searchInPlateform`,
            {
              plateform: this.user.proprietaire,
              Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 10,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.tabPrixNetFourn = response.data.results;
            this.tabPrixNetFournLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  async mounted() {
    await this.getFournisseur();
    this.getConfigurationCondition();
    this.getPrixNetFournisseur(1, 10);
    console.log(this.fournisseurTab);
    this.pageSwitch();
  },
};
</script>

<style scoped>
.bg-vk {
  background-color: #2262b3;
}
</style>
