<template>
  <div
    class="flex flex-row justify-between border-b border-bluevk p-2 cursor-pointer"
  >
    <div class="text-bluevk text-xl font-medium">{{ titre }}</div>
    <div class="flex flex-row items-center space-x-1">
      <!-- <span
        class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300"
        >En stock</span
      >
      <span
        class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
        >Commandable</span
      > -->
    </div>
  </div>
</template>
<script>
export default {
  name: "sectionTitle",
  props: {
    titre: {
      type: String,
      default: "Famille",
    },
  },
};
</script>
<style></style>
