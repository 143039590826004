<template>
  <div
    id="retour"
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.push('/articlehub')"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>
  <div>
    <div class="flex flex-row items-center justify-between">
      <span></span>
      <button
        class="p-1 rounded shadow bg-blue-500 text-white"
        @click="$router.push('/initInventaire')"
      >
        Démarrer un inventaire
      </button>
    </div>
    <div class="flex flex-row items-center mt-4 space-x-2">
      <button
        class="p-1 rounded shadow bg-orange-500 text-white"
        @click="(initialState = 'En cours'), getInventaire()"
      >
        En cours
      </button>
      <button
        class="p-1 rounded shadow bg-red-500 text-white"
        @click="(initialState = 'En erreur'), getInventaire()"
      >
        En erreur
      </button>
      <button
        class="p-1 rounded shadow bg-green-500 text-white"
        @click="(initialState = 'Terminé'), getInventaire()"
      >
        Terminé
      </button>
    </div>
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Numéro
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Adresse/marque
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Initié par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Débuté le
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Etat
                </th>

                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                class="cursor-pointer"
                v-for="(u, index) in inventaire"
                :key="index"
                @click="setInventaire(u)"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.Numero }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  v-if="u.marque"
                >
                  {{ u.adresse }}/{{ u.marque }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  v-else
                ></td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Initiator }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ getFRDate(u.Date) }}
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                >
                  <span v-if="u.etat == 'En cours'" class="text-orange-600">{{
                    u.etat
                  }}</span>
                  <span
                    v-else-if="u.etat == 'Terminé'"
                    class="text-green-600"
                    >{{ u.etat }}</span
                  >
                  <span
                    v-else-if="u.etat == 'En erreur'"
                    class="text-red-600"
                    >{{ u.etat }}</span
                  >
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-center">
                    Nbr article en stock : 1
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="flex justify-center">Nbr de réf. : 1</div>
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { getFrDate } from "@/hooks/tools/date.ts";

export default {
  data() {
    return {
      inventaire: [],
      initialState: "En cours",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setinventoryprocessing", "removeinventoryprocessing"]),
    getFRDate(date) {
      return getFrDate(date);
    },
    getInventaire() {
      axios
        .post(
          `${process.env.VUE_APP_API}/inventaire/getAll/${this.user.proprietaire}`,
          { etat: this.initialState }
        )
        .then((res) => {
          this.inventaire = res.data;
        });
    },
    startInventaire() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      axios
        .post(`${process.env.VUE_APP_API}/inventaire`, {
          plateform: this.user.proprietaire,
          Date: today,
          Initiator: this.user.username,
          etat: "En cours",
          ligne: [],
        })
        .then((res) => {
          this.setinventoryprocessing(res.data);
          this.$router.push("/inventaireStock");
        });
    },
    setInventaire(u) {
      if (this.initialState == "En cours") {
        this.setinventoryprocessing(u);
        this.$router.push("/inventaireStock");
      } else if (this.initialState == "Terminé") {
        this.setinventoryprocessing(u._id);
        this.$router.push("/inventaireRecap");
      }
    },
  },
  mounted() {
    this.getInventaire();
  },
};
</script>
<style></style>
