<template>
    <button
      class="bg-orangevk hover:bg-orangevkdark text-white font-bold py-2 px-4 rounded"
    >
      Archive
    </button>
  </template>
  
  <script>
  export default {
    name: "archiveButton",
  };
  </script>
  
  <style></style>
  