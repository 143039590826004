import axios from "axios";

export async function getPrixAchat(
  plateform: string,
  Prefixe_tarif: string,
  Ref_fournisseur: string,
  Code_EAN: string
) {
  const PA = await axios.post(
    `${process.env.VUE_APP_API}/article/getPrixAchat`,
    {
      plateform: plateform,
      Prefixe_tarif: Prefixe_tarif,
      Ref_fournisseur: Ref_fournisseur,
      Code_EAN: Code_EAN,
    }
  );

  return PA.data;
}

export async function getPrixAchatPond(
  plateform: string,
  Prefixe_tarif: string,
  Ref_fournisseur: string,
  Code_EAN: string
) {
  const PA = await axios.post(
    `${process.env.VUE_APP_API}/article/getPrixAchatPond`,
    {
      plateform: plateform,
      Prefixe_tarif: Prefixe_tarif,
      Ref_fournisseur: Ref_fournisseur,
      Code_EAN: Code_EAN,
    }
  );

  return PA.data;
}

export async function getPrixAchatDistribution(
  plateform: string,
  Prefixe_tarif: string,
  Ref_fournisseur: string,
  Code_EAN: string
) {
  const PA = await axios.post(
    `${process.env.VUE_APP_API}/article/getPrixAchatDistribution`,
    {
      plateform: plateform,
      Prefixe_tarif: Prefixe_tarif,
      Ref_fournisseur: Ref_fournisseur,
      Code_EAN: Code_EAN,
    }
  );

  return PA.data;
}

export async function getPrixAchatSorted(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Prefixe_tarif: string
) {
  const PA = await axios.post(`${process.env.VUE_APP_API}/article/getPA`, {
    plateform: plateform,
    Prefixe_tarif: Prefixe_tarif,
    Ref_fournisseur: Ref_fournisseur,
    Code_EAN: Code_EAN,
  });

  return PA.data;
}

export async function getPAList(plateform: string, articleList: Array<object>) {
  const PA = await axios.post(`${process.env.VUE_APP_API}/article/getPAList`, {
    plateform: plateform,
    articleList: articleList,
  });

  return PA.data;
}

export async function getFournisseurList(
  plateform: string,
  Article: object,
  Client: string,
  groupeClient: string,
  quantity: number
) {
  const fournisseurList = await axios.post(
    `${process.env.VUE_APP_API}/article/getFournisseurList`,
    {
      plateform: plateform,
      Article: Article,
      Client: Client,
      groupeClient: groupeClient,
      quantity: quantity,
    }
  );

  return fournisseurList.data;
}

export async function createSaisieRapide(data: object) {
  const saisieRapide = await axios.post(
    `${process.env.VUE_APP_API}/saisieRapide`,
    data
  );

  return saisieRapide.data;
}
