<template>
  <div>
    <div class="flex justify-center grid grid-cols-3 m-auto">
      <router-link to="/teamCommerciaux">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-blue-600"
        >
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> Commerciaux </span>
              <span class="text-gray-600 pt-2">
                Chiffres des commerciaux de l'equipe
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
