import axios from "axios";

export async function transfertStock(
  plateform_provider: string,
  plateform_receiver: string,
  quantity: number,
  Ref_fournisseur: string,
  Code_EAN: string,
  Prefixe_tarif: string,
  username: string
) {
  const transfert = await axios.post(
    `${process.env.VUE_APP_API}/stock/createTransfert`,
    {
      plateform_provider: plateform_provider,
      plateform_receiver: plateform_receiver,
      quantity: quantity,
      Ref_fournisseur: Ref_fournisseur,
      Code_EAN: Code_EAN,
      Prefixe_tarif: Prefixe_tarif,
      username: username,
    }
  );

  return transfert.data;
}

export async function getTransfert(plateform: string) {
  const transfert = await axios.get(
    `${process.env.VUE_APP_API}/stock/getTransfert/${plateform}`
  );

  return transfert.data;
}

export async function getArchivedTransfert(plateform: string) {
  const transfert = await axios.get(
    `${process.env.VUE_APP_API}/stock/getArchivedTransfert/${plateform}`
  );

  return transfert.data;
}

export async function cancelTransfert(id: string) {
  const transfert = await axios.post(
    `${process.env.VUE_APP_API}/stock/cancelTransfert`,
    {
      id: id,
    }
  );

  return transfert.data;
}

export async function deleteTransfert(id: string) {
  const transfert = await axios.post(
    `${process.env.VUE_APP_API}/stock/deleteTransfert`,
    {
      id: id,
    }
  );

  return transfert.data;
}

export async function prepareFacturationTransfert(plateform_provider: string) {
  const prepareFacture = await axios.post(
    `${process.env.VUE_APP_API}/stock/prepareFacturationTransfert`,
    {
      plateform_provider: plateform_provider,
    }
  );

  return prepareFacture.data;
}

export async function createBLTransfert(
  username: string,
  plateform_provider: string,
  data: Array<object>
) {
  const createBL = await axios.post(
    `${process.env.VUE_APP_API}/stock/createBLTransfert`,
    {
      username: username,
      plateform_provider: plateform_provider,
      data: data,
    }
  );

  return createBL.data;
}

export async function createComptoirTransfert(
  articles: Array<any>,
  username: string,
  plateform_provider: string
) {
  for (const art of articles) {
    if (art.stockFiliale) {
      for (const stockF of art.stockFiliale) {
        if (stockF.transfertState) {
          await transfertStock(
            stockF.plateform,
            plateform_provider,
            stockF.stock,
            art.Ref_fournisseur,
            art.Code_EAN,
            art.Prefixe_tarif,
            username
          );
        }
      }
    }
  }
}
