<template>
  <div>
    <div class="flex flex-col" v-if="configured == false">
      <div><span>Vous n'avez aucune configuration</span></div>
      <div>
        <button
          class="p-2 rounded shadow bg-blue-500 text-white mt-2"
          @click="initConfiguration()"
        >
          Démarrer l'initialisation
        </button>
        <span v-show="stateInit">Initialisation en cours...</span>
      </div>
    </div>
    <div v-else>
      <div class="flex justify-end">
        <button
          class="p-2 bg-blue-500 text-white rounded shadow"
          @click="saveConfiguration()"
        >
          Sauvegarder
        </button>
      </div>
      <div class="flex flex-wrap mx-6" v-if="configurationTab[0]">
        <div
          v-for="(ag, index) in configurationTab[0].configuration"
          :key="index"
          @drop="onAGDrop($event, index)"
          @dragover.prevent
          @dragenter.prevent
        >
          <div
            class="p-2 rounded shadow bg-white h-fit w-80 ml-4 mt-4"
            draggable="true"
            @dragstart="startAGDrag($event, index)"
          >
            <div class="flex flex-col">
              <div class="flex flex-row items-center">
                <div class="w-4/5">
                  <div v-if="listModifName[index].state">
                    <input
                      type="text"
                      class="p-1 shadow rounded-l focus:outline-2 outline-sky-300"
                      v-model="configurationTab[0].configuration[index].newName"
                    />
                    <span
                      class="p-1 shadow rounded-r cursor-pointer"
                      @click="
                        (listModifName[index].state = false),
                          (configurationTab[0].configuration[
                            index
                          ].named = true)
                      "
                      >Ok</span
                    >
                    <span
                      class="material-icons-round cursor-pointer text-blue-500 text-base"
                      @click="
                        (listModifName[index].state = false),
                          (configurationTab[0].configuration[
                            index
                          ].named = false)
                      "
                    >
                      restart_alt
                    </span>
                  </div>
                  <div v-else>
                    <span
                      v-if="configurationTab[0].configuration[index].named"
                      @click="moveToAG(ag)"
                      class="text-xl text-gray-600 hover:text-blue-600 hover:font-semibold cursor-pointer"
                      >{{ ag.newName }}</span
                    >
                    <span
                      v-else
                      @click="moveToAG(ag)"
                      class="text-xl text-gray-600 hover:text-blue-600 hover:font-semibold cursor-pointer"
                      >{{ ag.assemblyGroupName }}</span
                    >
                  </div>
                </div>
                <span
                  class="material-icons-round mr-3 cursor-pointer text-blue-500"
                  @click="listModifName[index].state = true"
                  v-show="!listModifName[index].state"
                >
                  mode_edit
                </span>
                <div class="justify-end">
                  <span
                    class="p-1 bg-green-500 text-white text-sm cursor-pointer rounded shadow"
                    v-if="ag.actif"
                    @click="
                      configurationTab[0].configuration[index].actif = false
                    "
                  >
                    Actif
                  </span>
                  <span
                    class="p-1 w-1/5 bg-red-500 text-white text-sm cursor-pointer rounded shadow"
                    @click="
                      configurationTab[0].configuration[index].actif = true
                    "
                    v-else
                  >
                    Desactivé
                  </span>
                </div>
              </div>
              <div
                class="flex flex-row items-center"
                v-show="!listChildState[index]"
              >
                <!-- <span
                class="text-sm text-blue-400 hover:text-blue-600 cursor-pointer"
                @click="getChildAssemblyGroup(ag.assemblyGroupNodeId, index)"
                >Voir plus</span> -->
                <span
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  @click="listChildState[index] = !listChildState[index]"
                  >keyboard_double_arrow_down</span
                >
              </div>

              <div
                class="flex flex-row"
                v-show="listChildState[index]"
                v-for="(child, indexC) in ag.child"
                :key="indexC"
                @drop="onDrop($event, index)"
                @dragover.prevent
                @dragenter.prevent
              >
                <span
                  class="flex flex-row space-x-2 items-center hover:text-blue-400 hover:font-semibold"
                  draggable="true"
                  @dragstart="startDrag($event, child, index, indexC)"
                >
                  <!-- {{
                    configurationTab[0].configuration[index].child[indexC].actif
                  }} -->
                  <div
                    class="bg-green-500 p-1 rounded shadow text-white text-xs cursor-pointer"
                    v-if="
                      configurationTab[0].configuration[index].child[indexC]
                        .actif
                    "
                    @click="
                      configurationTab[0].configuration[index].child[
                        indexC
                      ].actif = false
                    "
                  >
                    actif
                  </div>
                  <div
                    class="bg-red-500 p-1 rounded shadow text-white text-xs cursor-pointer"
                    v-else
                    @click="
                      configurationTab[0].configuration[index].child[
                        indexC
                      ].actif = true
                    "
                  >
                    désac
                  </div>
                  <div v-if="listModifName[index].child[indexC]">
                    <input
                      type="text"
                      class="p-1 shadow rounded-l focus:outline-2 outline-sky-300"
                      v-model="
                        configurationTab[0].configuration[index].child[indexC]
                          .newName
                      "
                    />
                    <span
                      class="p-1 shadow rounded-r cursor-pointer text-sm"
                      @click="
                        (listModifName[index].child[indexC] = false),
                          (configurationTab[0].configuration[index].child[
                            indexC
                          ].named = true)
                      "
                      >Ok</span
                    >
                    <span
                      class="material-icons-round cursor-pointer text-blue-500 text-base"
                      @click="
                        (listModifName[index].child[indexC] = false),
                          (configurationTab[0].configuration[index].child[
                            indexC
                          ].named = false)
                      "
                    >
                      restart_alt
                    </span>
                  </div>
                  <div v-else>
                    <span
                      v-if="
                        configurationTab[0].configuration[index].child[indexC]
                          .named
                      "
                      class="text-xs cursor-pointer"
                      >{{ child.newName }}</span
                    >
                    <span v-else class="text-xs cursor-pointer">{{
                      child.assemblyGroupName
                    }}</span>
                  </div>
                </span>
                <span
                  class="material-icons-round ml-3 cursor-pointer text-blue-500 text-sm"
                  @click="listModifName[index].child[indexC] = true"
                  v-show="!listModifName[index].child[indexC]"
                >
                  mode_edit
                </span>
              </div>
              <div
                class="flex flex-row h-5"
                v-show="listChildState[index]"
                @drop="onDrop($event, index)"
                @dragover.prevent
                @dragenter.prevent
              ></div>
              <div
                class="flex flex-row items-center"
                v-show="listChildState[index]"
              >
                <span
                  class="text-sm text-blue-400 hover:text-blue-600 cursor-pointer"
                  @click="listChildState[index] = !listChildState[index]"
                  >Voir moins</span
                >
                <span
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  @click="listChildState[index] = !listChildState[index]"
                >
                  keyboard_double_arrow_up
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      configured: false,
      stateInit: false,
      configurationTab: [],
      listChildState: [],
      listModifName: [],
      childSelected: [],
      dragAGIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setagselected"]),
    getState() {
      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/getstate`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.configured = res.data;
          if (res.data) {
            this.getConfiguration();
          }
        });
    },
    initConfiguration() {
      this.stateInit = true;
      axios
        .post(
          `${process.env.VUE_APP_API}/configurationCatalogue/initconfiguration`,
          {
            plateform: this.user.proprietaire,
          }
        )
        .then((res) => {
          this.stateInit = false;
          this.configurationTab = res.data;
          this.getState();
        });
    },
    getConfiguration() {
      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/getone`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.configurationTab = res.data;
          this.initListChild();
        });
    },
    initListChild() {
      this.configurationTab[0].configuration.forEach((element, index) => {
        this.listChildState.push(false);

        var chil = [];
        for (const childy of element.child) {
          chil.push(false);
        }
        this.listModifName.push({ state: false, child: chil });
      });
    },
    startDrag(evt, item, index, indexC) {
      // evt.dataTransfer.setData("Child", item);
      this.childSelected = item;
      evt.dataTransfer.setData("indexFirst", index);
      evt.dataTransfer.setData("indexChild", indexC);
      // this.configurationTab[0].configuration[index].child.splice(indexC, 1);
    },
    startAGDrag(evt, index) {
      this.dragAGIndex = index;
      // this.childSelected = item;
      // evt.dataTransfer.setData("indexFirst", index);
      // evt.dataTransfer.setData("indexChild", indexC);
      // this.configurationTab[0].configuration[index].child.splice(indexC, 1);
    },
    onDrop(evt, index) {
      // const childData = evt.dataTransfer.getData("Child");
      const indexFirst = evt.dataTransfer.getData("indexFirst");
      const indexChild = evt.dataTransfer.getData("indexChild");
      this.configurationTab[0].configuration[indexFirst].child.splice(
        indexChild,
        1
      );
      this.configurationTab[0].configuration[index].child.push(
        this.childSelected
      );
    },
    onAGDrop(evt, index) {
      this.configurationTab[0].configuration.splice(
        index,
        0,
        this.configurationTab[0].configuration.splice(this.dragAGIndex, 1)[0]
      );
      // const indexFirst = evt.dataTransfer.getData("indexFirst");
      // const indexChild = evt.dataTransfer.getData("indexChild");
      // this.configurationTab[0].configuration[indexFirst].child.splice(
      //   indexChild,
      //   1
      // );
      // this.configurationTab[0].configuration[index].child.push(
      //   this.childSelected
      // );
    },
    moveToAG(assemblygroup) {
      this.setagselected(assemblygroup);
      this.$router.push("/configurationAssemblyGroup");
    },
    saveConfiguration() {
      axios
        .put(
          `${process.env.VUE_APP_API}/configurationCatalogue/${this.configurationTab[0]._id}`,
          {
            data: this.configurationTab[0],
          }
        )
        .then(() => {
          this.getConfiguration();
          this.toast.success("Sauvegardé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
  },
  mounted() {
    this.getState();
  },
};
</script>
<style></style>
