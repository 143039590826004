import axios from "axios";

export async function getStock(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Code_marque: string
) {
  const stock = await axios.post(
    `${process.env.VUE_APP_API}/infoArticle/stockEANArticle`,
    {
      Code_EAN: Code_EAN,
      Code_marque: Code_marque,
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
    }
  );
  return stock.data;
}

export async function getAddressArticle(plateform: string) {
  const addressArticle = await axios.post(`${process.env.VUE_APP_API}/stock/`, {
    plateform: plateform,
  });
  return addressArticle.data;
}

export async function getStockFiliale(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Code_marque: string
) {
  const filialeStock = await axios.post(
    `${process.env.VUE_APP_API}/infoArticle/stockEANArticleGroupement`,
    {
      Code_EAN: Code_EAN,
      Code_marque: Code_marque,
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
    }
  );
  return filialeStock.data;
}

export async function getStockFournisseur(
  article: object,
  Ref_fournisseur: string,
  Code_marque: string,
  Prefixe_tarif: string,
  logCode: number,
  Quantity: number,
  login: string,
  plateform: string
) {
  const fournisseurStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/getFournisseurDispo`,
    {
      article: article,
      Ref_fournisseur: Ref_fournisseur,
      Code_marque: Code_marque,
      Prefixe_tarif: Prefixe_tarif,
      logCode: logCode,
      Quantity: Quantity,
      login: login,
      plateform: plateform,
    }
  );
  return fournisseurStock.data;
}

export async function updateStock(
  Code_EAN: string,
  stock: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string,
  min: number,
  max: number
) {
  const updateStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/updateStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        min: min,
        max: max,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return updateStock.data;
}
export async function setStock(
  Code_EAN: string,
  stock: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const setStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/setStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return setStock.data;
}
export async function reserveStock(
  id: string,
  Code_EAN: string,
  stock: number,
  reservedClient: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const reserveStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/reserveStock`,
    {
      plateform: plateform,
      dataUpdate: {
        id: id,
        stock: stock,
        reservedClient: reservedClient,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return reserveStock.data;
}
export async function incrementStock(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Prefixe_tarif: string,
  Code_marque: string,
  stock: number,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const incrementStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/incrementStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return incrementStock.data;
}
export async function decrementStock(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Prefixe_tarif: string,
  Code_marque: string,
  stock: number,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const decrementStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/decrementStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return decrementStock.data;
}

export async function mvStock(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  Prefixe_tarif: string,
  User: string,
  Description: string,
  OldStock: number,
  NewStock: number
) {
  const mvStock = await axios.post(`${process.env.VUE_APP_API}/mvStock`, {
    plateform: plateform,
    Date: new Date(),
    ArticleRef: Ref_fournisseur,
    ArticleEAN: Code_EAN,
    Prefixe_tarif: Prefixe_tarif,
    Depot: plateform,
    User: User,
    Description: Description,
    OldStock: OldStock,
    NewStock: NewStock,
  });

  return mvStock.data;
}

export async function esstock(
  plateform: string,
  dataUpdate: Array<object>,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const esstock = await axios.post(`${process.env.VUE_APP_API}/stock/esstock`, {
    plateform: plateform,
    Date: new Date(),
    dataUpdate: dataUpdate,
    dataMvt: {
      User: User,
      Description: Description,
      client: client,
      numeroDoc: numeroDoc,
    },
  });

  return esstock.data;
}

export async function stockMiseANiveau(
  plateform: string,
  dataUpdate: Array<object>,
  inventaireID: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string,
) {
  const stockMiseANiveau = await axios.post(
    `${process.env.VUE_APP_API}/stock/StockMiseANiveau`,
    {
      plateform: plateform,
      dataUpdate: dataUpdate,
      inventaireID: inventaireID,
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return stockMiseANiveau.data;
}

export async function importEsStock(
  plateform: string,
  dataUpdate: Array<object>
) {
  const importEsStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/importEsStock`,
    {
      plateform: plateform,
      dataUpdate: dataUpdate,
    }
  );

  return importEsStock.data;
}

export async function setAdresse(
  plateform: string,
  Ref_fournisseur: string,
  Code_EAN: string,
  adresse: string
) {
  const setAdresse = await axios.post(
    `${process.env.VUE_APP_API}/stock/setAdresse`,
    {
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
      Code_EAN: Code_EAN,
      adresse: adresse,
    }
  );

  return setAdresse.data;
}

export async function checkStock(
  Ref_fournisseur: string,
  Code_EAN: string,
  Code_marque: string,
  plateform: string,
  quantity: number
) {
  const checkStock = await axios.post(
    `${process.env.VUE_APP_API}/searchEngine/checkStock`,
    {
      Ref_fournisseur: Ref_fournisseur,
      Code_EAN: Code_EAN,
      Code_marque: Code_marque,
      plateform: plateform,
      quantity: quantity,
    }
  );

  return checkStock.data;
}
// Enlever stock et réserver
export async function decrementStockIncrementReserved(
  Code_EAN: string,
  stock: number,
  reservedClient: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string,
) {
  const decrementStockIncrementReserved = await axios.post(
    `${process.env.VUE_APP_API}/stock/decrementStockIncrementReserved`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        reservedClient: reservedClient,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return decrementStockIncrementReserved.data;
}
// Enlever réservé et ajouter en stock
export async function decrementReservedStock(
  Code_EAN: string,
  stock: number,
  reservedClient: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const decrementReservedStock = await axios.post(
    `${process.env.VUE_APP_API}/stock/decrementReservedStock`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        reservedClient: reservedClient,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return decrementReservedStock.data;
}
// Enlever du stock reservé
export async function incrementStockDecrementReserved(
  Code_EAN: string,
  stock: number,
  reservedClient: number,
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_marque: string,
  User: string,
  Description: string,
  client: string,
  numeroDoc: string
) {
  const incrementStockDecrementReserved = await axios.post(
    `${process.env.VUE_APP_API}/stock/incrementStockDecrementReserved`,
    {
      plateform: plateform,
      dataUpdate: {
        stock: stock,
        reservedClient: reservedClient,
        Ref_fournisseur: Ref_fournisseur,
        Prefixe_tarif: Prefixe_tarif,
        Code_EAN: Code_EAN,
        Code_marque: Code_marque,
      },
      dataMvt: {
        User: User,
        Description: Description,
        client: client,
        numeroDoc: numeroDoc,
      },
    }
  );

  return incrementStockDecrementReserved.data;
}
