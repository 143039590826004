<template>
  <div class="space-y-4">
    <div class="flex flex-row justify-end space-x-2">
      <span
        class="material-icons-round text-blue-500 text-3xl cursor-pointer"
        @click="addNewSessionComptoir()"
      >
        add_circle
      </span>
      <span
        class="material-icons-round text-blue-500 text-3xl cursor-pointer"
        @click="getallcomptoirsession()"
      >
        refresh
      </span>
    </div>
    <div class="flex justify-center grid grid-cols-3 m-auto gap-y-6">
      <div v-for="(session, index) in comptoirSession" :key="index">
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-green-600"
          @click="setcomptoirsession(session)"
          v-if="session.actif"
        >
          <div
            class="mx-2 my-4 w-24 h-24 bg-white rounded-full grid place-items-center border border-green-600"
          >
            <span class="text-blue-600 text-4xl">{{ session.Numero }}</span>
          </div>
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> {{ session.Client[0].Name }} </span>
              <span class="text-gray-600 pt-2"> {{ session.refClient }} </span>
              <span class="text-gray-600">
                <span class="text-blue-500">{{
                  session.actionArticle.length
                }}</span>
                article en cours
              </span>
              <button
                class="bg-red-500 text-white rounded shadow"
                @click="removecomptoirsession(session)"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
        <div
          class="flex bg-gray-100 space-x-4 border rounded-md mr-8 p-1 cursor-pointer hover:shadow-lg border-gray-600"
          v-else
          @click="setcomptoirsession(session)"
        >
          <div
            class="mx-2 my-4 w-24 h-24 bg-white rounded-full grid place-items-center border border-gray-600"
          >
            <span class="text-blue-600 text-4xl">{{ session.Numero }}</span>
          </div>
          <div class="flex justify-center">
            <div class="flex flex-col p-8">
              <span class="text-xl"> {{ session.Client[0].Name }} </span>
              <span class="text-gray-600 pt-2 w-full"> {{ session.refClient }} </span>
              <span class="text-gray-600">
                <span class="text-blue-500">{{
                  session.actionArticle.length
                }}</span>
                article en cours
              </span>
              <button
                class="bg-red-500 text-white rounded shadow"
                @click="removecomptoirsession(session)"
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "comptoirSession",
  data: () => ({}),
  computed: {
    ...mapGetters(["comptoirSession"]),
  },
  methods: {
    ...mapActions([
      "getallcomptoirsession",
      "createcomptoirsession",
      "setcomptoirsession",
      "removecomptoirsession",
    ]),
    addNewSessionComptoir() {
      this.createcomptoirsession({ clientFocus: "", refClient: "" });
    },
  },
  mounted() {
    this.getallcomptoirsession();
  },
};
</script>
