<template>
  <!-- Modal recap cmd vanheck -->
  <div
    v-if="openRecapVH"
    :class="`modal ${
      !openRecapVH && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openRecapVH = false), $emit('closeModalLKQ', false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 100rem; max-height: 60rem"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="(openRecapVH = false), $emit('closeModalLKQ', false)"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Panier instantané LKQ</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openRecapVH = false), $emit('closeModalLKQ', false)"
          >
            <svg
              class="text-black fill-current hover:scale-125 hover:border-gray-700 transition duration-150"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->

        <div
          v-if="loadingPreparation"
          class="flex justify-center animate-pulse m-2"
        >
          Chargement en cours...
        </div>
        <div v-else class="flex justify-center m-2">
          <tabCommandeVHI
            :Fournisseur="'VAN HECK'"
            typeFournisseur="configured"
            @deleteOne="deleteOneArticle"
            :tab="panier"
          />
        </div>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="(openRecapVH = false), $emit('closeModalLKQ', false)"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin modal récap cmd vanheck -->
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  getCommandeInst,
  prepareReception,
  refreshDispo,
  updatePreparation,
  removePanierInst,
  prepareCommande,
  prepareChangeDepotVH,
  changeDepotVH,
  getPanier,
  addPanierInst,
} from "@/hooks/commandeFournisseur/commandeFournisseur";
import tabCommandeVHI from "@/components/commandeFournisseur/tabCommandeVHI.vue";

export default {
  data() {
    return {
      openRecapVH: true,
      indexDepot: 0,
      depotTab: [],
      // logCode: "9980177",
      depot: "STANDARD",

      commande: [],
      loadingPreparation: false,
      refCommandeDSV: 0,
      comCommandeDSV: "",

      showRefDSV: false,
      loadingCmdDSV: false,
      stateCmdDSV: false,
      refCommandeVH: 0,
      comCommandeVH: "",
      showRefVH: false,
      loadingCmdVH: false,
      stateCmdVH: false,
      refCommandeMEYLE: 0,
      comCommandeMEYLE: "",
      showRefMEYLE: false,
      loadingCmdMEYLE: false,
      stateCmdMEYLE: false,
      refCommandeDEFAULT: 0,
      comCommandeDEFAULT: "",
      showRefDEFAULT: [],
      loadingCmdDEFAULT: false,
      stateCmdDEFAULT: false,
      openModalDepot: false,
      tabChangeDepot: [],
      loadingDepot: false,
      panier: [],
    };
  },
  components: {
    tabCommandeVHI,
  },
  computed: {
    ...mapGetters([
      "user",
      "plateform",
      "preparationFournisseur",
      "VH_a02Tab",
      "VH_cmd_Loading",
      "VH_cmd_state",
      "VH_articleTab",
    ]),
  },
  methods: {
    getFournisseursDepot() {
      axios
        .post(`${process.env.VUE_APP_API}/panierFournisseur/getactivated`, {
          plateform: this.user.proprietaire,
          fournisseur: "VAN HECK",
        })
        .then((res) => {
          if (res.data[0].accountActivated) {
            this.depotTab = res.data[0].Credential[0].entrepot;
          }
        });
    },
    changeDepot(depotToChange, indexToChange, codeDepot) {
      this.depot = depotToChange;
      this.plateform.grossierID = codeDepot;
      this.indexDepot = indexToChange + 1;
      this.getpanierfournisseur({ name: depotToChange });
    },

    async deleteOneArticle(data) {
      this.panier.splice(data.index, 1);
      await removePanierInst(this.user.proprietaire, data.data);
    },
  },
  async mounted() {
    this.loadingPreparation = true;
    const panier = await getPanier(this.user.proprietaire);
    for (const tab of panier.panier_inst) {
      if (tab.FournisseurSelected == "VAN HECK" || tab.Prefixe_tarif == "Vhi") {
        this.panier.push(tab);
      }
    }
    this.loadingPreparation = false;
    console.log(this.panier);
  },
};
</script>
