<template>
  <div
    id="retour"
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.push('/articlehub')"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>
  <!-- Tableau des transfert de stock Envoyés -->

  <div class="text-right mb-4">
    <router-link to="transfertarchive">
      <archiveButton />
    </router-link>
  </div>
  <div
    v-if="preparedBL.length == 0 && !showPrepareBL"
    class="relative overflow-x-auto shadow-md sm:rounded-lg mb-12"
  >
    <table class="w-full text-sm text-left text-gray-500">
      <caption
        class="text-xl bg-bluevk w-full font-semibold text-white pl-4 py-2"
        @click="prepareFacturationTransfert()"
      >
        Envoyés
      </caption>
      <thead class="text-xs bg-bluevk text-white uppercase bg-gray-50">
        <tr>
          <th scope="col" class="px-6 py-3">Fournisseur</th>
          <th scope="col" class="px-6 py-3">Receveur</th>
          <th scope="col" class="px-6 py-3">Référence</th>
          <th scope="col" class="px-6 py-3">Quantité</th>
          <th scope="col" class="px-6 py-3">EAN</th>
          <th scope="col" class="px-6 py-3">Etat</th>

          <th scope="col" class="px-6 py-3">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b"
          v-for="(item, index) in transfertTab.Provider"
          :key="index"
        >
          <td class="px-6 py-4">{{ item.plateform_provider }}</td>
          <td class="px-6 py-4">{{ item.plateform_receiver }}</td>
          <td class="px-6 py-4">
            {{ item.Prefixe_tarif }} - {{ item.Ref_fournisseur }}
          </td>
          <td class="px-6 py-4">{{ item.quantity }}</td>
          <td class="px-6 py-4">{{ item.Code_EAN }}</td>
          <td class="px-6 py-4 flex items-center">
            {{ item.state }} -
            <span v-if="item.archived == false" class="material-icons-outlined"
              >east</span
            ><span v-else>oui</span>
          </td>

          <td class="px-6 py-4">{{ item.date }}</td>
        </tr>
      </tbody>
    </table>
    <div class="flex justify-end">
      <button
        class="p-2 bg-blue-500 text-white rounded shadow"
        @click="prepareFacturationTransfert()"
      >
        Créer le bon de livraison
      </button>
    </div>
  </div>

  <!-- Tableau des transfert de stock Reçu -->
  <div
    v-if="preparedBL.length == 0 && !showPrepareBL"
    class="relative overflow-x-auto shadow-md sm:rounded-lg"
  >
    <table class="w-full text-sm text-left text-gray-500">
      <caption
        class="text-xl bg-bluevk w-full font-semibold text-white pl-4 py-2"
      >
        Reçu
      </caption>
      <thead class="text-xs bg-bluevk text-white uppercase bg-gray-50">
        <tr>
          <th scope="col" class="px-6 py-3">Fournisseur</th>
          <th scope="col" class="px-6 py-3">Receveur</th>
          <th scope="col" class="px-6 py-3">Référence</th>
          <th scope="col" class="px-6 py-3">Quantité</th>
          <th scope="col" class="px-6 py-3">EAN</th>
          <th scope="col" class="px-6 py-3">Etat</th>

          <th scope="col" class="px-6 py-3">Date</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b"
          v-for="(item, index) in transfertTab.Receiver"
          :key="index"
        >
          <td class="px-6 py-4">{{ item.plateform_provider }}</td>
          <td class="px-6 py-4">{{ item.plateform_receiver }}</td>
          <td class="px-6 py-4">
            {{ item.Prefixe_tarif }} - {{ item.Ref_fournisseur }}
          </td>
          <td class="px-6 py-4">{{ item.quantity }}</td>
          <td class="px-6 py-4">{{ item.Code_EAN }}</td>
          <td class="px-6 py-4 flex items-center">
            {{ item.state }} -
            <span v-if="item.archived == false" class="material-icons-outlined"
              >east</span
            ><span v-else>oui</span>
          </td>

          <td class="px-6 py-4">{{ item.date }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Tableau pour valider le BL à créer -->
  <div v-if="showPrepareBL">
    <returnButton @click="backPrepare" />
    <div v-if="loadingPrepareBL">
      <img
        src="../assets/LoadingGIF/loading6.gif"
        class="mx-auto mt-72"
        alt="loading gif auto"
      />
    </div>
    <div v-if="preparedBL.length > 0" class="flex flex-col space-y-8">
      <div
        v-for="(plateform, indexPlateform) in preparedBL"
        :key="indexPlateform"
        class="flex flex-col space-y-2"
      >
        <div class="p-2 border border-gray-300 bg-gray-200 rounded w-fit">
          {{ plateform.plateform }}
        </div>
        <div>
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs bg-bluevk text-white uppercase bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3">Ref.</th>
                <th scope="col" class="px-6 py-3">Quantité</th>
                <th scope="col" class="px-6 py-3">Prix d'achat</th>
                <th scope="col" class="px-6 py-3">Prix de vente</th>
                <th scope="col" class="px-6 py-3">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b"
                v-for="(article, indexArticle) in plateform.article"
                :key="indexArticle"
              >
                <td class="px-6 py-4">{{ article.Ref_fournisseur }}</td>
                <td class="px-6 py-4">{{ article.quantity }}</td>
                <td class="px-6 py-4">
                  {{ parseFloat(article.PrixAchat).toFixed(2) }} €
                </td>
                <td class="px-6 py-4">
                  <input
                    type="Number"
                    class="w-20"
                    v-model="
                      preparedBL[indexPlateform].article[indexArticle]
                        .Prix_vente
                    "
                  />
                  €
                </td>
                <td class="px-6 py-4">
                  {{
                    parseFloat(article.Prix_vente * article.quantity).toFixed(2)
                  }}
                  €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          @click="createBLTransfert()"
          class="p-2 bg-blue-500 text-white rounded shadow"
        >
          Valider
        </button>
      </div>
    </div>
  </div>

  <!-- recap BL -->
  <div v-if="recapBL.length > 0">
    <table class="w-full text-sm text-left text-gray-500">
      <thead class="text-xs bg-bluevk text-white uppercase bg-gray-50">
        <tr>
          <th scope="col" class="px-6 py-3">Propriétaire</th>
          <th scope="col" class="px-6 py-3">Client</th>
          <th scope="col" class="px-6 py-3">Date</th>
          <th scope="col" class="px-6 py-3">Numero</th>
          <th scope="col" class="px-6 py-3">Prix</th>
          <th scope="col" class="px-6 py-3">Transport</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="bg-white border-b"
          v-for="(item, indexItems) in recapBL"
          :key="indexItems"
        >
          <td class="px-6 py-4">{{ item.Owner }}</td>
          <td class="px-6 py-4">{{ item.Client }}</td>
          <td class="px-6 py-4">{{ item.Date }}</td>
          <td class="px-6 py-4">{{ item.Numero }}</td>
          <td class="px-6 py-4">{{ item.TotalPrice }} €</td>
          <td class="px-6 py-4">{{ item.Transport }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import {
  getTransfert,
  prepareFacturationTransfert,
  createBLTransfert,
  getArchivedTransfert,
} from "@/hooks/stock/transfertStock";
import returnButton from "@/components/Button/return.vue";
import archiveButton from "@/components/Button/archive.vue";
import { useToast } from "vue-toastification";

export default {
  name: "myDepot",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      transfertTab: [],
      preparedBL: [],
      recapBL: [],
      loadingPrepareBL: false,
      showPrepareBL: false,
    };
  },
  components: {
    returnButton,
    archiveButton,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async prepareFacturationTransfert() {
      this.loadingPrepareBL = true;
      this.showPrepareBL = true;
      this.preparedBL = await prepareFacturationTransfert(
        this.user.proprietaire
      );
      this.loadingPrepareBL = false;
    },
    backPrepare() {
      this.showPrepareBL = false;
      this.preparedBL = [];
    },
    async createBLTransfert() {
      this.recapBL = await createBLTransfert(
        this.user.username,
        this.user.proprietaire,
        this.preparedBL
      );
      this.toast.success("Bon de livraison créé !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  async mounted() {
    this.transfertTab = await getTransfert(this.user.proprietaire);
  },
};
</script>
<style>
.modal {
  transition: opacity 0.25s ease;
}
</style>
