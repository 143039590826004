<template>
  <button
    class="bg-bluevk hover:bg-bluevkdark text-white font-bold px-4 rounded"
    title="Cliquer pour revenir en arrière"
    @click="$router.back()"
  >
    <p class="my-auto">&lt; Retour</p>
  </button>

  <div class="flex justify-center mt-4">
    <div class="flex flex-col">
      <ul class="flex flex-col align-center space-y-20">
        <!-- INFORMATIONS GENERALES -->
        <li
          style="width: 90rem"
          class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
        >
          <div
            class="flex justify-between hover:text-blue-400"
            @click="showInformationsGenerales = !showInformationsGenerales"
          >
            <a class="text-xl"> Informations générales </a>
            <span
              v-show="!showInformationsGenerales"
              class="material-icons-outlined inset-y-0 right-0 flex items-center"
            >
              expand_more
            </span>
            <span
              v-show="showInformationsGenerales"
              class="material-icons-outlined inset-y-0 right-0 flex items-center"
            >
              expand_less
            </span>
          </div>
          <transition>
            <div
              v-show="showInformationsGenerales"
              class="bloc-couleur couleur-remplissage"
            >
              <div class="grid grid-cols-2 justify-between">
                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Numéro</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="numero"
                      type="text"
                      ref="statutjuridique"
                      @keydown.enter="this.$refs.transporteur.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div
                  class="w-full px-5 py-2 text-gray-700 bg-gray-200 border-b"
                >
                  <label class="text-xs">Nom</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="transporteur"
                      type="text"
                      ref="transporteur"
                      @keydown.enter="this.$refs.Adresse.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Adresse</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="adresse"
                    ref="Adresse"
                    @keydown.enter="this.$refs.Complement.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Complément d'adresse</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="textfield"
                    v-model="complement"
                    ref="Complement"
                    @keydown.enter="this.$refs.PostalCode.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="grid grid-cols-3">
                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Code Postal</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model="codePostal"
                      ref="PostalCode"
                      @keydown.enter="this.$refs.City.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Ville</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="textfield"
                      v-model="ville"
                      ref="City"
                      @keydown.enter="this.$refs.Country.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label for="Country" class="text-xs">Pays</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <select
                      name="Country"
                      v-model="pays"
                      ref="Country"
                      @keydown.enter="this.$refs.telephone.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                    >
                      <option value="ALLEMAGNE">ALLEMAGNE</option>
                      <option value="AUTRICHE">AUTRICHE</option>
                      <option value="BELGIQUE">BELGIQUE</option>
                      <option value="ESPAGNE">ESPAGNE</option>
                      <option selected value="FRANCE">FRANCE</option>
                      <option value="ITALIE">ITALIE</option>
                      <option value="LUXEMBOURG">LUXEMBOURG</option>
                      <option value="MAROC">MAROC</option>
                      <option value="PAYS-BAS">PAYS-BAS</option>
                      <option value="POLOGNE">POLOGNE</option>
                      <option value="SUISSE">SUISSE</option>
                      <option value="TURQUIE">TURQUIE</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="grid grid-cols-3">
                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Tél n°1</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="tel"
                      v-model="telephone"
                      ref="telephone"
                      @keydown.enter="this.$refs.telephone2.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Tél n°2</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="telephone2"
                      type="text"
                      ref="telephone2"
                      @keydown.enter="this.$refs.fax.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Fax</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="fax"
                      type="text"
                      ref="fax"
                      @keydown.enter="this.$refs.email.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">Email</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="email"
                    v-model="email"
                    ref="email"
                    @keydown.enter="this.$refs.siret.focus()"
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div class="grid grid-cols-3">
                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Siret</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="siret"
                      type="text"
                      ref="siret"
                      @keydown.enter="this.$refs.nafape.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">NAF-APE</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="nafape"
                      type="text"
                      ref="nafape"
                      @keydown.enter="this.$refs.numerotva.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Numéro T.V.A.</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="numerotva"
                      type="text"
                      ref="numerotva"
                      @keydown.enter="this.$refs.rcsrm.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                <label class="text-xs">RCS-RM</label>

                <div class="relative mt-2 rounded-md shadow-sm">
                  <input
                    v-model="rcsrm"
                    type="text"
                    ref="rcsrm"
                    @keydown.enter="
                      (this.showFinance = true), this.$refs.iban.focus()
                    "
                    class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  />
                </div>
              </div>
            </div>
          </transition>
        </li>

        <!-- FINANCE -->
        <li
          style="width: 90rem"
          class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
        >
          <div
            class="flex justify-between hover:text-blue-400"
            @click="showFinance = !showFinance"
          >
            <a class="text-xl"> Finance </a>
            <span
              v-show="!showFinance"
              class="material-icons-outlined inset-y-0 right-0 flex items-center"
            >
              expand_more
            </span>
            <span
              v-show="showFinance"
              class="material-icons-outlined inset-y-0 right-0 flex items-center"
            >
              expand_less
            </span>
          </div>
          <transition>
            <div v-show="showFinance" class="bloc-couleur couleur-remplissage">
              <div class="container">
                <div class="grid grid-cols-2">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">IBAN</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="iban"
                        type="text"
                        ref="iban"
                        @keydown.enter="this.$refs.swift.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">SWIFT/BIC</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model="swift"
                        type="text"
                        ref="swift"
                        @keydown.enter="this.$refs.nomdelabanque.focus()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Nom de la banque</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="nomdelabanque"
                      type="text"
                      ref="nomdelabanque"
                      @keydown.enter="this.$refs.ncomptecomptable.focus()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">N° compte comptable</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model="ncomptecomptable"
                      type="text"
                      ref="ncomptecomptable"
                      @keydown.enter="showService = true"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </li>

        <!-- CUT OFF -->
        <li
          style="width: 90rem"
          class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
        >
          <div
            class="flex justify-between hover:text-blue-400"
            @click="showService = !showService"
          >
            <a class="text-xl"> Cut-off </a>
            <span
              v-show="!showService"
              class="material-icons-outlined inset-y-0 right-0 flex items-center"
            >
              expand_more
            </span>
            <span
              v-show="showService"
              class="material-icons-outlined inset-y-0 right-0 flex items-center"
            >
              expand_less
            </span>
          </div>
          <transition>
            <div v-show="showService" class="bloc-couleur couleur-remplissage">
              <div class="container">
                <div class="flex flex-col space-y-2">
                  <div class="flex justify-start mt-2">
                    <button
                      @click="addCutoff()"
                      class="px-2 py-2 font-medium tracking-wide text-white bg-cyan-600 rounded-md hover:bg-cyan-500 focus:outline-none"
                    >
                      Ajouter un cut-off
                    </button>
                  </div>

                  <div
                    class="grid grid-cols-8 px-5 py-2 text-gray-700 bg-gray-200 border-b"
                  >
                    <div
                      v-for="(line, index) in cutoffs"
                      :key="index"
                      class="flex space-x-2"
                    >
                      <div>
                        <label class="text-xs">Cut-off</label>

                        <div class="relative mt-2 rounded-md shadow-sm">
                          <div class="flex space-x-2">
                            <input
                              type="time"
                              v-model="cutoffs[index].hour"
                              class="focus:outline-2 outline-sky-300 w-fit p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                            /><span
                              class="material-icons-outlined text-red-600 my-auto"
                              @click="removeCutoff(index)"
                            >
                              delete
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </li>
      </ul>

      <div class="flex justify-end mt-8">
        <button
          @click="saveTransporteur()"
          class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
        >
          Ajouter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { createTransporteur } from "@/hooks/transport/transporteur";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showInformationsGenerales: true,
      showFinance: false,
      showService: false,

      transporteur: "",
      numero: "",
      adresse: "",
      complement: "",
      codePostal: "",
      ville: "",
      pays: "FRANCE",
      telephone: "",
      telephone2: "",
      fax: "",
      email: "",
      siret: "",
      nafape: "",
      numerotva: "",
      rcsrm: "",

      iban: "",
      swift: "",
      nomdelabanque: "",
      ncomptecomptable: "",

      cutoffs: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async saveTransporteur() {
      await createTransporteur({
        plateform: this.user.proprietaire,
        numero: this.numero,
        transporteur: this.transporteur,
        adresse: this.adresse,
        complement: this.complement,
        codePostal: this.codePostal,
        ville: this.ville,
        pays: this.pays,
        telephone: this.telephone,
        telephone2: this.telephone2,
        fax: this.fax,
        email: this.email,
        siret: this.siret,
        nafape: this.nafape,
        numerotva: this.numerotva,
        rcsrm: this.rcsrm,
        iban: this.iban,
        swift: this.swift,
        cutoffs: this.cutoffs,
      });
      this.toast.success("Transporteur ajouté !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.$router.push("/Transporteur");
    },
    addCutoff() {
      this.cutoffs.push({ hour: "00:00" });
      console.log(this.cutoffs);
    },
    removeCutoff(index) {
      this.cutoffs.splice(index, 1);
    },
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.75s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
