<template>
  <div
    v-if="openAddManualFournisseur"
    :class="`modal ${
      !openAddManualFournisseur && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openAddManualFournisseur = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 80vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openAddManualFournisseur = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="py-4">
          <div class="py-2">
            <input
              type="text"
              placeholder="Rechercher un fournisseur"
              v-model="searchFournisseurManual"
              class="w-full px-2 py-1 me-2 text-sm font-medium border border-gray-300 text-gray-400 rounded"
            />
          </div>
          <div class="flex flex-col space-y-2 max-h-40 overflow-y-auto">
            <div
              class="flex flex-row items-center justify-between px-2 py-1 me-2 text-base font-medium text-gray-600 bg-gray-100 rounded"
              v-for="(f, indexFourn) in filteredFournisseurManual"
              :key="indexFourn"
            >
              <div>{{ f.CompanyName }}</div>
              <div>
                <button
                  class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
                  @click="addManualFournisseur(f.CompanyName)"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="openAddFournisseur"
    :class="`modal ${
      !openAddFournisseur && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openAddFournisseur = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 80vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openAddFournisseur = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="py-4">
          <div v-if="loadingFournisseur">Chargement en cours...</div>
          <div v-else>
            <div class="flex flex-col space-y-2">
              <div
                class="flex flex-row items-center justify-between px-2 py-1 me-2 text-base font-medium text-gray-600 bg-gray-100 rounded"
                v-for="(f, indexF) in linkedFournisseurTab"
                :key="indexF"
              >
                <div>{{ f }}</div>
                <div>
                  <button
                    class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded"
                    @click="addlinkedFournisseur(f)"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex cursor-pointer" @click="back()">
    <span class="material-icons-outlined"> arrow_back </span>
    <span>retour</span>
  </div>
  <div class="flex justify-center"><menuConfiguration /></div>
  <div class="flex justify-center mt-4">
    <div class="flex flex-row space-x-2">
      <span class="text-2xl text-bluevk">{{ brandSelected.mfrName }}</span>
    </div>
  </div>
  <div class="flex justify-between mt-4">
    <div class="flex flex-row space-x-2">
      <div
        class="flex flex-row items-center justify-between px-2 py-1 me-2 text-sm font-medium text-blue-800 border border-blue-100 rounded"
        v-for="(fou, indexFou) in fournisseurSetList"
        :key="indexFou"
      >
        <div class="flex flex-row items-center space-x-4">
          <div>{{ fou.CompanyName }}</div>
        </div>
        <button
          @click="deleteFournisseur(fou)"
          class="material-icons-round text-sm"
        >
          clear
        </button>
      </div>
    </div>
    <div class="flex flex-row space-x-2">
      <button
        class="flex flex-row items-center justify-between px-2 py-1 me-2 text-sm font-medium bg-bluevk text-white rounded"
        @click="setAddManualFournisseur()"
      >
        <div>Ajouter</div>
        <span class="material-icons-round">add</span>
      </button>
    </div>
  </div>
  <div v-show="loadingSousFamille">Chargement en cours...</div>
  <div class="mt-4">
    <div class="flex flex-col space-y-2">
      <div
        v-for="(SF, index) in sousFamille"
        :key="index"
        class="flex flex-row bg-white rounded p-2 hover:bg-gray-100 w-full"
      >
        <div class="flex flex-col space-y-2 w-full p-2">
          <div class="flex flex-row items-center space-x-4">
            <span class="text-lg"> {{ SF.name }} </span>
          </div>
          <div class="grid grid-cols-8 gap-2">
            <div
              class="flex flex-row items-center justify-between px-2 py-1 me-2 text-sm font-medium text-blue-800 bg-blue-100 rounded"
              v-for="(fournisseur, indexF) in SF.Fournisseur"
              :key="indexF"
            >
              <div class="flex flex-row items-center space-x-4">
                <div class="flex flex-col">
                  <div class="relative">{{ fournisseur.position }}</div>
                  <!-- <div class="absolute bg-">test</div> -->
                </div>
                <div>{{ fournisseur.CompanyName }}</div>
              </div>
              <button
                class="material-icons-round text-sm"
                @click="deletelinkedFournisseur(fournisseur._id, index, indexF)"
              >
                clear
              </button>
            </div>
            <button
              class="flex flex-row items-center justify-between px-2 py-1 me-2 text-sm font-medium border border-gray-300 text-gray-400 rounded"
              @click="setAddFournisseur(SF, index)"
            >
              <div>Ajouter un fournisseur</div>
              <span class="material-icons-round">add</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
import configurationBrandArticles from "./configurationBrandArticles.vue";
export default {
  data() {
    return {
      sousFamille: [],
      genArt: [],
      saveGenArt: [],
      loadingSousFamille: false,
      showAddBrand: [],
      groupeList: [],
      groupeType: "",
      selectedGroup: {},
      groupToCreate: "",
      showFamille: false,
      familleList: [],
      familleType: "",
      familleSelected: {},
      familleToCreate: "",
      showSousFamille: false,
      sousFamilleList: [],
      sousFamilleType: "",
      sousFamilleSelected: {},
      sousFamilleToCreate: "",
      showAddButton: false,
      showGenArtRemain: [],
      remainGATab: [],
      fournisseurList: [],
      openAddFournisseur: false,
      openAddManualFournisseur: false,
      linkedFournisseurTab: [],
      loadingFournisseur: false,
      selectedSousFamille: {},
      selectedSousFamilleIndex: 0,
      searchFournisseurManual: "",
      fournisseurSetList: [],
    };
  },
  components: {
    menuConfiguration,
  },
  computed: {
    ...mapGetters(["brandSelected", "user", "comptoirSessionActive"]),
    filteredFournisseurManual() {
      if (this.searchFournisseurManual && this.openAddManualFournisseur) {
        return this.fournisseurList.filter((item) => {
          return item.CompanyName.startsWith(this.searchFournisseurManual);
        });
      } else {
        return this.fournisseurList;
      }
    },
  },
  methods: {
    ...mapActions(["setbrandselected", "setgenericarticleselected"]),
    back() {
      this.setbrandselected({ name: "" });
      this.$router.push("/ConfigurationBrandPriority");
    },
    async getFournisseur() {
      const res = await axios.post(
        `${process.env.VUE_APP_API}/fournisseur/getall`,
        {
          plateform: this.user.proprietaire,
        }
      );

      this.fournisseurList = res.data;
    },
    async setAssemblyGroup() {
      this.loadingSousFamille = true;
      const res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getLinkedBrandFournisseur`,
        {
          plateform: this.user.proprietaire,
          brandName: this.brandSelected.mfrName,
        }
      );
      this.sousFamille = res.data.sf;
      this.genArt = res.data.ga;
      this.loadingSousFamille = false;
      await this.getFournisseurListed();
    },
    async changeBrand(indexSousFamille, idSF) {
      const updatedSF = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: idSF,
          brandName: this.brandSelected.mfrName,
          type: "primary",
        }
      );
      this.sousFamille[indexSousFamille] = updatedSF.data;
    },
    async changeBrandScd(indexSousFamille, idSF) {
      const updatedSF = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: idSF,
          brandName: this.brandSelected.mfrName,
          type: "secondary",
        }
      );
      this.sousFamille[indexSousFamille] = updatedSF.data;
    },
    async changeAllBrand() {
      for (const [indexSF, SF] of this.sousFamille.entries()) {
        const updatedSF = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
          {
            id: SF._id,
            brandName: this.brandSelected.mfrName,
            type: "primary",
          }
        );
        this.sousFamille[indexSF] = updatedSF.data;
      }
    },
    cancelAddGroupe(indexGA) {
      this.showAddBrand[indexGA] = !this.showAddBrand[indexGA];
      this.showFamille = false;
      this.showSousFamille = false;
      this.showAddButton = false;
      this.groupeType = "";
      this.selectedGroup = {};
      this.groupToCreate = "";
      this.familleType = "";
      this.familleSelected = {};
      this.familleToCreate = "";
      this.sousFamilleType = "";
      this.sousFamilleSelected = {};
      this.sousFamilleToCreate = "";
    },
    async prepareAddGA(indexGA) {
      this.showAddBrand = [];
      this.showFamille = false;
      this.showSousFamille = false;
      this.showAddButton = false;
      this.groupeType = "";
      this.selectedGroup = {};
      this.groupToCreate = "";
      this.familleType = "";
      this.familleSelected = {};
      this.familleToCreate = "";
      this.sousFamilleType = "";
      this.sousFamilleSelected = {};
      this.sousFamilleToCreate = "";
      this.showAddBrand[indexGA] = !this.showAddBrand[indexGA];
      const getGroupe = await axios.get(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getGroupe/${this.user.proprietaire}`
      );
      this.groupeList = getGroupe.data;
    },
    async setGenArt(sousFamilleId, genericArticleId, indexSF, indexGAN) {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setGenArt`,
        {
          id: sousFamilleId,
          genericArticleId: parseInt(genericArticleId),
        }
      );
      this.sousFamille[indexSF].genericArticleId.splice(indexGAN, 1);
      this.sousFamille[indexSF].genericArticleId_name.splice(indexGAN, 1);
    },
    async deleteBrand(sousFamilleId, brand, indexSF) {
      const delBd = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteBrandSousFamille`,
        {
          id: sousFamilleId,
          brand: brand,
        }
      );
      this.sousFamille[indexSF] = delBd.data;
    },
    async deleteBrandScd(sousFamilleId, brand, indexSF) {
      const delBd = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteBrandScdSousFamille`,
        {
          id: sousFamilleId,
          brand: brand,
        }
      );
      this.sousFamille[indexSF] = delBd.data;
    },
    async getRemainGenArt(sousFamilleId, indexSF) {
      if (!this.showGenArtRemain[indexSF]) {
        const res = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/getRemainGenArt`,
          {
            id: sousFamilleId,
            brand: this.brandSelected.mfrName,
          }
        );
        this.remainGATab = res.data;
      }

      this.showGenArtRemain[indexSF] = !this.showGenArtRemain[indexSF];
    },
    async addDirectGA(sousFamilleId, genericArticleId, indexSF) {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addGenArt`,
        {
          id: sousFamilleId,
          genArt: genericArticleId,
        }
      );
      this.sousFamille[indexSF] = res.data;
      this.showGenArtRemain[indexSF] = false;
    },
    async setAddFournisseur(sousFamille, indexSF) {
      this.openAddFournisseur = true;
      this.loadingFournisseur = true;
      this.linkedFournisseurTab = [];
      this.selectedSousFamille = sousFamille;
      this.selectedSousFamilleIndex = indexSF;
      await this.getLinkedFournisseur(sousFamille);
    },
    async getLinkedFournisseur(sousFamille) {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getLinkedFournisseur`,
        {
          plateform: this.user.proprietaire,
          sousFamille: sousFamille,
          brand: this.brandSelected.dataSupplierId,
        }
      );
      this.linkedFournisseurTab = res.data;
      this.loadingFournisseur = false;
    },
    async addlinkedFournisseur(fournisseur) {
      const res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addlinkedFournisseur`,
        {
          plateform: this.user.proprietaire,
          sousFamille: this.selectedSousFamille,
          fournisseur: fournisseur,
          brand: this.brandSelected.mfrName,
        }
      );
      this.openAddFournisseur = false;
      this.linkedFournisseurTab = [];
      this.sousFamille[this.selectedSousFamilleIndex].Fournisseur = res.data;
      await this.getFournisseurListed();
    },
    async deletelinkedFournisseur(_id, indexSF, indexF) {
      const res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deletelinkedFournisseur`,
        {
          _id: _id,
        }
      );
      this.sousFamille[indexSF].Fournisseur.splice(indexF, 1);
      await this.getFournisseurListed();
    },
    setAddManualFournisseur() {
      this.openAddManualFournisseur = true;
      this.getFournisseur();
    },
    async addManualFournisseur(fournisseur) {
      for (const [indexSF, SF] of this.sousFamille.entries()) {
        const res = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/addlinkedFournisseur`,
          {
            plateform: this.user.proprietaire,
            sousFamille: SF,
            fournisseur: fournisseur,
            brand: this.brandSelected.mfrName,
          }
        );
        this.sousFamille[indexSF].Fournisseur = res.data;
      }
      this.openAddManualFournisseur = false;
      await this.getFournisseurListed();
    },
    async getFournisseurListed() {
      this.fournisseurSetList = [];
      for (const SF of this.sousFamille) {
        for (const f of SF.Fournisseur) {
          let found = false;
          for (const fSet of this.fournisseurSetList) {
            if (fSet.CompanyName === f.CompanyName) {
              found = true;
              break;
            }
          }
          if (!found) {
            this.fournisseurSetList.push(f);
          }
        }
      }
    },
    async deleteFournisseur(fournisseur) {
      for (const [indexSF, SF] of this.sousFamille.entries()) {
        for (const [indexF, f] of SF.Fournisseur.entries()) {
          if (f.CompanyName === fournisseur.CompanyName) {
            const res = await axios.post(
              `${process.env.VUE_APP_API}/configurationCatalogueV2/deletelinkedFournisseur`,
              {
                _id: f._id,
              }
            );
            this.sousFamille[indexSF].Fournisseur.splice(indexF, 1);
          }
        }
      }
      await this.getFournisseurListed();
    },
  },
  beforeMount() {
    this.setAssemblyGroup();
  },
};
</script>
<style></style>
