<template>
  <table class="min-w-full" v-if="article.length > 0">
    <thead class="rounded-t-full">
      <tr class="rounded-t-full">
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Réf
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Déscription
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Prix
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Qté
        </th>
        <th
          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
        >
          Dispo
        </th>
      </tr>
    </thead>
    <tbody class="h-24 bg-gray-50 space-y-2">
      <tr
        class="hover:shadow"
        v-for="(item, indexItem) in article"
        :key="indexItem"
      >
        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div class="flex flex-row space-x-2 items-center">
            <div>
              <input
                type="checkbox"
                class="form-checkbox"
                v-model="item.checked"
              />
            </div>
            <div class="flex flex-col space-y-1">
              <span class="material-icons-round text-base"> sync </span>
            </div>
            <div class="text-sm leading-5 text-gray-900">
              {{ item.Ref_fournisseur }}
            </div>
            <div>
              <span
                class="material-icons-round bg-red-500 rounded-sm text-white text-xs cursor-pointer"
                @click="
                  $emit('deleteOne', {
                    index: indexItem,
                    fournisseur: Fournisseur,
                    typeFournisseur: typeFournisseur,
                  })
                "
              >
                clear
              </span>
            </div>
            <div v-if="item.Prefixe_tarif == 'Vhi'">
              <div
                class="p-1 bg-blue-500 text-white rounded shadow cursor-pointer"
                @click="showDepot[indexItem] = !showDepot[indexItem]"
              >
                {{ item.depot.name }}
              </div>
              <div
                class="bg-white shadow rounded flex flex-col absolute z-20"
                v-show="showDepot[indexItem]"
              >
                <span
                  v-for="(dep, indexDep) in item.depot_dispo"
                  :key="indexDep"
                  class="p-2 cursor-pointer hover:bg-gray-200"
                  >{{ dep.name }}</span
                >
              </div>
            </div>
          </div>
        </td>
        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div class="text-sm leading-5 text-gray-900">
            {{ item.Description }}
          </div>
        </td>
        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div class="leading-5 text-gray-900 flex flex-col">
            <div class="text-sm">{{ item.prix_brut }} €</div>
            <div
              class="text-sm flex flex-row items-center space-x-1"
              v-if="user.accountType == 'Administrateur'"
            >
              <span>PA: </span>
              <input
                @change="
                  $emit('setPA', {
                    index: indexItem,
                    prix_net: item.prix_net,
                    fournisseur: Fournisseur,
                    typeFournisseur: typeFournisseur,
                  })
                "
                type="number"
                v-model="item.prix_net"
                class="w-16 p-1"
              />
              <span>€</span>
            </div>
            <div class="text-xs" v-if="user.accountType == 'Administrateur'">
              {{ item.remise }} %
            </div>
          </div>
        </td>
        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div
            class="flex flex-row items-center space-x-2 text-sm leading-5 text-gray-900"
          >
            <input
              class="p-1 w-16"
              type="number"
              @change="
                refreshDispo(
                  indexItem,
                  item.Ref_fournisseur,
                  item.quantity_asked
                ),
                  $emit('changeQuantity')
              "
              v-model="item.quantity_asked"
            />
          </div>
        </td>
        <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
          <div class="flex flex-row items-center space-x-2">
            <div class="space-y-1">
              <!-- Nuit -->
              <div
                v-if="parseInt(item.quantity_available.night) > 0"
                class="flex"
              >
                <div
                  :title="item.quantity_available.night_date"
                  class="flex border border-yellow-500 p-0.5 rounded shadow items-center"
                >
                  <span
                    class="material-icons-outlined text-yellow-500"
                    style="font-size: 15px"
                    >light_mode</span
                  >

                  <span
                    class="text-xs text-black"
                    v-if="parseInt(item.quantity_available.night)"
                  >
                    {{ parseInt(item.quantity_available.night) }}
                  </span>
                </div>

                <!-- <div v-if="item.quantity_available.night_date" class="ml-2"> le {{  }}</div> -->
              </div>

              <!-- Jour -->
              <div
                v-if="parseInt(item.quantity_available.day) > 0"
                class="flex"
              >
                <div
                  :title="item.quantity_available.day_date"
                  class="flex border border-blue-700 p-0.5 rounded shadow"
                >
                  <span
                    class="material-icons-outlined text-blue-700 "
                    style="font-size: 15px"
                    >nightlight</span
                  >

                  <span
                    class="text-xs text-black"
                    v-if="parseInt(item.quantity_available.day)"
                  >
                    {{ parseInt(item.quantity_available.day) }}
                  </span>
                </div>

                <!-- <div v-if="item.quantity_available.day_date" class="ml-2">le {{ item.quantity_available.day_date }}</div> -->
              </div>
            </div>
            <div>
              <span
                class="material-icons-round text-sm text-blue-500 cursor-pointer"
                @click="
                  refreshDispo(
                    indexItem,
                    item.Ref_fournisseur,
                    item.quantity_asked
                  )
                "
                v-show="!loadingRefreshDispo[indexItem]"
              >
                replay
              </span>
              <span
                class="material-icons-round text-orange-500 animate-pulse text-sm"
                v-show="loadingRefreshDispo[indexItem]"
              >
                cloud_sync
              </span>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <div class="h-16"></div>
  </table>
</template>
<script>
import { refreshDispo } from "@/hooks/commandeFournisseur/commandeFournisseur";
import { mapGetters } from "vuex";
export default {
  props: ["article", "Fournisseur", "typeFournisseur"],
  data() {
    return {
      loadingRefreshDispo: [],
      showDepot: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async refreshDispo(index, ref, quantity) {
      this.loadingRefreshDispo[index] = true;
      const refresh = await refreshDispo(
        this.user.proprietaire,
        this.Fournisseur,
        ref,
        quantity
      );
      if (refresh.state) {
        this.$emit("refreshDispo", {
          index: index,
          fournisseur: this.Fournisseur,
          data: refresh,
          typeFournisseur: this.typeFournisseur,
        });
      }
      this.loadingRefreshDispo[index] = false;
    },
  },
};
</script>
<style></style>
