import axios from "axios";

export async function sendA05(
  infoCommande: Array<object>,
  plateform: string,
  user: string,
  login: string,
  refCommande: number,
  comCommande: string,
  logCode: string,
) {
  try {
    const commanderA05 = await axios.post(
      `${process.env.VUE_APP_API}/vanheck/commanderA05`,
      {
        infoCommande: infoCommande,
        plateform: plateform,
        user: user,
        login: login,
        refCommande: refCommande,
        comCommande: comCommande,
        logCode: logCode,
      }
    );
    return commanderA05.data;
  } catch (error) {
    return "Erreur";
  }
}
