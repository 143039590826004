<template>
  <div
    id="retour"
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.push('/paniersClient')"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>

  <div class="mt-2">
    <div class="text-right">
      <div class="flex justify-between font-medium text-left">
        <div class="flex flex-col">
          <p>{{ panier.client }}</p>
          <p class="text-gray-600 text-xs">Créé par :</p>
          <p class="text-left">{{ panier.user }}</p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Panier du :</p>
          <p>{{ getFRDateSansH(panier.cartDate) }}</p>
          <p class="text-bluevk">{{ panier.cutoff }}</p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Créé le :</p>
          <p>{{ getFRDate(panier.date) }}</p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Frais de port appliqué :</p>
          <p>{{ panier.shippingCosts }} € / {{ panier.freeShippingAt }} €</p>
          <p class="text-gray-400 text-xs">
            Frais de port du client : {{ panier.savedShippingCosts }} €
          </p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Total HT BC :</p>
          <p>{{ financial(panier.BCtotalPrice) }} €</p>
        </div>

        <div class="flex flex-col">
          <p class="text-gray-600 text-xs">Total HT BL :</p>
          <p>{{ financial(panier.BLtotalPrice) }} €</p>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Type / Lieu de création
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Numéro BC / Date / Créé par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT BC
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Numéro BL / Date / Créé par
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT BL
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(u, index) in panier.document" :key="index">
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u.type }}</span
                  >
                  <div class="text-sm leading-5 text-gray-900">
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                      >{{ u.created_here }}</span
                    >
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm border-b border-gray-200 whitespace-nowrap"
                >
                  <p>BC {{ u.BCnumber }} : {{ getFRDate(u.BCdate) }}</p>
                  <p class="text-orangevk">{{ u.BCuser }}</p>
                </td>

                <td
                  class="px-6 py-4 font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ financial(u.BCtotalPrice) }} €
                </td>

                <td
                  class="px-6 py-4 text-sm border-b border-gray-200 whitespace-nowrap"
                >
                  <p>BL {{ u.BLnumber }} : {{ getFRDate(u.BLdate) }}</p>
                  <p class="text-orangevk">{{ u.BLuser }}</p>
                </td>

                <td
                  class="px-6 py-4 font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ financial(u.BLtotalPrice) }} €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getByID } from "@/hooks/transport/panierTransport.ts";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  name: "MyPanierClient",
  data() {
    return {
      panier: [],
    };
  },
  computed: {
    ...mapGetters(["idDetailPanierClient"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getFRDateSansH(date) {
      return getFrDateSansH(date);
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  async beforeMount() {
    this.panier = await getByID(this.idDetailPanierClient);
  },
};
</script>
