<template>
  <div class="flex">
    <router-link to="/configurationhub" class="flex flex-row items-center">
      <backButton />
    </router-link>
  </div>
  <div class="mt-2 space-y-4">
    <div class="grid grid-cols-7 gap-5" v-if="brandTab.length > 0">
      <div
        v-for="(Brand, indexBrand) in brandTab"
        :key="indexBrand"
        class="bg-white rounded flex flex-row items-center justify-between p-1"
        @click="catArticleMarque(Brand)"
      >
        <span class="ml-1 cursor-pointer text-sm">
          {{ Brand.brand }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { useToast } from "vue-toastification";
import backButton from "@/components/Button/back.vue";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      brandTab: [],
    };
  },
  computed: {
    ...mapGetters(["user", "confGenArtSelected"]),
  },
  methods: {
    ...mapActions([
      "setConfGenArtSelected",
      "setgenericarticleselected",
      "setbrandselected",
    ]),
    async getGenArtBrand() {
      const getGenArtBrand = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getOneGenArtForBrand`,
        {
          genArt: this.confGenArtSelected,
        }
      );
      this.brandTab = getGenArtBrand.data;
    },
    async catArticleMarque(brand) {
      this.setbrandselected({
        mfrName: brand.brand,
        dataSupplierId: parseInt(brand.brandID),
      });
      this.setgenericarticleselected(this.confGenArtSelected);
      this.$router.push("/configurationBrandArticles");
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getGenArtBrand();
  },
  components: {
    backButton,
  },
};
</script>
<style></style>
