<template>
  <div class="animate-pulse">
    <div
      class="flex flex-col bg-white rounded-sm drop-shadow-sm px-2 py-2 space-y-2"
    >
      <div class="flex flex-row">
        <div class="w-1/5 font-semibold">
          <span class="w-20 bg-gray-200 text-gray-200 rounded">- -</span>
        </div>
        <div class="flex flex-row space-x-2 w-3/5">
          <span class="text-blue-600 font-semibold">- -</span>
          <span class="material-icons-outlined text-orange-600 text-base">
            cached
          </span>
        </div>
        <div class="w-1/5"></div>
      </div>
      <div class="flex flex-row">
        <div class="w-1/5">
          <div class="drop-shadow-sm bg-gray-200 rounded w-24 h-24"></div>
        </div>
        <div class="flex flex-row w-3/5 bg-gray-200 rounded">
          <div class="flex flex-col w-3/4">
            <div class="font-semibold"></div>
            <div class="text-sm text-gray-400 max-h-10 overflow-hidden"></div>
            <div
              class="flex flex-row items-center text-sm text-green-500 w-fit cursor-pointer"
            >
              <span></span>
            </div>
            <div
              class="flex flex-row items-center text-sm text-blue-500 cursor-pointer w-fit"
            >
              <span></span>
            </div>
          </div>
          <div class="w-1/4">
            <div class="flex flex-row items-center space-x-2 ml-2"></div>
          </div>
        </div>
        <div class="flex flex-row w-1/5">
          <div class="w-1/3">
            <div class="flex flex-row items-center mx-auto text-sm"></div>
          </div>
          <div class="w-2/3 text-sm bg-gray-200 rounded"></div>
        </div>
      </div>
    </div>
    <div class="flex"><span class="mx-auto">Chargement en cours...</span></div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
