<template>
  <div>
    <router-link to="/profil">
      <div
        class="flex flex-row items-center cursor-pointer w-24 rounded-full hover:shadow-lg px-2 text-gray-600 mb-2"
      >
        <span class="material-icons-round"> arrow_back </span>
        <span class="p-2 text-black">Retour</span>
      </div>
    </router-link>

    <div class="flex flex-col">
      <div
        v-for="(fourn, index) in fournisseurs"
        :key="index"
        class="flex flex-col w-full bg-white p-3 rounded shadow"
      >
        <div class="flex flex-row justify-between">
          <div class="flex flex-row">
            <div>
              <span>{{ fourn.CompanyName }}</span>
            </div>
            <div class="ml-2">
              <span
                v-if="fourn.Credential[0].actif"
                class="px-2 py-1 bg-green-500 text-white rounded-xl shadow bg"
                >Actif</span
              >
              <span
                v-else
                class="px-2 py-1 bg-red-500 text-white rounded-xl shadow bg"
                >Désactivé</span
              >
            </div>
          </div>
          <div class="flex flex-row items-center">
            <span
              @click="settingMode = !settingMode"
              class="material-icons-round text-gray-500 cursor-pointer"
            >
              settings
            </span>
            <div class="ml-4">
              <button
                v-show="settingMode"
                @click="setCredential(fourn)"
                class="p-2 bg-blue-500 text-white rounded shadow"
              >
                Valider
              </button>
            </div>
          </div>
        </div>
        <div class="flex flex-col space-y-2 mt-2">
          <div v-if="!fourn.Credential[0].actif">
            <span class="text-orange-500">
              Veuillez activer le compte en entrant vos login!
            </span>
          </div>
          <span>Code : {{ fourn.Credential[0].login }}</span>
          <div class="flex flex-row">
            <div class="flex flex-row space-x-4 items-center">
              <span>Login : </span>
              <span v-show="!settingMode" class="ml-2">{{
                fourn.Credential[0].login
              }}</span>
              <input
                v-show="settingMode"
                v-model="fournisseurs[index].Credential[0].login"
                type="text"
                class="p-1 rounded shadow focus:outline-2 outline-sky-300"
              />
            </div>
            <div class="flex flex-row ml-4 space-x-4 items-center">
              <span>Mot de passe : </span>
              <span v-show="!settingMode" class="ml-2">********</span>
              <input
                v-show="settingMode"
                v-model="fournisseurs[index].Credential[0].password"
                type="password"
                class="p-1 rounded shadow focus:outline-2 outline-sky-300"
              />
            </div>
          </div>
          <div class="flex flex-row items-center spaxe-x-2">
            <span>Dépôts : </span>
            <span
              class="material-icons-round text-blue-400 cursor-pointer"
              v-show="settingMode"
              @click="addEntrepot(index)"
            >
              add_circle
            </span>
          </div>
          <div
            class="flex flex-row ml-6"
            v-for="(depot, index_depot) in fournisseurs[index].Credential[0]
              .entrepot"
            :key="index_depot"
          >
            <div class="flex flex-row space-x-4 items-center">
              <span>Nom : </span>
              <span v-show="!settingMode" class="ml-2">{{ depot.name }}</span>
              <input
                v-show="settingMode"
                v-model="
                  fournisseurs[index].Credential[0].entrepot[index_depot].name
                "
                type="text"
                class="p-1 rounded shadow focus:outline-2 outline-sky-300"
              />
            </div>
            <div class="flex flex-row ml-4 space-x-4 items-center">
              <span>Code : </span>
              <span v-show="!settingMode" class="ml-2">{{ depot.code }}</span>
              <input
                v-show="settingMode"
                v-model="
                  fournisseurs[index].Credential[0].entrepot[index_depot].code
                "
                type="text"
                class="p-1 rounded shadow focus:outline-2 outline-sky-300"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- nouvelle version pour ajouter un dépôt par fournisseur -->
    <div class="mt-6 space-y-4" id="create_depot">
      <div class="relative">
        <div
          class="block flex flex-col space-y-4 bg-white rounded-md p-1 border border-blue-500 shadow-lg peer focus:outline-none focus:ring-0 focus:border-blue-600"
        >
          <div v-if="showCreateDepot" class="p-4 bg-white">
            <div class="flex space-x-4 mt-4">
              <div class="relative">
                <input
                  type="text"
                  id="fournisseur"
                  v-model="fournisseur"
                  class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="fournisseur"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Fournisseur</label
                >
              </div>
              <div class="relative">
                <input
                  type="text"
                  id="prefixe_tarif"
                  v-model="prefixe_tarif"
                  class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="prefixe_tarif"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Préfixe tarif</label
                >
              </div>
              <div class="relative">
                <select
                  type="number"
                  id="priorite"
                  v-model="priorite"
                  class="block w-44 px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                >
                  <option v-for="numero in tabPriorite" :key="numero">{{ numero }}</option>
                </select>
                <label
                  for="priorite"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Priorité</label
                >
              </div>
            </div>
            <div class="flex space-x-4 mt-6">
              <div class="relative my-auto">
                <input
                  type="text"
                  id="login_depot"
                  v-model="loginDepot"
                  class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="login_depot"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Login dépôt</label
                >
              </div>
              <div class="relative my-auto">
                <input
                  type="text"
                  id="mdp_depot"
                  v-model="mdpDepot"
                  class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="mdp_depot"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Mot de passe</label
                >
              </div>
              <div class="relative my-auto">
                <input
                  type="text"
                  id="nom_depot"
                  v-model="nom_depot"
                  class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="nom_depot"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Nom dépôt</label
                >
              </div>
              <div class="relative my-auto">
                <input
                  type="text"
                  id="code_depot"
                  v-model="code_depot"
                  class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                />
                <label
                  for="code_depot"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Code dépôt</label
                >
              </div>
              <button
                class="bg-bluevk text-white hover:bg-blue-600 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
                @click="createDepot()"
              >
                <span class="material-icons-outlined text-xl mr-2">
                  forward
                </span>
                <span>Valider</span>
              </button>
            </div>
          </div>
        </div>

        <button
          for="create_depot"
          @click="showCreateDepot = !showCreateDepot"
          class="absolute flex space-x-1 text-md text-blue-500 hover:text-blue-700 transform -translate-y-4 scale-75 top-2 z-10 origin-[1] bg-gray-50 rounded-md px-1"
        >
          <span class="material-icons-outlined my-auto"> add_circle </span>
          <p>Ajouter un dépôt</p>
        </button>
      </div>

      <!-- Liste dépôts -->
      <div v-for="(depot, index) in tabDepot" :key="index" class="pt-6">
        <div class="border border-orange-500 rounded-md shadow-lg p-4 bg-white">
          <div class="flex space-x-4 mt-4">
            <div class="relative">
              <input
                type="text"
                id="fournisseur"
                v-model="depot.fournisseur"
                class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="fournisseur"
                class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Fournisseur</label
              >
            </div>
            <div class="relative">
              <input
                type="text"
                id="prefixe_tarif"
                v-model="depot.prefixe_tarif"
                class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="prefixe_tarif"
                class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Préfixe tarif</label
              >
            </div>
              <div class="relative">
                <select
                  type="number"
                  id="priorite"
                  v-model="depot.priorite"
                  class="block w-44 px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
                >
                  <option v-for="numero in tabPriorite" :key="numero">{{ numero }}</option>
                </select>
                <label
                  for="priorite"
                  class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >Priorité</label
                >
              </div>
          </div>
          <div class="flex space-x-4 mt-6">
            <div class="relative">
              <input
                type="text"
                id="login_depot"
                v-model="depot.login"
                class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="login_depot"
                class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Login dépôt</label
              >
            </div>
            <div class="relative">
              <input
                type="text"
                id="mdp_depot"
                v-model="depot.mdp"
                class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="mdp_depot"
                class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Mot de passe</label
              >
            </div>
            <div class="relative">
              <input
                type="text"
                id="nom_depot"
                v-model="depot.nom_depot"
                class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="nom_depot"
                class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Nom dépôt</label
              >
            </div>
            <div class="relative">
              <input
                type="text"
                id="code_depot"
                v-model="depot.code_depot"
                class="block px-2.5 pb-1 pt-2 w-full text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer shadow-lg"
              />
              <label
                for="code_depot"
                class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >Code dépôt</label
              >
            </div>
            <button
              class="bg-green-500 text-white hover:bg-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
              @click="updateDepot(depot)"
            >
              <span class="material-icons-outlined text-xl mr-2"> edit </span>
              <span>Modifier</span>
            </button>
            <button
              class="bg-red-500 text-white hover:bg-red-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
              @click="removeDepot(depot, index)"
            >
              <span class="material-icons-outlined text-xl mr-2"> delete </span>
              <span>Supprimer</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import {
  createDepot,
  getAllByPlateform,
  updateOne,
  removeOne,
} from "@/hooks/fournisseur/depotFournisseur";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      fournisseurs: [],
      settingMode: false,
      login: "",
      mdp: "",

      tabDepot: [],
      showCreateDepot: true,
      fournisseur: "",
      prefixe_tarif: "",
      loginDepot: "",
      mdpDepot: "",
      nom_depot: "",
      code_depot: "",
      priorite: null,

      tabPriorite: this.genererTabPriorite(20)
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    genererTabPriorite(nombre) {
      // Génère un tableau de 1 à nombre
      return Array.from({ length: nombre }, (_, index) => index + 1);
    },
    setCredential(fourn) {
      fourn.Credential[0].actif = true;
      fourn.Credential[0].code = fourn.Credential[0].login;
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/setCredential`, {
          plateform: this.user.proprietaire,
          fournisseur: "VAN HECK",
          credential: fourn.Credential[0],
        })
        .then(() => {
          this.settingMode = false;
          this.getFournisseurs();
        });
    },
    getFournisseurs() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getactivated`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.fournisseurs = res.data;
        });
    },
    addEntrepot(index) {
      this.fournisseurs[index].Credential[0].entrepot.push({
        name: "",
        code: "",
      });
    },
    saveInformation() {
      // enregistrer le fournisseur
    },

    // nouvelle version de dépôt
    async getAllDepot() {
      this.tabDepot = await getAllByPlateform(this.user.proprietaire);
    },
    async createDepot() {
      await createDepot(
        this.user.proprietaire,
        this.fournisseur,
        this.prefixe_tarif,
        this.loginDepot,
        this.mdpDepot,
        this.nom_depot,
        this.code_depot,
        this.priorite,
      );
      this.success("Ajouté !");
      (this.fournisseur = ""),
        (this.prefixe_tarif = ""),
        (this.loginDepot = ""),
        (this.mdpDepot = ""),
        (this.nom_depot = ""),
        (this.code_depot = ""),
        (this.priorite = null),
        this.getAllDepot();
    },
    async updateDepot(data) {
      await updateOne(data._id, data);
      this.success("Modifié !");
    },
    async removeDepot(data, index) {
      await removeOne(data._id);
      this.tabDepot.splice(index, 1);
      this.success("Supprimé !");
    },

    // Message
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  mounted() {
    this.getFournisseurs();
    this.getAllDepot();
  },
};
</script>
<style></style>
