<template>
  <div class="space-y-4">
    <div
      class="bg-white p-2 rounded shadow flex flex-col space-y-4"
      v-for="(gp, indexGP) in configurationCatalogue"
      :key="indexGP"
    >
      <div class="text-lg text-bluevk">{{ gp.name }}</div>
      <div
        class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-8 gap-3"
      >
        <div v-for="(famille, indexF) in gp.famille" :key="indexF">
          <div
            class="p-4 rounded border border-gray-400 flex flex-col cursor-pointer hover:border-gray-500 hover:shadow-lg"
            v-if="famille.actif"
          >
            <div class="flex flex-row">
              <div class="flex flex-col w-11/12">
                <img
                  class="h-16 mx-auto"
                  v-if="famille.stateLogo"
                  :src="famille.logo_url"
                  @click="getArticles(famille)"
                />
                <span
                  class="text-lg text-gray-600 mx-auto"
                  @click="getArticles(famille)"
                  >{{ famille.name }}</span
                >
              </div>
              <div
                class="flex flex-row items-center w-1/12"
                v-show="!showSousFamille[indexGP][indexF]"
              >
                <span
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  @click="
                    showSousFamille[indexGP][indexF] =
                      !showSousFamille[indexGP][indexF]
                  "
                  >keyboard_double_arrow_down</span
                >
              </div>
              <div
                class="flex flex-row items-center w-1/12"
                v-show="showSousFamille[indexGP][indexF]"
              >
                <span
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  @click="
                    showSousFamille[indexGP][indexF] =
                      !showSousFamille[indexGP][indexF]
                  "
                >
                  keyboard_double_arrow_up
                </span>
              </div>
            </div>

            <div
              class="flex flex-col text-sm"
              v-show="showSousFamille[indexGP][indexF]"
            >
              <div
                v-for="(sousFamille, indexSF) in famille.sousFamille"
                :key="indexSF"
              >
                <span
                  class="flex flex-row items-center hover:text-blue-400 hover:font-semibold"
                  v-if="sousFamille.actif"
                >
                  <span class="material-icons-round"> arrow_right </span>
                  <div>
                    <span
                      @click="getSousFamilleArticle(sousFamille)"
                      class="text-xs cursor-pointer"
                      >{{ sousFamille.name }}</span
                    >
                  </div></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 flex justify-center">
      <img src="@/assets/tecdoc_logo.jpg" class="" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      showSousFamille: [[], [], [], [], [], [], [], [], [], [], [], [], [], []],
    };
  },
  props: {
    configurationCatalogue: {
      type: Array,
    },
    carId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setfamilleselected"]),
    async getArticles(famille) {
      this.setfamilleselected({ famille: famille, carId: this.carId });
      this.$router.push("/catalogueTecDocNew");
    },
    async getSousFamilleArticle(famille) {
      this.setfamilleselected({ famille: famille, carId: this.carId });
      this.$router.push("/catalogueTecDocSousFamille");
    },
  },
};
</script>
<style></style>
