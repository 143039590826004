<template>
  <div
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.go(-1)"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>
</template>
<script>
export default {
  name: "backButton",
};
</script>
<style></style>
