import axios from "axios";

// Créée un transporteur
export async function createTransporteur(
    data: null,

) {
    const transporteur = await axios.post(
        `${process.env.VUE_APP_API}/transporteur`,
        data
    );
    return transporteur.data;
}

// Récupére les transporteurs
export async function getTransporteurs(
    plateform: string,

) {
    const transporteur = await axios.post(
        `${process.env.VUE_APP_API}/transporteur/getAll`,
        {
            plateform: plateform,

        }
    );
    return transporteur.data;
}

// Récupére les cut-off
export async function getAllCutOff(
    plateform: string,
) {
    const data = await axios.post(
        `${process.env.VUE_APP_API}/transporteur/getAllCutOff`,
        {
            plateform: plateform,
        }
    );
    return data.data;
}

// Récupére un transporteur avec son _id
export async function getTransporteurById(
    plateform: string,
    uid: string,
) {
    const transporteur = await axios.post(
        `${process.env.VUE_APP_API}/transporteur/getById`,
        {
            plateform: plateform,
            id: uid
        }
    );
    return transporteur.data;
}

// Enregistre un transporteur avec son _id
export async function saveTransporteur(
    plateform: string,
    uid: string,
    infoTab: null,
) {
    const transporteur = await axios.put(`${process.env.VUE_APP_API}/transporteur`,
        {
            plateform: plateform,
            id: uid,
            tab: infoTab
        }
    );
    return transporteur.data;
}

// Récupère les tarifs
export async function getAllTarifs(
    plateform: string,
    uid: string,
) {
    const transporteur = await axios.post(`${process.env.VUE_APP_API}/tarifTransporteur/getAll`,
        {
            plateform: plateform,
            id: uid,
        }
    );
    return transporteur.data;
}

// Enregistre les tarifs
// export async function saveTarifs(
//     plateform: string,
//     uid: string,
//     infoTab: object, 
// ) {
//     const transporteur = await axios.post(`${process.env.VUE_APP_API}/tarifTransporteur/save`,
//     {
//         plateform: plateform,
//         id: uid,
//         tab: infoTab
//     }
//     );
//     return transporteur.data;
// }

// Insert un tarif
export async function createTarifTransporteur(
    plateform: string,
    uid: string,
    infoTab: object,
) {
    const transporteur = await axios.post(`${process.env.VUE_APP_API}/tarifTransporteur`,
        {
            plateform: plateform,
            id: uid,
            tab: infoTab
        }
    );
    return transporteur.data;
}

// Modifie un tarif par id
export async function updateTarifTransporteur(
    plateform: string,
    uid: string,
    tarifId: string,
    infoTab: object,
) {
    const transporteur = await axios.post(`${process.env.VUE_APP_API}/tarifTransporteur/updateById`,
        {
            plateform: plateform,
            id: uid,
            tarifId: tarifId,
            tab: infoTab
        }
    );
    return transporteur.data;
}

// Supprime un tarif par id
export async function removeTarifTransporteur(
    plateform: string,
    uid: string,
    tarifId: string,
) {
    const transporteur = await axios.post(`${process.env.VUE_APP_API}/tarifTransporteur/removeById`,
        {
            plateform: plateform,
            id: uid,
            tarifId: tarifId,
        }
    );
    return transporteur.data;
}