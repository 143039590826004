import axios from "axios";

// Récupérer les documents par client
export async function getDocumentByClient(
    plateform: string,
    clientId: string,
    typeDoc: string
) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/${typeDoc}/getDocumentByClient`,
        {
            plateform: plateform,
            clientId: clientId,
        }
    );
    return response.data;
}

// Regrouper les documents par client
export async function groupedDocByClient(
    plateform: string,
    user: string,
    typeDoc: string,
    tabDoc: null
) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/${typeDoc}/groupedDocByClient`,
        {
            plateform: plateform,
            user: user,
            tabDoc: tabDoc,
        }
    );
    return response.data;
}