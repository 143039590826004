<template>
  <div
    class="flex flex-row items-center justify-between p-2 bg-white border border-gray-200 rounded w-36 md:w-44 cursor-pointer"
  >
    <span> {{ text }} </span>
    <label class="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        v-model="state_checkbox"
        class="sr-only peer"
        @change="$emit('updateCheckbox', state_checkbox)"
      />
      <div
        class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
      ></div>
    </label>
  </div>
</template>
<script>
export default {
  name: "checkButton",
  data() {
    return {
      state_checkbox: false,
    };
  },
  props: {
    text: {
      type: String,
      default: "Valider",
    },
  },
};
</script>
<style></style>
