export function getFrDate(date: Date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    // weekday: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
}

export function getFrDateSansH(date: Date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function getFrDateMonth(date: Date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    month: "2-digit",
  });
}

export function getFrDateYear(date: Date) {
  return new Date(date).toLocaleDateString("fr-FR", {
    year: "numeric",
  });
}

export function getDateToISO(formatDate: Date) {
  const dd = String(formatDate.getDate()).padStart(2, "0");
  const mm = String(formatDate.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = formatDate.getFullYear();
  const hh = formatDate.getHours();
  const ss = formatDate.getSeconds();
  const mmm = formatDate.getMinutes();
  const date = yyyy + "-" + mm + "-" + dd + "T" + hh + ":" + mmm + ":" + ss;
  return date;

}