<template>
  <div class="text-gray-500">
    {{ Ref_complete }}
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      Ref_complete: "",
    };
  },
  props: ["article"],
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getRefCourte() {
      await axios
        .post(`${process.env.VUE_APP_API}/catalogue/getRefCourte`, {
          Ref_fournisseur: this.article,
        })
        .then((res) => {
          this.Ref_complete = res.data[0].Ref_fournisseur;
        });
    },
  },
  mounted() {
    this.getRefCourte();
  },
};
</script>
<style></style>
