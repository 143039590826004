<template>
  <div
    v-if="showAjoutPromo"
    :class="`modal ${
      !showAjoutPromo && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showAjoutPromo = false, $emit('close')"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Ajouter une promotion</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showAjoutPromo = false, $emit('close')"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div class="w-full max-w-screen-lg mx-auto">
          <div class="bg-white shadow-md rounded p-10 mb-4 border">
            <div class="grid grid-cols-2 gap-8 mx-3 mb-6">
              <div class="mt-2 text-gray-700">
                <label class="text-sm">Titre</label>
                <div class="relative mt-2 rounded-md shadow-md">
                  <span
                    class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                  >
                    <span class="material-icons-round">title</span>
                  </span>

                  <input
                    v-model="titre"
                    type="text"
                    class="w-full focus:outline-2 outline-sky-300 pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    placeholder="Titre"
                    :class="{ error: formIsValid && !titre }"
                    required
                  />
                </div>
              </div>
              <div class="text-gray-700">
                <label class="text-sm"
                  >Image <span class="text-gray-500">(optionnel)</span></label
                >
                <div class="relative mt-2 rounded-md shadow-md">
                  <input
                    v-show="imageState"
                    type="file"
                    ref="file"
                    accept="image/png, image/jpeg, image/jpg"
                    class="w-full focus:outline-2 outline-sky-300 pl-3 bg-white pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    @change="onFileChange($event)"
                  />
                </div>
                <img
                  class="h-32 w-36"
                  v-if="imagePromo"
                  :src="imagePromo"
                  v-show="!imageState"
                />
                <button
                  class="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 mt-2 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
                  v-if="imagePromo"
                  @click="onImageDelete"
                >
                  supprimer l'image
                </button>
              </div>
            </div>
            <div class="mx-3 mb-6 border-b">
              <label class="text-sm">Référence</label>

              <div class="relative mt-2 rounded-md shadow-md">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">qr_code_2</span>
                </span>

                <input
                  v-model="articleSearch"
                  v-on:keyup.enter="getOneArticle()"
                  @input="autoComplete(articleSearch)"
                  placeholder="P85020, P85024,..."
                  type="text"
                  class="w-full focus:outline-2 outline-sky-300 pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  :class="{ error: formIsValid && !articleSearch }"
                  required
                />

                <ul
                  v-show="showAutoComplete"
                  class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                >
                  <li
                    class="bg-white px-4 hover:bg-gray-300 cursor-pointer"
                    v-for="(auto, index) in autoCompleteTab"
                    :key="index"
                    @click="setAutoComplete(auto.Ref_fournisseur)"
                  >
                    {{ auto.Ref_fournisseur }}
                  </li>
                  <div class="flex justify-end bg-white">
                    <span
                      class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25 cursor-pointer"
                      @click="showAutoComplete = false"
                      >close</span
                    >
                  </div>
                </ul>
              </div>
              <div
                class="flex flex-col cursor-pointer"
                v-if="searchTab.length > 0"
                v-show="showSearch"
              >
                <div
                  @click="handlerArticle(arti.article)"
                  v-for="(arti, index) in searchTab"
                  :key="index"
                >
                  <span>Ref : {{ arti.article.Ref_fournisseur }}</span
                  ><span>
                    Fournisseur : {{ arti.article.fournisseur }}
                    {{ arti.article.Prefixe_tarif }} -
                  </span>
                  <span v-if="arti.article.Code_marque">
                    {{ arti.article.Code_marque }}
                  </span>
                  -
                  <span v-if="arti.article.Description">
                    {{ arti.article.Description }}
                  </span>
                </div>
              </div>
            </div>

            <div
              class="flex justify-between flex-row items-center mb-2 mx-3 text-gray-700"
              v-if="articlePromo.length > 0"
            >
              <div>
                <span class="text-sm">{{
                  articlePromo[0].Ref_fournisseur
                }}</span>
                <span class="text-sm ml-2">{{
                  articlePromo[0].Code_marque
                }}</span>
                <span class="text-sm ml-2"
                  >{{
                    parseFloat(articlePromo[0].Prix_euro).toFixed(2)
                  }}
                  €</span
                >
              </div>
              <div>
                <button
                  class="text-vk-blue bg-vk1 border border-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 mt-2 text-center"
                  @click="
                    findOne(
                      articlePromo[0].Ref_fournisseur,
                      articlePromo[0].Code_marque
                    )
                  "
                >
                  Rechercher une promo
                </button>
                <button
                  class="ml-5 text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-1.5 mt-2 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
                  v-if="articlePromo"
                  @click="onRefDelete"
                >
                  supprimer Réf
                </button>
              </div>

              <div v-for="(exiPromo, index) in foundPromo" :key="index">
                <button
                  class="ml-2 p-1 bg-orange-500 text-white text-sm rounded shadow"
                  v-if="exiPromo.type == 'remise'"
                  @click="setPromoExist(exiPromo)"
                >
                  {{ exiPromo.titre }} : {{ exiPromo.Remise }} %
                </button>
                <button
                  class="ml-2 p-1 bg-orange-500 text-white text-sm rounded shadow"
                  v-else-if="exiPromo.type == 'prixnet'"
                  @click="setPromoExist(exiPromo)"
                >
                  {{ exiPromo.titre }} :
                  {{ parseFloat(exiPromo.PrixNet).toFixed(2) }} €
                </button>
                <button
                  class="ml-2 p-1 bg-orange-500 text-white text-sm rounded shadow"
                  v-else-if="exiPromo.type == 'quantitatif'"
                  @click="setPromoExist(exiPromo)"
                >
                  {{ exiPromo.titre }} :
                  {{ exiPromo.PrixQuantitatif[0].designation }}
                </button>
              </div>
            </div>

            <div class="flex justify-between mb-6 mx-3">
              <div class="mt-2 text-gray-700 w-2/12 mr-5">
                <label class="text-sm">Quantité</label>
                <div class="relative mt-2 rounded-md shadow-md">
                  <input
                    v-model="quantite"
                    type="number"
                    min="1"
                    class="w-full focus:outline-2 outline-sky-300 pl-5 py-1.5 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    :class="{ error: formIsValid && !quantite }"
                    required
                  />
                </div>
              </div>

              <div class="mt-2 text-gray-700 w-9/12 mx-5">
                <label class="text-sm">Désignation</label>
                <div class="relative mt-2 rounded-md shadow-md">
                  <input
                    v-model="designation"
                    type="text"
                    class="w-full focus:outline-2 outline-sky-300 pl-5 py-1.5 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    :class="{ error: formIsValid && !designation }"
                    required
                  />
                </div>
              </div>

              <div class="mt-2 text-gray-700 w-2/12 ml-5">
                <label class="text-sm">Remise</label>
                <div class="flex items-center">
                  <input
                    v-model="remise"
                    type="number"
                    min="0"
                    max="100"
                    class="mt-2 rounded-md shadow-md focus:outline-2 outline-sky-300 pl-5 py-1.5 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                  /><span class="text-xl ml-2">%</span>
                </div>
              </div>

              <div class="mt-2 text-gray-700 w-2/12 mx-5">
                <label class="text-sm">Prix net HT</label>
                <div class="flex items-center">
                  <input
                    v-model="prixnet"
                    type="number"
                    min="0"
                    class="w-full mt-2 rounded-md shadow-md focus:outline-2 outline-sky-300 pl-5 py-1.5 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    :class="{ error: formIsValid && !prixnet }"
                    required
                  /><span class="text-xl ml-2">€</span>
                </div>
              </div>
            </div>

            <div class="flex justify-end mt-6">
              <button
                v-show="!isLoading"
                class="px-6 py-2 mx-3 mt-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                @click="addPromo()"
                type="submit"
              >
                Valider
              </button>
              <button>
                <span class="mx-auto" v-show="isLoading">
                  <PulseLoader color="#2d74da" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import {
  searchArticles,
  prepareArticleimport,
} from "@/hooks/searchEngine/articles";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      showAjoutPromo: true,

      promoTab: [],
      prixQtifTab: [],
      foundPromo: [],
      articleSearch: "",
      titre: "",
      typeRemise: "quantitatif",
      remise: 0,
      prixnet: 0,
      quantite: 0,
      designation: "",
      remiseQtif: 0,
      prixnetQtif: 0,
      fileImage: null,
      imagePromo: null,
      imageState: true,
      articlePromo: [],
      isLoading: false,
      showSearch: false,
      autoCompleteTab: [],
      showAutoComplete: false,
      formIsValid: false,
      searchTab: [],
    };
  },
  props: [ "valueOpenCreer" ],
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters(["user", "comptoirSessionActive", "plateform"]),
  },
  methods: {
    handlerArticle(arti) {
      this.articlePromo.push(arti);
      this.showSearch = false;
    },
    deleteItem(id) {
      axios
        .post(`${process.env.VUE_APP_API}/promo/deletePromo`, {
          id: id,
        })
        .then(() => {
          this.getPromos();
          this.success("La promo a bien été supprimée");
        });
    },
    async getOneArticle() {
      this.showAutoComplete = false;
      if (this.articleSearch != "") {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        this.searchTab = await searchArticles(
          this.articleSearch,
          plateform_mere,
          this.comptoirSessionActive[0].Client[0].Name,
          this.comptoirSessionActive[0].Client[0].Group.Name
        );
        this.showSearch = true;
      } else {
        this.error("Completez le champs");
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.fileImage = file;
      this.imagePromo = URL.createObjectURL(file);
      console.log(this.imagePromo);
      this.imageState = !this.imageState;
    },
    onImageDelete() {
      this.fileImage = null;
      this.imagePromo = null;
      this.imageState = !this.imageState;
      this.$refs.file.value = "";
    },
    onRefDelete() {
      this.articlePromo = [];
      this.articleSearch = "";
    },
    findOne(reference, marque) {
      axios
        .post(`${process.env.VUE_APP_API}/promo/getone`, {
          promoinfo: {
            plateformID: this.user.proprietaire,
            Ref_fournisseur: reference,
            Code_marque: marque,
          },
        })
        .then((res) => {
          this.foundPromo = res.data;
          this.promoTab = res.data;
        });
    },
    changeType(type) {
      this.typeRemise = type;
    },
    addPromo() {
      // var today = new Date();
      // var dd = String(today.getDate()).padStart(2, "0");
      // var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      // var yyyy = today.getFullYear();
      // var hour = today.getHours();
      // var seconds = today.getSeconds();
      // var minutes = today.getMinutes();
      // today =
      //   dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      var fd = new FormData();

      fd.append("image", this.fileImage);
      fd.append("date", new Date());
      fd.append("titre", this.titre);
      fd.append("plateform", this.user.proprietaire);
      fd.append("type", this.typeRemise);
      fd.append("Ref_fournisseur", this.articlePromo[0].Ref_fournisseur);
      fd.append("Code_marque", this.articlePromo[0].Code_marque);
      fd.append("Prefixe_tarif", this.articlePromo[0].Prefixe_tarif);
      fd.append("Remise", this.remise);
      fd.append("PrixNet", this.prixnet);
      fd.append("Quantite", this.quantite);
      fd.append("Designation", this.designation);
      fd.append("designation", this.designationQtif);
      fd.append("PrixQuantitatif", JSON.stringify(this.prixQtifTab));
      fd.append("withImage", true);

      console.log(this.imagePromo);

      if (
        this.remise >= 0 &&
        this.designation != "" &&
        this.quantite > 0 &&
        this.prixnet != "" &&
        this.prixnet >= 0 &&
        this.titre != "" &&
        this.articlePromo[0].Ref_fournisseur &&
        this.imagePromo
      ) {
        (this.isLoading = true),
          axios
            .post(`${process.env.VUE_APP_API}/promo/image`, fd, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.isLoading = false;
              this.getPromos();
              this.success("Promotion ajoutée avec une image!");
            });
      } else if (
        this.remise >= 0 &&
        this.designation != "" &&
        this.quantite > 0 &&
        this.prixnet != "" &&
        this.prixnet >= 0 &&
        this.titre != "" &&
        this.articlePromo[0].Ref_fournisseur &&
        !this.imagePromo
      ) {
        (this.isLoading = true),
          axios
            .post(`${process.env.VUE_APP_API}/promo`, {
              infos: {
                date: new Date(),
                titre: this.titre,
                plateform: this.user.proprietaire,
                type: this.typeRemise,
                Ref_fournisseur: this.articlePromo[0].Ref_fournisseur,
                Code_marque: this.articlePromo[0].Code_marque,
                Prefixe_tarif: this.articlePromo[0].Prefixe_tarif,
                Remise: this.remise,
                PrixNet: this.prixnet,
                Quantite: this.quantite,
                Designation: this.designation,
                PrixQuantitatif: this.prixQtifTab,
                withImage: false,
                designation: this.designationQtif,
              },
            })
            .then(() => {
              this.isLoading = false;
              this.getPromos();
              this.success("Promotion ajoutée sans image!");
            });
      } else {
        this.error("Veuillez entrer tous les champs manquants");
      }
    },
    getPromos() {
      axios
        .get(`${process.env.VUE_APP_API}/promo/${this.user.proprietaire}`)
        .then((res) => {
          this.promoTab = res.data;
        });
    },
    setPromoExist(promo) {
      this.articleSearch = promo.Ref_fournisseur;
      this.prixQtifTab = promo.PrixQuantitatif;
      this.titre = promo.titre;
      this.typeRemise = promo.type;
      this.remise = promo.Remise;
      this.remise1 = promo.remiseQtf;
      this.prixnet = promo.PrixNet;
      this.Quantite = promo.quantite;
      this.Designation = promo.designation;
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    autoComplete(ref) {
      if (this.articleSearch != "") {
        axios
          .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
          .then((response) => {
            this.showAutoComplete = true;
            this.autoCompleteTab = response.data;
          });
      } else {
        this.showAutoComplete = false;
      }
    },
    setAutoComplete(ref) {
      this.articleSearch = ref;
      this.showAutoComplete = false;
      this.getOneArticle();
    },
    validateForm() {
      if (
        !this.titre ||
        !this.articlePromo ||
        !this.quantite ||
        !this.designation ||
        this.remise < 0 ||
        !this.prixnet
      ) {
        this.formIsValid = true;
      } else if (
        this.titre &&
        this.articlePromo &&
        this.quantite &&
        this.designation &&
        this.prixnet
      ) {
        this.articlePromo = [];
        this.titre = "";
        this.articleSearch = "";
        this.quantite = 0;
        this.designation = "";
        this.remise = 0;
        this.prixnet = 0;
      } else {
        this.formIsValid = false;
      }
    },
  },
  mounted() {
    this.showAjoutPromo = this.valueOpenCreer;
  }
};
</script>
