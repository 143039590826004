<template>
  <!-- Modal Ref Article -->
  <div
    v-if="openModal"
    :class="`modal ${
      !openModal && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openModal = false), $emit('closeModalRefFourn', false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 50rem"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="(openModal = false), $emit('closeModalRefFourn', false)"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Références</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openModal = false), $emit('closeModalRefFourn', false)"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Réf
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Prix
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Stock
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Panier
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ref, index) in RefSim" :key="index">
              <td
                v-if="ref.article[0]"
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                <div class="text-sm leading-5 text-gray-900">
                  {{ ref.article[0].Ref_fournisseur }}
                </div>
                <div class="text-sm leading-5 text-gray-900">
                  {{ ref.article[0].Description }}
                </div>
              </td>
              <td
                v-if="ref.article[0]"
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                <div class="text-sm leading-5 text-gray-900">
                  {{ ref.article[0].Code_marque }}
                </div>
              </td>
              <td
                v-if="ref.article[0]"
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ financial(ref.article[0].Prix_euro) }} €
              </td>
              <td
                v-if="ref.article[0]"
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                <stockvue
                  :quantity="tmp_quantity"
                  :Article="ref.article[0]"
                  :key="ref.article[0].Code_EAN"
                />
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                v-if="ref.article[0] && prixtab[index] != 'NOK'"
              >
                <paniervue
                  :article="ref.article[0]"
                  :quantity="1"
                  @updateQuantity="checkQuantity"
                />
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                v-else
              >
                --
              </td>
            </tr>
          </tbody>
        </table>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="(openModal = false), $emit('closeModalRefFourn', false)"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin Modal Ref Article -->
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import stockvue from "@/components/Article/stock.vue";
import paniervue from "@/components/Article/panier.vue";

export default {
  data() {
    return {
      openModal: true,
      RefSim: [],
      tmp_quantity: 1,
    };
  },

  props: ["Ref_fournisseur"],
  computed: {
    ...mapGetters(["user", "prixtab"]),
  },
  components: {
    stockvue,
    paniervue,
  },
  methods: {
    getRefSimilaire() {
      axios
        .post(`${process.env.VUE_APP_API}/vanheck/getRefSimilaire`, {
          Ref_fournisseur: this.Ref_fournisseur,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.RefSim = response.data;
        })
        .catch(() => {
          this.RefSim = [];
        });
    },
    checkQuantity(n) {
      this.tmp_quantity = n;
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  mounted() {
    this.getRefSimilaire();
  },
};
</script>
