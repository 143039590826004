<template>
  <div class="bg-white w-full lg:p-4 xl:p-4 md:p-4 sm:p-4 rounded-sm shadow">
    <div class="flex justify-between">
      <backButton />
      <div class="flex justify-center">
        <button
          @click="createPDF(factureSelected)"
          class="bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded"
        >
          Imprimer
        </button>
      </div>
    </div>

    <div
      v-if="loadingPDF"
      class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-20"
    >
      <div class="bg-white py-5 px-20 rounded border shadow">
        <!-- <svg
                          class="animate-spin h-10 w-10 text-blue-500 mx-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z"></path>
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg> -->

        <div class="loader"></div>
        <p class="text-center mt-2">Chargement...</p>
      </div>
    </div>

    <div class="p-8 w-full">
      <!-- En-tête -->
      <div class="w-full">
        <div
          class="flex flex-col items-center w-full md:flex-row sm:flex-row lg:pb-10 xl:pb-10 md:pb-10 sm:pb-5"
        >
          <div class="w-1/2 sm:w-1/4">
            <img
              :src="plateform.lien_logo"
              alt="Logo de l'entreprise"
              class="w-1/2 h-auto mx-auto"
            />
          </div>
        </div>

        <!-- "Bon de livraison" sous l'image en md et sm -->
        <div class="mt-4 md:mt-0 sm:mt-0 text-center">
          <div
            class="text-gray-700 uppercase font-medium text-xl sm:text-s max-w-3/4 mx-auto"
          >
            Facture
            <span class="text-bluevk">n° {{ factureSelected.Numero }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-end text-gray-700 text-xs font-medium pt-8">
        Le {{ getFRDate(factureSelected.Date) }}
      </div>

      <!-- Adresses -->
      <div class="sm:flex sm:justify-between sm:pt-4 sm:pb-8">
        <div class="sm:w-2/4 md:mr-3 bg-white rounded-sm shadow-md">
          <div
            class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm"
          >
            Expéditeur
          </div>
          <div class="md:flex justify-between">
            <!-- Première partie -->
            <div class="flex-1 md:w-1/2 m-3">
              <div class="text-bluevk text-xs uppercase font-bold">
                {{ plateform.Titre2 }}
              </div>
              <div class="text-bluevk text-xs font-m mt-1">
                {{ plateform.Adresse }} <br />
                {{ plateform.Code_postale }}
                <span class="uppercase">{{ plateform.Ville }}</span
                ><br />
                {{ plateform.Pays }}
              </div>
            </div>
            <!-- Filet entre les deux parties -->
            <div class="my-1 border-l border-gray-300"></div>
            <!-- Deuxième partie -->
            <div class="flex-1 m-3 pb-3">
              <div class="text-xs font-bold text-gray-700">
                {{ plateform.Titre1 }}
              </div>
              <div class="text-xs text-gray-700 font-m mt-1">
                {{ plateform.Email }}<br />
                {{ plateform.Telephone }}
              </div>
              <div class="text-xs text-gray-700 mt-1">
                {{ plateform.Siret }} - {{ plateform.Tva }} -
                {{ plateform.Naf }}
              </div>
            </div>
          </div>
        </div>
        <!-- encart destinataire -->
        <div class="sm:w-2/4 md:ml-3 bg-white rounded-sm shadow-md">
          <div
            class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm"
          >
            Destinataire
          </div>
          <div
            class="md:flex justify-between"
            v-if="factureSelected.clientInfo[0]"
          >
            <!-- Première partie -->
            <div class="flex-1 m-3">
              <div class="text-bluevk text-xs uppercase font-bold">
                {{ factureSelected.Client }}
              </div>
              <div class="text-bluevk text-xs font-m mt-1">
                {{ factureSelected.clientInfo[0].Adresse }}<br />
                {{ factureSelected.clientInfo[0].PostalCode }}
                <span class="uppercase">{{
                  factureSelected.clientInfo[0].City
                }}</span
                ><br />
                {{ factureSelected.clientInfo[0].Country }}
              </div>
            </div>
            <!-- Filet entre les deux parties -->
            <div class="my-1 border-l border-gray-300"></div>
            <!-- Deuxième partie -->
            <div class="flex-1 m-3 pb-3">
              <div class="text-xs text-gray-700 font-bold">
                {{ factureSelected.Name }}
              </div>
              <div class="text-xs text-gray-700 font-m mt-1">
                {{ factureSelected.clientInfo[0].email }}<br />
                {{ factureSelected.clientInfo[0].telephone }}
              </div>
              <div class="text-xs text-gray-700 mt-1">
                {{ factureSelected.clientInfo[0].siret }} -
                {{ factureSelected.clientInfo[0].numerotva }} -
                {{ factureSelected.clientInfo[0].nafape }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="factureSelected.Bdls.length > 0">
        <div v-for="(bdl, indexBdl) in factureSelected.Bdls" :key="indexBdl">
          <div class="md:mt-8">
            <div class="text-xs font-bold text-gray-700">
              Bdl n° : {{ bdl.Numero }}
            </div>
            <div class="text-xs font-bold text-gray-700">
              Servi par : {{ bdl.Name }}
            </div>
            <div class="text-xs italic font-medium text-bluevk ml-auto">
              Commentaire : {{ bdl.Note }}
            </div>
          </div>
          <!-- tableau responsive -->
          <div class="overflow-x-auto">
            <table
              class="mt-2 mb-4 w-full text-gray-700 text-xs rounded-sm border-collapse"
            >
              <!-- Table headers -->
              <thead>
                <tr class="bg-bluevk text-white text-left px-3 rounded-t-sm">
                  <th class="px-2 py-1 text-xs font-medium">Qté</th>
                  <th class="px-2 py-1 text-xs font-medium">Réf.</th>
                  <th class="px-2 py-1 text-xs font-medium">Appellation</th>
                  <th
                    class="px-2 py-1 text-xs font-medium"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    PA €
                  </th>
                  <th
                    class="px-2 py-1 text-xs font-medium"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    Marge sur PV €
                  </th>
                  <th
                    class="px-2 py-1 text-xs font-medium"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    Marge sur PV %
                  </th>
                  <th class="px-2 py-1 text-xs font-medium">Prix brut HT</th>
                  <th class="px-2 py-1 text-xs font-medium">Remise</th>
                  <th class="px-2 py-1 text-xs font-medium">Prix net HT</th>
                  <th class="px-2 py-1 text-xs font-medium">Montant HT</th>
                </tr>
              </thead>

              <!-- Table body -->
              <tbody class="text-xs font-medium">
                <!-- Rows -->
                <tr v-for="(article, index) in bdl.Articles" :key="index">
                  <td class="border border-gray-300 p-2">
                    {{ article.quantity }}
                  </td>

                  <td class="border border-gray-300 p-2">
                    {{ article.Ref_fournisseur }}
                  </td>

                  <td class="border border-gray-300 p-2">
                    {{ article.Description }}
                  </td>

                  <!-- Prix achat -->
                  <td
                    class="border border-gray-300 p-2 font-bold text-orange-600"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    {{ parseFloat(article.PrixAchat).toFixed(2) }}
                    €
                  </td>

                  <!-- Marge sur PV € -->
                  <td
                    class="border border-gray-300 p-2 font-bold text-green-600"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    {{
                      parseFloat(
                        parseFloat(article.Prix_vente).toFixed(2) -
                          parseFloat(article.PrixAchat).toFixed(2)
                      ).toFixed(2)
                    }}
                    €
                  </td>

                  <!-- Marge sur PV % -->
                  <td
                    class="border border-gray-300 p-2 font-bold text-green-600"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    {{
                      parseFloat(
                        ((parseFloat(article.Prix_vente).toFixed(2) -
                          parseFloat(article.PrixAchat).toFixed(2)) /
                          parseFloat(article.Prix_euro).toFixed(2)) *
                          100
                      ).toFixed(2)
                    }}
                    %
                  </td>

                  <td class="border border-gray-300 p-2">
                    {{ parseFloat(article.Prix_euro).toFixed(2) }}€
                  </td>

                  <td class="border border-gray-300 p-2">
                    {{ article.remise }}%
                  </td>

                  <td class="border border-gray-300 p-2">
                    <div v-if="article.PrixFournisseurType != 'prixnet'">
                      {{
                        parseFloat(
                          article.Prix_euro -
                            article.Prix_euro * (article.remise / 100)
                        ).toFixed(2)
                      }}
                    </div>
                    <div v-else>
                      {{ parseFloat(article.Prix_vente).toFixed(2) }}
                    </div>
                  </td>

                  <td class="border border-gray-300 p-2">
                    <div v-if="article.PrixFournisseurType != 'prixnet'">
                      {{
                        parseFloat(
                          (article.Prix_euro -
                            article.Prix_euro * (article.remise / 100)) *
                            article.quantity
                        ).toFixed(2)
                      }}
                    </div>
                    <div v-else>
                      {{
                        parseFloat(
                          article.Prix_vente * article.quantity
                        ).toFixed(2)
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="md:mt-8">
          <div class="text-xs font-bold text-gray-700">
            Servi par : {{ factureSelected.Name }}
          </div>
          <div class="text-xs italic font-medium text-bluevk ml-auto">
            Commentaire : {{ factureSelected.Note }}
          </div>
        </div>
        <!-- tableau responsive -->
        <div class="overflow-x-auto">
          <table
            class="mt-2 mb-4 w-full text-gray-700 text-xs rounded-sm border-collapse"
          >
            <!-- Table headers -->
            <thead>
              <tr class="bg-bluevk text-white text-left px-3 rounded-t-sm">
                <th class="px-2 py-1 text-xs font-medium">Qté</th>
                <th class="px-2 py-1 text-xs font-medium">Réf.</th>
                <th class="px-2 py-1 text-xs font-medium">Appellation</th>
                <th
                  class="px-2 py-1 text-xs font-medium"
                  v-if="user.accountType == 'Administrateur'"
                >
                  PA €
                </th>
                <th
                  class="px-2 py-1 text-xs font-medium"
                  v-if="user.accountType == 'Administrateur'"
                >
                  Marge sur PV €
                </th>
                <th
                  class="px-2 py-1 text-xs font-medium"
                  v-if="user.accountType == 'Administrateur'"
                >
                  Marge sur PV %
                </th>
                <th class="px-2 py-1 text-xs font-medium">Prix brut HT</th>
                <th class="px-2 py-1 text-xs font-medium">Remise</th>
                <th class="px-2 py-1 text-xs font-medium">Prix net HT</th>
                <th class="px-2 py-1 text-xs font-medium">Montant HT</th>
              </tr>
            </thead>

            <!-- Table body -->
            <tbody class="text-xs font-medium">
              <!-- Rows -->
              <tr
                v-for="(article, index) in factureSelected.pdfData"
                :key="index"
              >
                <td class="border border-gray-300 p-2">
                  {{ article.quantite }}
                </td>

                <td class="border border-gray-300 p-2">{{ article.ref }}</td>

                <td class="border border-gray-300 p-2">
                  {{ article.description }}
                </td>

                <!-- Prix achat -->
                <td
                  class="border border-gray-300 p-2 font-bold text-orange-600"
                  v-if="user.accountType == 'Administrateur'"
                >
                  {{
                    parseFloat(
                      factureSelected.Articles[index].PrixAchat
                    ).toFixed(2)
                  }}
                  €
                </td>

                <!-- Marge sur PV € -->
                <td
                  class="border border-gray-300 p-2 font-bold text-green-600"
                  v-if="user.accountType == 'Administrateur'"
                >
                  {{
                    parseFloat(
                      parseFloat(
                        factureSelected.Articles[index].Prix_vente
                      ).toFixed(2) -
                        parseFloat(
                          factureSelected.Articles[index].PrixAchat
                        ).toFixed(2)
                    ).toFixed(2)
                  }}
                  €
                </td>

                <!-- Marge sur PV % -->
                <td
                  class="border border-gray-300 p-2 font-bold text-green-600"
                  v-if="user.accountType == 'Administrateur'"
                >
                  {{
                    parseFloat(
                      ((parseFloat(
                        factureSelected.Articles[index].Prix_vente
                      ).toFixed(2) -
                        parseFloat(
                          factureSelected.Articles[index].PrixAchat
                        ).toFixed(2)) /
                        parseFloat(
                          factureSelected.Articles[index].Prix_euro
                        ).toFixed(2)) *
                        100
                    ).toFixed(2)
                  }}
                  %
                </td>

                <td class="border border-gray-300 p-2">
                  {{
                    parseFloat(
                      factureSelected.Articles[index].Prix_euro
                    ).toFixed(2)
                  }}€
                </td>

                <td class="border border-gray-300 p-2">
                  {{ factureSelected.Articles.remise }}%
                </td>

                <td class="border border-gray-300 p-2">
                  <div
                    v-if="
                      factureSelected.Articles[index].PrixFournisseurType !=
                      'prixnet'
                    "
                  >
                    {{
                      parseFloat(
                        factureSelected.Articles[index].Prix_euro -
                          factureSelected.Articles[index].Prix_euro *
                            (article.remise / 100)
                      ).toFixed(2)
                    }}
                  </div>
                  <div v-else>
                    {{
                      parseFloat(
                        factureSelected.Articles[index].Prix_vente
                      ).toFixed(2)
                    }}
                  </div>
                </td>

                <td class="border border-gray-300 p-2">
                  {{ parseFloat(article.total).toFixed(2) }}€
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex justify-end items-center pb-8 text-gray-700">
        <div class="w-1/3 flex justify-end">
          <table
            class="mt-4 mb-8 w-full rounded border-collapse border border-gray-300"
          >
            <thead></thead>
            <tbody class="text-xs font-medium">
              <!-- Première ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Transport</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(factureSelected.Transport).toFixed(2) }}€
                </td>
              </tr>

              <!-- Deuxième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total HT</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(factureSelected.TotalPrice).toFixed(2) }}€
                </td>
              </tr>

              <!-- Troisième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">TVA</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(factureSelected.TotalPrice * 0.2).toFixed(2) }}€
                </td>
              </tr>

              <!-- Quatrième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total TTC</td>
                <td class="border border-gray-300 p-2">
                  {{
                    parseFloat(
                      factureSelected.TotalPrice +
                        factureSelected.TotalPrice * 0.2
                    ).toFixed(2)
                  }}€
                </td>
              </tr>

              <!-- Cinquième ligne -->
              <tr v-if="user.accountType == 'Administrateur'">
                <td class="border border-gray-300 font-bold p-2">Marge</td>
                <td class="border border-gray-300 font-bold p-2 text-green-600">
                  {{ setMargeTotal }} €, soit {{ setMargeTotalePourcentage }} %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-1/3 flex ml-auto">
        <div
          class="text-xs font-medium flex justify-end items-center text-gray-700 ml-auto"
        >
          <p>
            <span class="font-bold">Date d'échéance :</span> {{ dateEcheance }}
          </p>
        </div>
      </div>
      <!-- Mode de règlement -->
      <div class="flex justify-end items-center pb-8 text-gray-700">
        <div class="w-1/3 flex justify-end">
          <table
            class="mt-2 mb-8 w-full rounded border-collapse border border-gray-300"
            v-if="factureSelected.reglement.length > 0"
          >
            <thead>
              <tr>
                <td
                  class="text-xs font-medium py-1 px-2 rounded-t-sm border border-gray-300 bg-bluevk text-white font-bold p-2"
                  colspan="2"
                >
                  Mode de règlement
                </td>
              </tr>
            </thead>
            <tbody class="text-xs font-medium">
              <tr>
                <td class="border border-gray-300 p-2">
                  <div class="flex flex-row space-x-2">
                    <span
                      v-for="(regl, indexRegl) in factureSelected.reglement"
                      :key="indexRegl"
                    >
                      {{ regl.placeholder }} {{ regl.model }}€
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="FactureImprime" class="mt-4">
      <h2 class="text-xl font-semibold mb-2">Facture</h2>
      <p><strong>Numéro de commande :</strong> {{ numeroCommande }}</p>
      <p><strong>Nom du client :</strong> {{ nomClient }}</p>
      <p><strong>Articles :</strong></p>
      <pre>{{ articles }}</pre>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import { createfacture } from "@/hooks/pdf/facture";
import { createfacturepro } from "@/hooks/pdf/groupebdl";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  data() {
    return {
      bonDeLivraisonImprime: false,
      loadingPDF: false,
    };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["user", "plateform", "factureSelected"]),
    dateEcheance() {
      const dateEditionParts = this.factureSelected.Date.split("/");
      const day = parseInt(dateEditionParts[0], 10);
      const month = parseInt(dateEditionParts[1], 10);
      const year = parseInt(dateEditionParts[2], 10);

      const nextMonth = month === 12 ? 1 : month + 1;
      const nextYear = month === 12 ? year + 1 : year;

      const lastDayOfNextMonth = new Date(nextYear, nextMonth, 0);

      const dayFormatted = String(lastDayOfNextMonth.getDate()).padStart(
        2,
        "0"
      );
      const monthFormatted = String(lastDayOfNextMonth.getMonth() + 1).padStart(
        2,
        "0"
      );
      const yearFormatted = lastDayOfNextMonth.getFullYear();

      return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
    },
    setMargeTotal() {
      var total = 0.0;

      if (this.factureSelected.Bdls.length > 0) {
        for (const bdl of this.factureSelected.Bdls) {
          for (const art of bdl.Articles) {
            if (art.PrixAchat) {
              const marge = parseFloat(
                parseFloat(art.Prix_vente).toFixed(2) -
                  parseFloat(art.PrixAchat).toFixed(2)
              ).toFixed(2);
              total += parseFloat(marge) * art.quantity;
              console.log(total);
            }
          }
        }
      } else {
        for (const art of this.factureSelected.Articles) {
          if (art.PrixAchat) {
            const marge = parseFloat(
              parseFloat(art.Prix_vente).toFixed(2) -
                parseFloat(art.PrixAchat).toFixed(2)
            ).toFixed(2);
            total += parseFloat(marge) * art.quantity;
            console.log(total);
          }
        }
      }

      return total.toFixed(2);
    },
    setMargeTotalePourcentage() {
      var chiffreAffairesPPC = 0.0;
      var chiffreAffairesPV = 0.0;
      var coutTotal = 0.0;

      if (this.factureSelected.Bdls.length > 0) {
        for (const bdl of this.factureSelected.Bdls) {
          for (const art of bdl.Articles) {
            if (art.PrixAchat) {
              chiffreAffairesPPC +=
                parseFloat(art.Prix_euro).toFixed(2) * art.quantity;
              chiffreAffairesPV +=
                parseFloat(art.Prix_vente).toFixed(2) * art.quantity;
              coutTotal += parseFloat(art.PrixAchat).toFixed(2) * art.quantity;
            }
          }
        }
      } else {
        for (const art of this.factureSelected.Articles) {
          if (art.PrixAchat) {
            chiffreAffairesPPC +=
              parseFloat(art.Prix_euro).toFixed(2) * art.quantity;
            chiffreAffairesPV +=
              parseFloat(art.Prix_vente).toFixed(2) * art.quantity;
            coutTotal += parseFloat(art.PrixAchat).toFixed(2) * art.quantity;
          }
        }
      }

      const margeTotalePourcentage =
        ((parseFloat(chiffreAffairesPV) - parseFloat(coutTotal)) /
          parseFloat(chiffreAffairesPPC)) *
        100;
      return margeTotalePourcentage.toFixed(2);
    },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async createPDF(objet) {
      this.loadingPDF = true;
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (objet.regroupe) {
        await this.createPDFfacture(objet._id, dateedition, datepdf);
      } else {
        if (dateedition == datepdf) {
          if (objet.TotalPrice >= 0) {
            createfacture(objet, false, false);
          } else {
            createfacture(objet, false, true);
          }
        } else if (objet.TotalPrice >= 0) {
          createfacture(objet, true, false);
        } else {
          createfacture(objet, true, true);
        }
      }
      this.loadingPDF = false;
    },
    async createPDFfacture(id, dateedition, datepdf) {
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
          id: id,
        })
        .then((objet) => {
          if (dateedition == datepdf) {
            if (objet.data.TotalPrice >= 0) {
              createfacturepro(objet.data, false, false);
            } else {
              createfacturepro(objet.data, false, true);
            }
          } else if (objet.data.TotalPrice >= 0) {
            createfacturepro(objet.data, true, false);
          } else {
            createfacturepro(objet.data, true, true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingPDF = false;
        });
    },
  },
};
</script>

<style>
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
