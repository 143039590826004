import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JsBarcode from "jsbarcode";
import JSZip from "jszip";

export function etiquetteRangement(Code_EAN, Ref_fournisseur) {
  var canvas = document.createElement("canvas");

  JsBarcode(canvas, Code_EAN, {
    format: "ean13",
    lineColor: "#000000", // Assurez-vous d'utiliser une couleur noire pure
    width: 6, // Augmentez la largeur pour une meilleure lisibilité
    height: 150, // Ajustez la hauteur pour correspondre aux étiquettes
    displayValue: false,

  });

  var docDefinition = {
    content: [
      {
        text: Code_EAN,
        bold: true,
        fontSize: 4, // Ajustez la taille pour s'adapter à l'étiquette
        margin: [0, 4, 0, 0],
        alignment: "center",
      },
      {
        image: canvas.toDataURL("image/png"),
        width: 68, // Augmentez la largeur pour une meilleure qualité
        height: 20,
        margin: [0, 0, 0, 0],
        alignment: "center",
      },
      {
        text: Ref_fournisseur,
        bold: true,
        fontSize: 10,
        margin: [0, -1, 0, 0],
        alignment: "center",
      },
    ],
    pageSize: {
      width: 70, // Largeur en mm
      height: 40, // Hauteur en mm
    },
    pageMargins: [0, 0, 0, 0],
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.createPdf(docDefinition).open();
}


export function etiquetteExcelRangement(objet) {
  var docDefinition = {};
  var content = [];
  const zip = new JSZip();
  for (var i = 0; i < objet.length; i++) {
    var convas = document.createElement("canvas");
    var ligneRef = { text: "" };
    if (i + 1 != objet.length) {
      ligneRef = {
        text: "",
        pageBreak: "after",
      };
    }
    var fontRef = 0;
    if (objet[i].ref.length > 12) {
      fontRef = 105;
    } else if (objet[i].ref.length > 11) {
      fontRef = 115;
    } else if (objet[i].ref.length > 10) {
      fontRef = 125;
    } else if (objet[i].ref.length > 8) {
      fontRef = 135;
    } else {
      fontRef = 145;
    }
    JsBarcode(convas, objet[i].ean, {
      format: "ean13",
      lineColor: "#000",
      width: 2,
      height: 50,
      displayValue: false,
      fontOptions: "bold",
    });
    content.push([
      {
        text: objet[i].ean,
        fontSize: 70,
        margin: [0, 80, 0, 0],
        alignment: "center",
        bold: true,
      },
      {
        image: convas.toDataURL("image/png"),
        width: 880,
        height: 250,
        margin: [0, -10, 0, 0],
        alignement: "center",
      },
      {
        text: objet[i].ref,
        fontSize: fontRef,
        margin: [0, 10, 0, 0],
        alignment: "center",
        bold: true,
      },
      ligneRef,
    ]);
  }

  docDefinition = {
    content: [content],
    pageSize: {
      width: 60, // Largeur de la page en mm
      height: 30, // Hauteur de la page en mm
    },
    pageOrientation: "landscape",
    pageMargins: [0, 0, 0, 0],
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.createPdf(docDefinition).getBlob((blob) => {
    zip.file("myPDF.pdf", blob);
    zip.generateAsync({ type: "blob" }).then((content) => {
      const url = window.URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = "myZip.zip";
      link.click();
      window.URL.revokeObjectURL(url);
    });
  });
}
