<template>
  <div
    id="retour"
    class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400 cursor-pointer"
    @click="$router.back()"
  >
    <img src="@/assets/RetourIcon.png" class="w-6" />
    <span style="color: #5976e8">Retour</span>
  </div>
  <div class="flex justify-between">
    <div class="flex flex-row items-center space-x-4">
      <searchInput
        placeholder="Rechercher"
        @searchWord="search(1, 10, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
        class="mt-1"
      />
      <div class="bg-white p-2 rounded shadow">
        <div class="flex flex-row items-center space-x-2">
          <span class="cursor-pointer"> Mis au panier </span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              class="sr-only peer"
              @change="setFilterPanier()"
              v-model="filterPanier"
            />
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
            ></div>
          </label>
          <span
            v-if="stateFilterPanier"
            class="material-icons-outlined text-red-500 cursor-pointer"
            @click="closeFilterPanier()"
          >
            clear
          </span>
        </div>
      </div>
    </div>

    <button
      class="p-2 my-auto bg-green-500 hover:bg-green-600 text-white rounded shadow"
      title="Exporter le tableau"
      @click="exportExcel()"
    >
      Exporter en Excel
    </button>
  </div>
  <div>
    <!-- Breadcrumb -->
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Type
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Client
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Recherche
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Résultat
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Mis au panier
                </th>

                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                class="hover:bg-gray-100 cursor-pointer"
                v-for="(savedS, indexS) in savedSearch"
                :key="indexS"
                @click="openSavedSearch(savedS)"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div v-if="savedS.type == 'search'">Recherche Cross</div>
                  <div v-else-if="savedS.type == 'catalogue_piece_F'">
                    Catalogue Famille
                  </div>
                  <div v-else-if="savedS.type == 'catalogue_piece_SF'">
                    Catalogue Sous Famille
                  </div>
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex flex-row items-center space-x-2">
                    <span>{{ savedS.Client }} </span
                    ><span>{{ savedS.User }}</span>
                  </div>
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ getFRDate(savedS.Date) }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div v-if="savedS.type == 'search'">{{ savedS.Search }}</div>
                  <div v-else>
                    <div v-if="savedS.Search_data.length > 0">
                      {{ savedS.Search_data[0].name }}
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div
                    v-if="savedS.Result.data.length > 0"
                    class="text-green-600 font-bold"
                  >
                    Ok
                  </div>
                  <div v-else class="text-red-600 font-bold">
                    Aucun résultat
                  </div>
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div
                    v-if="savedS.Mise_Panier"
                    class="p-1 bg-green-500 text-white rounded shadow"
                  >
                    Oui
                  </div>
                  <div v-else class="p-1 bg-red-500 text-white rounded shadow">
                    Non
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="savedSearchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(savedSearchLength / 10)"
            :clickFunction="getMvtStock"
            :currentPage="currentPage"
            :typeList="'recherches'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 10)"
            :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
            :currentPage="currentPage"
            :typeList="'recherches'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import {
  getSavedSearch,
  searchInPlateform,
} from "@/hooks/statistiques/savedSearch.ts";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      savedSearch: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      savedSearchLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: null,
      searchInProgress: false,
      searchCatchError: false,

      stateFilterPanier: false,
      stateFilterNoResult: false,
      stateFilterStock: false,
      filterPanier: false,
      filterNoResult: false,
      filterStock: false,
    };
  },
  components: {
    pageNumber,
    searchInput,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async getMvtStock(skipValue, limitValue) {
      this.currentPage = skipValue;
      const savedSearch = await getSavedSearch(
        this.user.proprietaire,
        skipValue,
        limitValue,
        {
          State_panier: this.stateFilterPanier,
          Mise_Panier: this.filterPanier,
        }
      );
      this.savedSearch = savedSearch.savedSearch;
      this.savedSearchLength = savedSearch.count;
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    async search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getMvtStock(this.currentPage, limitValue);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        const searchIn = await searchInPlateform(
          this.user.proprietaire,
          searchTerm,
          (skipValue - 1) * 10,
          limitValue,
          {
            State_panier: this.stateFilterPanier,
            Mise_Panier: this.filterPanier,
          }
        );
        this.searchInProgress = false;
        this.savedSearch = searchIn.results;
        this.searchLength = searchIn.count;
      }
    },
    openSavedSearch(search) {
      this.$router.push({
        name: "detailSavedSearch",
        params: { searchID: search._id },
      });
    },
    async setFilterPanier() {
      if (!this.stateFilterPanier) {
        this.stateFilterPanier = true;
      }
      const savedSearch = await getSavedSearch(this.user.proprietaire, 0, 10, {
        State_panier: this.stateFilterPanier,
        Mise_Panier: this.filterPanier,
      });
      this.savedSearch = savedSearch.savedSearch;
      this.savedSearchLength = savedSearch.count;
    },
    async closeFilterPanier() {
      if (this.stateFilterPanier) {
        this.stateFilterPanier = false;
        this.filterPanier = false;
      }
      const savedSearch = await getSavedSearch(this.user.proprietaire, 0, 10, {
        State_panier: this.stateFilterPanier,
        Mise_Panier: this.filterPanier,
      });
      this.savedSearch = savedSearch.savedSearch;
      this.savedSearchLength = savedSearch.count;
    },
    async exportExcel() {
      var lengthTab;
      if (this.searchLength != null) {
        lengthTab = this.searchLength;
      } else {
        lengthTab = this.savedSearchLength;
      }
      const savedSearch = await getSavedSearch(
        this.user.proprietaire,
        0,
        lengthTab,
        {
          State_panier: this.stateFilterPanier,
          Mise_Panier: this.filterPanier,
        }
      );
      let tabExcl = [];
      for (const search of savedSearch.savedSearch) {
        let tab = [];
        tabExcl.push({
          type: search.type,
          User: search.User,
          Client: search.Client,
          Date: getFrDate(search.Date),
          Search: search.Search,
          Result: search.Result.data.length > 0 ? "Ok" : "Aucun résultat",
          Mise_Panier: search.Mise_Panier ? "Oui" : "Non",
        });
      }
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(tabExcl);
      XLSX.utils.book_append_sheet(wb, ws, "Recherhes enregistrées");

      XLSX.writeFile(wb, "StatsRecherches_" + this.user.proprietaire + ".xlsx");

      this.success("Le fichier a été téléchargé");
    },
  },
  async mounted() {
    this.getMvtStock(0, 10);
    this.pageSwitch();
  },
};
</script>
