<template>
  <div
    id="retour"
    @click="back()"
    class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400 cursor-pointer"
  >
    <img src="../assets/RetourIcon.png" class="w-6" />
    <span style="color: #5976e8">Retour</span>
  </div>
  <div class="mb-10">
    <menuDetailFournisseur />
  </div>
  <div class="flex justify-end">
    <button
      class="p-2 bg-green-500 text-white rounded shadow"
      @click="exportExcel()"
    >
      Exporter en Excel
    </button>
  </div>
  <div>
    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Article
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Code_EAN
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Quantité à commander
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(stockO, indexS) in stockToOrder" :key="indexS">
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ stockO.Ref_fournisseur }}
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ stockO.Code_EAN }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ stockO.qty_to_order }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import menuDetailFournisseur from "../components/Fournisseur/menuDetailFournisseur.vue";
import * as XLSX from "xlsx";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";

export default {
  data() {
    return {
      stockToOrder: [],
      fournisseurTab: {},
    };
  },
  computed: {
    ...mapGetters(["fournisseurID", "user"]),
  },
  components: {
    menuDetailFournisseur,
  },
  methods: {
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    back() {
      this.$router.back();
    },
    getStockToOrder() {
      axios
        .post(`${process.env.VUE_APP_API}/stock/stockToOrder`, {
          plateform: this.user.proprietaire,
          fournisseur: this.fournisseurTab.Prefixe_tarif,
        })
        .then((res) => {
          this.stockToOrder = res.data;
        });
    },
    exportExcel() {
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.stockToOrder);
      XLSX.utils.book_append_sheet(wb, ws, "Commande");

      XLSX.writeFile(wb, "Stock_Commande_" + this.user.proprietaire + ".xlsx");
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  async mounted() {
    await this.getFournisseur();
    this.getStockToOrder();
  },
};
</script>
<style></style>
