import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import store from "../../store";
import { getLastDayMonth } from "../../hooks/tools/getLastDayMonth";
import {
  getFrDate,
  getFrDateMonth,
  getFrDateYear,
  getFrDateSansH,
} from "@/hooks/tools/date.ts";

export async function createfacturepro(objet, duplicata, avoir) {
  var today = new Date();
  var da = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  var hour = today.getHours();
  var minutes = today.getMinutes();
  var edition =
    "Edité le " + da + "/" + mm + "/" + yyyy + " à " + hour + ":" + minutes;
  var datepdf = "Le " + getFrDate(objet.Date) + ",";
  var monthadd = getFrDateMonth(objet.Date);
  var yearadd = getFrDateYear(objet.Date);
  let dateecheance;
  if (objet.paymentDeadline) {
    dateecheance = getFrDateSansH(objet.paymentDeadline);
  } else {
    dateecheance = await getLastDayMonth(parseInt(monthadd), parseInt(yearadd));
  }

  let colDuplicata;
  if (duplicata) {
    colDuplicata = {
      columns: [
        {
          text: "Duplicata",
          style: "invoiceTitleDetail",
          margin: [0, 0, 0, 0],
          width: 390,
        },
      ],
    };
  }

  let colAvoir;
  if (avoir) {
    colAvoir = {
      columns: [
        {
          text: "Avoir",
          style: "invoiceTitle",
          margin: [0, 0, 0, 0],
          width: 390,
        },
      ],
    };
  }

  var reglementMethod = "";
  objet.reglement.forEach((element) => {
    reglementMethod += element.placeholder + " : " + element.model + " € / ";
  });

  var tablesBL = [];
  var produits = [];

  objet.Bdls.forEach((element1) => {
    produits.push([
      {
        text: "Qté",
        style: ["itemsHeader", "center"],
        border: [true, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Produit",
        style: "itemsHeader",
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text:
          "Servi par : " +
          objet.Name +
          "            " +
          "réf. cl. : " +
          element1.Note,
        style: "itemsHeader",
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Px brut HT",
        style: ["itemsHeader", "center"],
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Remise",
        style: ["itemsHeader", "center"],
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Px net HT",
        style: ["itemsHeader", "center"],
        border: [false, true, false, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
      {
        text: "Mt HT",
        style: ["itemsHeader", "center"],
        border: [false, true, true, false],
        borderColor: ["#949494", "#949494", "#949494", "#949494"],
        fillColor: "#EEEEEE",
      },
    ]);
    element1.pdfData.forEach((element, index) => {
      var prixTTC = element1.Articles[index].Prix_euro;
      var prixRemise;
      var remise;
      if (element1.Articles[index].PrixFournisseurType == "prixnet") {
        prixRemise = element1.Articles[index].Prix_vente;
        element1.Articles[index].remise = "prix net";
        element1.pdfData[index].remise = "prix net";
        remise = "prix net";
      } else {
        prixRemise = parseFloat(
          prixTTC - prixTTC * (element.remise / 100)
        ).toFixed(2);
        remise = element.remise + "%";
      }
      produits.push([
        {
          text: element.quantite,
          style: "itemNumber",
          border: [true, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#FAFAFA",
        },
        {
          text: element.ref,
          style: "itemTitle",
          border: [true, false, false, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#F7F7F7",
        },
        {
          text:
            element.commentaire != ""
              ? element.description + "\n \n" + "Note : " + element.commentaire
              : element.description,
          style: "itemTitle",
          border: [false, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#F7F7F7",
        },
        {
          text: parseFloat(prixTTC).toFixed(2),
          style: "itemNumber",
          border: [true, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#FAFAFA",
        },
        {
          text: remise,
          style: "itemNumber",
          border: [true, false, true, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#F7F7F7",
        },
        {
          text: parseFloat(prixRemise).toFixed(2),
          style: "itemNumber",
          border: [true, false, false, false],
          borderColor: ["#949494", "#949494", "#FFFFFF", "#949494"],
          fillColor: "#FAFAFA",
        },
        {
          text: parseFloat(prixRemise * element.quantite).toFixed(2),
          style: "itemNumber",
          border: [false, false, true, false],
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
          fillColor: "#F7F7F7",
        },
      ]);
    });
    tablesBL.push(
      {
        // text: "Servi par : " + element1.Name + "  RC : " + element1.Note,
        text: "BL n°" + element1.Numero + "  du " + getFrDate(element1.Date),
        style: "tableTitle",
      },
      {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          margin: [0, 2],
          widths: [30, 50, 210, 40, 40, 40, 40],
          text: objet.clientInfo[0].nomcommercial,
          body: produits,
        },
      }
    );
    produits = [];
  });

  var footerBody = {};
  footerBody = {
    // headers are automatically repeated if the table spans over multiple pages
    // you can declare how many rows should be treated as headers
    headerRows: 0,
    widths: [180, 80, "*", 80, "*"],

    body: [
      // Total
      [
        {
          text: "",
          style: "itemsFooterTotalTitle",
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
        },
        {
          text: "Transport : ",
          style: "itemsFooterTotalTitle",
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
        },
        {
          text: parseFloat(objet.Transport).toFixed(2) + " €",
          style: "itemsFooterTotalValue",
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
        },
        {
          text: "Total HT : ",
          style: "itemsFooterTotalTitle",
        },
        {
          text: parseFloat(objet.TotalPrice).toFixed(2) + " €",
          style: "itemsFooterTotalValue1",
        },
      ],
      [
        {
          text: "Date d'échéance : " + dateecheance,
          style: "itemsFooterTotalTitle",
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
        },
        {
          text: "TVA : ",
          style: "itemsFooterTotalTitle",
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
        },
        {
          text: parseFloat(objet.TotalPrice * 0.2).toFixed(2) + " €",
          style: "itemsFooterTotalValue",
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
        },
        {
          text: "Total TTC : ",
          style: "itemsFooterTotalTitle",
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
        },
        {
          text:
            parseFloat(objet.TotalPrice + objet.TotalPrice * 0.2).toFixed(2) +
            " €",
          style: "itemsFooterTotalValue1",
          borderColor: ["#949494", "#949494", "#949494", "#949494"],
        },
      ],
    ],
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  let infoCompClient = "";
  if (objet.clientInfo[0].professionnel == true && objet.clientInfo[0].siret) {
    infoCompClient =
      objet.clientInfo[0].siret +
      " - " +
      objet.clientInfo[0].numerotva +
      " - " +
      objet.clientInfo[0].nafape;
  }
  let moyendepaiement = "";
  if (
    objet.clientInfo[0].professionnel == true &&
    objet.clientInfo[0].moyendepaiement
  ) {
    moyendepaiement =
      "Moyen de paiement : " +
      objet.clientInfo[0].moyendepaiement +
      "\n" +
      "N° Client : " +
      objet.clientInfo[0].NumeroClient;
  }

  var dd = {
    header: [
      {
        columns: [
          {
            text: edition,
            style: "documentHeaderRight",
          },
        ],
      },
      // Header
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            image: "imgg",
            width: 100,
            height: 100,
          },
          // margin: [left, top, right, bottom]
          [
            {
              columns: [
                {
                  text: "Facture",
                  style: "invoiceTitle",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            colAvoir,
            {
              columns: [
                {
                  text: "n°" + "100000" + objet.Numero,
                  style: "invoiceTitleDetail",
                  margin: [0, 0, 0, 0],
                  width: 390,
                },
              ],
            },
            colDuplicata,
            {
              stack: [
                {
                  columns: [
                    {
                      text: "",
                      style: "invoiceSubValue",
                      width: 180,
                    },
                    {
                      text: datepdf,
                      style: "invoiceSubValue",
                      margin: [0, 40, 0, 0],
                      width: 190,
                    },
                  ],
                },
              ],
            },
          ],
        ],
      },
      // Billing Headers
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Titre1 + "\n",
            style: "invoiceBillingTitle",
            width: 245,
            margin: [0, 5, 0, 0],
          },
          {
            text: objet.Client + "\n",
            style: "invoiceBillingTitle1",
            width: 245,
            margin: [25, 0, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Titre2 + "\n",
            style: "invoiceBillingSubTitle",
            width: 245,
            margin: [40, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].Adresse
              ? objet.clientInfo[0].Adresse
              : "",
            style: "invoiceBillingDetails",
            width: 210,
            margin: [24, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Adresse,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 10, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 10, 0, 0],
          },
          {
            text: objet.clientInfo[0].Complement
              ? objet.clientInfo[0].Complement
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [-1, -9, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text:
              store.state.plateform.Code_postale +
              " " +
              store.state.plateform.Ville,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text:
              objet.clientInfo[0].PostalCode && objet.clientInfo[0].City
                ? objet.clientInfo[0].PostalCode +
                  " " +
                  objet.clientInfo[0].City
                : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Pays,
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetails",
            width: 0,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].Country
              ? objet.clientInfo[0].Country
              : "",
            style: "invoiceBillingDetails",
            width: 250,
            margin: [0, -20, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Telephone,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 5, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingContactCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].telephone
              ? objet.clientInfo[0].telephone
              : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -5, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: store.state.plateform.Email,
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: objet.clientInfo[0].email ? objet.clientInfo[0].email : "",
            style: "invoiceBillingContactCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text:
              store.state.plateform.Siret +
              " - " +
              store.state.plateform.Tva +
              " - " +
              store.state.plateform.Naf,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 0, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: infoCompClient,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -10, 0, 0],
          },
        ],
      },
      {
        margin: [40, 0, 0, 0],
        columns: [
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, 2, 0, 0],
          },
          {
            text: "",
            style: "invoiceBillingDetailsCompany",
            width: 40,
            margin: [0, 0, 0, 0],
          },
          {
            text: moyendepaiement,
            style: "invoiceBillingDetailsCompany",
            width: 210,
            margin: [0, -8, 0, 0],
          },
        ],
      },
    ],
    footer: function (currentPage, pageCount) {
      return [
        {
          columns: [
            {
              text: "\n",
            },
          ],
        },
        {
          columns: [
            {
              text: `${store.state.plateform.Forme_sociale} ${store.state.plateform.Titre1} au capital de ${store.state.plateform.Capital} € dont le siège social est situé à ${store.state.plateform.Adresse} ${store.state.plateform.Code_postale} ${store.state.plateform.Ville}. \n En cas de retard de paiement, taux de penalité appliqué égal à 3 fois le taux d'intérêt en vigueur. Pas d'escompte en cas de paiement anticipé. Echange ou retour sous 15 jours.`,
              style: "documentFooterCenter",
            },
          ],
        },
        {
          columns: [
            {
              text: `Page ${currentPage} / ${pageCount}`,
              style: "documentFooterCenter1",
            },
          ],
        },
      ];
    },
    content: [
      // Items
      tablesBL,
      "\n\n",
      {
        table: footerBody,
        layout: "lightHorizontalLines",
      },
      {
        absolutePosition: { x: 40, y: 765 },
        table: {
          headerRows: 0,
          widths: [100, "*"],

          body: [
            [
              {
                text: "Mode de règlement",
                style: "itemsFooterResteTitle",
                borderColor: ["#949494", "#949494", "#949494", "#949494"],
              },
              {
                text: reglementMethod,
                style: "itemsFooterReste",
                borderColor: ["#949494", "#949494", "#949494", "#949494"],
              },
            ],
          ],
        },
      },
    ],
    images: {
      imgg: {
        url: `${store.state.plateform.lien_logo}`,
        headers: {
          "Cache-Control": "no-cache",
        },
      },
    },
    styles: {
      // Document Header
      documentHeaderRight: {
        fontSize: 10,
        margin: [0, 10, 15, 5],
        alignment: "right",
      },
      documentHeaderCenter: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
      },
      // Document Footer
      documentFooterLeft: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "left",
      },
      documentFooterCenter: {
        fontSize: 6,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterCenter1: {
        fontSize: 9,
        margin: [0, 1, 0, 0],
        alignment: "center",
      },
      documentFooterRight: {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
      },
      // Invoice Title
      invoiceTitle: {
        fontSize: 22,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      invoiceTitleDetail: {
        fontSize: 10,
        bold: true,
        alignment: "right",
        margin: [0, 0, 0, 15],
      },
      // Invoice Details
      invoiceSubValue: {
        fontSize: 9,
        alignment: "right",
      },
      // Billing Headers
      invoiceBillingTitle: {
        fontSize: 14,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingTitle1: {
        fontSize: 10,
        bold: true,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      invoiceBillingSubTitle1: {
        fontSize: 14,
        alignment: "left",
        margin: [0, 20, 0, 0],
      },
      // Billing Details
      invoiceBillingDetails: {
        margin: [0, 0, 0, 0],
        fontSize: 11,
        alignment: "left",
      },
      invoiceBillingContactCompany: {
        fontSize: 8,
      },
      invoiceBillingDetailsCompany: {
        fontSize: 7,
      },
      // Items Header
      itemsHeader: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        bold: true,
        fillColor: "#CECECE",
      },
      // Item Title
      itemTitle: {
        fontSize: 8,
      },
      itemNumber: {
        margin: [0, 2, 0, 2],
        fontSize: 7,
        alignment: "center",
      },
      itemTotal: {
        margin: [0, 2, 0, 2],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      tableTitle: {
        margin: [0, 2, 0, 0],
        fontSize: 8,
        alignment: "left",
      },
      // Items Footer (Subtotal, Total, Tax, etc)
      itemsFooterSubTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterSubValue: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "center",
      },
      itemsFooterTotalTitle: {
        margin: [0, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue: {
        margin: [-30, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "left",
      },
      itemsFooterTotalValue1: {
        margin: [-10, 5, 0, 5],
        fontSize: 10,
        bold: true,
        alignment: "right",
      },
      itemsFooterResteTitle: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
        bold: true,
        fillColor: "#CECECE",
      },
      itemsFooterReste: {
        fontSize: 8,
        margin: [0, 2, 0, 2],
      },
      //signaturePlaceholder: {
      //    margin: [0,70,0,0],
      //},
      //signatureName: {
      //    bold: true,
      //    alignment:'center',
      //},
      //signatureJobTitle: {
      //    italics: true,
      //    fontSize: 10,
      //    alignment:'center',
      //},
      notesTitle: {
        fontSize: 10,
        bold: true,
        margin: [0, 50, 0, 3],
      },
      notesText: {
        fontSize: 10,
      },
      center: {
        alignment: "center",
      },
    },
    pageMargins: [40, 270, 40, 55],
    defaultStyle: {
      columnGap: 20,
    },
  };
  pdfMake.createPdf(dd).open();
}
