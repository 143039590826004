<template>
  <div v-if="caisse && comptoirSessionActive.length > 0" class="-mt-2">
    <!-- MODALS -->
    <addArticle v-if="showArticle" :showArticle="showArticle" />
    <choiceDaSilva
      v-if="
        plateform.structure_depot == true &&
        comptoirSessionActive[0].documentType == 'Bon de livraison' &&
        comptoirSessionActive[0].consigneData.length > 0 &&
        comptoirSessionActive[0].Client[0].numcomptedasilva &&
        comptoirSessionActive[0].Client[0].numcomptedasilva != ''
      "
    />

    <modalPanierLKQ v-if="openRecapVH" @closeModalLKQ="openRecapVH = $event" />

    <remisesFournisseurs
      v-if="openRefSimil"
      :Ref_fournisseur="refSimil"
      @closeModalRemFourn="openRefSimil = $event"
    />

    <referencesFournisseurs
      v-if="openRefArticle"
      :Ref_fournisseur="refSimil"
      @closeModalRefFourn="openRefArticle = $event"
    />

    <!-- <modalTransport
      v-if="openModalTransport"
      @closeModalTransport="openModalTransport = $event"
      @dataTransport="(this.dataTransport = $event), saveDocument()"
      :comptoir="true"
      :client="comptoirSessionActive[0].Client"
      :fraisdeport="comptoirSessionActive[0].fraisdeport"
    /> -->

    <!-- modal image ref -->
    <div
      v-if="openModalArticle"
      :class="`modal ${
        !openModalArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openModalArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 68vw; max-height: 80vh"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 pt-4 text-left modal-content">
          <div class="flex items-center justify-end pb-3">
            <!-- <p class="text-2xl font-bold">Titre</p> -->
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openModalArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <article-info :key="articleInfo_componentKey" />
        </div>
      </div>
    </div>
    <!-- fin modal image ref -->

    <!-- modal promos  -->
    <div
      v-if="promoShow"
      :class="`modal ${
        !promoShow && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="promoShow = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 72rem; max-height: 42rem"
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between mb-3">
            <p class="text-2xl font-bold">Promotions</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="promoShow = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Référence
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Quantité
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Désignation
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Remise
                </th>

                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-white uppercase border-b border-gray-200 bg-gray-50 bg-vk"
                >
                  Prix net HT
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="item in foundPromo"
                :key="item.id"
                @click="
                  setPrixQuantitatif(item.Quantite, item.PrixNet),
                    (promoShow = false)
                "
                class="cursor-pointer bg-vk1"
              >
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ item.Ref_fournisseur }} -
                  {{ item.Code_marque }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ item.Quantite }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ item.Designation }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ item.Remise }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(item.PrixNet).toFixed(2) }} €
                </td>
              </tr>
            </tbody>
          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="promoShow = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- fin modal promos -->

    <!-- modal encaissement -->
    <div
      v-if="openSave"
      :class="`modal ${
        !openSave && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openSave = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 72rem; max-height: 52rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Encaissement</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openSave = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Commentaire interne</label>

            <div class="relative mt-2 rounded-md shadow-sm">
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <span class="material-icons-round"> subtitles </span>
              </span>

              <input
                type="text"
                v-model="commentaireEncaissement"
                class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none"
              />
            </div>
          </div>

          <div class="px-5 py-6 text-gray-700 bg-gray-200 border-b">
            <label class="text-xs">Mode de règlement</label>
            <div class="relative">
              <label class="text-xs"
                >Montant à payer : {{ financial(setTotalTTC) }} €</label
              >
            </div>

            <div class="relative flex justify-center p-1">
              <div
                class="ml-6"
                v-for="(moyen, ind) in moyenPaiement"
                :key="ind"
              >
                <div class="flex flex-col">
                  <img
                    class="w-16 border border-black rounded-md m-auto cursor-pointer hover:shadow focus:outline-2 outline-sky-300"
                    :src="moyen.image"
                    @click="setReglement(moyen.abbrev)"
                  />
                  <span style="font-size: 8px" class="m-auto">{{
                    moyen.type
                  }}</span>
                </div>
              </div>
            </div>

            <div
              class="relative mt-2 rounded-md shadow-sm"
              v-for="(reglement, index) in comptoirSessionActive[0]
                .reglementTab"
              :key="index"
            >
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <img
                  class="w-9 h-9 rounded-md m-auto rounded-md"
                  :src="reglement.img"
                />
              </span>

              <input
                type="number"
                :placeholder="reglement.placeholder"
                :disabled="
                  comptoirSessionActive[0].reglementTab[index].disabled
                "
                v-model="comptoirSessionActive[0].reglementTab[index].model"
                class="w-2/5 focus:outline-2 outline-sky-300 pl-16 pr-2 py-2 border-transparent rounded-md appearance-none"
              />
              <input
                @change="errorEncaissement = false"
                type="date"
                :disabled="
                  comptoirSessionActive[0].reglementTab[index].disabled
                "
                v-model="comptoirSessionActive[0].reglementTab[index].date"
                :min="todayDate"
                class="ml-2 focus:outline-2 outline-sky-300 w-2/5 px-5 py-2 border-transparent rounded-md appearance-none"
              />
              <span
                v-if="!comptoirSessionActive[0].reglementTab[index].disabled"
                class="w-1/5 px-5 py-2 border-transparent rounded-md appearance-none"
              >
                <span
                  class="mt-2 material-icons-outlined text-red-600 cursor-pointer"
                  @click="removeReglement(index)"
                >
                  clear
                </span>
              </span>
            </div>

            <div class="relative mt-3" v-show="errorPaymentMethod">
              <label class="text-xs text-red-500"
                >Cette méthode de paiement n'existe pas</label
              >
            </div>
            <div class="relative mt-3" v-show="errorEncaissement">
              <label class="text-xs text-red-500"
                >Veuillez encaisser la facture</label
              >
            </div>
            <div class="relative mt-3">
              <label class="text-l"
                >Reste dû : {{ financial(setRestant) }} €</label
              >
              <label class="text-xs"> - </label>
              <label class="text-l"
                >A rendre : {{ financial(setRendu) }} €</label
              >
            </div>
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openSave = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="saveDocument()"
              class="cursor-pointer px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
              v-if="!loadingValider"
            >
              Enregistrer
            </button>
            <button
              class="cursor-pointer px-6 py-3 font-medium tracking-wide text-white bg-gray-300 rounded-md hover:bg-blue-500 focus:outline-none animate-pulse"
              disabled
              v-else-if="loadingValider"
            >
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- fin modal encaissement -->

    <!-- Modal Retour -->
    <div
      v-if="openRetour"
      :class="`modal ${
        !openRetour && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="(openRetour = false), (tabRetourHistorique = true)"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 100rem; max-height: 100rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p v-show="tabRetourHistorique" class="text-2xl font-bold">
              Retour pièce ou consigne
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="(openRetour = false), (tabRetourHistorique = true)"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div
            class="flex cursor-pointer rounded-md bg-blue-600 hover:bg-blue-500 w-fit px-2 shadow-md text-white"
            v-show="!tabRetourHistorique"
            @click="tabRetourHistorique = !tabRetourHistorique"
          >
            <span class="material-icons-round my-auto"> arrow_back </span>
            <span class="my-auto">Retour</span>
          </div>
          <div>
            <div
              class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 hover:bg-white"
              style="height: 50vh"
            >
              <div
                v-if="tabRetourHistorique && loadingSearchDocToReturn"
                class="flex justify-center animate-pulse m-2"
              >
                Chargement en cours...
              </div>
              <div v-else-if="tabRetourHistorique && !loadingSearchDocToReturn">
                <div
                  class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto"
                >
                  <table class="min-w-full">
                    <thead>
                      <tr>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Doc n°
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Date
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Client
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Créé par
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Prix total
                        </th>
                        <th
                          class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                        >
                          Référence client
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr
                        v-for="(historiqueFile, index) in tabRetour"
                        :key="index"
                        @click="attachRetour(historiqueFile)"
                        class="cursor-pointer hover:bg-blue-400 hover:bg-opacity-25"
                      >
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ historiqueFile.type }} N°
                          {{ historiqueFile.Numero }}
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ getFRDate(historiqueFile.Date) }}
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ historiqueFile.Client }}
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ historiqueFile.Name }}
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ financial(historiqueFile.TotalPrice) }} €
                        </td>
                        <td
                          class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                        >
                          {{ historiqueFile.Note }}
                        </td>

                        <!-- <td class="px-6 py-3 border-b border-gray-200 bg-gray-50"></td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!-- New -->
              <h4 v-if="!tabRetourHistorique" class="text-2xl font-medium">
                {{ retourSelected.type }} n° {{ retourSelected.Numero }}
              </h4>
              <div
                v-if="!tabRetourHistorique"
                class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto"
              >
                <table class="min-w-full" style="width: 65rem">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Réf.
                      </th>
                      <th
                        class="w-80 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Description / Marque
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Qté
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        P.V.
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Remise
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Px final
                      </th>
                      <th
                        class="border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Total
                      </th>
                      <th
                        class="border-r px-6 py-3 text-xs font-medium leading-4 tracking-wider text-blue-600 uppercase text-center border-b border-gray-200 bg-gray-50"
                      >
                        Qté déjà retourné
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      ></th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr
                      v-for="(
                        retourArticlesS, indexR
                      ) in retourSelected.Articles"
                      :key="indexR"
                    >
                      <!-- réference -->
                      <td
                        class="px-2 border-b border-gray-200 whitespace-nowrap tableTDTH w-44"
                      >
                        <div class="flex flex-col space-y-2">
                          <div>{{ retourArticlesS.Ref_fournisseur }}</div>
                        </div>
                      </td>

                      <!-- description / marque -->
                      <td
                        class="w-80 px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="text-sm leading-5 whitespace-normal w-fit">
                          {{ retourArticlesS.Description }}
                        </div>
                        <div class="text-xs leading-5 text-gray-900">
                          {{ retourArticlesS.Code_marque }}
                        </div>
                      </td>

                      <!-- quantité -->
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <input
                          disabled
                          class="m-auto focus:outline-2 outline-sky-300 border border-green-600"
                          style="width: 6vh"
                          type="number"
                          :value="retourArticlesS.quantity"
                        />
                      </td>

                      <!-- prix vente -->
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        {{ parseFloat(retourArticlesS.Prix_euro).toFixed(2) }} €
                      </td>

                      <!-- remise / prix net -->
                      <td
                        class="px-2 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="flex text-sm leading-5 text-gray-900">
                          <div class="flex">
                            <p
                              v-if="
                                retourArticlesS.PrixFournisseurType != 'prixnet'
                              "
                            >
                              {{ financial(retourArticlesS.remise) }} %
                            </p>
                            <p v-else>
                              {{ retourArticlesS.PrixFournisseurType }}
                            </p>
                          </div>
                        </div>
                      </td>

                      <!-- prix final -->
                      <td
                        class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div
                          class="flex space-x-1 text-sm leading-5 text-gray-900"
                        >
                          <p
                            v-if="
                              retourArticlesS.PrixFournisseurType != 'prixnet'
                            "
                          >
                            {{
                              financial(
                                retourArticlesS.Prix_euro -
                                  retourArticlesS.Prix_euro *
                                    (retourArticlesS.remise / 100)
                              )
                            }}
                          </p>
                          <p v-else>
                            {{ financial(retourArticlesS.Prix_vente) }}
                          </p>

                          <p>€</p>
                        </div>
                      </td>

                      <!-- total -->
                      <td
                        class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div
                          class="flex space-x-1 text-sm leading-5 text-gray-900"
                        >
                          <span
                            v-if="
                              retourArticlesS.PrixFournisseurType != 'prixnet'
                            "
                          >
                            {{
                              financial(
                                (retourArticlesS.Prix_euro -
                                  retourArticlesS.Prix_euro *
                                    (retourArticlesS.remise / 100)) *
                                  retourArticlesS.quantity
                              )
                            }}
                          </span>
                          <span v-else>
                            {{
                              financial(
                                retourArticlesS.Prix_vente *
                                  retourArticlesS.quantity
                              )
                            }}
                          </span>
                          <span>€</span>
                        </div>
                      </td>

                      <!-- quantité déjà retourné -->
                      <td
                        class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <input
                          disabled
                          class="mx-auto flex justify-center focus:outline-2 outline-sky-300 border border-blue-600"
                          style="width: 6vh"
                          type="number"
                          :value="retourArticlesS.quantityReturned"
                        />
                      </td>

                      <!-- ajout panier -->
                      <td
                        class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        <input
                          class="focus:outline-2 outline-sky-300 border border-red-600"
                          style="width: 6vh"
                          type="number"
                          min="0"
                          :placeholder="
                            retourArticlesS.quantity -
                            retourArticlesS.quantityReturned
                          "
                          :max="
                            parseInt(
                              retourArticlesS.quantity -
                                retourArticlesS.quantityReturned
                            )
                          "
                          v-model="taQtyReturned[indexR]"
                        />
                        <button
                          class="ml-4 material-icons-round text-xs px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
                          @click="
                            addReturnCart(
                              retourArticlesS,
                              retourSelected.Date,
                              taQtyReturned[indexR],
                              retourSelected._id,
                              retourArticlesS.Code_EAN,
                              indexR
                            )
                          "
                        >
                          shopping_cart
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <span v-show="showErrorMsgReturn" class="text-red-600"
              >Retour supérieur à l'achat !</span
            >
            <!-- <div
              class="flex justify-end space-x-4 py-4"
              v-show="!tabRetourHistorique"
            >
              <span>Qté à retourner : </span>
              <input
                class="focus:outline-2 outline-sky-300 border border-red-600"
                style="width: 6vh"
                type="number"
                min="0"
                :max="quantityReturned"
                v-model="qtyReturned"
              />
            </div> -->
          </div>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="
                (openRetour = false),
                  (tabRetourHistorique = !tabRetourHistorique),
                  (tabRetourHistorique = true)
              "
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <!-- <button
              @click="addRetour()"
              v-show="!tabRetourHistorique"
              class="cursor-pointer px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Valider
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Retour -->

    <!-- Modal Détails dispo -->
    <div
      v-if="openDetailsDispo"
      :class="`modal ${
        !openDetailsDispo && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openDetailsDispo = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 50rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="openDetailsDispo = false"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Disponibilité</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openDetailsDispo = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Dispo.
                </th>
                <!-- <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equivalent A
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equivalent B
                </th> -->
              </tr>
            </thead>
            <tbody v-if="tabDispoUAN[indexDelaisLivraison]">
              <tr>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  Livraison Jour
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex space-x-12">
                    <h1>
                      {{ tabDispoUAN[indexDelaisLivraison].qtyJour }}
                    </h1>
                    <h1 class="bg-gray-600 px-2 text-white">
                      {{
                        getFRDate(tabDispoUAN[indexDelaisLivraison].dateJour)
                      }}
                    </h1>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  Livraison Nuit
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex space-x-12">
                    <h1>
                      {{ tabDispoUAN[indexDelaisLivraison].qtySoir }}
                    </h1>
                    <h1
                      v-if="
                        parseInt(tabDispoUAN[indexDelaisLivraison].dateSoir) !=
                        1980
                      "
                      class="bg-gray-600 px-2 text-white"
                    >
                      {{
                        getFRDate(tabDispoUAN[indexDelaisLivraison].dateSoir)
                      }}
                    </h1>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="openDetailsDispo = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal Détails dispo -->

    <!-- Modal recap cmd vanheck -->
    <!-- <div
      :class="`modal ${
        !openRecapVH && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        v-if="detailRecapVH"
        @click="openRecapVH = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>
      <div
        v-else
        @click="(openRecapVH = false), getDoc()"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 50rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          v-if="detailRecapVH"
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="openRecapVH = false"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <div
          v-else
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
          @click="(openRecapVH = false), getDoc()"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>
        <div class="px-6 py-4 text-left modal-content">
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold" v-show="detailRecapVH">
              Récapitulatif de la commande
            </p>
            <div
              v-if="detailRecapVH"
              class="z-50 cursor-pointer modal-close"
              @click="openRecapVH = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>

            <div
              v-else
              class="z-50 cursor-pointer modal-close"
              @click="(openRecapVH = false), getDoc()"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>


          <div
            class="flex justify-end mt-4 space-x-4"
            v-show="!commandState"
            v-if="VH_a02Tab.length > 0"
          >
            <div class="flex space-x-4">
              <div
                class="p-2 bg-sky-500 text-white rounded shadow-lg hover:bg-sky-300 cursor-pointer"
                @click="changeDepot('STANDARD', -1, plateform.grossierID)"
              >
                Standard
              </div>
              <div
                v-for="(depotFound, indexdepot) in depotTab"
                :key="indexdepot"
              >
                <div
                  class="p-2 bg-sky-500 text-white rounded shadow-lg hover:bg-sky-300 cursor-pointer"
                  @click="
                    changeDepot(depotFound.name, indexdepot, depotFound.code)
                  "
                >
                  {{ depotFound.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="flex space-x-1 justify-center" v-show="!commandState">
            <div
              class="py-2 mt-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
              v-show="detailRecapVH"
            >
              <div
                class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
              >
                <table class="min-w-full" style="max-height: 18vh">
                  <thead>
                    <tr>
                      <th
                        class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Ref
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Description
                      </th>
                      <th
                        style="width: 3%"
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Qté
                      </th>
                      <th
                        style="width: 10%"
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Dispo
                      </th>
                      <th
                        style="width: 5%"
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Prix
                      </th>
                    </tr>
                  </thead>

                  <tbody class="bg-white" v-if="VH_a02Tab[indexDepot]">
                    <tr
                      v-for="(line, index) in VH_a02Tab[indexDepot].lines"
                      :key="index"
                    >
                      <td
                        class="px-2 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        {{ line.B1_Artikelnummer[0] }}
                        <span
                          v-show="!loadingCommand"
                          class="inline-flex px-2 text-xs font-semibold leading-5 text-red-500 rounded-full"
                          style="cursor: pointer"
                          @click="deleteLine(index)"
                        >
                          <span
                            class="material-icons-round bg-red-500 rounded-sm text-white"
                            style="font-size: small"
                          >
                            clear
                          </span>
                        </span>
                      </td>

                      <td
                        class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        {{ line.B1_Omschrijving[0] }}
                      </td>

                      <td
                        style="width: 3%"
                        class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        <input
                          v-show="!loadingCommand"
                          type="number"
                          v-model="qtyCmdVH[index]"
                          class="border rounded-md focus:outline-2 outline-sky-300"
                          style="width: 4vh"
                          :placeholder="
                            parseInt(line.B1_LA_Aantal_response[0]) +
                            parseInt(line.B1_LB_Aantal_response[0])
                          "
                          @keyup.enter="
                            getDispoCmdVH(
                              this.VH_articleTab[index].Ref_fournisseur,
                              this.VH_articleTab[index].Code_marque,
                              qtyCmdVH[index],
                              index
                            )
                          "
                        />
                        <span v-show="loadingCommand">
                          {{
                            parseInt(line.B1_LA_Aantal_response[0]) +
                            parseInt(line.B1_LB_Aantal_response[0])
                          }}
                        </span>
                      </td>

                      <td
                        v-if="showDispoCmdVH[index]"
                        class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                      >
                      </td>

                      <td
                        v-else
                        style="width: 10%"
                        class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        <div class="space-y-1">
                          <div v-if="line.B1_LA_DatumTijd[0]" class="flex">
                            <div
                              class="flex border border-blue-700 p-0.5 rounded shadow"
                            >
                              <span
                                class="material-icons-outlined text-blue-700"
                                style="font-size: 15px"
                                >nightlight</span
                              >
                              <span class="text-xs text-black">
                                {{ line.B1_LA_Aantal_response[0] }}
                              </span>
                            </div>
                            <div class="ml-2">
                              le {{ line.B1_LA_DatumTijd[0] }}
                            </div>
                          </div>

                          <div
                            v-if="parseInt(line.B1_LB_Aantal_response[0]) > 0"
                            class="flex"
                          >
                            <div
                              v-if="line.B1_LB_DatumTijd[0]"
                              class="flex border border-yellow-500 p-0.5 rounded shadow"
                            >
                              <span
                                class="material-icons-outlined text-yellow-500"
                                style="font-size: 15px"
                                >light_mode</span
                              >
                              <span class="text-xs text-black">
                                {{ line.B1_LB_Aantal_response[0] }}
                              </span>
                            </div>
                            <div class="ml-2">
                              le {{ line.B1_LB_DatumTijd[0] }}
                            </div>
                          </div>
                        </div>
                      </td>

                      <td
                        style="width: 5%"
                        class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        <div class="flex flex-col">
                          <div>
                            {{ parseFloat(line.B1_Brutoprijs[0]).toFixed(2) }} €
                          </div>

                          <div
                            class="flex flex-col"
                            v-if="parseFloat(line.B1_Korting[0]) > 0"
                          >
                            <span class="text-gray-800 text-xs"
                              >PA :
                              {{
                                parseFloat(
                                  parseFloat(line.B1_Brutoprijs[0]) -
                                    parseFloat(line.B1_Brutoprijs[0]) *
                                      (parseFloat(line.B1_Korting[0]) / 100)
                                ).toFixed(2)
                              }}
                              €</span
                            >
                            <span class="text-gray-800 text-xs"
                              >( {{ line.B1_Korting[0] }} % )</span
                            >
                          </div>

                          <span
                            class="text-gray-800 text-xs"
                            v-else-if="line.B1_Nettoprijs[0]"
                            >PA : {{ line.B1_Nettoprijs[0] }} €</span
                          >

                          <span class="text-gray-800 text-xs" v-else
                            >Pas de remise</span
                          >

                          <span
                            class="text-gray-800 text-xs"
                            v-if="parseFloat(line.B1_Korting[0]) == 0"
                            >(prix net)</span
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="flex justify-center" v-show="cmdEncaissementVH">
            <div class="flex flex-col border-t" v-show="!commandState">
              <p class="text-2xl font-bold text-red-600">
                Voulez-vous passer la commande ?
              </p>
              <div class="flex flex-col">
                <div class="flex justify-center space-x-6 text-xl text-white">
                  <div
                    class="cursor-pointer mb-1 py-1 px-2 rounded-md bg-sky-400 hover:bg-sky-300"
                    @click="getCmdVH()"
                  >
                    <span class="">oui</span>
                  </div>
                  <div
                    id="select"
                    class="cursor-pointer mb-1 py-1 px-2 rounded-md bg-red-400 hover:bg-red-300"
                    @click="(openRecapVH = false), getDoc()"
                  >
                    <span class="">non</span>
                  </div>
                </div>
                <div
                  class="mt-6 flex flex-row space-x-4 justify-center items-center"
                  v-show="loadingCommand"
                >
                  <span>Commande en cours...</span>
                  <PulseLoader color="#3b82f6" v-show="true" />
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-end pt-2">
            <button
              v-if="detailRecapVH"
              @click="openRecapVH = false"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              v-else
              @click="(openRecapVH = false), getDoc()"
              class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Fin modal récap cmd vanheck -->

    <!-- FIN MODALS -->

    <!-- BODY -->
    <div class="ml-8 flex justify-between -mt-4">
      <div class="flex leading-5 text-gray-900 text-xs">
        <!-- <span
          >Commande de la journée:
          {{ financial(comptoirSessionActive[0].totalPanier) }} € /
          {{ financial(comptoirSessionActive[0].francodeport) }}
          €</span
        > -->
        <div
          v-if="panierTransportValue"
          class="flex justify-end space-x-2 text-xs leading-5 text-gray-900"
        >
          <div class="flex space-x-4">
            <p>
              Total hors taxes des commandes jusqu'à
              {{ panierTransportValue.cutoff }} :
              {{ financial(panierTransportValue.BCtotalPrice) }} € /
              {{ financial(comptoirSessionActive[0].Client[0].francodeport) }} €
            </p>
            <p
              v-if="panierTransportValue.shippingCosts === 0"
              class="text-green-600"
            >
              Franco atteint ! Vous bénéficiez de la livraison gratuite pour les
              commandes passées jusqu'à {{ panierTransportValue.cutoff }}.
            </p>
            <p v-else class="text-blue-600">
              Franco non atteint ! Le frais de port s'élève à
              {{ panierTransportValue.shippingCosts }} € pour les commandes passées
              jusqu'à {{ panierTransportValue.cutoff }}.
            </p>
          </div>
        </div>
        <p class="ml-6">
          Tél. : {{ comptoirSessionActive[0].Client[0].telephone }}
        </p>
        <p class="ml-6">
          Tél2. : {{ comptoirSessionActive[0].Client[0].telephone2 }}
        </p>
      </div>
      <div
        v-if="comptoirSessionActive[0].repriseTypeDoc != null"
        class="my-auto text-sm text-green-700 font-medium"
      >
        Reprise {{ comptoirSessionActive[0].repriseTypeDoc }} n°
        {{ comptoirSessionActive[0].repriseNumDoc }}
      </div>
    </div>
    <div class="flex -ml-2 space-x-1">
      <!-- début partie outils -->
      <div class="">
        <div
          style="width: 2rem"
          class="flex flex-col space-y-4 bg-white bg-opacity-50 rounded-md py-1 px-0.5 h-fit"
        >
          <!-- LKQ -->
          <div class="flex space-x-7 m-auto">
            <a href="https://www.partsnet.fr/pno.aspx" target="_blank">
              <img
                src="../assets/Fournisseurs/LKQ.jpg"
                @mouseover="showNameCmdVH = true"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
              />
            </a>
            <div
              style="font-size: 10px; margin-left: -15px"
              class="absolute flex justify-end rounded-full w-6 h-5 bg-red-700 text-white m-auto"
              v-if="articleTotalLKQ"
            >
              <span class="m-auto">{{ articleTotalLKQ }}</span>
            </div>
            <!-- menu LKQ -->
            <div
              v-show="showNameCmdVH"
              @mouseleave="showNameCmdVH = false"
              class="menu-LKQ absolute z-30 rounded-md flex justify-center -mt-8 -ml-2 p-1 bg-gray-50 border-2 border-black"
            >
              <!-- PARTSNET 1 -->
              <!-- <img
                src="../assets/Fournisseurs/LKQ.jpg"
                style="width: 300px; height: 250px"
              /> -->
              <div class="grid grid-cols-2">
                <div
                  class="flex flex-col cursor-pointer"
                  @click="prepareCMDVH(true)"
                  v-if="!cmdVHloading"
                >
                  <span
                    style="font-size: 30px"
                    class="material-icons-outlined m-auto font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-sky-400 to-orange-400 cursor-pointer hover:shadow-lg rounded-full p-1"
                    >factory</span
                  >
                  <span style="font-size: 8px" class="m-auto">Cmd fourn.</span>
                </div>
                <div class="flex flex-col cursor-pointer" v-else>
                  <span
                    style="font-size: 30px"
                    class="animate-spin material-icons-outlined m-auto font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-sky-400 to-orange-400 cursor-pointer hover:shadow-lg rounded-full p-1"
                    >sync</span
                  >
                  <span style="font-size: 8px" class="m-auto">Prepa...</span>
                </div>

                <!-- <div v-if="carcatToken.access_token != ''">
                  <div class="flex flex-col" @click="openCarCat()">
                    <img
                      src="@/assets/CarCat.png"
                      style="width: 2.4rem; height: 2.4rem"
                      class="m-auto font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-sky-400 to-orange-400 cursor-pointer hover:shadow-lg rounded-full p-1"
                    />
                    <span style="font-size: 8px" class="m-auto">Catalogue</span>
                  </div>
                </div>
                <div v-else>
                  <a
                    class="flex flex-col"
                    href="https://lkq-carsys-fr-staging.auth.eu-central-1.amazoncognito.com/login?client_id=2s9qagpalko23h8fvnt2u66df5&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://distrib.vekteur.fr"
                  >
                    <img
                      src="@/assets/CarCat.png"
                      style="width: 2.4rem; height: 2.4rem"
                      class="m-auto font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-sky-400 to-orange-400 cursor-pointer hover:shadow-lg rounded-full p-1"
                    />
                    <span style="font-size: 8px" class="m-auto">Catalogue</span>
                  </a>
                </div> -->

                <div class="flex flex-col" @click="openRecapVH = !openRecapVH">
                  <span
                    style="font-size: 30px"
                    class="material-icons-outlined m-auto font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-sky-400 to-orange-400 cursor-pointer hover:shadow-lg rounded-full p-1"
                    >view_list</span
                  >
                  <span style="font-size: 8px" class="m-auto">Récap. cmd</span>
                </div>

                <div class="flex flex-col" @click="closeCarCat()">
                  <span
                    style="font-size: 30px"
                    class="material-icons-outlined m-auto font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-sky-400 to-orange-400 cursor-pointer hover:shadow-lg rounded-full p-1"
                    >download</span
                  >
                  <span style="font-size: 8px" class="m-auto">Panier</span>
                </div>
              </div>
            </div>
            <!-- fin menu LKQ -->
          </div>

          <!-- DOYEN -->
          <div class="flex space-x-7 m-auto">
            <a href="https://www.doyen4u.net/" target="_blank">
              <img
                src="../assets/Fournisseurs/Doyen.png"
                @mouseover="showNameDoyen = true"
                @mouseleave="showNameDoyen = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameDoyen"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/Doyen.png"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- IDLP -->
          <div class="flex space-x-7 m-auto">
            <a href="https://www.hubidl.com/" target="_blank">
              <img
                src="../assets/Fournisseurs/idlp.jpeg"
                @mouseover="showNameIdlp = true"
                @mouseleave="showNameIdlp = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameIdlp"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/idlp.jpeg"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- AUTOPARTNER -->
          <div class="flex space-x-7 m-auto">
            <a href="https://apcat.eu/" target="_blank">
              <img
                src="../assets/Fournisseurs/AutoPartner.gif"
                @mouseover="showNameAutoPartner = true"
                @mouseleave="showNameAutoPartner = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameAutoPartner"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/AutoPartner.gif"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- DASILVA -->
          <div class="flex space-x-7 m-auto">
            <a href="https://www.groupe-dasilva.com/" target="_blank">
              <img
                src="../assets/Fournisseurs/DaSilva.jpeg"
                @mouseover="showNameDaSilva = true"
                @mouseleave="showNameDaSilva = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameDaSilva"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/DaSilva.jpeg"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- MEYLE -->
          <div class="flex space-x-7 m-auto">
            <a
              href="https://www.meyle.com/fr/centre-des-services/catalogue-en-ligne/"
              target="_blank"
            >
              <img
                src="../assets/Fournisseurs/Meyle.jpg"
                @mouseover="showNameMeyle = true"
                @mouseleave="showNameMeyle = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameMeyle"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/Meyle.jpg"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- PROCODIS -->
          <div class="flex space-x-7 m-auto">
            <a href="https://catalogue.procodisfrance.com/" target="_blank">
              <img
                src="../assets/Fournisseurs/Procodis.png"
                @mouseover="showNameProcodis = true"
                @mouseleave="showNameProcodis = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameProcodis"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/Procodis.png"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- VANWEZEL -->
          <div class="flex space-x-7 m-auto">
            <a href="https://vwa.autopartscat.com/" target="_blank">
              <img
                src="../assets/Fournisseurs/VanWezel.jpg"
                @mouseover="showNameVanWezel = true"
                @mouseleave="showNameVanWezel = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameVanWezel"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/VanWezel.jpg"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- SKF -->
          <div class="flex space-x-7 m-auto">
            <a href="https://login.tecalliance.net/" target="_blank">
              <img
                src="../assets/Fournisseurs/SKF.jpg"
                @mouseover="showNameSkf = true"
                @mouseleave="showNameSkf = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameSkf"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/SKF.jpg"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- KRAFTWERK -->
          <div class="flex space-x-7 m-auto">
            <a href="https://www.kraftwerktools.fr/fr-fr/" target="_blank">
              <img
                src="../assets/Fournisseurs/kraftwerk.png"
                @mouseover="showNameKraftwerk = true"
                @mouseleave="showNameKraftwerk = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameKraftwerk"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/kraftwerk.png"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- JBM -->
          <div class="flex space-x-7 m-auto">
            <a href="https://www.jbmcamp.com/fr/" target="_blank">
              <img
                src="../assets/Fournisseurs/Jbm.png"
                @mouseover="showNameJbm = true"
                @mouseleave="showNameJbm = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameJbm"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/Jbm.png"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- IGOL -->
          <div class="flex space-x-7 m-auto">
            <a href="https://www.igol.com/" target="_blank">
              <img
                src="../assets/Fournisseurs/Igol.jpg"
                @mouseover="showNameIgol = true"
                @mouseleave="showNameIgol = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameIgol"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/Igol.jpg"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- MOTORPARTS -->
          <div class="flex space-x-7 m-auto">
            <a href="https://myshop.mp-i.eu/Account/" target="_blank">
              <img
                src="../assets/Fournisseurs/Motorparts.png"
                @mouseover="showNameMotorparts = true"
                @mouseleave="showNameMotorparts = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameMotorparts"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/Motorparts.png"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>

          <!-- SODISE -->
          <div class="flex space-x-7 m-auto">
            <a href="https://www.sodise.com/" target="_blank">
              <img
                src="../assets/Fournisseurs/Sodise.jpg"
                @mouseover="showNameSodise = true"
                @mouseleave="showNameSodise = false"
                style="width: 28px; height: 24px"
                class="relative material-icons-outlined bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
            /></a>
            <div
              v-show="showNameSodise"
              style="font-size: 12px"
              class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
            >
              <img
                src="../assets/Fournisseurs/Sodise.jpg"
                style="width: 300px; height: 250px"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- fin partie outils -->

      <div class="">
        <div class="flex space-x-2">
          <div
            v-if="
              !showBtnRechercheArticle ||
              comptoirSessionActive[0].documentType == 'Retour'
            "
            class="flex space-x-2 bg-orange-400 items-center h-fit p-0.5 rounded-md mt-3"
          >
            <!-- Input recherche article pour retour -->
            <div class="tableHEADTR relative">
              <div
                class="relative my-auto items-center w-full py-2 pl-6 pr-12 text-sm text-gray-700 bg-white border border-b border-red-500 rounded-l rounded-r"
                v-if="comptoirSessionActive[0].Client[0].Name == ''"
              >
                Veuillez choisir un client pour insérer un article
              </div>
              <input
                v-else
                v-on:keyup.enter="getDocToReturn()"
                v-model="articleSearchToReturn"
                placeholder="Rechercher un article dans factures et bons de livraison"
                class="relative my-auto items-center focus:outline-2 outline-sky-300 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-md appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
              />

              <div
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
              >
                <svg
                  class="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>

            <!-- Bouton pour recherche article -->
            <button
              @click="
                (showBtnRechercheArticle = true),
                  (articleSearch = articleSearchToReturn)
              "
              title="Rechercher un article"
              class="material-icons-round text-white [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]"
            >
              123
            </button>
          </div>
          <div
            v-else
            class="flex space-x-2 bg-blue-400 items-center h-fit p-0.5 rounded-md mt-3"
          >
            <!-- Input recherche article-->
            <div class="tableHEADTR relative">
              <div
                class="relative my-auto items-center w-full py-2 pl-6 pr-12 text-sm text-gray-700 bg-white border border-b border-red-500 rounded-l rounded-r"
                v-if="comptoirSessionActive[0].Client[0].Name == ''"
              >
                Veuillez choisir un client pour insérer un article
              </div>
              <input
                v-else
                v-on:keyup.enter="getOneArticle()"
                v-model="articleSearch"
                placeholder="Entrer un code article"
                class="relative my-auto items-center focus:outline-2 outline-sky-300 w-full py-2 pl-6 pr-12 text-sm text-gray-700 placeholder-gray-400 bg-white border border-b border-gray-400 rounded-md appearance-none focus:bg-white focus:placeholder-gray-600 focus:text-gray-700"
              />
              <ul
                v-show="showArticleList"
                class="absolute z-20 min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
              >
                <div class="bg-white" v-show="loadingSearchArticle">
                  Recherche en cours...
                </div>
                <!-- Table pour trouver un produit -->
                <table class="min-w-full" v-if="!loadingSearchArticle">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Réf./Equipe.
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Descr./Fourn.
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Stock
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Prix
                      </th>
                      <th
                        class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        <button
                          class="cursor-pointer"
                          @click="showArticleList = false"
                        >
                          <span
                            class="material-icons-outlined rounded-full p-0.5 text-red-600 hover:bg-red-400 hover:bg-opacity-25"
                            >close</span
                          >
                        </button>
                      </th>
                    </tr>
                  </thead>

                  <tbody class="bg-white" v-if="searchTab.length > 0">
                    <tr
                      v-for="(csArticle, index) in searchTab"
                      :key="index"
                      @click="handlerArticle(csArticle)"
                      class="bg-blue-100 hover:bg-opacity-25 cursor-pointer"
                      style="max-height: 10px"
                    >
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap"
                      >
                        <div class="text-sm leading-5 text-gray-900">
                          {{ csArticle.article.Ref_fournisseur }}
                        </div>
                        <div class="text-sm leading-5 text-gray-900">
                          {{ csArticle.article.Code_marque }}
                        </div>
                      </td>

                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap"
                      >
                        <div class="text-sm leading-5 text-gray-500">
                          {{ csArticle.article.Description }}
                        </div>
                        <div
                          class="text-sm leading-5 bg-blue-500 text-white p-1 rounded w-fit"
                        >
                          {{ csArticle.article.Prefixe_tarif }}
                        </div>
                      </td>

                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap"
                      >
                        <div class="flex flex-col">
                          <span v-if="csArticle.stockData.length > 0">
                            {{ csArticle.stockData[0].stock }}
                          </span>
                          <span v-else>0</span>
                          <span
                            v-if="
                              csArticle.stockData.length > 0 &&
                              csArticle.stockData[0].reservedClient &&
                              csArticle.stockData[0].reservedClient != ''
                            "
                          >
                            Réservé: {{ csArticle.stockData[0].reservedClient }}
                          </span>
                        </div>
                      </td>

                      <td
                        class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      >
                        <!-- <span v-if="csArticle.article.Prix_ppc"
                        >{{ financial(csArticle.article.Prix_ppc) }} €</span
                      >
                      <span v-else
                        >{{ financial(csArticle.article.Prix_euro) }} €</span
                      > -->
                        <div class="flex flex-row space-x-2 items-center">
                          <span class="text-xs line-through"
                            >{{ financial(csArticle.prix.prix_brut) }} €</span
                          >
                          <span
                            >{{ financial(csArticle.prix.prix_net) }} €</span
                          >
                          <span
                            class="p-1 text-xs bg-orange-500 text-white rounded shadow animate-pulse"
                            v-if="csArticle.prix.promo"
                            >Promos</span
                          >
                        </div>
                      </td>
                      <td
                        class="w-12 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                      ></td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      ></th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
                      ></th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
                      ></th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
                      ></th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50"
                      ></th>
                    </tr>
                  </thead>
                </table>
                <!-- Fin Table pour trouver un produit -->
              </ul>

              <div
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
              >
                <svg
                  class="w-4 h-4 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>

            <!-- Bouton pour retour -->
            <button
              @click="
                (showBtnRechercheArticle = false),
                  (articleSearchToReturn = articleSearch)
              "
              title="Rechercher un article pour retour"
              class="material-icons-round text-white [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]"
            >
              keyboard_return
            </button>
          </div>

          <div class="flex space-x-1 mt-3">
            <!-- partie outils client -->
            <div class="flex flex-col m-auto mx-4 h-fit">
              <!-- DEBUT Historique client -->
              <div
                style="width: 28px"
                class="flex align-center bg-cyan-600 rounded-md hover:bg-cyan-500"
              >
                <span
                  @click="showOutilsClient = true"
                  class="material-icons-outlined cursor-pointer mx-auto bg-cyan-600 hover:bg-cyan-500 text-white rounded-md"
                  >more_horiz</span
                >
              </div>
              <div
                v-show="showOutilsClient"
                @click="showOutilsClient = false"
                class="absolute w-screen h-screen z-10 top-0 left-0"
              ></div>
              <div
                v-show="showOutilsClient"
                style="width: 28px"
                class="absolute m-auto z-20 py-1 px-1 mt-4 flex flex-col space-y-2 bg-cyan-600 text-white shadow rounded-b-md"
              >
                <!-- DEBUT Article -->
                <div class="flex m-auto mt-2 w-full">
                  <button
                    @click="toggleModale()"
                    @mouseover="showNameArticlePar = true"
                    @mouseleave="showNameArticlePar = false"
                    class="relative material-icons-outlined cursor-pointer hover:bg-cyan-500 border border-white rounded-full"
                    style="font-size: 18px"
                  >
                    article
                  </button>

                  <div
                    v-show="showNameArticlePar"
                    style="width: 125px; font-size: 15px; margin-left: 20px"
                    class="absolute rounded-r-full bg-cyan-600 text-white"
                  >
                    Ajouter un article
                  </div>
                </div>

                <!-- FIN Article -->

                <!-- DEBUT SAISIE RAPIDE -->
                <div class="flex space-x-4 m-auto mt-2">
                  <div
                    @click="showSaisieChaud = !showSaisieChaud"
                    @mouseover="showNameSaiseChaud = true"
                    @mouseleave="showNameSaiseChaud = false"
                    class="relative material-icons-outlined cursor-pointer hover:bg-cyan-500 border border-white rounded-full"
                    style="font-size: 18px"
                  >
                    drive_file_rename_outline
                  </div>
                  <transition>
                    <div
                      v-show="showNameSaiseChaud"
                      style="width: 100px; font-size: 15px; margin-left: 20px"
                      class="absolute rounded-r-full bg-cyan-600 text-white"
                    >
                      <span>Saisie rapide</span>
                    </div>
                  </transition>
                </div>
                <!-- FIN SAISIE RAPIDE -->

                <!-- DEBUT SAISIE COMMENTAIRE -->
                <div class="flex space-x-4 m-auto mt-2">
                  <div
                    @click="
                      (showSaisieComment = !showSaisieComment),
                        (showInputSaisieComment = [])
                    "
                    @mouseover="showNameSaiseComment = true"
                    @mouseleave="showNameSaiseComment = false"
                    class="relative material-icons-outlined cursor-pointer hover:bg-cyan-500 border border-white rounded-full"
                    style="font-size: 18px"
                  >
                    rate_review
                  </div>
                  <transition>
                    <div
                      v-show="showNameSaiseComment"
                      style="width: 140px; font-size: 15px; margin-left: 20px"
                      class="absolute rounded-r-full bg-cyan-600 text-white"
                    >
                      <span>Saisie commentaire</span>
                    </div>
                  </transition>
                </div>
                <!-- FIN SAISIE COMMENTAIRE -->
              </div>
            </div>

            <!-- fin partie outils client -->
            <div class="flex flex-row">
              <div class="flex flex-row">
                <select
                  @change="
                    savecomptoirsession({
                      id: 4,
                      clientFocus: comptoirSessionActive[0].Client,
                      refClient: comptoirSessionActive[0].refClient,
                      consigneData: comptoirSessionActive[0].consigneData,
                      consigneNonData: comptoirSessionActive[0].consigneNonData,
                      dsvCheck: comptoirSessionActive[0].dsvCheck,
                      documentType: comptoirSessionActive[0].documentType,
                    })
                  "
                  v-model="comptoirSessionActive[0].documentType"
                  style="width: 12rem"
                  class="cursor-pointer block w-full h-full pl-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r focus:outline-2 outline-sky-300"
                >
                  <option value="Devis">Devis</option>
                  <option value="Bon de commande">Bon de commande</option>
                  <option
                    v-if="
                      comptoirSessionActive[0].Client[0].professionnel != false
                    "
                    value="Bon de livraison"
                  >
                    Bon de livraison
                  </option>
                  <option value="Bon de livraison Avoir">
                    Bon de livraison Avoir
                  </option>
                  <option value="Facturation">Facture</option>
                  <option value="Retour">Retour</option>
                </select>
              </div>
              <button
                class="px-6 py-2 ml-3 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none"
                @click="openEncaissement(comptoirSessionActive[0].documentType)"
                v-if="actionArticle.length > 0 && !loadingValider"
              >
                Valider
              </button>
              <button
                class="px-6 py-2 ml-3 font-medium tracking-wide text-white bg-gray-200 rounded-md hover:bg-blue-400 focus:outline-none animate-pulse"
                disabled
                v-else-if="actionArticle.length > 0 && loadingValider"
              >
                Valider
              </button>
            </div>
          </div>
        </div>

        <div class="flex flex-col mt-2">
          <div
            class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full"
            >
              <table class="min-w-full" style="height: 70vh">
                <thead class="tableHEADTR">
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      <div class="flex">
                        <span>Réf./Equipe.</span>
                      </div>
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Stock
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      <span>Descr./Fourn.</span>
                      <span
                        @click="getDispos()"
                        style="font-size: 17px"
                        class="material-icons-outlined p-0.5 text-blue-500 cursor-pointer hover:animate-spin hover:bg-gray-100 rounded-full"
                        >cloud_sync</span
                      >
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    ></th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Qté
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      P.V.
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Remise
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Px final HT
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Total HT
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      Total TTC
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white tableBODY" style="height: 70vh">
                  <tr
                    v-for="(articleAct, index) in actionArticle"
                    :key="index"
                    class="tableHEADTR"
                  >
                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row justify-between items-center">
                        <div class="flex space-x-2">
                          <div class="flex flex-col">
                            <div class="flex flex-row items-center">
                              <div
                                class="space-x-1 flex text-sm leading-5 text-gray-900"
                              >
                                <span
                                  v-if="articleAct.Prefixe_tarif == 'Vhi'"
                                  class="material-icons-outlined m-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                                  style="font-size: 11px"
                                  @click="
                                    getRefSimilaire(articleAct.Ref_fournisseur)
                                  "
                                >
                                  sync
                                </span>
                                <span>{{ articleAct.Ref_fournisseur }}</span>

                                <span
                                  class="material-icons-outlined m-auto cursor-pointer text-blue-500 hover:bg-gray-100 rounded hover:shadow"
                                  style="font-size: 12px"
                                  @click="plusInfo(articleAct)"
                                >
                                  info
                                </span>
                              </div>
                            </div>
                            <div>
                              <div
                                v-if="articleAct.Ref_complementaire"
                                class="text-sm items-center text-cyan-600"
                              >
                                <span>{{ articleAct.Ref_complementaire }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="flex flex-col space-y-1 mt-1">
                            <span
                              v-if="articleAct.Promo_set"
                              class="material-icons-outlined animate-pulse text-orange-500 cursor-pointer"
                              style="font-size: 15px"
                              @click="
                                openPromo(
                                  articleAct.Ref_fournisseur,
                                  articleAct.Code_marque,
                                  index
                                )
                              "
                            >
                              discount
                            </span>
                            <span
                              v-if="
                                articleAct.Prefixe_tarif == 'Vhi'
                              "
                              class="material-icons-outlined animate-pulse text-orange-500 cursor-pointer"
                              style="font-size: 15px"
                              @click="
                                openModalRefSimil(articleAct.Ref_fournisseur)
                              "
                            >
                              auto_graph
                            </span>
                          </div>
                        </div>
                        <div class="flex flex-col space-y-1">
                          <div>
                            <span
                              class="material-icons-round text-blue-500 text-base cursor-pointer"
                              v-show="!articleAct.inCommande"
                              @click="
                                addToCommandInst(
                                  articleAct,
                                  actionArticle[index].quantity -
                                    articleAct.stockData,
                                  index
                                )
                              "
                            >
                              add_shopping_cart
                            </span>
                            <span
                              class="material-icons-round text-blue-500 text-base cursor-pointer"
                              v-show="articleAct.inCommande"
                              @click="prepareCMDVH(true)"
                            >
                              published_with_changes
                            </span>
                          </div>
                          <div>
                            <span
                              @click="prepareCMDVH(false)"
                              class="material-icons-round text-green-700 text-base cursor-pointer"
                            >
                              add_shopping_cart
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-col">
                        <div class="flex flex-row items-center">
                          <div class="text-2xs mx-auto">
                            {{ user.proprietaire }}
                          </div>
                          <div
                            class="bg-green-600 text-white mx-auto text-xs border border-gray-300 py-0.5 px-2 rounded-full"
                            v-if="articleAct.stockData"
                          >
                            {{ articleAct.stockData }}
                          </div>
                          <div
                            class="text-red-600 mx-auto text-xs border border-gray-300 py-0.5 px-2 rounded-full"
                            v-else
                          >
                            0
                          </div>
                          <div class="flex flex-row items-center">
                            <div
                              title="Réservé"
                              class="text-green-600 mx-auto text-xs border border-gray-300 py-0.5 px-2 rounded-full"
                              v-if="articleAct.reserveStockData"
                            >
                              {{ articleAct.reserveStockData }}
                            </div>
                          </div>
                        </div>

                        <div
                          v-for="(stF, indexStF) in articleAct.stockFiliale"
                          :key="indexStF"
                          class="flex flex-row items-center"
                        >
                          <div
                            class="text-2xs mx-auto cursor-pointer"
                            @click="setStockFiliale(index, indexStF)"
                          >
                            {{ stF.plateform }}
                          </div>
                          <div class="flex mx-auto">
                            <input
                              v-if="
                                articleAct.stockFiliale[indexStF].transfertState
                              "
                              type="Number"
                              class="w-12 bg-green-600 text-white mx-auto text-xs border border-gray-300 p-0.5 rounded-full"
                              v-model="articleAct.stockFiliale[indexStF].stock"
                            />
                            <input
                              v-else
                              type="Number"
                              class="w-12 mx-auto text-xs border border-gray-300 p-0.5 rounded-full"
                              v-model="articleAct.stockFiliale[indexStF].stock"
                            />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div
                        class="text-sm leading-5 whitespace-normal w-[24rem]"
                      >
                        {{ articleAct.Description }}
                      </div>

                      <div class="flex flex-row items-center space-x-2">
                        <div class="text-xs leading-5 text-gray-900">
                          {{ articleAct.Code_marque }}
                        </div>
                        <div class="text-sm leading-5 text-gray-900">
                          <span class="text-xs text-blue-500">{{
                            articleAct.FournisseurSelected
                          }}</span>

                          <span
                            v-if="articleAct.Prefixe_tarif == 'Vhi'"
                            @click="
                              (showDispo[index] = true),
                                getDispo(
                                  articleAct.Ref_fournisseur,
                                  articleAct.Code_marque,
                                  actionArticle[index].quantity,
                                  articleAct.Prefixe_tarif,
                                  index
                                )
                            "
                            style="font-size: 15px"
                            class="material-icons-outlined p-0.5 text-blue-500 cursor-pointer hover:animate-spin hover:bg-gray-100 rounded-full"
                            >cloud_sync</span
                          >
                          <button
                            v-show="showSaisieComment"
                            @click="
                              (showInputSaisieComment[index] =
                                !showInputSaisieComment[index]),
                                (articleAct.commentaire = '')
                            "
                            style="font-size: 15px"
                            class="material-icons-outlined ml-8 p-0.5 text-white cursor-pointer bg-orange-300 hover:bg-orange-400 rounded-full"
                          >
                            rate_review
                          </button>
                        </div>

                        <div v-if="articleAct.dateBL" class="">
                          <div class="text-sm leading-5 whitespace-normal">
                            <label title="Champs obligatoire"
                              >Raison du retour :
                            </label>
                            <label
                              class="text-red-600 font-medium"
                              title="Champs obligatoire"
                              >*</label
                            >
                            <select
                              v-model="articleAct.raisonRetour"
                              class="border border-2 rounded-md sm:w-10 md:w-12 lg:w-20 xl:w-32 2xl:w-40 outline-2 focus:outline-sky-300"
                              :class="{
                                'red-border': articleAct.raisonRetour === '',
                              }"
                              title="Champs obligatoire"
                              @change="
                                savecomptoirsession({
                                  id: 4,
                                  clientFocus:
                                    this.comptoirSessionActive[0].Client,
                                  refClient:
                                    this.comptoirSessionActive[0].refClient,
                                  consigneData:
                                    this.comptoirSessionActive[0].consigneData,
                                  consigneNonData:
                                    this.comptoirSessionActive[0]
                                      .consigneNonData,
                                  dsvCheck:
                                    this.comptoirSessionActive[0].dsvCheck,
                                })
                              "
                            >
                              <option
                                v-for="option in tabRaisonRetour"
                                :key="option.id"
                                :value="option.name"
                              >
                                {{ option.name }}
                              </option>
                            </select>
                            <input
                              v-if="articleAct.raisonRetour == 'Autre'"
                              class="border border-2 rounded-md sm:w-10 md:w-12 lg:w-20 xl:w-32 2xl:w-40 outline-2 focus:outline-sky-300"
                              v-model="articleAct.raisonRetourAutre"
                              placeholder="Précisez la raison"
                              :class="{
                                'red-border':
                                  articleAct.raisonRetourAutre === '',
                              }"
                            />
                          </div>
                          <div
                            class="flex flex-col text-xs leading-5 text-gray-900 sm:text-xs md:text-md sm:w-10 md:w-12"
                          >
                            <p>
                              {{ articleAct.typeDoc }} n° {{ articleAct.numBL }}
                            </p>
                            <p>Date : {{ getFRDate(articleAct.dateBL) }}</p>
                          </div>
                        </div>
                      </div>
                      <textarea
                        v-show="showInputSaisieComment[index]"
                        v-model="articleAct.commentaire"
                        rows="2"
                        cols="40"
                        class="border px-1 py-0.5 focus:outline-2 outline-sky-300 text-xs"
                      ></textarea>
                    </td>

                    <td
                      v-if="showDispo[index]"
                      class="items-center px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        class="rounded p-1 flex flex-col text-xs px-6 space-y-1 cursor-pointer"
                        @click="openDetailsDispo = true"
                      >
                        <div v-if="tabDispoUAN[index]">
                          <div
                            style="width: 55%"
                            class="flex border border-yellow-500 p-0.5 rounded shadow"
                          >
                            <span
                              class="material-icons-outlined text-yellow-500"
                              style="font-size: 15px"
                              >light_mode</span
                            >
                            <span class="text-xs text-black">
                              {{ tabDispoUAN[index].qtyJour }}
                            </span>
                          </div>

                          <div
                            style="width: 55%"
                            class="flex border border-blue-700 p-0.5 rounded shadow"
                          >
                            <span
                              class="material-icons-outlined text-blue-700"
                              style="font-size: 15px"
                              >nightlight</span
                            >
                            <span class="text-xs text-black">
                              {{ tabDispoUAN[index].qtySoir }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      v-else
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    ></td>

                    <!-- Quantité -->
                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center space-x-1">
                        <input
                          class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                          v-model="actionArticle[index].quantity"
                          :disabled="articleAct.disabled"
                          style="width: 35%"
                          type="number"
                          :placeholder="articleAct.Qte_mini"
                          :min="articleAct.Qte_mini"
                          :id="'qty' + index"
                          @change="
                            checkPrixQuantitatif(index),
                              adaptConsigneQuantity(index),
                              setRemiseArticle(
                                actionArticle[index],
                                index,
                                actionArticle[index].remise,
                                actionArticle[index].quantity,
                                articleAct.Prix_euro
                              ),
                              getDispo(
                                articleAct.Ref_fournisseur,
                                articleAct.Code_marque,
                                actionArticle[index].quantity,
                                articleAct.Prefixe_tarif,
                                index
                              ),
                              savecomptoirsession({
                                id: 1,
                                clientFocus: comptoirSessionActive[0].Client,
                                refClient: comptoirSessionActive[0].refClient,
                                consigneData:
                                  comptoirSessionActive[0].consigneData,
                                consigneNonData:
                                  comptoirSessionActive[0].consigneNonData,
                                dsvCheck: comptoirSessionActive[0].dsvCheck,
                              })
                          "
                          @keyup.enter="
                            retourArticles(
                              actionArticle[index].quantity,
                              articleAct,
                              index
                            )
                          "
                        />

                        <span
                          class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                          style="cursor: pointer"
                          @click="
                            suppActionArticle(articleAct),
                              initFournisseursModel(),
                              (showDispo[index] = false),
                              (tabDispoUAN[index] = false),
                              suppVHloadingstate({ index: index }),
                              savecomptoirsession({
                                id: 2,
                                clientFocus: comptoirSessionActive[0].Client,
                                refClient: comptoirSessionActive[0].refClient,
                                consigneData:
                                  comptoirSessionActive[0].consigneData,
                                consigneNonData:
                                  comptoirSessionActive[0].consigneNonData,
                                dsvCheck: comptoirSessionActive[0].dsvCheck,
                              })
                          "
                        >
                          <span
                            class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                            style="font-size: small"
                          >
                            clear
                          </span>
                        </span>
                        <!-- <span
                          v-if="!articleAct.Ref_fournisseur.startsWith('C : ')"
                          class="material-icons-outlined text-orange-500 hover:text-orange-400 cursor-pointer"
                          style="font-size: 15px"
                          @click="
                            retourArticlesButton(
                              actionArticle[index].quantity,
                              articleAct,
                              index
                            )
                          "
                        >
                          undo
                        </span> -->
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <span
                        class="cursor-pointer"
                        v-if="articleAct.PrixFournisseurType != 'prixnet'"
                        @mouseover="showPA[index] = true"
                        @mouseleave="showPA[index] = false"
                        ><input
                          type="Number"
                          class="w-3/5"
                          :step="0.01"
                          v-model="articleAct.Prix_euro"
                        />€</span
                      >
                      <span
                        class="cursor-pointer"
                        v-else
                        @mouseover="showPA[index] = true"
                        @mouseleave="showPA[index] = false"
                        ><input
                          type="Number"
                          class="w-3/5"
                          :step="0.01"
                          v-model="articleAct.Prix_euro"
                        />
                        €</span
                      >
                      <div
                        class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
                        v-show="showPA[index]"
                      >
                        <div class="text-sm leading-2 text-blue-500">
                          <span
                            v-if="articleAct.PrixFournisseurType == 'remise'"
                            >PA :
                            {{ financial(articleAct.Prix_achat) }}
                            €</span
                          >
                          <span v-else>
                            PA :
                            {{ financial(articleAct.Prix_achat) }}
                            €
                          </span>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <span
                        v-if="
                          articleAct.Promo_set && articleAct.prix_quantitatif
                        "
                        class="text-sm p-1 bg-orange-500 text-white rounded-lg"
                      >
                        Promo
                      </span>
                      <span
                        v-else
                        class="text-sm py-1 text-gray-500"
                        @mouseover="showMarge[index] = true"
                        @mouseleave="showMarge[index] = false"
                        >{{ articleAct.PrixFournisseurType }}</span
                      >

                      <div
                        class="flex text-sm leading-5 text-gray-900"
                        @mouseover="showMarge[index] = true"
                        @mouseleave="showMarge[index] = false"
                      >
                        <div class="flex">
                          <input
                            v-model="actionArticle[index].remise"
                            v-if="articleAct.PrixFournisseurType != 'prixnet'"
                            class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                            type="number"
                            style="width: 100%"
                            placeholder="0"
                            min="0"
                            max="100"
                            maxlength="3"
                            step="5"
                            @change="
                              setRemiseArticle(
                                actionArticle[index],
                                index,
                                actionArticle[index].remise,
                                actionArticle[index].quantity,
                                articleAct.Prix_euro
                              ),
                                savecomptoirsession({
                                  id: 3,
                                  clientFocus: comptoirSessionActive[0].Client,
                                  refClient: comptoirSessionActive[0].refClient,
                                  consigneData:
                                    comptoirSessionActive[0].consigneData,
                                  consigneNonData:
                                    comptoirSessionActive[0].consigneNonData,
                                  dsvCheck: comptoirSessionActive[0].dsvCheck,
                                })
                            "
                          />
                          <span
                            v-if="articleAct.PrixFournisseurType != 'prixnet'"
                            >%</span
                          >
                        </div>
                      </div>
                      <div
                        class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
                        v-show="showMarge[index]"
                      >
                        <div class="text-sm leading-2 text-blue-500">
                          Marge :
                          {{
                            getMargeParArticle(
                              articleAct.Prix_euro,
                              articleAct.Prix_achat,
                              actionArticle[index].remise,
                              articleAct.PrixFournisseurType,
                              articleAct.Prix_vente,
                              articleAct.Ref_fournisseur
                            )
                          }}
                          €,
                          {{
                            getMarge(
                              articleAct.Prix_euro,
                              articleAct.Prix_achat,
                              actionArticle[index].remise,
                              articleAct.PrixFournisseurType,
                              articleAct.Prix_vente,
                              articleAct.Ref_fournisseur
                            )
                          }}
                          %
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        class="flex space-x-1 text-sm leading-5 text-gray-900"
                        @mouseover="showTTC1[index] = true"
                        @mouseleave="showTTC1[index] = false"
                      >
                        <span
                          v-if="articleAct.PrixFournisseurType != 'prixnet'"
                        >
                          {{
                            financial(
                              articleAct.Prix_euro -
                                articleAct.Prix_euro *
                                  (actionArticle[index].remise / 100)
                            )
                          }}
                        </span>
                        <span v-else>
                          <span
                            ><input
                              class="w-3/5"
                              type="Number"
                              v-model="actionArticle[index].Prix_vente"
                          /></span>
                        </span>

                        <span>€</span>
                      </div>
                      <div
                        class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
                        v-show="showTTC1[index]"
                      >
                        <div class="text-sm leading-2 text-blue-500">
                          <span
                            v-if="articleAct.PrixFournisseurType != 'prixnet'"
                          >
                            TTC :
                            {{
                              financial(
                                (articleAct.Prix_euro -
                                  articleAct.Prix_euro *
                                    (actionArticle[index].remise / 100)) *
                                  0.2 +
                                  (articleAct.Prix_euro -
                                    articleAct.Prix_euro *
                                      (actionArticle[index].remise / 100))
                              )
                            }}
                          </span>
                          <span v-else>
                            TTC :
                            {{
                              (
                                parseFloat(articleAct.Prix_vente) +
                                parseFloat(articleAct.Prix_vente) * 0.2
                              ).toFixed(2)
                            }}
                          </span>
                          <span>€</span>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        class="flex space-x-1 text-sm leading-5 text-gray-900"
                        @mouseover="showTTC2[index] = true"
                        @mouseleave="showTTC2[index] = false"
                      >
                        <span
                          v-if="articleAct.PrixFournisseurType != 'prixnet'"
                        >
                          {{
                            financial(
                              (articleAct.Prix_euro -
                                articleAct.Prix_euro *
                                  (actionArticle[index].remise / 100)) *
                                actionArticle[index].quantity
                            )
                          }}
                        </span>
                        <span v-else>
                          {{
                            financial(
                              actionArticle[index].Prix_vente *
                                actionArticle[index].quantity
                            )
                          }}
                        </span>
                        <span>€</span>
                      </div>
                      <div
                        class="absolute mt-2 bg-gray-200 p-2 rounded shadow-lg z-20"
                        v-show="showTTC2[index]"
                      >
                        <div class="text-sm leading-2 text-blue-500">
                          <span
                            v-if="articleAct.PrixFournisseurType != 'prixnet'"
                          >
                            TTC :
                            {{
                              financial(
                                (articleAct.Prix_euro -
                                  articleAct.Prix_euro *
                                    (actionArticle[index].remise / 100)) *
                                  actionArticle[index].quantity *
                                  0.2 +
                                  (articleAct.Prix_euro -
                                    articleAct.Prix_euro *
                                      (actionArticle[index].remise / 100)) *
                                    actionArticle[index].quantity
                              )
                            }}
                          </span>
                          <span v-else>
                            TTC :
                            {{
                              financial(
                                parseFloat(articleAct.Prix_vente) *
                                  actionArticle[index].quantity *
                                  0.2 +
                                  parseFloat(articleAct.Prix_vente) *
                                    actionArticle[index].quantity
                              )
                            }}
                          </span>
                          <span>€</span>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div
                        class="flex space-x-1 text-sm leading-5 text-gray-900"
                      >
                        <span
                          v-if="articleAct.PrixFournisseurType != 'prixnet'"
                        >
                          {{
                            financial(
                              (articleAct.Prix_euro -
                                articleAct.Prix_euro *
                                  (actionArticle[index].remise / 100)) *
                                actionArticle[index].quantity *
                                0.2 +
                                (articleAct.Prix_euro -
                                  articleAct.Prix_euro *
                                    (actionArticle[index].remise / 100)) *
                                  actionArticle[index].quantity
                            )
                          }}
                        </span>
                        <span v-else>
                          {{
                            financial(
                              parseFloat(articleAct.Prix_vente) *
                                actionArticle[index].quantity *
                                0.2 +
                                parseFloat(articleAct.Prix_vente) *
                                  actionArticle[index].quantity
                            )
                          }}
                        </span>
                        <span>€</span>
                      </div>
                    </td>
                  </tr>

                  <!-- saisie rapide -->
                  <tr
                    class="tableHEADTR shadow border bg-gray-100 rounded-lg"
                    v-show="showSaisieChaud"
                  >
                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-col">
                        <input
                          class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                          type="text"
                          placeholder="Ref"
                          v-model="lineAddArticle_ref"
                          @keyup.enter="addManualLine()"
                        />
                        <input
                          class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                          type="text"
                          placeholder="Marque"
                          v-model="lineAddArticle_marque"
                          @keyup.enter="addManualLine()"
                        />
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      --
                    </td>

                    <td
                      class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="flex justify-between space-x-1">
                        <div class="flex flex-col">
                          <input
                            class="border px-1 py-0.5 focus:outline-2 outline-sky-300"
                            type="text"
                            placeholder="Description"
                            v-model="lineAddArticle_desc"
                            @keyup.enter="addManualLine()"
                          />

                          <div class="flex">
                            <button
                              @click="
                                lineAddArticle_fournisseur_selected = null
                              "
                              class="material-icons-round absolute text-xs text-red-500 hover:text-red-400 mt-1 ml-1"
                            >
                              cancel
                            </button>
                            <select
                              v-model="lineAddArticle_fournisseur_selected"
                              class="pl-4"
                            >
                              <option value=""></option>
                              <option
                                v-for="(c, index) in fournisseurList"
                                :key="index"
                                :value="c"
                                placeholder="Fournisseur"
                              >
                                {{ c.CompanyName }}
                              </option>
                            </select>
                          </div>
                          <input
                            class="mt-1 border px-1 py-0.5 focus:outline-2 outline-sky-300"
                            style="width: 50%"
                            type="number"
                            placeholder="Poids en g"
                            v-model="lineAddArticle_poids"
                            @keyup.enter="addManualLine()"
                          />
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    ></td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center space-x-2">
                        <input
                          class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                          style="width: 35%"
                          type="number"
                          v-model="lineAddArticle_quantity"
                          @keyup.enter="addManualLine()"
                        />
                        <span
                          class="inline-flex px-2 h-fit text-xs font-semibold leading-5 text-red-500 rounded-full"
                          style="cursor: pointer"
                          @click="showSaisieChaud = false"
                        >
                          <span
                            class="material-icons-round bg-red-500 hover:bg-opacity-25 rounded-sm text-white cursor-pointer"
                            style="font-size: small"
                          >
                            clear
                          </span>
                        </span>
                        <div class="flex flex-col">
                          <div
                            class="text-sm bg-sky-400 hover:bg-sky-300 shadow-lg p-1 text-white rounded-md cursor-pointer"
                            @click="addManualLine()"
                          >
                            <p class="flex justify-center">Valider</p>
                            <p class="flex justify-center">saisie</p>
                          </div>
                          <!-- <span
                            class="material-icons-outlined text-sm text-green-600 cursor-pointer"
                            @click="
                              lineAddArticle_quantity =
                                lineAddArticle_quantity + 1
                            "
                          >
                            add
                          </span>
                          <span
                            class="material-icons-outlined text-sm text-red-600 cursor-pointer"
                            @click="
                              lineAddArticle_quantity =
                                lineAddArticle_quantity - 1
                            "
                          >
                            remove
                          </span> -->
                        </div>
                        <!-- revenir ici -->
                      </div>
                    </td>

                    <td
                      class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-col space-y-1">
                        <div>
                          PA :
                          <input
                            class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                            style="width: 35%"
                            type="number"
                            v-model="lineAddArticle_pa"
                            @keyup.enter="addManualLine()"
                          />
                          €
                        </div>
                        <div>
                          PV :
                          <input
                            class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                            style="width: 35%"
                            type="number"
                            v-model="lineAddArticle_pv"
                            @keyup.enter="addManualLine()"
                          />
                          €
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-2 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <input
                        class="border rounded border-gray-400 focus:outline-2 outline-sky-300"
                        style="width: 35%"
                        type="number"
                        v-model="lineAddArticle_remise"
                        @keyup.enter="addManualLine()"
                      />
                      %
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        financial(
                          lineAddArticle_pv -
                            lineAddArticle_pv * (lineAddArticle_remise / 100)
                        )
                      }}
                      €
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        financial(
                          (lineAddArticle_pv -
                            lineAddArticle_pv * (lineAddArticle_remise / 100)) *
                            lineAddArticle_quantity
                        )
                      }}
                      €
                    </td>

                    <td
                      class="px-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        financial(
                          (lineAddArticle_pv -
                            lineAddArticle_pv * (lineAddArticle_remise / 100)) *
                            lineAddArticle_quantity *
                            0.2 +
                            (lineAddArticle_pv -
                              lineAddArticle_pv *
                                (lineAddArticle_remise / 100)) *
                              lineAddArticle_quantity
                        )
                      }}
                      €
                    </td>
                  </tr>
                </tbody>
                <thead class="tableHEADTR">
                  <tr class="tableHEADTR">
                    <!-- VIDER et QUANTITE TOTALE -->
                    <th
                      class="w-40 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      <div class="flex justify-between">
                        <button
                          class="flex justify-between items-center p-2 text-gray-600 border rounded-md hover:bg-red-200"
                          @click="clearPanier()"
                        >
                          <span
                            class="material-icons-outlined"
                            style="color: red"
                            >delete_forever</span
                          >
                          <button class="text-xs mx-4">Vider</button>
                        </button>
                        <p class="m-auto">{{ setQuantity }} pcs</p>
                      </div>
                    </th>

                    <!-- REMISE GENERALE -->
                    <th
                      class="w-32 px-6 py-3 text-xs font-medium text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      <div class="flex flex-col">
                        <button
                          class="flex justify-between space-x-2 m-auto items-center px-2 py-2 text-gray-600 border rounded-md hover:bg-blue-300 bg-blue-200"
                          @click="showRemiseGen = !showRemiseGen"
                          v-if="showRemiseGen"
                        >
                          <span class="">Remise gén.</span>
                          <span class="material-icons-outlined text-xs my-auto">
                            arrow_back_ios
                          </span>
                        </button>
                        <button
                          v-else
                          class="flex justify-between space-x-2 m-auto items-center px-2 py-2 text-gray-600 border rounded-md hover:bg-blue-300 bg-blue-200"
                          @click="showRemiseGen = !showRemiseGen"
                        >
                          <span class="">Remise gén.</span>
                          <span class="material-icons-outlined text-xs my-auto">
                            arrow_forward_ios
                          </span>
                        </button>
                        <input
                          type="number"
                          style="width: 5rem"
                          min="0"
                          max="100"
                          maxlength="3"
                          step="5"
                          class="m-auto block border rounded-md pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase"
                          v-if="showRemiseGen"
                          v-model="comptoirSessionActive[0].remisegenerale"
                        />
                      </div>
                    </th>

                    <th
                      class="w-12 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    ></th>

                    <!-- REFERENCE CLIENT -->
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      <div class="relative flex flex-col">
                        <span>Réf. client :</span>
                        <div
                          class="my-6 absolute inset-y-0 left-0 flex items-center px-1 text-gray-700 h-4"
                          @click="
                            (comptoirSessionActive[0].refClient = ''),
                              savecomptoirsession({
                                id: 4,
                                clientFocus: comptoirSessionActive[0].Client,
                                refClient: comptoirSessionActive[0].refClient,
                                consigneData:
                                  comptoirSessionActive[0].consigneData,
                                consigneNonData:
                                  comptoirSessionActive[0].consigneNonData,
                                dsvCheck: comptoirSessionActive[0].dsvCheck,
                              })
                          "
                        >
                          <span
                            style="font-size: 15px"
                            class="material-icons-outlined cursor-pointer rounded-full bg-red-400 hover:bg-red-300 text-white hover:from-transparent"
                            >cancel</span
                          >
                        </div>
                        <input
                          @change="
                            savecomptoirsession({
                              id: 4,
                              clientFocus: comptoirSessionActive[0].Client,
                              refClient: comptoirSessionActive[0].refClient,
                              consigneData:
                                comptoirSessionActive[0].consigneData,
                              consigneNonData:
                                comptoirSessionActive[0].consigneNonData,
                              dsvCheck: comptoirSessionActive[0].dsvCheck,
                            })
                          "
                          class="block border rounded-md pl-5 pr-1 py-1 border-2 border-sky-200 focus:outline-2 outline-sky-300 text-sm font-bold uppercase"
                          v-model="comptoirSessionActive[0].refClient"
                          required
                        />
                      </div>
                    </th>

                    <th
                      class="w-12 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    ></th>

                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                    ></th>

                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      <!-- FRAIS DE PORT et FRANCO -->
                      <div
                        v-if="
                          comptoirSessionActive[0].Client[0].professionnel ==
                            true &&
                          (comptoirSessionActive[0].documentType === 'Devis' ||
                            comptoirSessionActive[0].documentType === 'Facture')
                        "
                        class="flex flex-col mt-2"
                      >
                        <div class="flex text-xs leading-5 text-gray-900">
                          <span class="pr-4 my-auto">Frais de port :</span>
                          <!-- <input
                                v-if="comptoirSessionActive[0].fraisdeportsave == 0"
                                type="number"
                                min="0"
                                title="Le total de votre panier est supérieur ou égal à franco"
                                :placeholder="comptoirSessionActive[0].fraisdeport"
                                value="0"
                                class="w-12 h-6 focus:outline-2 outline-sky-300 px-1 rounded-md border-2 border-sky-200 focus:outline-2 outline-sky-300"
                              /> -->
                          <input
                            type="number"
                            min="0"
                            :placeholder="comptoirSessionActive[0].fraisdeport"
                            v-model="comptoirSessionActive[0].fraisdeportsave"
                            class="w-12 h-6 my-auto focus:outline-2 outline-sky-300 px-1 rounded-md border-2 border-sky-200 focus:outline-2 outline-sky-300"
                            @change="
                              savecomptoirsession({
                                id: 4,
                                clientFocus: comptoirSessionActive[0].Client,
                                fraisdeportsave:
                                  comptoirSessionActive[0].fraisdeportsave,
                              })
                            "
                          />
                        </div>

                        <div class="text-xs leading-5 text-gray-900 my-auto">
                          Franco :
                          <span
                            >{{
                              financial(comptoirSessionActive[0].francodeport)
                            }}
                            €</span
                          >
                        </div>
                      </div>
                    </th>

                    <!-- TOTAL -->
                    <th
                      class="w-64 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                    >
                      <div
                        v-if="actionArticle.length > 0"
                        @mouseover="showMargeTotal = true"
                        @mouseleave="showMargeTotal = false"
                        class="flex flex-col hover:bg-gray-100 cursor-pointer rounded-md"
                      >
                        <div
                          v-if="showRemiseGen"
                          class="flex flex-col text-sm leading-5 text-gray-900 font-medium"
                        >
                          <div>
                            Total HT :
                            {{ financial(setTotalSansRemise) }} €
                          </div>
                          <div>
                            Total HT Remisé : {{ financial(setTotal) }} €
                          </div>
                        </div>
                        <div v-else class="text-sm leading-5 text-gray-900">
                          Total HT :
                          {{ financial(setTotal) }} €
                        </div>
                        <div class="text-xl leading-5 text-gray-900">
                          Total TTC :
                          {{ financial(setTotalTTC) }} €
                        </div>
                      </div>
                      <div
                        v-else
                        @mouseover="showMargeTotal = true"
                        @mouseleave="showMargeTotal = false"
                        class="hover:bg-gray-100 cursor-pointer rounded-md"
                      >
                        <div class="text-sm leading-5 text-gray-900">
                          Total HT : 0.00 €
                        </div>
                        <div class="text-sm leading-5 text-gray-900">
                          Total TTC : 0.00 €
                        </div>
                      </div>
                      <div
                        v-if="actionArticle.length > 0"
                        v-show="showMargeTotal"
                        class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                      >
                        Marge total : {{ financial(setMargeTotalEuro) }} € soit
                        {{ financial(setMargeTotalePourcentage) }} %
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="caisse && comptoirSessionActive.length < 1">
    <msgSession />
  </div>

  <div v-else>
    <div class="flex">
      <span>Veuillez ouvrir la caisse pour pouvoir utiliser le comptoir</span>
    </div>
    <div class="flex">
      <router-link to="/caisse">
        <button
          class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
        >
          Aller à la caisse
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { createlivraison } from "@/hooks/pdf/livraison";
import { createcommande } from "@/hooks/pdf/commande";
import { createdevis } from "@/hooks/pdf/devis";
import { createfacture } from "@/hooks/pdf/facture";
import { createtransport } from "@/hooks/pdf/transport";
import { searchArticles, prepareArticle } from "@/hooks/searchEngine/articles";
import {
  calculRemise,
  calculPrixAchat,
} from "@/hooks/calculComptable/calculRemise";
import addArticle from "../components/ajoutArticle/ajoutArticle.vue";
import articleInfo from "../components/articleInfo/articleInfo.vue";
import msgSession from "../components/messageErreurSession/msgSession.vue";
import choiceDaSilva from "../components/Comptoir/choiceDaSilva.vue";
import remisesFournisseurs from "../components/Comptoir/remisesFournisseurs.vue";
import referencesFournisseurs from "../components/Comptoir/referencesFournisseurs.vue";
// import modalTransport from "../components/Comptoir/modalTransport.vue";
import { createComptoirTransfert } from "@/hooks/stock/transfertStock";
// import { getTotalPriceAlll } from "@/hooks/comptoir/StatsPanier";
import { mvStock, updateStock } from "@/hooks/stock/stock.ts";
import {
  addPanierInst,
  getPanier,
} from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import {
  getPanierTransport,
  manageTransportCart,
} from "@/hooks/transport/panierTransport.ts";
import { createReception } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import modalPanierLKQ from "@/components/Comptoir/modalPanierLKQ.vue";
import { createSaisieRapide } from "@/hooks/articles/articles";
import { getFrDate } from "@/hooks/tools/date.ts";
import { decrementStockIncrementReserved } from "@/hooks/stock/stock.ts";
import { getPaiementLimit } from "@/hooks/tools/getLastDayMonth.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openSave: false,
      openModalTransport: false,
      openRetour: false,
      showArticleList: false,
      openRecapVH: false,
      cmdEncaissementVH: false,
      detailRecapVH: false,
      tabRetourHistorique: true,
      groupClient: "",
      articleSearch: "",
      foundArticle: [],
      // documentType: "Bon de livraison",
      commentaireEncaissement: "",
      totalDebutFrais: "",
      totalDebutFraisNonConsigne: "",
      totalDebutFraisConsigne: "",
      historique: [],
      numDoc: 0,
      tel: "",
      transport: "",
      historiqueShow: false,
      showSessionTab: [],
      prixRemise: 0,
      checkboxDevis: false,
      checkboxFacturation: false,
      checkboxFacturation1: false,
      tabFilterHistorique: [],
      // reglementTab: [],
      moyenPaiement: [
        {
          type: "LCR",
          abbrev: "lc",
          image: require("@/assets/icons_payments/LCR.png"),
        },
        {
          type: "Espèce",
          abbrev: "es",
          image: require("@/assets/icons_payments/ESP.png"),
        },
        {
          type: "Virement",
          abbrev: "vi",
          image: require("@/assets/icons_payments/Virement.png"),
        },
        {
          type: "Carte Bancaire",
          abbrev: "cb",
          image: require("@/assets/icons_payments/CB.png"),
        },
        {
          type: "Chèque Comptant",
          abbrev: "cc",
          image: require("@/assets/icons_payments/CHQ.png"),
        },
      ],
      errorPaymentMethod: false,
      fournisseurs: [],
      fournisseursModel: [],
      tempRemiseFourn: [],
      retourSelected: [],
      retour_id: "",
      retour_art: "",
      retour_qty: "",
      promoShow: false,
      tabPromo: [],
      indexArticlePromo: 0,
      showArticlePar: false,
      showOutilsClient: false,
      showSession: false,
      showNameSaiseChaud: false,
      showNameSaiseComment: false,
      showNameCmdVH: false,
      showNameCmdVH2: false,
      showNameDoyen: false,
      showNameIdlp: false,
      showNameAutoPartner: false,
      showNameDaSilva: false,
      showNameMeyle: false,
      showNameProcodis: false,
      showNameVanWezel: false,
      showNameSkf: false,
      showNameKraftwerk: false,
      showNameJbm: false,
      showNameIgol: false,
      showNameMotorparts: false,
      showNameSodise: false,

      showNameHistClient: false,
      showNameClientPar: false,
      showNameArticlePar: false,
      showMargeTotal: false,

      qtyCmdVH: [],
      showDispoCmdVH: [],
      TabCmdFactureVHtemp: [],
      TabCreateFacture: [],
      TabCmdBLVHtemp: [],
      TabCreateLivraison: [],
      TotalPriceTemp: "",
      loadingCommand: false,
      commandState: false,
      resultA05: [],
      savea02Tab: [],

      professionnelParticulier: false,
      nomParticulier: "",
      prenomParticulier: "",
      adresseParticulier: "",
      complementParticulier: "",
      codepostalParticulier: "",
      villeParticulier: "",
      paysParticulier: "FRANCE",
      emailParticulier: "",
      telephoneParticulier: "",

      tabDispoUAN: [],
      showDispo: [],
      openDetailsDispo: false,
      indexDelaisLivraison: 0,

      openRefSimil: false,
      openRefArticle: false,
      refSimil: "",

      // showClickRef: [],
      showPA: [],
      showMarge: [],
      showTTC1: [],
      showTTC2: [],
      // showAddRemise: [],
      vehicule_set: false,
      errorEncaissement: false,
      fournisseurList: [],
      lineAddArticle_quantity: 1,
      lineAddArticle_pv: 1.0,
      lineAddArticle_pa: 0,
      lineAddArticle_remise: 0,
      lineAddArticle_marque: "",
      lineAddArticle_ref: "",
      lineAddArticle_desc: "",
      lineAddArticle_poids: "",
      lineAddArticle_fournisseur_selected: null,
      showSaisieChaud: false,
      showSaisieComment: false,
      showInputSaisieComment: [],
      saisiecommentaire: "",
      autoCompleteTab: [],
      showAutoComplete: false,
      allSelected: [],
      selected: [],
      selectedValue: [],

      indexDepot: 0,
      depotTab: [],
      // logCode: "9980177",
      depot: "STANDARD",

      tabRetour: [],
      tabRetourArticles: [],
      quantityReturned: "",
      qtyReturned: "",
      showErrorMsgReturn: false,
      indexArticleReturned: null,
      showAddRemoveQty: [],

      searchFournisseur: "",
      showMorePanierFournisseur: [],
      showEntreeSortieStock: [],
      showDispoFournisseur: [],

      margetotalpourcent: 0,
      resultSearchTab: [],

      articleInfo_componentKey: 0,
      openModalArticle: false,

      showArticle: false,
      tabPrixNetFourn: [],
      tabPrixNetFournClient: "",
      tabPrixNetClient: [],
      foundPromo: [],
      searchTab: [],
      loadingSearchArticle: false,
      // totalPriceAllDay: 0.0,

      showRemiseGen: false,

      cmdVHloading: false,
      panierVH: [],

      panierTransport: {},
      totalDebut: 0.0,

      tabRaisonRetour: [
        { id: 0, name: "Pièce incorrecte" },
        { id: 1, name: "Dommages pendant le transport" },
        { id: 2, name: "Article défectueux" },
        { id: 3, name: "Commande en double" },
        { id: 4, name: "Consigne" },
        { id: 5, name: "Non compatible" },
        { id: 6, name: "Pièce manquante" },
        { id: 7, name: "Mauvaise quantité" },
        { id: 8, name: "Retard de livraison" },
        { id: 9, name: "Article endommagé après utilisation" },
        { id: 10, name: "Changement d'avis" },
        { id: 11, name: "Autre" },
      ],
      taQtyReturned: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
      showBtnRechercheArticle: true,
      articleSearchToReturn: "",
      loadingSearchDocToReturn: false,
      articleExist: "",
      dataTransport: {},
      loadingValider: false,
      tempTotalHT: 0.0,
    };
  },
  components: {
    // PulseLoader,
    articleInfo,
    msgSession,
    addArticle,
    choiceDaSilva,
    // sessionsComptoir,
    modalPanierLKQ,
    remisesFournisseurs,
    referencesFournisseurs,
    // modalTransport,
  },
  computed: {
    ...mapGetters([
      "actionArticle",
      "user",
      "caisse",
      "crossoetab",
      "generalloading",
      "carcatToken",
      "carCatLink",
      "carCatReference",
      "clientFocus",
      "refClient",
      "prixtab",
      "vehicule",
      "vehiculeSet",
      "VH_a02Tab",
      "VH_cmd_Loading",
      "VH_cmd_state",
      "VH_articleTab",
      "comptoirSession",
      "comptoirSessionActive",
      "dataConsigne",
      "dataNonConsigne",
      "dsvCheck",
      "plateform",
      "restoreTransportCart",
      "panierTransportValue"
    ]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      var totalRemiseGen = 0.0;
      var totaldebut = 0.0;

      if (this.actionArticle.length > 0) {
        this.actionArticle.forEach((element, index) => {
          if (element.PrixFournisseurType == "prixnet") {
            totalRemise = parseFloat(element.Prix_vente);
          } else {
            totalRemise =
              parseFloat(element.Prix_euro) -
              parseFloat(element.Prix_euro) *
                (this.actionArticle[index].remise / 100);
          }
          totaldebut =
            totaldebut + totalRemise * this.actionArticle[index].quantity;
          this.totalDebutFrais = totaldebut;
        });

        if (
          this.comptoirSessionActive[0].Client[0].professionnel == true &&
          (this.comptoirSessionActive[0].documentType === "Devis" ||
            this.comptoirSessionActive[0].documentType === "Facture")
        ) {
          if (parseFloat(this.comptoirSessionActive[0].francodeport) >= 0) {
            if (parseFloat(this.comptoirSessionActive[0].totalPanier) > 0.0) {
              if (
                parseFloat(this.comptoirSessionActive[0].totalPanier) +
                  totaldebut <
                parseFloat(this.comptoirSessionActive[0].francodeport)
              ) {
                this.setfraisdeport({
                  value: this.comptoirSessionActive[0].fraisdeportsave,
                });
                total =
                  totaldebut +
                  parseFloat(this.comptoirSessionActive[0].fraisdeportsave);
              } else {
                this.setfraisdeport({ value: "0" });
                total =
                  totaldebut +
                  parseFloat(this.comptoirSessionActive[0].fraisdeportsave);
              }
            } else {
              if (
                parseFloat(totaldebut) <
                parseFloat(this.comptoirSessionActive[0].francodeport)
              ) {
                this.setfraisdeport({
                  value: this.comptoirSessionActive[0].fraisdeportsave,
                });
                total =
                  totaldebut +
                  parseFloat(this.comptoirSessionActive[0].fraisdeportsave);
              } else {
                this.setfraisdeport({ value: "0" });
                total += totaldebut;
              }
            }
          } else {
            total = totaldebut;
          }
        } else {
          total = totaldebut;
        }
      } else {
        total += totaldebut;
      }

      if (
        this.comptoirSessionActive[0].documentType === "Devis" ||
        this.comptoirSessionActive[0].documentType === "Facture"
      ) {
        totalRemiseGen =
          parseFloat(totaldebut) -
          parseFloat(totaldebut) *
            (this.comptoirSessionActive[0].remisegenerale / 100) +
          parseFloat(this.comptoirSessionActive[0].fraisdeportsave);
      } else {
        totalRemiseGen =
          parseFloat(totaldebut) -
          parseFloat(totaldebut) *
            (this.comptoirSessionActive[0].remisegenerale / 100);
      }

      if (totaldebut >= 0) {
        if (
          this.comptoirSessionActive[0].remisegenerale &&
          this.comptoirSessionActive[0].remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          return total;
        }
      } else {
        if (
          this.comptoirSessionActive[0].remisegenerale &&
          this.comptoirSessionActive[0].remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          this.setfraisdeport({ value: "0" });
          return totaldebut;
        }
      }
    },
    // TotalDayPrice() {
    //   var totalDayPrice =
    //     this.setTotal +
    //     parseFloat(this.totalPriceAllDay) -
    //     parseFloat(this.comptoirSessionActive[0].fraisdeportsave);

    //   if (
    //     totalDayPrice >= parseFloat(this.comptoirSessionActive[0].francodeport)
    //   ) {
    //     this.setfraisdeport({ value: "0" });
    //   }
    //   return totalDayPrice;
    // },
    setTotalSansRemise() {
      var total = 0.0;
      var totalFinal = 0.0;
      var totalFrais = this.totalDebutFrais;

      // total = totalFrais - totalFrais * (this.comptoirSessionActive[0].remisegenerale / 100);

      totalFinal =
        parseFloat(totalFrais) +
        parseFloat(this.comptoirSessionActive[0].fraisdeportsave);

      return totalFinal;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
    setQuantity() {
      var qty = 0;
      this.actionArticle.forEach((element) => {
        qty = qty + parseFloat(element.quantity);
      });
      return qty;
    },
    setRestant() {
      var restant = 0;
      var paye = 0;
      this.comptoirSessionActive[0].reglementTab.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      restant = parseFloat(this.setTotalTTC) - parseFloat(paye);
      if (restant < 0) {
        restant = 0;
      }
      return parseFloat(restant).toFixed(2);
    },
    setRendu() {
      var rendu = 0;
      var paye = 0;
      this.comptoirSessionActive[0].reglementTab.forEach((element) => {
        paye = paye + parseFloat(element.model);
      });
      if (paye > this.setTotalTTC) {
        rendu = Math.abs(paye - this.setTotalTTC);
      }
      return rendu;
    },
    articleTotalLKQ() {
      return this.panierVH.length;
    },
    setTotalConsigne() {
      var totalRemise = 0.0;
      var totaldebut = 0.0;
      this.comptoirSessionActive[0].consigneData.forEach((element, index) => {
        totalRemise =
          parseFloat(element.Prix_euro) -
          parseFloat(element.Prix_euro) *
            (this.comptoirSessionActive[0].consigneData[index].remise / 100);
        // totalRemiseSupp =
        //   totalRemise - totalRemise * (this.remiseTabSCD[index] / 100);
        totaldebut =
          totaldebut +
          totalRemise *
            this.comptoirSessionActive[0].consigneData[index].quantity;
        this.totalDebutFraisConsigne = totaldebut;
      });
      return totaldebut;
    },
    setTotalConsigneSansRemise() {
      // var total = 0.0;
      var totalFinal = 0.0;
      var totalFrais = this.totalDebutFraisConsigne;

      totalFinal =
        parseFloat(totalFrais) +
        parseFloat(this.comptoirSessionActive[0].fraisdeportsave);

      return totalFinal;
    },
    todayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    checkInputsRaisonRetour() {
      if (
        this.comptoirSessionActive[0].documentType == "Retour" ||
        this.comptoirSessionActive[0].documentType == "Bon de livraison Avoir"
      ) {
        var line = false;
        this.actionArticle.forEach((element, i) => {
          if (
            (element.Ref_fournisseur.startsWith("Retour sur") &&
              !element.raisonRetour) ||
            element.raisonRetour === "" ||
            (element.raisonRetour == "Autre" &&
              element.raisonRetourAutre === "")
          ) {
            line = true;
            console.log("Champ vide dans la ligne " + (i + 1));
          }
        });
        return line;
      } else {
        return null;
      }
    },
    setMargeTotalEuro() {
      let total = 0;

      this.actionArticle.forEach((item) => {
        if (!item.Ref_fournisseur.startsWith("C :")) {
          let pv = 0;

          if (item.PrixFournisseurType !== "prixnet") {
            pv = (item.Prix_euro - item.Prix_euro * (item.remise / 100)) * 100;
          } else {
            pv = item.Prix_vente * 100;
          }

          total += pv - item.Prix_achat * 100;
        }
      });

      return total / 100;
    },
    setMargeTotalePourcentage() {
      let total = 0;
      let pvTotal = 0;
      let paTotal = 0;
      let qty = 0;

      this.actionArticle.forEach((item) => {
        if (!item.Ref_fournisseur.startsWith("C :")) {
          qty += 1;
          let prixV = 0;

          if (item.PrixFournisseurType !== "prixnet") {
            prixV =
              (item.Prix_euro - item.Prix_euro * (item.remise / 100)) * 100;
          } else {
            prixV = item.Prix_vente * 100;
          }

          pvTotal += prixV;
          paTotal += item.Prix_achat * 100;

          total += ((pvTotal - paTotal) / paTotal) * 100;
        }
      });

      return total / qty;
    }, 
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "suppactionArticle",
      "clearactionArticle",
      "updateactionArticle",
      "updatestockactionArticle",
      "updateremiseactionArticle",
      "addcaisse",
      "suppStockArticle",
      "addStockArticle",
      // "setMvtStock",
      "searchCross",
      "searchDirect",
      "searchTranslatedDirect",
      "searchTranslatedDirectAdd",
      "storecarcattoken",
      "clearcarcattoken",
      "addpaniercarcat",
      "setcarcatlinks",
      "clearclientfocus",
      "setprixquantitatif",
      "removeprixquantitatif",
      "checkArticleVH",
      "clearVHloadingstate",
      "suppVHloadingstate",
      "suppVH_a02Tab",
      "clearVH_a02Tab",
      "clearvehicule",
      "createcomptoirsession",
      "savecomptoirsession",
      "getallcomptoirsession",
      "setcomptoirsession",
      "removecomptoirsession",
      "getpanierfournisseur",
      "suppVH_PrixLot",
      "addetatretour",
      "suppetatretour",
      "updateqtyactionarticle",
      "setArticleInfo",
      "setfraisdeport",
      "setremisearticle",
      "setquantityarticle",
      "updaterefclient",
      "adddatanonconsigne",
      "suppdatanonconsigne",
      "cleardatanonconsigne",
      "setdsvcheck",
      "adddataconsigne",
      "updateremisedataconsigne",
      "updateqtydataconsigne",
      "suppdataconsigne",
      "cleardataconsigne",
      "setPreparationFournisseur",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    toggleModale() {
      this.showArticle = !this.showArticle;
    },
    async getPanierTransportClient() {
      this.panierTransport = await getPanierTransport(
        this.user.proprietaire,
        this.comptoirSessionActive[0].Client[0]._id
      );
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    select() {
      this.allSelected = [];
      document.getElementById("myCheck").checked;
    },
    adaptConsigneQuantity(index) {
      if (
        this.actionArticle[index + 1] &&
        this.actionArticle[index + 1].Ref_fournisseur.startsWith("C :")
      ) {
        this.actionArticle[index + 1].quantity =
          this.actionArticle[index].quantity;
      }
    },
    async addManualLine() {
      var dataTab = {};
      var m = Math;
      var d = Date;
      var h = 16;
      var s = (s) => m.floor(s).toString(h);
      dataTab._id =
        s(d.now() / 1000) +
        " ".repeat(h).replace(/./g, () => s(m.random() * h));
      dataTab.PrixFournisseurType = "remise";

      dataTab.quantity = this.lineAddArticle_quantity;
      dataTab.remise = this.lineAddArticle_remise;
      dataTab.commentaire = "";
      dataTab.quantityPrep = 0;
      dataTab.prixtotal = dataTab.Prix_euro;
      dataTab.prixfinal = dataTab.Prix_euro;
      dataTab.Ref_fournisseur = this.lineAddArticle_ref;
      dataTab.Code_marque = this.lineAddArticle_marque;
      dataTab.Description = this.lineAddArticle_desc;
      dataTab.Poids = this.lineAddArticle_poids;
      dataTab.Prix_euro = this.lineAddArticle_pv;
      dataTab.Prix_achat = this.lineAddArticle_pa;
      if (this.lineAddArticle_fournisseur_selected) {
        dataTab.fournisseur =
          this.lineAddArticle_fournisseur_selected.CompanyName;
        dataTab.Prefixe_tarif =
          this.lineAddArticle_fournisseur_selected.Prefixe_tarif;
        dataTab.FournisseurSelected =
          this.lineAddArticle_fournisseur_selected.CompanyName;
      } else {
        dataTab.FournisseurSelected = "Aucun";
      }
      this.addactionArticle(dataTab);

      await createSaisieRapide({
        type: "piece",
        plateform: this.user.proprietaire,
        Prefixe_tarif: this.lineAddArticle_fournisseur_selected.Prefixe_tarif,
        Ref_fournisseur: this.lineAddArticle_ref,
        Description: this.lineAddArticle_desc,
        Code_EAN: "",
        Code_marque: this.lineAddArticle_marque,
        Poids: this.lineAddArticle_poids,
        fournisseur: this.lineAddArticle_fournisseur_selected.CompanyName,
        Prix_euro: this.lineAddArticle_pv,
        Prix_achat: this.lineAddArticle_pa,
        PrixFournisseurType: "remise",
        quantity: this.lineAddArticle_quantity,
        remise: this.lineAddArticle_remise,
      });

      this.showSaisieChaud = false;
    },
    async handlerArticle(structure) {
      var existArticle = false;

      for (const [index, actArticle] of this.actionArticle.entries()) {
        if (
          actArticle.Ref_fournisseur == structure.article.Ref_fournisseur &&
          actArticle.Prefixe_tarif == structure.article.Prefixe_tarif &&
          actArticle.Code_marque == structure.article.Code_marque
        ) {
          this.actionArticle[index].quantity =
            parseInt(this.actionArticle[index].quantity) + 1;
          existArticle = true;
          this.setRemiseArticle(
            this.actionArticle[index],
            index,
            this.actionArticle[index].remise,
            this.actionArticle[index].quantity,
            actArticle.Prix_euro
          );
        }
      }

      if (existArticle == false) {
        let dataTab = structure.article;
        dataTab.PrixFournisseurType = structure.prix.PrixFournisseurType;
        dataTab.remise = structure.prix.remise;
        dataTab.remiseFournisseur = structure.prix.remiseFournisseur;
        dataTab.Prix_vente = structure.prix.prix_net;
        dataTab.Prix_euro = structure.prix.prix_brut;
        dataTab.Prix_achat = structure.prix.prix_achat;
        dataTab.FournisseurSelected = structure.prix.FournisseurSelected;
        dataTab.Promo_set = structure.prix.promo;
        dataTab.quantity = 1;
        dataTab.quantityPrep = 0;
        dataTab.quantityReturned = 0;

        await this.addConsigne(dataTab);
        await this.addactionArticle(dataTab);

        this.savecomptoirsession({
          id: 6,
          clientFocus: this.comptoirSessionActive[0].Client,
          refClient: this.comptoirSessionActive[0].refClient,
          consigneData: this.comptoirSessionActive[0].consigneData,
          consigneNonData: this.comptoirSessionActive[0].consigneNonData,
          dsvCheck: this.comptoirSessionActive[0].dsvCheck,
        });
      }
      this.showAutoComplete = false;
      this.showArticleList = false;
      this.articleSearch = "";
    },
    async addConsigne(dataConsigne) {
      const foundConsigne = await axios.post(
        `${process.env.VUE_APP_API}/article/getconsigne`,
        {
          data: dataConsigne,
        }
      );
      if (foundConsigne.data.length > 0) {
        if (this.comptoirSessionActive[0].dsvCheck == false) {
          this.addactionArticle({
            _id: dataConsigne._id + "Consigne",
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
        if (dataConsigne.Code_marque == "DA SILVA") {
          this.adddataconsigne(dataConsigne);
          this.adddataconsigne({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: 1,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        } else {
          this.adddatanonconsigne(dataConsigne);
          this.adddatanonconsigne({
            _id: dataConsigne._id,
            Ref_fournisseur: "C : " + dataConsigne.Ref_fournisseur,
            Prix_euro: dataConsigne.Montant_consigne,
            Prix_vente: dataConsigne.Montant_consigne,
            PrixFournisseurType: "prixnet",
            disabled: true,
            quantity: dataConsigne.quantity,
            quantityPrep: 0,
            quantityReturned: 0,
            remise: 0,
            prixtotal: dataConsigne.Prix_euro,
            prixfinal: dataConsigne.Prix_euro,
            Code_marque: dataConsigne.Code_marque,
            Description: dataConsigne.Description,
            commentaire: dataConsigne.commentaire,
          });
        }
      } else {
        // if (
        //   this.comptoirSessionActive[0].Client[0].numcomptedasilva &&
        //   this.comptoirSessionActive[0].Client[0].numcomptedasilva != ""
        // ) {
        //   this.adddatanonconsigne(dataConsigne);
        // }
        if (dataConsigne.Code_marque == "DA SILVA") {
          this.adddataconsigne(dataConsigne);
        } else {
          this.adddatanonconsigne(dataConsigne);
        }
      }
    },
    clearPanier() {
      this.cleardataconsigne();
      this.cleardatanonconsigne();
      this.clearactionArticle();
      this.clearVHloadingstate();
      this.setdsvcheck(false);
      this.showSaisieChaud = false;
      this.savecomptoirsession({
        id: 12,
        clientFocus: this.comptoirSessionActive[0].Client,
        refClient: this.comptoirSessionActive[0].refClient,
        consigneData: this.comptoirSessionActive[0].consigneData,
        consigneNonData: this.comptoirSessionActive[0].consigneNonData,
        dsvCheck: this.comptoirSessionActive[0].dsvCheck,
        repriseTypeDoc: null,
        repriseNumDoc: null,
        repriseIdDoc: null,
      });
    },
    async suppActionArticle(dataArt) {
      // if (!dataArt._id.endsWith('Consigne')){
      //   const foundConsigne = await axios.post(
      //     `${process.env.VUE_APP_API}/article/getconsigne`,
      //     {
      //       data: dataArt,
      //     }
      //   );
      //   if (foundConsigne.data.length > 0 && dataArt.Code_marque=='DA SILVA') {
      //     await this.suppactionArticle(dataArt._id);
      //     this.suppdataconsigne(dataArt._id);
      //   } else {
      //     await this.suppactionArticle(dataArt._id);
      //     this.suppdatanonconsigne(dataArt._id);
      //   }
      // } else {
      await this.suppactionArticle(dataArt._id);
      this.suppdataconsigne(dataArt._id);
      this.suppdatanonconsigne(dataArt._id);
      // }
      this.savecomptoirsession({
        id: 13,
        clientFocus: this.comptoirSessionActive[0].Client,
        refClient: this.comptoirSessionActive[0].refClient,
        consigneData: this.comptoirSessionActive[0].consigneData,
        consigneNonData: this.comptoirSessionActive[0].consigneNonData,
        dsvCheck: this.comptoirSessionActive[0].dsvCheck,
      });
    },
    retourArticles(quantite, art, index) {
      if (quantite < 0) {
        this.openRetour = true;
        this.fetchHistoriqueRetour(art);
        // this.fetchHistoriqueRetour1(art.Ref_fournisseur);
        this.retour_id = art._id;
        this.retour_art = art.Ref_fournisseur;
        this.retour_qty = quantite;
        this.indexArticleReturned = index;
      }
    },
    async openEncaissement(doc) {
      // await this.getBdlsOfTheDayByClient(this.comptoirSessionActive[0].Client[0]._id);
      if (doc == "Bon de commande" || doc == "Facturation") {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const day = today.getDate().toString().padStart(2, "0");
        console.log(this.comptoirSessionActive[0].Client[0]);
        if (
          this.comptoirSessionActive[0].reglementTab.length == 0 &&
          this.comptoirSessionActive[0].Client[0].professionnel == true &&
          doc == "Facturation"
        ) {
          switch (this.comptoirSessionActive[0].Client[0].moyendepaiement) {
            case "Espèce":
              this.comptoirSessionActive[0].reglementTab.push({
                img: require("@/assets/icons_payments/ESP.png"),
                icon: "payments",
                placeholder: "Espèce",
                model: parseFloat(this.setRestant).toFixed(2),
                date: `${year}-${month}-${day}`,
                disabled: false,
                user: this.user.username,
              });
              this.errorPaymentMethod = false;
              break;
            case "CB":
              this.comptoirSessionActive[0].reglementTab.push({
                img: require("@/assets/icons_payments/CB.png"),
                icon: "credit_card",
                placeholder: "Carte Bancaire",
                model: parseFloat(this.setRestant).toFixed(2),
                date: `${year}-${month}-${day}`,
                disabled: false,
                user: this.user.username,
              });
              this.errorPaymentMethod = false;
              break;
            case "Chèque":
              this.comptoirSessionActive[0].reglementTab.push({
                img: require("@/assets/icons_payments/CHQ.png"),
                icon: "money",
                placeholder: "Chèque Comptant",
                model: parseFloat(this.setRestant).toFixed(2),
                date: `${year}-${month}-${day}`,
                disabled: false,
                user: this.user.username,
              });
              this.errorPaymentMethod = false;
              break;
            case "Virement":
              this.comptoirSessionActive[0].reglementTab.push({
                img: require("@/assets/icons_payments/Virement.png"),
                icon: "account_balance",
                placeholder: "Virement",
                model: parseFloat(this.setRestant).toFixed(2),
                date: `${year}-${month}-${day}`,
                disabled: false,
                user: this.user.username,
              });
              this.errorPaymentMethod = false;
              break;
            case "LCR":
              this.comptoirSessionActive[0].reglementTab.push({
                img: require("@/assets/icons_payments/LCR.png"),
                icon: "account_balance",
                placeholder: "LCR",
                model: parseFloat(this.setRestant).toFixed(2),
                date: `${year}-${month}-${day}`,
                disabled: false,
                user: this.user.username,
              });
              this.errorPaymentMethod = false;
              break;
            default:
              this.errorPaymentMethod = true;
              break;
          }
          this.openSave = true;

          this.savecomptoirsession({
            id: 4,
            clientFocus: this.comptoirSessionActive[0].Client,
            reglementTab: this.comptoirSessionActive[0].reglementTab,
          });
        }
        this.errorPaymentMethod = false;
        this.openSave = true;
      }
      // else if (
      //   doc == "Bon de livraison" &&
      //   this.comptoirSessionActive[0].Client[0].professionnel &&
      //   this.comptoirSessionActive[0].Client[0].livrepartransporteur == true
      // ) {
      //   this.openModalTransport = true;
      // }
      else {
        this.saveDocument();
        this.fetchHistoriqueDone();
      }
    },
    retourArticlesButton(quantite, art, index) {
      this.openRetour = true;
      this.fetchHistoriqueRetour(art);
      // this.fetchHistoriqueRetour1(art.Ref_fournisseur);
      this.retour_id = art._id;
      this.retour_art = art.Ref_fournisseur;
      this.retour_qty = quantite;
      this.indexArticleReturned = index;
    },
    attachRetour(historiqueFileSelected) {
      this.retourSelected = [];
      this.taQtyReturned = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      this.tabRetourHistorique = false;
      this.retourSelected = historiqueFileSelected;
      // this.getQtyReturned();
    },
    getQtyReturned() {
      this.retourSelected.Articles.forEach((element) => {
        if (element.Ref_fournisseur == this.retour_art) {
          this.quantityReturned = element.quantity;
        }
      });
    },
    setQtyReturned() {
      const dataArt = {
        indexArt: this.indexArticleReturned,
        quantity: -this.qtyReturned,
      };
      this.updateqtyactionarticle(dataArt);
      const dataArtConsigne = {
        Articles: this.tabRetourArticles,
        quantity: -this.qtyReturned,
      };
      this.updateqtydataconsigne(dataArtConsigne);
    },
    suppEtatRetour(ind) {
      this.suppetatretour({ index: ind });
      document.getElementById(`qty${ind}`).disabled = false;
      document.getElementById(`qty${ind}`).style.borderColor = "#9ea3af";
    },
    getDocToReturn() {
      this.tabRetour = [];
      this.tabRetourHistorique = true;
      this.openRetour = true;
      this.loadingSearchDocToReturn = true;
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByClientInPlateform`, {
          uid: {
            client: this.comptoirSessionActive[0].Client[0].Name,
            owner: this.user.proprietaire,
            article: this.articleSearchToReturn,
          },
        })
        .then((response) => {
          this.loadingSearchDocToReturn = false;
          this.tabRetour = response.data;
        });
    },
    async checkItemExist(idBL, ean) {
      await axios
        .post(`${process.env.VUE_APP_API}/comptoirSession/checkItemExist`, {
          plateform: this.user.proprietaire, // plateform
          client: this.comptoirSessionActive[0].Client[0].Name, // Client
          user: this.user.username, // Client
          idBL: idBL,
          ean: ean,
        })
        .then((response) => {
          this.articleExist = response.data;
          console.log(response);
        })
        .catch((error) => {
          this.articleExist = false;
          console.log(error);
        });
    },
    async addReturnCart(data, dateBL, quantity, idBL, ean, index) {
      await this.checkItemExist(idBL, ean);
      if (this.articleExist == false) {
        if (
          parseInt(quantity) >
            parseInt(data.quantity - data.quantityReturned) ||
          parseInt(quantity) <= 0
        ) {
          this.toast.error("Quantité invalide !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else {
          if (this.indexArticleReturned != null) {
            this.actionArticle.splice(this.indexArticleReturned, 1);
            this.indexArticleReturned = null;
          }
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();
          var hour = today.getHours();
          var seconds = today.getSeconds();
          var minutes = today.getMinutes();
          today =
            dd +
            "/" +
            mm +
            "/" +
            yyyy +
            " " +
            hour +
            ":" +
            minutes +
            ":" +
            seconds;
          if (
            this.retourSelected.Articles[index + 1] &&
            this.retourSelected.Articles[index + 1].Ref_fournisseur.startsWith(
              "C :"
            )
          ) {
            console.log("dans consigne");
            this.actionArticle.unshift({
              ...this.retourSelected.Articles[index + 1],
            });
            this.actionArticle[0].Prix_euro =
              this.retourSelected.Articles[index + 1].Prix_euro;
            this.actionArticle[0].Prix_vente =
              this.retourSelected.Articles[index + 1].Prix_vente;
            this.actionArticle[0].PrixFournisseurType =
              this.retourSelected.Articles[index + 1].PrixFournisseurType;
            this.actionArticle[0].remise =
              this.retourSelected.Articles[index + 1].remise;
            this.actionArticle[0].quantity =
              -this.retourSelected.Articles[index + 1].quantity;
            this.actionArticle[0].requestedQuantity =
              -this.retourSelected.Articles[index + 1].quantity;
            this.actionArticle[0].idBL = idBL;
            this.actionArticle[0].dateBL = this.retourSelected.Date;
            this.actionArticle[0].typeDoc = this.retourSelected.type;
            this.actionArticle[0].numBL = this.retourSelected.Numero;
            this.actionArticle[0].raisonRetour = "";
            this.actionArticle[0].raisonRetourAutre = "";
            this.actionArticle.unshift({
              ...data,
            });
            this.actionArticle[0].Prix_euro = data.Prix_euro;
            this.actionArticle[0].Prix_vente = data.Prix_vente;
            this.actionArticle[0].PrixFournisseurType =
              data.PrixFournisseurType;
            this.actionArticle[0].remise = data.remise;
            this.actionArticle[0].quantity = -quantity;
            this.actionArticle[0].requestedQuantity = -quantity;
            this.actionArticle[0].idBL = idBL;
            this.actionArticle[0].dateBL = this.retourSelected.Date;
            this.actionArticle[0].typeDoc = this.retourSelected.type;
            this.actionArticle[0].numBL = this.retourSelected.Numero;
            this.actionArticle[0].raisonRetour = "";
            this.actionArticle[0].raisonRetourAutre = "";
            this.toast.success("Ajouté au panier !", {
              position: "bottom-right",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else {
            this.actionArticle.unshift({
              ...data,
            });
            this.actionArticle[0].Prix_euro = data.Prix_euro;
            this.actionArticle[0].Prix_vente = data.Prix_vente;
            this.actionArticle[0].PrixFournisseurType =
              data.PrixFournisseurType;
            this.actionArticle[0].remise = data.remise;
            this.actionArticle[0].quantity = -quantity;
            this.actionArticle[0].requestedQuantity = -quantity;
            this.actionArticle[0].idBL = idBL;
            this.actionArticle[0].dateBL = this.retourSelected.Date;
            this.actionArticle[0].typeDoc = this.retourSelected.type;
            this.actionArticle[0].numBL = this.retourSelected.Numero;
            this.actionArticle[0].raisonRetour = "";
            this.actionArticle[0].raisonRetourAutre = "";
          }
          this.savecomptoirsession({
            id: 4,
            clientFocus: this.comptoirSessionActive[0].Client,
            refClient: this.comptoirSessionActive[0].refClient,
            consigneData: this.comptoirSessionActive[0].consigneData,
            consigneNonData: this.comptoirSessionActive[0].consigneNonData,
            dsvCheck: this.comptoirSessionActive[0].dsvCheck,
            documentType: "Retour",
          });
          this.articleSearchToReturn = "";
          this.toast.success("Ajouté au panier !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        }
      } else {
        this.toast.error("Ce produit est dans le panier !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    addRetour(articleRetourData) {
      if (this.qtyReturned > this.quantityReturned) {
        this.showErrorMsgReturn = true;
      } else {
        this.savecomptoirsession({
          id: 4,
          clientFocus: this.comptoirSessionActive[0].Client,
          refClient: this.comptoirSessionActive[0].refClient,
          consigneData: this.comptoirSessionActive[0].consigneData,
          consigneNonData: this.comptoirSessionActive[0].consigneNonData,
          dsvCheck: this.comptoirSessionActive[0].dsvCheck,
          documentType: "Retour",
        });
        this.showErrorMsgReturn = false;
        this.openRetour = false;
        this.tabRetourHistorique = true;
        this.tabRetourHistorique = !this.tabRetourHistorique;
        // this.qtyTab[this.indexArticleReturned] = -this.qtyReturned;
        this.setquantityarticle({
          index: this.indexArticleReturned,
          quantity: -this.qtyReturned,
        });
        this.setQtyReturned();
        this.qtyReturned = "";
        this.addetatretour({ index: this.indexArticleReturned });
        document.getElementById(
          `qty${this.indexArticleReturned}`
        ).disabled = true;
        document.getElementById(
          `qty${this.indexArticleReturned}`
        ).style.borderColor = "orange";
        console.log(this.actionArticle[this.indexArticleReturned]);
        this.actionArticle[this.indexArticleReturned].Prix_euro =
          articleRetourData.Prix_euro;
        this.actionArticle[this.indexArticleReturned].Prix_vente =
          articleRetourData.Prix_vente;
        this.actionArticle[this.indexArticleReturned].PrixFournisseurType =
          articleRetourData.PrixFournisseurType;
        this.actionArticle[this.indexArticleReturned].remise =
          articleRetourData.remise;
        this.actionArticle[this.indexArticleReturned].dateBL =
          this.retourSelected.Date;
        this.actionArticle[this.indexArticleReturned].raisonRetour = "";
        this.actionArticle[this.indexArticleReturned].raisonRetourAutre = "";
        // this.addactionArticle({
        //   _id: this.retour_id + "retour",
        //   Ref_fournisseur:
        //     "Retour sur " +
        //     this.retourSelected.type +
        //     " n°" +
        //     this.retourSelected.Numero,
        //   Description: "",
        //   dateBL: this.retourSelected.Date,
        //   raisonRetour: "",
        //   commentaire: "",
        //   Prix_vente: 0,
        //   quantity: 1,
        //   remise: 0,
        //   Prix_euro: 0,
        // });
      }
    },
    etatRetour() {
      this.actionArticle.forEach((element, index) => {
        if (element.etatRetour == true || element.raisonRetour) {
          document.getElementById(`qty${index}`).disabled = true;
          document.getElementById(`qty${index}`).style.borderColor = "orange";
          this.showAddRemoveQty[index] = false;
        }
      });
    },
    getFactureArticle(value, state) {
      //TODO supprimer cette fonction
      if (state == true) {
        this.tabFilterHistorique.push(value);
        this.fetchHistoriqueFiltred();
      } else {
        var indexD = this.tabFilterHistorique.indexOf(value);

        if (indexD > -1) {
          this.tabFilterHistorique.splice(indexD, 1);
        }
        this.fetchHistoriqueFiltred();
      }
    },
    async addToCommandInst(article, Qty, index) {
      await addPanierInst(this.user.proprietaire, article, Qty);
      this.actionArticle[index].inCommande = true;
      this.getPanierVH();
    },
    deleteLine(index) {
      this.suppVH_a02Tab({
        index: index,
        name: this.depot,
        indexDepot: this.indexDepot,
      });
      this.suppVH_PrixLot({ index: index });
    },
    async getOneArticle() {
      this.showAutoComplete = false;
      this.showArticleList = true;
      if (this.articleSearch != "") {
        this.searchTab = [];
        this.loadingSearchArticle = true;
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }
        this.searchTab = await searchArticles(
          this.articleSearch,
          plateform_mere,
          this.comptoirSessionActive[0].Client[0].Name,
          this.comptoirSessionActive[0].Client[0].Group.Name
        );
        this.loadingSearchArticle = false;

        this.articleSearch = "";
      } else {
        if (this.searchTab.length >= 1) {
          this.handlerArticle(this.searchTab[0]);
          this.showAutoComplete = false;
          this.showArticleList = false;
          this.articleSearch = "";
        }
      }
    },
    getDoc() {
      if (this.comptoirSessionActive[0].documentType) {
        if (this.comptoirSessionActive[0].documentType == "Facturation") {
          if (this.TotalPriceTemp >= 0) {
            createfacture(this.TabCreateFacture, false, false);
          } else {
            createfacture(this.TabCreateFacture, false, true);
          }
          this.$router.push("/facture");
        }
        if (this.comptoirSessionActive[0].documentType == "Bon de livraison") {
          if (this.TotalPriceTemp >= 0) {
            createlivraison(this.TabCreateLivraison, false, false);
          } else {
            createlivraison(this.TabCreateLivraison, false, true);
          }
          // this.$router.push("/bdl");
        }
      }
    },
    async getCmdVH() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      var pdfinfo = [];

      var prixRemise = 0.0;

      this.comptoirSessionActive[0].reglementTab.push({
        icon: "money",
        placeholder: "Reste dû",
        model: parseFloat(this.setRestant).toFixed(2),
        date: "",
        disabled: false,
        user: this.user.username,
      });

      this.savecomptoirsession({
        id: 4,
        clientFocus: this.comptoirSessionActive[0].Client,
        reglementTab: this.comptoirSessionActive[0].reglementTab,
      });

      // this.comptoirSessionActive[0].reglementTab.push({
      //   icon: "money",
      //   placeholder: "Rendu",
      //   model: parseFloat(this.setRendu).toFixed(2),
      //   date: "",
      // });

      this.actionArticle.forEach((element, index) => {
        prixRemise =
          parseFloat(element.Prix_euro) -
          parseFloat(element.Prix_euro) *
            (this.actionArticle[index].remise / 100);
        // prixRemiseSupp =
        //   prixRemise - prixRemise * (this.remiseTabSCD[index] / 100);
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: this.actionArticle[index].quantity,
          remise: this.actionArticle[index].remise,
          commentaire: element.commentaire,
          remisegenerale: this.comptoirSessionActive[0].remisegenerale,
          // remiseSupp: this.remiseTabSCD[index],
          prixU: prixRemise,
          total: prixRemise * this.actionArticle[index].quantity,
          totalSansRemise: this.setTotalSansRemise,
        };
      });

      if (this.comptoirSessionActive[0].documentType) {
        this.commandState = false;
        this.loadingCommand = true;
        if (this.comptoirSessionActive[0].documentType == "Facturation") {
          await axios
            .post(
              `${process.env.VUE_APP_API}/commandeFournisseur/commanderA05`,
              {
                infoCommande: this.VH_a02Tab[this.indexDepot].lines,
                plateform: this.user.proprietaire,
                Document: this.TabCmdFactureVHtemp,
                Articles: this.VH_articleTab,
                User: this.user.username,
                login: this.plateform.grossierID,
              }
            )
            .then((res) => {
              this.loadingCommand = false;
              this.commandState = true;
              this.resultA05 = res.data;
              this.savea02Tab = this.VH_a02Tab[this.indexDepot].lines;
              this.clearVH_a02Tab({ name: this.depot });
              this.toast.success("Commande passée !", {
                position: "bottom-right",
                timeout: 1000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 1,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });

              if (this.TotalPriceTemp >= 0) {
                createfacture(this.TabCreateFacture, false, false);
              } else {
                createfacture(this.TabCreateFacture, false, true);
              }
              this.$router.push("/facture");
            });
        }
        if (this.comptoirSessionActive[0].documentType == "Bon de livraison") {
          axios
            .post(
              `${process.env.VUE_APP_API}/commandeFournisseur/commanderA05`,
              {
                infoCommande: this.VH_a02Tab[this.indexDepot].lines,
                plateform: this.user.proprietaire,
                Document: this.TabCmdBLVHtemp,
                Articles: this.VH_articleTab,
                User: this.user.username,
                login: this.plateform.grossierID,
              }
            )
            .then((res) => {
              this.loadingCommand = false;
              this.commandState = true;
              this.resultA05 = res.data;
              this.savea02Tab = this.VH_a02Tab[this.indexDepot].lines;
              this.clearVH_a02Tab({ name: this.depot });
              this.toast.success("Commande passée !", {
                position: "bottom-right",
                timeout: 1000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 1,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              if (this.TotalPriceTemp >= 0) {
                createlivraison(this.TabCreateLivraison, false, false);
              } else {
                createlivraison(this.TabCreateLivraison, false, true);
              }
              // this.$router.push("/bdl");
            });
        }
      }
    },
    async saveDocumentArticle(today) {
      var pdfinfo = [];

      var prixRemise = 0.0;
      var rest = this.setRestant;

      await this.actionArticle.forEach((element, index) => {
        if (element.PrixFournisseurType == "prixnet") {
          prixRemise = parseFloat(element.Prix_vente);
        } else {
          prixRemise =
            parseFloat(element.Prix_euro) -
            parseFloat(element.Prix_euro) *
              (this.actionArticle[index].remise / 100);
        }
        // prixRemiseSupp =
        //   prixRemise - prixRemise * (this.remiseTabSCD[index] / 100);
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: this.actionArticle[index].quantity,
          remise: this.actionArticle[index].remise,
          commentaire: element.commentaire ? element.commentaire : "",
          remisegenerale: this.comptoirSessionActive[0].remisegenerale,
          // remiseSupp: this.remiseTabSCD[index],
          prixU: prixRemise,
          total: prixRemise * this.actionArticle[index].quantity,
          totalSansRemise: this.setTotalSansRemise,
        };
      });

      this.tempTotalHT = this.setTotal;

      switch (this.comptoirSessionActive[0].documentType) {
        case "Devis":
          await axios
            .post(`${process.env.VUE_APP_API}/devis/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1;
            });
          var dataDevis = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: this.setTotal,
            TotalPriceTTC: this.setTotalTTC,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.actionArticle,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Archived: false,
            Transport: this.comptoirSessionActive[0].fraisdeportsave,
          };
          await axios
            .post(`${process.env.VUE_APP_API}/devis`, dataDevis)
            .then((res) => {
              createdevis(res.data, false);
              this.$router.push("/devis");
            });
          break;
        case "Bon de commande":
          await axios
            .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1;
            });
          var dataCommande = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: this.setTotal,
            TotalPriceTTC: this.setTotalTTC,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.actionArticle,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
          };
          await axios
            .post(`${process.env.VUE_APP_API}/commandes`, dataCommande)
            .then((res) => {
              this.setRepriseDoc();
              createcommande(res.data, false);
              this.$router.push("/commandes");
            });
          await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
            Type: "Commande",
            cmdPro: this.comptoirSessionActive[0].Client[0].professionnel,
            Owner: this.comptoirSessionActive[0].Client[0].Name,
            Name: this.user.username,
            plateform: this.user.proprietaire,
            Date: new Date(),
            Numero: 123,
            TotalPrice: this.setTotal,
            TotalPriceTTC: this.setTotalTTC,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Commentaire: this.commentaire,
            clientInfo: this.comptoirSessionActive[0].Client,
            BCNumber: this.numDoc,
            Articles: this.actionArticle,
            status: "Commande Passée",
            preparedBy: "personne",
            refCommande: "kjgkjyg",
            PourcentageStatus: "10",
            cmdstate: [true, false, false, false, true],
            Transport: 0,
          });
          for (const element of this.actionArticle) {
            if (element.Prefixe_tarif) {
              decrementStockIncrementReserved(
                element.Code_EAN,
                element.quantity,
                element.quantity,
                this.user.proprietaire,
                element.Ref_fournisseur,
                element.Prefixe_tarif,
                element.Code_marque,
                this.user.username,
                "Comptoir : Bon de commande n°" +
                  this.numDoc +
                  " édité par : " +
                  this.user.username +
                  ". " +
                  element.quantity +
                  " réservé.",
                this.comptoirSessionActive[0].Client[0].Name,
                this.numDoc
              );
            }
          }
          break;
        case "Bon de livraison":
          // await createComptoirTransfert(
          //   this.actionArticle,
          //   this.user.username,
          //   this.user.proprietaire
          // );
          await axios
            .post(`${process.env.VUE_APP_API}/bdl/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1;
            });

          var dataBL = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: this.setTotal,
            TotalPriceTTC: this.setTotalTTC,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.actionArticle,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
            dataTransport: this.dataTransport,
          };
          await axios
            .post(`${process.env.VUE_APP_API}/bdl`, dataBL)
            .then(async (res) => {
              await this.getBdlsOfTheDayByClient(dataBL.clientInfo[0]._id);
              this.setRepriseDoc();
              // if (this.VH_a02Tab.length > 0) {
              //   this.openRecapVH = true;
              //   this.detailRecapVH = true;
              //   this.cmdEncaissementVH = true;
              // } else {

              if (this.setTotal >= 0) {
                createlivraison(dataBL, false, false);
              } else {
                createlivraison(dataBL, false, true);
              }
              // axios
              //   .post(`${process.env.VUE_APP_API}/bdl/getone`, {
              //     uid: {
              //       BLNumber: dataBL.Numero,
              //       plateform: this.user.proprietaire,
              //     },
              //   })
              //   .then((response) => {
              //     if (this.setTotal >= 0) {
              //       createlivraison(response.data, false, false);
              //     } else {
              //       createlivraison(response.data, false, true);
              //     }
              //     console.log("BON DE LIVRAISON : ", response.data);
              //   });

              dataBL = this.dataBdl;
              // this.$router.push("/bdl");
              // }
            });
          for (const element of this.actionArticle) {
            if (element.Prefixe_tarif) {
              await updateStock(
                element.Code_EAN,
                element.quantity,
                this.user.proprietaire,
                element.Ref_fournisseur,
                element.Prefixe_tarif,
                element.Code_marque,
                this.user.username,
                "Comptoir : Bon de livraison n°" +
                  this.numDoc +
                  " édité par : " +
                  this.user.username,
                this.comptoirSessionActive[0].Client[0].Name,
                this.numDoc,
                element.Qte_mini,
                element.Qte_maxi
              );
            }
          }
          this.TotalPriceTemp = this.setTotal;
          (this.TabCreateLivraison = this.dataBdl),
            (this.TabCmdBLVHtemp = [
              {
                Name: this.user.username,
                type: "BL",
                Numero: this.numDoc,
                Owner: this.user.proprietaire,
                TotalPrice: this.setTotal,
                TotalPriceTTC: this.setTotalTTC,
                Client: this.comptoirSessionActive[0].Client[0].Name,
                Date: new Date(),
                IsProcessed: false,
                Note: this.comptoirSessionActive[0].refClient,
                Articles: this.actionArticle,
                reglement: this.comptoirSessionActive[0].reglementTab,
                pdfData: pdfinfo,
                clientInfo: this.comptoirSessionActive[0].Client,
                Transport: 0,
                dataTransport: this.dataTransport,
              },
            ]);
          break;
        case "Bon de transport":
          await axios
            .post(`${process.env.VUE_APP_API}/bdt/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1;
            });
          var dataBT = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: this.setTotal,
            TotalPriceTTC: this.setTotalTTC,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.actionArticle,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
          };
          await axios
            .post(`${process.env.VUE_APP_API}/bdt`, dataBT)
            .then((res) => {
              createtransport(res.data, false);
              // this.$router.push("/bdl");
            });
          break;
        case "Avoir":
          await axios
            .post(`${process.env.VUE_APP_API}/avoir/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1;
            });
          await axios.post(`${process.env.VUE_APP_API}/avoir`, {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: this.setTotal,
            TotalPriceTTC: this.setTotalTTC,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.actionArticle,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
          });
          break;
        case "Facturation":
          if (rest == 0.0 || rest == 0) {
            // await createComptoirTransfert(
            //   this.actionArticle,
            //   this.user.username,
            //   this.user.proprietaire
            // );
            await axios
              .post(`${process.env.VUE_APP_API}/facture/getlast`, {
                uid: this.user.proprietaire,
              })
              .then((response) => {
                this.numDoc = response.data + 1;
              });
            console.log(
              await getPaiementLimit(
                Date.now(),
                this.comptoirSessionActive[0].Client[0].delaidepaiement
              )
            );
            var dataFacture = {
              Name: this.user.username,
              Owner: this.user.proprietaire,
              Date: new Date(),
              Numero: this.numDoc,
              TotalPrice: this.setTotal,
              TotalPriceTTC: this.setTotalTTC,
              paymentDeadline: await getPaiementLimit(
                Date.now(),
                this.comptoirSessionActive[0].Client[0].delaidepaiement
              ),
              Client: this.comptoirSessionActive[0].Client[0].Name,
              IsProcessed: false,
              Note: this.comptoirSessionActive[0].refClient,
              Articles: this.actionArticle,
              reglement: this.comptoirSessionActive[0].reglementTab,
              pdfData: pdfinfo,
              clientInfo: this.comptoirSessionActive[0].Client,
              Transport: this.comptoirSessionActive[0].fraisdeportsave,
            };
            await axios
              .post(`${process.env.VUE_APP_API}/facture`, dataFacture)
              .then((res) => {
                // if (this.VH_a02Tab.length > 0) {
                //   this.openRecapVH = true;
                //   this.detailRecapVH = true;
                //   this.cmdEncaissementVH = true;
                // } else {
                if (this.setTotal >= 0) {
                  createfacture(res.data, false, false);
                } else {
                  createfacture(res.data, false, true);
                }
                dataFacture = res.data;
                this.$router.push("/facture");
                // }
              });
            for (const element of this.actionArticle) {
              if (element.Prefixe_tarif) {
                await updateStock(
                  element.Code_EAN,
                  element.quantity,
                  this.user.proprietaire,
                  element.Ref_fournisseur,
                  element.Prefixe_tarif,
                  element.Code_marque,
                  this.user.username,
                  "Comptoir : facture n°" +
                    this.numDoc +
                    " édité par : " +
                    this.user.username,
                  this.comptoirSessionActive[0].Client[0].Name,
                  this.numDoc,
                  element.Qte_mini,
                  element.Qte_maxi
                );
              }
            }
            this.TotalPriceTemp = this.setTotal;
            (this.TabCreateFacture = dataFacture),
              (this.TabCmdFactureVHtemp = [
                {
                  Name: this.user.username,
                  type: "Facture",
                  Numero: this.numDoc,
                  Owner: this.user.proprietaire,
                  TotalPrice: this.setTotal,
                  TotalPriceTTC: this.setTotalTTC,
                  Client: this.comptoirSessionActive[0].Client[0].Name,
                  Date: new Date(),
                  IsProcessed: false,
                  Note: this.comptoirSessionActive[0].refClient,
                  Articles: this.actionArticle,
                  reglement: this.comptoirSessionActive[0].reglementTab,
                  pdfData: pdfinfo,
                  clientInfo: this.comptoirSessionActive[0].Client,
                  Transport: this.comptoirSessionActive[0].fraisdeportsave,
                },
              ]);
          } else {
            this.errorEncaissement = true;
          }
          break;
        default:
          break;
      }
    },
    async saveDocumentConsigne(today) {
      var pdfinfo = [];

      var prixRemise = 0.0;
      // var rest = this.setRestant;

      // var tabClient = [];

      var clientCode = "";
      var blType = "";

      if (this.setTotalConsigne >= 0) {
        blType = "F";
      } else {
        blType = "A";
      }
      if (this.comptoirSessionActive[0].dsvCheck == true) {
        clientCode = this.comptoirSessionActive[0].Client[0].numcomptedasilva;
        //   tabClient.push(this.plateform);
      } else {
        clientCode = this.plateform.dasilva.codeClient;
        //   tabClient.push(this.comptoirSessionActive[0].Client);
      }

      this.comptoirSessionActive[0].consigneData.forEach((element, index) => {
        if (element.PrixFournisseurType == "prixnet") {
          prixRemise = parseFloat(element.Prix_vente);
        } else {
          prixRemise =
            parseFloat(element.Prix_euro) -
            parseFloat(element.Prix_euro) *
              (this.comptoirSessionActive[0].consigneData[index].remise / 100);
        }

        // prixRemiseSupp =
        //   prixRemise - prixRemise * (this.remiseTabSCD[index] / 100);
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: this.comptoirSessionActive[0].consigneData[index].quantity,
          remise: this.comptoirSessionActive[0].consigneData[index].remise,
          commentaire: element.commentaire,
          remisegenerale: 0,
          // remiseSupp: this.remiseTabSCD[index],
          prixU: prixRemise,
          total:
            prixRemise *
            this.comptoirSessionActive[0].consigneData[index].quantity,
          totalSansRemise: this.setTotalConsigneSansRemise,
        };
      });

      this.tempTotalHT = this.setTotalConsigne;

      switch (this.comptoirSessionActive[0].documentType) {
        case "Bon de livraison":
          await axios
            .post(`${process.env.VUE_APP_API}/bdl/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1 + "D";
            });
          var dataBL = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: this.setTotalConsigne,
            TotalPriceTTC: this.setTotalConsigne + this.setTotalConsigne * 0.2,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.comptoirSessionActive[0].consigneData,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
            Fournisseur: "DA SILVA",
            textFile: {
              consignataire: this.plateform.dasilva.consignataire,
              typeBL: blType,
              codeClient: clientCode,
              numeroCmd: this.numDoc,
              commentaire: "",
            },
            dataTransport: this.dataTransport,
          };
          await axios
            .post(`${process.env.VUE_APP_API}/bdl`, dataBL)
            .then(async (res) => {
              await this.getBdlsOfTheDayByClient(dataBL.clientInfo[0]._id);
              this.setRepriseDoc();
              // if (this.VH_a02Tab.length > 0) {
              //   this.openRecapVH = true;
              //   this.detailRecapVH = true;
              //   this.cmdEncaissementVH = true;
              // } else {
              axios
                .post(`${process.env.VUE_APP_API}/bdl/getone`, {
                  uid: {
                    BLNumber: dataBL.Numero,
                    plateform: this.user.proprietaire,
                  },
                })
                .then((response) => {
                  if (this.setTotalConsigne >= 0) {
                    createlivraison(response.data, false, false);
                  } else {
                    createlivraison(response.data, false, true);
                  }
                  console.log("BON DE LIVRAISON : ", response.data);
                });
              // }
            });
          this.TotalPriceTemp = this.setTotalConsigne;
          (this.TabCreateLivraison = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: this.setTotalConsigne,
            TotalPriceTTC: this.setTotalConsigne + this.setTotalConsigne * 0.2,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.comptoirSessionActive[0].consigneData,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
            Fournisseur: "DA SILVA",
            dataTransport: this.dataTransport,
          }),
            (this.TabCmdBLVHtemp = [
              {
                Name: this.user.username,
                type: "BL",
                Numero: this.numDoc,
                Owner: this.user.proprietaire,
                TotalPrice: this.setTotalConsigne,
                TotalPriceTTC:
                  this.setTotalConsigne + this.setTotalConsigne * 0.2,
                Client: this.comptoirSessionActive[0].Client[0].Name,
                Date: new Date(),
                IsProcessed: false,
                Note: this.comptoirSessionActive[0].refClient,
                Articles: this.comptoirSessionActive[0].consigneData,
                reglement: this.comptoirSessionActive[0].reglementTab,
                pdfData: pdfinfo,
                clientInfo: this.comptoirSessionActive[0].Client,
                Transport: 0,
                Fournisseur: "DA SILVA",
              },
            ]);
          break;
        case "Bon de transport":
          await axios
            .post(`${process.env.VUE_APP_API}/bdt/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1 + "D";
            });
          await axios
            .post(`${process.env.VUE_APP_API}/bdt`, {
              Name: this.user.username,
              Owner: this.user.proprietaire,
              Date: new Date(),
              Numero: this.numDoc,
              TotalPrice: this.setTotalConsigne,
              TotalPriceTTC:
                this.setTotalConsigne + this.setTotalConsigne * 0.2,
              Client: this.comptoirSessionActive[0].Client[0].Name,
              IsProcessed: false,
              Note: this.comptoirSessionActive[0].refClient,
              Articles: this.comptoirSessionActive[0].consigneData,
              reglement: this.comptoirSessionActive[0].reglementTab,
              pdfData: pdfinfo,
              clientInfo: this.comptoirSessionActive[0].Client,
              Transport: 0,
              Fournisseur: "DA SILVA",
            })
            .then(() => {
              createtransport(
                {
                  Name: this.user.username,
                  Owner: this.user.proprietaire,
                  Date: new Date(),
                  Numero: this.numDoc,
                  TotalPrice: this.setTotalConsigne,
                  TotalPriceTTC:
                    this.setTotalConsigne + this.setTotalConsigne * 0.2,
                  Client: this.comptoirSessionActive[0].Client[0].Name,
                  IsProcessed: false,
                  Note: this.comptoirSessionActive[0].refClient,
                  Articles: this.comptoirSessionActive[0].consigneData,
                  reglement: this.comptoirSessionActive[0].reglementTab,
                  pdfData: pdfinfo,
                  clientInfo: this.comptoirSessionActive[0].Client,
                  Transport: 0,
                  Fournisseur: "DA SILVA",
                },
                false
              );
              // this.$router.push("/bdl");
            });
          break;
        default:
          break;
      }
    },
    async saveDocumentNonConsigne(today) {
      var pdfinfo = [];

      var prixRemise = 0.0;
      var total = 0.0;
      // var totaldebut = 0.0;
      // var transport = 0.0;

      this.comptoirSessionActive[0].consigneNonData.forEach(
        (element, index) => {
          if (element.PrixFournisseurType == "prixnet") {
            prixRemise = parseFloat(element.Prix_vente);
          } else {
            prixRemise =
              parseFloat(element.Prix_euro) -
              parseFloat(element.Prix_euro) *
                (this.comptoirSessionActive[0].consigneNonData[index].remise /
                  100);
          }

          total =
            total +
            prixRemise *
              this.comptoirSessionActive[0].consigneNonData[index].quantity;
          // transport = this.comptoirSessionActive[0].fraisdeportsave;
          // if (totaldebut >= parseFloat(this.franco)) {
          //   transport = "0";
          //   total = totaldebut + parseFloat(transport);
          // } else {
          //   transport = this.comptoirSessionActive[0].fraisdeportsave;
          //   total = totaldebut + parseFloat(transport);
          // }
          // prixRemiseSupp =
          //   prixRemise - prixRemise * (this.remiseTabSCD[index] / 100);
          pdfinfo[index] = {
            description: element.Description,
            ref: element.Ref_fournisseur,
            quantite:
              this.comptoirSessionActive[0].consigneNonData[index].quantity,
            remise: this.comptoirSessionActive[0].consigneNonData[index].remise,
            commentaire: element.commentaire,
            remisegenerale: 0,
            // remiseSupp: this.remiseTabSCD[index],
            prixU: prixRemise,
            total:
              prixRemise *
              this.comptoirSessionActive[0].consigneNonData[index].quantity,
            totalSansRemise: total,
          };
        }
      );

      this.tempTotalHT = total;

      switch (this.comptoirSessionActive[0].documentType) {
        case "Bon de livraison":
          await axios
            .post(`${process.env.VUE_APP_API}/bdl/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1;
            });
          var dataBL = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: total,
            TotalPriceTTC: total + total * 0.2,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.comptoirSessionActive[0].consigneNonData,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
            Fournisseur: "",
            textFile: {
              consignataire: "",
              typeBL: "",
              codeClient: "",
              numeroCmd: "",
              commentaire: "",
            },
            dataTransport: this.dataTransport,
          };
          await axios
            .post(`${process.env.VUE_APP_API}/bdl`, dataBL)
            .then(async () => {
              await this.getBdlsOfTheDayByClient(dataBL.clientInfo[0]._id);
              this.setRepriseDoc();
              // if (this.VH_a02Tab.length > 0) {
              //   this.openRecapVH = true;
              //   this.detailRecapVH = true;
              //   this.cmdEncaissementVH = true;
              // } else {
              axios
                .post(`${process.env.VUE_APP_API}/bdl/getone`, {
                  uid: {
                    BLNumber: dataBL.Numero,
                    plateform: this.user.proprietaire,
                  },
                })
                .then((response) => {
                  if (total >= 0) {
                    createlivraison(response.data, false, false);
                  } else {
                    createlivraison(response.data, false, true);
                  }
                  console.log("BON DE LIVRAISON : ", response.data);
                });
              // }
            });
          for (const element of this.actionArticle) {
            if (element.Prefixe_tarif) {
              await updateStock(
                element.Code_EAN,
                element.quantity,
                this.user.proprietaire,
                element.Ref_fournisseur,
                element.Prefixe_tarif,
                element.Code_marque,
                this.user.username,
                "Comptoir : Bon de livraison n°" +
                  this.numDoc +
                  " édité par : " +
                  this.user.username,
                this.comptoirSessionActive[0].Client[0].Name,
                this.numDoc,
                element.Qte_mini,
                element.Qte_maxi
              );
            }
          }
          this.TotalPriceTemp = total;
          (this.TabCreateLivraison = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: this.numDoc,
            TotalPrice: total,
            TotalPriceTTC: total + total * 0.2,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: this.comptoirSessionActive[0].consigneNonData,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfo,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
            Fournisseur: "",
            dataTransport: this.dataTransport,
          }),
            (this.TabCmdBLVHtemp = [
              {
                Name: this.user.username,
                type: "BL",
                Numero: this.numDoc,
                Owner: this.user.proprietaire,
                TotalPrice: total,
                TotalPriceTTC: total + total * 0.2,
                Client: this.comptoirSessionActive[0].Client[0].Name,
                Date: new Date(),
                IsProcessed: false,
                Note: this.comptoirSessionActive[0].refClient,
                Articles: this.comptoirSessionActive[0].consigneNonData,
                reglement: this.comptoirSessionActive[0].reglementTab,
                pdfData: pdfinfo,
                clientInfo: this.comptoirSessionActive[0].Client,
                Transport: 0,
                Fournisseur: "",
              },
            ]);
          break;
        case "Bon de transport":
          await axios
            .post(`${process.env.VUE_APP_API}/bdt/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              this.numDoc = response.data + 1 + "D";
            });
          await axios
            .post(`${process.env.VUE_APP_API}/bdt`, {
              Name: this.user.username,
              Owner: this.user.proprietaire,
              Date: new Date(),
              Numero: this.numDoc,
              TotalPrice: total,
              TotalPriceTTC: total + total * 0.2,
              Client: this.comptoirSessionActive[0].Client[0].Name,
              IsProcessed: false,
              Note: this.comptoirSessionActive[0].refClient,
              Articles: this.comptoirSessionActive[0].consigneNonData,
              reglement: this.comptoirSessionActive[0].reglementTab,
              pdfData: pdfinfo,
              clientInfo: this.comptoirSessionActive[0].Client,
              Transport: 0,
              Fournisseur: "",
            })
            .then(() => {
              createtransport(
                {
                  Name: this.user.username,
                  Owner: this.user.proprietaire,
                  Date: new Date(),
                  Numero: this.numDoc,
                  TotalPrice: total,
                  TotalPriceTTC: total + total * 0.2,
                  Client: this.comptoirSessionActive[0].Client[0].Name,
                  IsProcessed: false,
                  Note: this.comptoirSessionActive[0].refClient,
                  Articles: this.comptoirSessionActive[0].consigneNonData,
                  reglement: this.comptoirSessionActive[0].reglementTab,
                  pdfData: pdfinfo,
                  clientInfo: this.comptoirSessionActive[0].Client,
                  Transport: 0,
                  Fournisseur: "",
                },
                false
              );
            });
          break;
        default:
          break;
      }
    },
    async saveDocument(dataTransport) {
      this.loadingValider = true;
      if (
        !this.checkInputsRaisonRetour ||
        this.checkInputsRaisonRetour == null
      ) {
        this.clearclientfocus();
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        var hour = today.getHours();
        var seconds = today.getSeconds();
        var minutes = today.getMinutes();
        today =
          dd +
          "/" +
          mm +
          "/" +
          yyyy +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          seconds;

        // var prixRemiseSupp = 0.0;
        if (this.comptoirSessionActive[0].refClient == "") {
          this.toast.error("Veuillez saisir une référence client !", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else {
          this.comptoirSessionActive[0].reglementTab.push({
            icon: "money",
            placeholder: "Reste dû",
            model: parseFloat(this.setRestant).toFixed(2),
            date: "",
            disabled: false,
            user: this.user.username,
          });
          this.savecomptoirsession({
            id: 4,
            clientFocus: this.comptoirSessionActive[0].Client,
            reglementTab: this.comptoirSessionActive[0].reglementTab,
          });

          // this.comptoirSessionActive[0].reglementTab.push({
          //   icon: "money",
          //   placeholder: "Rendu",
          //   model: parseFloat(this.setRendu).toFixed(2),
          //   date: "",
          // });
          if (this.comptoirSessionActive[0].documentType == "Retour") {
            if (this.setTotal <= 0.0) {
              await this.sendRetour();
            } else {
              this.toast.error(
                "Montant de retour ne peut pas être supérieur à zéro  !",
                {
                  position: "bottom-right",
                  timeout: 2000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 1,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                }
              );
              return;
            }
          } else if (
            this.comptoirSessionActive[0].documentType ==
            "Bon de livraison Avoir"
          ) {
            if (this.setTotal <= 0.0) {
              await this.sendRetourBDL();
            } else {
              this.toast.error(
                "Montant de retour ne peut pas être supérieur à zéro  !",
                {
                  position: "bottom-right",
                  timeout: 2000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 1,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                }
              );
              return;
            }
          } else {
            if (
              this.plateform.structure_depot == true &&
              this.comptoirSessionActive[0].dsvCheck == true
            ) {
              if (this.comptoirSessionActive[0].consigneData.length > 0) {
                await this.saveDocumentConsigne(today);
              }
              if (this.comptoirSessionActive[0].consigneNonData.length > 0) {
                await this.saveDocumentNonConsigne(today);
              }
            } else {
              if (
                this.plateform.structure_depot == true &&
                this.comptoirSessionActive[0].consigneData.length > 0
              ) {
                await this.saveDocumentConsigne(today);
              }
              await this.saveDocumentArticle(today);
            }

            await this.manageTransport(this.tempTotalHT);
            await this.getPanierTransportClient();

            this.toast.success("Document sauvegardé !", {
              position: "bottom-right",
              timeout: 1000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 1,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }

          if (
            this.comptoirSessionActive[0].documentType != "Devis" &&
            this.comptoirSessionActive[0].documentType != "Retour"
          ) {
            this.saveFilialeCmd();
          }
          // if (
          //   this.comptoirSessionActive[0].documentType == "Bon de livraison" &&
          //   this.comptoirSessionActive[0].Client[0].professionnel == true
          // ) {
          //   await this.updatePanierTransport(this.numDoc);
          // }
          this.articleSearch = "";
          this.articleSearchToReturn = "";
          this.clearvehicule();
          this.removecomptoirsession(this.comptoirSessionActive[0]);
          this.errorEncaissement = false;
          this.openSave = false;
          this.showSaisieChaud = false;
          this.cleardataconsigne();
          this.cleardatanonconsigne();
          this.clearactionArticle();
          if (this.comptoirSession.length > 1) {
            this.savecomptoirsession({
              id: 7,
              clientFocus: this.comptoirSessionActive[0].Client,
              refClient: this.comptoirSessionActive[0].refClient,
              consigneData: this.comptoirSessionActive[0].consigneData,
              consigneNonData: this.comptoirSessionActive[0].consigneNonData,
              dsvCheck: this.comptoirSessionActive[0].dsvCheck,
            });
          }
          this.clearVHloadingstate();
        }
      } else {
        this.toast.error("Remplissez les champs 1 !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      this.loadingValider = false;
    },
    async manageTransport(totalHT) {
      const session = this.comptoirSessionActive[0];
      const documentType = session.documentType;
      const isBonDeCommande = documentType === "Bon de commande";
      const isBonDeLivraison = documentType === "Bon de livraison";

      if (isBonDeCommande || isBonDeLivraison) {
        const transportData = {
          type: documentType,
          created_here: "comptoir distrib",
          BCnumber: isBonDeCommande ? (this.numDoc).toString() : "",
          BCdate: isBonDeCommande ? new Date() : "",
          BCuser: isBonDeCommande ? this.user.username : "",
          BCtotalPrice: isBonDeCommande ? totalHT : 0,
          BLnumber: isBonDeLivraison ? (this.numDoc).toString() : "",
          BLdate: isBonDeLivraison ? new Date() : "",
          BLuser: isBonDeLivraison ? this.user.username : "",
          BLtotalPrice: isBonDeLivraison ? totalHT : 0,
        };

        await manageTransportCart(
          this.user.proprietaire,
          session.Client[0]._id,
          this.user.username,
          transportData
        );
      }
    },
    async getBdlsOfTheDayByClient(clientId) {
      await axios
        .post(`${process.env.VUE_APP_API}/bdl/getBdlsOfTheDay`, {
          plateform: this.user.proprietaire,
          clientId: clientId,
          returnData: true,
          user: this.user.username,
        })
        .then((response) => {
          this.dataBdl = response.data;
          console.log(response);
        });
    },
    async saveFilialeCmd() {
      for (const [indexArt, art] of this.actionArticle.entries()) {
        for (const [indexST, stArt] of art.stockFiliale.entries()) {
          if (stArt.transfertState) {
            let ArticlesTab = [];
            let pdfinfo = [];
            const filialeInformation = await axios.get(
              `${process.env.VUE_APP_API}/plateform/getone/${stArt.plateform}`
            );
            if (filialeInformation.data.length > 0) {
              let art_data = { ...art };
              art_data.quantity = stArt.stock;
              art_data.PrixFournisseurType = "prixnet";
              art_data.Prix_vente = art_data.Prix_achat * stArt.coef.value;
              art_data.prix_net = art_data.Prix_achat * stArt.coef.value;
              art_data.quantity_received = 0;
              art_data.quantity_asked = stArt.stock;

              //TODO ajouter au ArticlesTab
              ArticlesTab.push(art_data);
              //TODO ajouter au pdfinfo
              pdfinfo.push({
                description: art_data.Description,
                ref: art_data.Ref_fournisseur,
                quantite: art_data.quantity,
                remise: 0,
                commentaire: art_data.commentaire,
                remisegenerale: 0,
                prixU: art_data.Prix_vente,
                total: art_data.Prix_vente * art_data.quantity,
                totalSansRemise: art_data.Prix_vente * art_data.quantity,
              });
              // create bon de commande
              // create commande
              var today = new Date();
              var dd = String(today.getDate()).padStart(2, "0");
              var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
              var yyyy = today.getFullYear();
              var hour = today.getHours();
              var seconds = today.getSeconds();
              var minutes = today.getMinutes();
              let numDoc = 0;
              today =
                dd +
                "/" +
                mm +
                "/" +
                yyyy +
                " " +
                hour +
                ":" +
                minutes +
                ":" +
                seconds;
              await axios
                .post(`${process.env.VUE_APP_API}/commandes/getlast`, {
                  uid: filialeInformation.data[0].Name,
                })
                .then((response) => {
                  numDoc = response.data + 1;
                });
              var dataCommande = {
                Name: this.user.username,
                Owner: filialeInformation.data[0].Name,
                Date: new Date(),
                Numero: numDoc,
                TotalPrice: art_data.Prix_vente,
                TotalPriceTTC: art_data.Prix_vente + art_data.Prix_vente * 0.2,
                Client: this.user.proprietaire,
                IsProcessed: false,
                Note: "Commande inter depot",
                Articles: ArticlesTab,
                reglement: [],
                pdfData: pdfinfo,
                clientInfo: filialeInformation.data,
                Transport: 0,
              };
              await axios.post(
                `${process.env.VUE_APP_API}/commandes`,
                dataCommande
              );
              await axios.post(`${process.env.VUE_APP_API}/commandesClient`, {
                Type: "Commande",
                cmdPro: filialeInformation.data[0].professionnel,
                Owner: this.user.proprietaire,
                Name: this.user.username,
                plateform: filialeInformation.data[0].Name,
                Date: new Date(),
                Numero: 123,
                TotalPrice: art_data.Prix_vente,
                TotalPriceTTC: art_data.Prix_vente + art_data.Prix_vente * 0.2,
                Client: this.user.proprietaire,
                IsProcessed: false,
                Note: "Commande inter depot",
                Commentaire: "Commande Automatique inter depot",
                clientInfo: filialeInformation.data,
                BCNumber: numDoc,
                Articles: ArticlesTab,
                status: "Commande Passée",
                preparedBy: "personne",
                refCommande: "kjgkjyg",
                PourcentageStatus: "10",
                cmdstate: [true, false, false, false, true],
                Transport: 0,
              });
              for (const element of this.actionArticle) {
                if (element.Prefixe_tarif) {
                  decrementStockIncrementReserved(
                    element.Code_EAN,
                    element.quantity,
                    element.quantity,
                    this.user.proprietaire,
                    element.Ref_fournisseur,
                    element.Prefixe_tarif,
                    element.Code_marque,
                    this.user.username,
                    "Comptoir : Bon de commande n°" +
                      numDoc +
                      " édité par : " +
                      this.user.username +
                      ". " +
                      element.quantity +
                      " réservé.",
                    this.comptoirSessionActive[0].Client[0].Name,
                    numDoc
                  );
                }
              }
              // FIIINNNNN
              //TODO creation de la reception de stock
              await createReception(
                this.user.username,
                this.user.proprietaire,
                filialeInformation.data[0].Name,
                ArticlesTab,
                "Commande Inter Depot",
                "Stock",
                [dataCommande],
                []
              );
            }
          }
        }
      }
    },
    async sendRetour() {
      if (!this.checkInputsRaisonRetour) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
        var yyyy = today.getFullYear();
        var hour = today.getHours();
        var seconds = today.getSeconds();
        var minutes = today.getMinutes();
        today =
          dd +
          "/" +
          mm +
          "/" +
          yyyy +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          seconds;

        var tabConsigne = [];
        var tabRetour = [];

        await this.actionArticle.forEach((element, index) => {
          if (
            element.Ref_fournisseur.startsWith("C :") &&
            !this.actionArticle[index - 1]
          ) {
            tabConsigne.push(element);
          } else if (
            element.Ref_fournisseur.startsWith("C :") &&
            this.actionArticle[index - 1] &&
            !this.actionArticle[index - 1].Montant_consigne
          ) {
            tabConsigne.push(element);
          } else if (
            element.Ref_fournisseur.startsWith("C :") &&
            this.actionArticle[index - 1] &&
            this.actionArticle[index - 1].Montant_consigne &&
            this.actionArticle[index - 1].Ref_fournisseur !=
              element.Ref_fournisseur.substr(4)
          ) {
            console.log(element.Ref_fournisseur.substr(4));
            tabConsigne.push(element);
          } else {
            tabRetour.push(element);
          }
        });

        var pdfinfoRetour = [];
        var prixRemiseRetour = 0.0;
        var totalRetour = 0.0;
        var totalRemiseRetour = 0.0;

        // Retour pièce
        if (tabRetour.length > 0) {
          tabRetour.forEach((element, index) => {
            if (element.PrixFournisseurType == "prixnet") {
              prixRemiseRetour = parseFloat(element.Prix_vente);
            } else {
              prixRemiseRetour =
                parseFloat(element.Prix_euro) -
                parseFloat(element.Prix_euro) *
                  (this.actionArticle[index].remise / 100);
            }
            pdfinfoRetour[index] = {
              description: element.Description,
              ref: element.Ref_fournisseur,
              quantite: this.actionArticle[index].quantity,
              remise: this.actionArticle[index].remise,
              commentaire:
                element.typeDoc +
                " n°" +
                element.numBL +
                " du " +
                this.getFRDate(element.dateBL),
              prixU: prixRemiseRetour,
              total: prixRemiseRetour * this.actionArticle[index].quantity,
              totalSansRemise: this.setTotalSansRemise,
            };

            // total tabRetour
            if (element.PrixFournisseurType == "prixnet") {
              totalRemiseRetour = parseFloat(
                parseFloat(element.Prix_vente * element.quantity).toFixed(2)
              );
            } else {
              totalRemiseRetour = parseFloat(
                parseFloat(
                  (element.Prix_euro -
                    element.Prix_euro * (element.remise / 100)) *
                    element.quantity
                ).toFixed(2)
              );
            }
            totalRetour = totalRetour + totalRemiseRetour;
          });

          await axios.post(`${process.env.VUE_APP_API}/retour`, {
            plateform: this.user.proprietaire,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            Name: this.user.username,
            Date: new Date(),
            clientInfo: this.comptoirSessionActive[0].Client,
            Articles: tabRetour,
            requestedArticles: tabRetour,
            requestedPdfData: pdfinfoRetour,
            pdfData: pdfinfoRetour,
            reglement: [],
            Note: this.comptoirSessionActive[0].refClient,
            status: "Retour demandé",
            Archived: false,
            Totalretour: totalRetour,
            requestedTotalretour: totalRetour,
            PourcentageStatus: "10",
            retourstate: [true, false, false, false],
          });
        }

        var pdfinfoConsigne = [];
        var prixRemiseConsigne = 0.0;
        var totalConsigne = 0.0;
        var totalRemiseConsigne = 0.0;

        // Consigne
        if (tabConsigne.length > 0) {
          tabConsigne.forEach((element, index) => {
            if (element.PrixFournisseurType == "prixnet") {
              prixRemiseConsigne = parseFloat(element.Prix_vente);
            } else {
              prixRemiseConsigne =
                parseFloat(element.Prix_euro) -
                parseFloat(element.Prix_euro) *
                  (tabConsigne[index].remise / 100);
            }
            pdfinfoConsigne[index] = {
              description: element.Description,
              ref: element.Ref_fournisseur,
              quantite: tabConsigne[index].quantity,
              remise: tabConsigne[index].remise,
              commentaire:
                element.typeDoc +
                " n°" +
                element.numBL +
                " du " +
                this.getFRDate(element.dateBL),
              prixU: prixRemiseConsigne,
              total: prixRemiseConsigne * tabConsigne[index].quantity,
              totalSansRemise: this.setTotalSansRemise,
            };

            // total tabConsgine
            if (element.PrixFournisseurType == "prixnet") {
              totalRemiseConsigne = parseFloat(
                parseFloat(element.Prix_vente * element.quantity).toFixed(2)
              );
            } else {
              totalRemiseConsigne = parseFloat(
                parseFloat(
                  (element.Prix_euro -
                    element.Prix_euro * (element.remise / 100)) *
                    element.quantity
                ).toFixed(2)
              );
            }
            totalConsigne = totalConsigne + totalRemiseConsigne;
          });

          await axios.post(`${process.env.VUE_APP_API}/retourConsigne`, {
            plateform: this.user.proprietaire,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            Name: this.user.username,
            Date: new Date(),
            clientInfo: this.comptoirSessionActive[0].Client,
            Articles: tabConsigne,
            requestedArticles: tabConsigne,
            pdfData: pdfinfoConsigne,
            requestedPdfData: pdfinfoConsigne,
            reglement: [],
            Note: this.comptoirSessionActive[0].refClient,
            status: "Retour demandé",
            Archived: false,
            Totalretour: totalConsigne,
            requestedTotalretour: totalConsigne,
            PourcentageStatus: "10",
            retourstate: [true, false, false, false],
          });
        }

        await this.ajustQuantityReturned(this.actionArticle);
        this.$router.push("/Retour");
      } else {
        this.toast.error("Remplissez les champs !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async sendRetourBDL() {
      if (!this.checkInputsRaisonRetour) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
        var yyyy = today.getFullYear();
        var hour = today.getHours();
        var seconds = today.getSeconds();
        var minutes = today.getMinutes();
        today =
          dd +
          "/" +
          mm +
          "/" +
          yyyy +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          seconds;

        var tabConsigne = [];
        var tabRetour = [];

        await this.actionArticle.forEach((element, index) => {
          if (
            element.Ref_fournisseur.startsWith("C :") &&
            !this.actionArticle[index - 1]
          ) {
            tabConsigne.push(element);
          } else if (
            element.Ref_fournisseur.startsWith("C :") &&
            this.actionArticle[index - 1] &&
            this.actionArticle[index - 1].Code_marque != "DA SILVA"
          ) {
            tabConsigne.push(element);
          } else if (
            element.Ref_fournisseur.startsWith("C :") &&
            this.actionArticle[index - 1] &&
            this.actionArticle[index - 1].Code_marque == "DA SILVA" &&
            this.actionArticle[index - 1].Ref_fournisseur !=
              element.Ref_fournisseur.substr(4)
          ) {
            console.log(element.Ref_fournisseur.substr(4));
            tabConsigne.push(element);
          } else {
            tabRetour.push(element);
          }
        });

        var pdfinfoRetour = [];
        var prixRemiseRetour = 0.0;
        var totalRetour = 0.0;
        var totalRemiseRetour = 0.0;

        // Retour pièce
        if (tabRetour.length > 0) {
          tabRetour.forEach((element, index) => {
            if (element.PrixFournisseurType == "prixnet") {
              prixRemiseRetour = parseFloat(element.Prix_vente);
            } else {
              prixRemiseRetour =
                parseFloat(element.Prix_euro) -
                parseFloat(element.Prix_euro) *
                  (this.actionArticle[index].remise / 100);
            }
            pdfinfoRetour[index] = {
              description: element.Description,
              ref: element.Ref_fournisseur,
              quantite: this.actionArticle[index].quantity,
              remise: this.actionArticle[index].remise,
              commentaire:
                element.typeDoc +
                " n°" +
                element.numBL +
                " du " +
                this.getFRDate(element.dateBL),
              prixU: prixRemiseRetour,
              total: prixRemiseRetour * this.actionArticle[index].quantity,
              totalSansRemise: this.setTotalSansRemise,
            };

            // total tabRetour
            if (element.PrixFournisseurType == "prixnet") {
              totalRemiseRetour = parseFloat(
                parseFloat(element.Prix_vente * element.quantity).toFixed(2)
              );
            } else {
              totalRemiseRetour = parseFloat(
                parseFloat(
                  (element.Prix_euro -
                    element.Prix_euro * (element.remise / 100)) *
                    element.quantity
                ).toFixed(2)
              );
            }
            totalRetour = totalRetour + totalRemiseRetour;
          });

          const numDoc = await axios.post(
            `${process.env.VUE_APP_API}/bdl/getlast`,
            {
              uid: this.user.proprietaire,
            }
          );
          let dataBL = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: numDoc.data + 1,
            TotalPrice: totalRetour,
            TotalPriceTTC: totalRetour + totalRetour * 0.2,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: tabRetour,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfoRetour,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
            Fournisseur: "",
            textFile: {
              consignataire: "",
              typeBL: "",
              codeClient: "",
              numeroCmd: "",
              commentaire: "",
            },
            dataTransport: this.dataTransport,
          };
          await axios.post(`${process.env.VUE_APP_API}/bdl`, dataBL);
          createlivraison(dataBL, false, true);
        }

        var pdfinfoConsigne = [];
        var prixRemiseConsigne = 0.0;
        var totalConsigne = 0.0;
        var totalRemiseConsigne = 0.0;

        // Consigne
        if (tabConsigne.length > 0) {
          tabConsigne.forEach((element, index) => {
            if (element.PrixFournisseurType == "prixnet") {
              prixRemiseConsigne = parseFloat(element.Prix_vente);
            } else {
              prixRemiseConsigne =
                parseFloat(element.Prix_euro) -
                parseFloat(element.Prix_euro) *
                  (tabConsigne[index].remise / 100);
            }
            pdfinfoConsigne[index] = {
              description: element.Description,
              ref: element.Ref_fournisseur,
              quantite: tabConsigne[index].quantity,
              remise: tabConsigne[index].remise,
              commentaire:
                element.typeDoc +
                " n°" +
                element.numBL +
                " du " +
                this.getFRDate(element.dateBL),
              prixU: prixRemiseConsigne,
              total: prixRemiseConsigne * tabConsigne[index].quantity,
              totalSansRemise: this.setTotalSansRemise,
            };

            // total tabConsgine
            if (element.PrixFournisseurType == "prixnet") {
              totalRemiseConsigne = parseFloat(
                parseFloat(element.Prix_vente * element.quantity).toFixed(2)
              );
            } else {
              totalRemiseConsigne = parseFloat(
                parseFloat(
                  (element.Prix_euro -
                    element.Prix_euro * (element.remise / 100)) *
                    element.quantity
                ).toFixed(2)
              );
            }
            totalConsigne = totalConsigne + totalRemiseConsigne;
          });

          const numDoc = await axios.post(
            `${process.env.VUE_APP_API}/bdl/getlast`,
            {
              uid: this.user.proprietaire,
            }
          );
          let dataBL = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            Date: new Date(),
            Numero: numDoc.data + 1,
            TotalPrice: totalRetour,
            TotalPriceTTC: totalRetour + totalRetour * 0.2,
            Client: this.comptoirSessionActive[0].Client[0].Name,
            IsProcessed: false,
            Note: this.comptoirSessionActive[0].refClient,
            Articles: tabConsigne,
            reglement: this.comptoirSessionActive[0].reglementTab,
            pdfData: pdfinfoConsigne,
            clientInfo: this.comptoirSessionActive[0].Client,
            Transport: 0,
            Fournisseur: "",
            textFile: {
              consignataire: "",
              typeBL: "",
              codeClient: "",
              numeroCmd: "",
              commentaire: "",
            },
            dataTransport: this.dataTransport,
          };
          await axios.post(`${process.env.VUE_APP_API}/bdl`, dataBL);
          createlivraison(dataBL, false, true);
        }

        await this.ajustQuantityReturned(this.actionArticle);
        this.$router.push("/bdl");
      } else {
        this.toast.error("Remplissez les champs !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    async ajustQuantityReturned(article) {
      for (const [indexA, element] of article.entries()) {
        // if (!element.Ref_fournisseur.startsWith("Retour sur")) {
        console.log(-element.quantity);
        await axios
          .put(`${process.env.VUE_APP_API}/bdl/return/${element.idBL}`, {
            _id: element._id,
            Ref_fournisseur: element.Ref_fournisseur,
            quantityReturned: -element.quantity,
          })
          .then((response) => {
            console.log("qty ajusté", response);
          })
          .catch((error) => {
            console.log(error);
          });
        // }
      }
    },
    async setRepriseDoc() {
      if (this.comptoirSessionActive[0].repriseTypeDoc != null) {
        const doc = this.comptoirSessionActive[0].repriseTypeDoc;
        await axios.put(
          `${process.env.VUE_APP_API}/${doc}/${this.comptoirSessionActive[0].repriseIdDoc}`,
          {
            Archived: true,
          }
        );
      }
    },
    setRefclient() {
      this.updaterefclient();
    },
    getHistorique() {
      axios
        .post(`${process.env.VUE_APP_API}/comptoir/getone`, {
          uid: this.comptoirSessionActive[0].Client[0].Name,
        })
        .then((response) => {
          this.historique = response.data;
        });
    },
    setHistoriqueFile(historiquedata) {
      this.updateactionArticle(historiquedata);
    },
    setStockArticle(ind, qty) {
      const dataArt = { indexArt: ind, quantity: qty };
      this.updatestockactionArticle(dataArt);
    },
    setRemiseArticle(data, ind, rem, qty, prix) {
      const PrixFinal = parseFloat(prix) - parseFloat(prix) * (rem / 100);
      const prixTotal = PrixFinal * qty;
      const dataArt = {
        indexArt: ind,
        quantity: qty,
        remise: rem,
        prixtotal: prixTotal,
        prixfinal: PrixFinal,
      };
      this.updateremiseactionArticle(dataArt);
      const dataArtConsigne = {
        Articles: data,
        quantity: qty,
        remise: rem,
        prixtotal: prixTotal,
        prixfinal: PrixFinal,
      };
      this.updateremisedataconsigne(dataArtConsigne);
    },
    filterHistoriqueFile(value, state) {
      if (state == true) {
        this.tabFilterHistorique.push(value);
        this.fetchHistoriqueFiltred();
      } else {
        var indexD = this.tabFilterHistorique.indexOf(value);

        if (indexD > -1) {
          this.tabFilterHistorique.splice(indexD, 1);
        }
        this.fetchHistoriqueFiltred();
      }
    },
    fetchHistoriqueFiltred() {
      axios
        .post(`${process.env.VUE_APP_API}/comptoir/getType`, {
          uid: this.comptoirSessionActive[0].Client[0].Name,
          Type: this.tabFilterHistorique,
        })
        .then((response) => {
          this.historique = response.data;
        });
    },
    fetchHistoriqueRetour(art) {
      this.tabRetourArticles = [];
      this.tabRetour = [];
      axios
        .post(`${process.env.VUE_APP_API}/retour/getByClientInPlateform`, {
          uid: {
            client: this.comptoirSessionActive[0].Client[0].Name,
            owner: this.user.proprietaire,
            article: art.Ref_fournisseur,
          },
        })
        .then((response) => {
          console.log(response.data);
          for (const [indexR, R] of response.data.entries()) {
            if (R.TotalPrice > 0 && R.IsProcessed == false) {
              this.tabRetour.push(R);
            }
          }
          console.log(this.tabRetour);
          // this.tabRetour = response.data;
          this.tabRetourArticles = art;
        });
    },
    fetchHistoriqueDone() {
      if (this.retourSelected._id) {
        axios
          .put(`${process.env.VUE_APP_API}/retour/${this.retourSelected._id}`, {
            IsProcessed: true,
          })
          .then((response) => {
            console.log("Retour modifié avec succès :", response.data);
          })
          .catch((error) => {
            console.error("Erreur lors de la modification du retour :", error);
          });
      }
    },
    setReglement(method) {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");
      switch (method) {
        case "es":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cb":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cc":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "vi":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "lc":
          this.comptoirSessionActive[0].reglementTab.push({
            img: require("@/assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: parseFloat(this.setRestant).toFixed(2),
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        default:
          this.errorPaymentMethod = true;
          break;
      }
      console.log(this.comptoirSessionActive[0].reglementTab);
      this.savecomptoirsession({
        id: 4,
        clientFocus: this.comptoirSessionActive[0].Client,
        reglementTab: this.comptoirSessionActive[0].reglementTab,
      });
    },
    removeReglement(index) {
      this.comptoirSessionActive[0].reglementTab.splice(index, 1);
      this.savecomptoirsession({
        id: 4,
        clientFocus: this.comptoirSessionActive[0].Client,
        reglementTab: this.comptoirSessionActive[0].reglementTab,
      });
    },
    getFournisseurDispo() {
      axios
        .get(`${process.env.VUE_APP_API}/matriceFourniSet/getall`)
        .then((response) => {
          this.fournisseurs = response.data;
        });
    },
    initFournisseursModel() {
      this.actionArticle.forEach((element) => {
        this.fournisseursModel.push(element.FournisseurSelected);
      });
    },
    // Marge en €
    getMargeParArticle(prix, prix_achat, remise, type, prix_vente, ref) {
      let pv = 0;
      let marge = 0;

      if (!ref.startsWith("C :")) {
        if (type === "remise") {
          pv = (prix - prix * (remise / 100)) * 100;
          marge = pv - prix_achat * 100;
        } else if (type === "prixnet") {
          pv = prix_vente * 100;
          marge = pv - prix_achat * 100;
        }

        return Number((marge / 100).toFixed(2));
      } else {
        return 0;
      }
    },
    // Marge en %
    getMarge(prix, prix_achat, remise, type, prix_vente, ref) {
      if (!ref.startsWith("C :")) {
        let pv = 0;
        let pa = prix_achat * 100;
        let marge = 0;

        if (type === "remise") {
          pv = (prix - prix * (remise / 100)) * 100;
          marge = ((pv - pa) / pa) * 100;
        } else if (type === "prixnet") {
          pv = prix_vente * 100;
          marge = ((pv - pa) / pa) * 100;
        }

        return Number(marge.toFixed(2));
      } else {
        return 0;
      }
    },
    //TODO supprimer les 3 fonctions carcat
    async initCarCatLogin() {
      await this.$router.isReady();
      if (this.$route.query.code) {
        // axios
        //   .get(
        //     `${process.env.VUE_APP_API}/carcat/getToken/${this.$route.query.code}`
        //   )
        //   .then((res) => {

        //   });
        await axios
          .post(
            `https://lkq-carsys-fr-staging.auth.eu-central-1.amazoncognito.com/oauth2/token`,
            `grant_type=authorization_code&code=${this.$route.query.code}&client_id=2s9qagpalko23h8fvnt2u66df5&redirect_uri=https://distrib.vekteur.fr`,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Basic ${btoa(
                  "2s9qagpalko23h8fvnt2u66df5:1iq3fpl78967eiehogrhtpgp0d79n97cn3d2t27no9721fu7gcsv"
                )}`,
              },
            }
          )
          .then((result) => {
            this.storecarcattoken(result.data);
          })
          .catch((error) => {
            this.clearcarcattoken();
          });

        this.$router.push("/carcat");
      }
    },
    openCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/v2/ticket`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier:
              "referenceVekteur" + Math.floor(Math.random() * 1000000000),
            // OrderCreationBehaviour: "force_new_order",
            // ShowNavigation: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          this.setcarcatlinks({
            link: result.data.Data.TicketURL,
            ref: result.data.Data.ReferenceIdentifier,
          });
          // this.openCarCatModal = true;
          this.$router.push("/carcat");
        })
        .catch((error) => {
          this.clearcarcattoken();
        });
    },
    closeCarCat() {
      axios
        .post(
          `https://lkq-europe-prod.apigee.net/gms-api-public-iframe-routes-fr/iframe/retrieve-orders`,
          {
            IDToken: this.carcatToken.id_token,
            ReferenceIdentifier: this.carCatReference,
            PerPage: 1000,
            Page: 0,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.carcatToken.access_token}`,
              "X-api-key": "IbW4A7bqa447M2c7WhFwagVtbMjL9DLd",
            },
          }
        )
        .then((result) => {
          result.data.Data.Workorders[0].Lines.forEach((element) => {
            this.addpaniercarcat(element.LineCode);
          });
        })
        .catch((error) => {
          this.clearcarcattoken();
        });
    },
    openPromo(reference, marque, index) {
      this.promoShow = true;
      this.indexArticlePromo = index;
      axios
        .post(`${process.env.VUE_APP_API}/promo/getone`, {
          promoinfo: {
            plateformID: this.user.proprietaire,
            Ref_fournisseur: reference,
            Code_marque: marque,
          },
        })
        .then((res) => {
          this.foundPromo = res.data;
        });
    },
    checkPrixQuantitatif(index) {
      if (
        this.actionArticle[this.indexArticlePromo].prix_quantitatif &&
        this.actionArticle[this.indexArticlePromo].prix_quantitatif == true
      ) {
        if (
          this.actionArticle[this.indexArticlePromo].quantity <
          this.actionArticle[this.indexArticlePromo].quantity_remise
        ) {
          this.removeprixquantitatif({ index: index });
        }
      }
    },
    setPrixQuantitatif(quantite, prix) {
      if (
        this.actionArticle[this.indexArticlePromo].prix_quantitatif &&
        this.actionArticle[this.indexArticlePromo].prix_quantitatif == true
      ) {
        this.removeprixquantitatif({ index: this.indexArticlePromo });
      }
      this.setquantityarticle({
        index: this.indexArticlePromo,
        quantity: quantite,
      });
      this.setremisearticle({
        index: this.indexArticlePromo,
        remise: 0,
      });
      this.setprixquantitatif({
        index: this.indexArticlePromo,
        quantite: quantite,
        prix: prix,
        remise: 0,
      });
    },
    setprixnet(quantite, prix) {
      if (
        this.actionArticle[this.indexArticlePromo].prix_quantitatif &&
        this.actionArticle[this.indexArticlePromo].prix_quantitatif == true
      ) {
        this.removeprixquantitatif({ index: this.indexArticlePromo });
      }

      this.setquantityarticle({
        index: this.indexArticlePromo,
        quantity: quantite,
      });
      this.setremisearticle({
        index: this.indexArticlePromo,
        remise: 0,
      });
      this.setprixquantitatif({
        index: this.indexArticlePromo,
        quantite: quantite,
        prix: prix,
      });
    },
    setRemisePromo(remise) {
      if (
        this.actionArticle[this.indexArticlePromo].prix_quantitatif &&
        this.actionArticle[this.indexArticlePromo].prix_quantitatif == true
      ) {
        this.removeprixquantitatif({ index: this.indexArticlePromo });
      }
      // this.remiseTab[this.indexArticlePromo] = remise;
      this.setremisearticle({
        index: this.indexArticlePromo,
        remise: remise,
      });
    },
    getDispos() {
      this.actionArticle.forEach((element, index) => {
        if (element.Prefixe_tarif == "Vhi") {
          this.getDispo(
            element.Ref_fournisseur,
            element.Code_marque,
            this.actionArticle[index].quantity,
            element.Prefixe_tarif,
            index
          );
          this.showDispo[index] = true;
        }
      });
    },
    async getDispoCmdVH(ref, brand, quantity, index) {
      if (this.Prefixe_tarif == "Vhi" && !this.stock) {
        this.indexDelaisLivraison = index;
        this.tabDispoUAN.splice(index, 1);
        axios
          .post(`${process.env.VUE_APP_API}/catalogue/getDispoFournisseur`, {
            Ref_fournisseur: ref,
            Code_marque: brand,
            logCode: this.plateform.grossierID,
            Quantity: quantity,
            login: this.plateform.grossierID,
            plateform: this.user.proprietaire,
          })
          .then((response) => {
            console.log(response.data);
            this.showDispoCmdVH[index] = true;
            this.tabDispoUAN[index] = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async getDispo(ref, brand, quantity, prefixe, index) {
      if (prefixe == "Vhi" && quantity > 0 && !ref.startsWith("Retour sur")) {
        this.indexDelaisLivraison = index;
        axios
          .post(`${process.env.VUE_APP_API}/catalogue/getDispoFournisseur`, {
            Ref_fournisseur: ref,
            Code_marque: brand,
            logCode: this.plateform.grossierID,
            Quantity: quantity,
            login: this.plateform.grossierID,
            plateform: this.user.proprietaire,
          })
          .then((response) => {
            console.log("dispo : ", response.data);
            this.showDispoCmdVH[index] = true;
            this.tabDispoUAN[index] = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    openModalRefSimil(ref) {
      this.refSimil = ref;
      this.openRefSimil = true;
    },
    getRefSimilaire(ref) {
      this.refSimil = ref;
      this.openRefArticle = true;
    },
    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },
    autoComplete(ref) {
      if (this.articleSearch != "") {
        axios
          .get(`${process.env.VUE_APP_API}/article/autocomplete/${ref}`)
          .then((response) => {
            this.showAutoComplete = true;
            this.autoCompleteTab = response.data;
          });
      } else {
        this.showAutoComplete = false;
      }
    },
    setAutoComplete(ref) {
      this.articleSearch = ref;
      this.showAutoComplete = false;
      this.getOneArticle();
      this.showArticleList = true;
    },
    createComptoirSession(data) {
      if (this.comptoirSessionActive[0].Client[0].Name) {
        this.createcomptoirsession(data);
      } else {
        this.toast.warning("Une session est déjà active !", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    getFournisseursDepot() {
      axios
        .post(`${process.env.VUE_APP_API}/panierFournisseur/getactivated`, {
          plateform: this.user.proprietaire,
          fournisseur: "VAN HECK",
        })
        .then((res) => {
          if (res.data[0].accountActivated) {
            this.depotTab = res.data[0].Credential[0].entrepot;
          }
        });
    },
    changeDepot(depotToChange, indexToChange, codeDepot) {
      this.depot = depotToChange;
      this.plateform.grossierID = codeDepot;
      this.indexDepot = indexToChange + 1;
      this.getpanierfournisseur({ name: depotToChange });
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    getPromos() {
      axios
        .get(`${process.env.VUE_APP_API}/promo/${this.user.proprietaire}`)
        .then((res) => {
          this.foundPromo = res.data;
        });
    },
    async prepareCMDVH(data) {
      this.cmdVHloading = true;
      let panierCmdVH = await getPanier(this.user.proprietaire);

      let panierPrepa = panierCmdVH.panier_inst.filter((article) => {
        return article.Prefixe_tarif == "Vhi";
      });
      this.setPreparationFournisseur({
        plateform: this.user.proprietaire,
        panier: panierPrepa,
        fournisseur: "Vhi",
      });
      if (data) {
        this.$router.push("/commandeInstFournisseur");
      } else {
        this.$router.push("/commandeInstFournisseur");
      }
    },
    async getPanierVH() {
      let panierCmdVH = await getPanier(this.user.proprietaire);

      this.panierVH = panierCmdVH.panier_inst.filter((article) => {
        return article.Prefixe_tarif == "Vhi";
      });
    },
    // async getPanierTransport() {
    //   const panierTransport = await getPanierTransportByClient(
    //     this.user.proprietaire,
    //     this.comptoirSessionActive[0].Client[0].Name
    //   );
    //   console.log(this.panierTransport);

    //   this.savecomptoirsession({
    //     id: 4,
    //     clientFocus: this.comptoirSessionActive[0].Client,
    //     totalPanier: panierTransport.valeur,
    //   });
    // },
    // async updatePanierTransport(numero) {
    //   await updatePanierTransportByClient(
    //     this.user.proprietaire,
    //     this.comptoirSessionActive[0].Client[0].Name,
    //     this.comptoirSessionActive[0].documentType,
    //     numero,
    //     this.totalDebutFrais
    //   );
    // },
    setStockFiliale(indexArt, indexStF) {
      this.actionArticle[indexArt].stockFiliale[indexStF].transfertState =
        !this.actionArticle[indexArt].stockFiliale[indexStF].transfertState;
      if (this.actionArticle[indexArt].stockFiliale[indexStF].transfertState) {
        this.actionArticle[indexArt].stockFiliale[indexStF].stockSave =
          this.actionArticle[indexArt].stockFiliale[indexStF].stock;
        if (this.actionArticle[indexArt].stockData) {
          if (
            this.actionArticle[indexArt].quantity -
              this.actionArticle[indexArt].stockData <=
            this.actionArticle[indexArt].stockFiliale[indexStF].stockSave
          ) {
            this.actionArticle[indexArt].stockFiliale[indexStF].stock =
              this.actionArticle[indexArt].quantity -
              this.actionArticle[indexArt].stockData;
            if (this.actionArticle[indexArt].stockFiliale[indexStF].stock < 0) {
              this.actionArticle[indexArt].stockFiliale[indexStF].stock = 0;
            }
          }
        } else {
          this.actionArticle[indexArt].stockFiliale[indexStF].stock = 1;
        }
      } else {
        if (this.actionArticle[indexArt].stockFiliale[indexStF].stockSave) {
          this.actionArticle[indexArt].stockFiliale[indexStF].stock =
            this.actionArticle[indexArt].stockFiliale[indexStF].stockSave;
        }
      }
    },
  },
  beforeMount() {
    this.getPanierTransportClient();
    this.getpanierfournisseur({ name: this.depot });
    this.getFournisseursDepot();
    this.getallcomptoirsession();
    this.getPanierVH();
  },
  async mounted() {
    this.getPromos();
    this.getFournisseur();
    this.getFournisseurDispo();
    this.initFournisseursModel();
    this.initCarCatLogin();
    this.etatRetour();
  },
};
</script>
<style scoped>
.modal {
  transition: opacity 0.25s ease;
}

.tableTDTH {
  text-align: left;
  padding: 8px;
}

.tableBODY {
  overflow: auto;
}

.tableHEADTR {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.menu-LKQ {
  animation: menu-LKQ 0.5s;
}

.bg-vk {
  background-color: #2262b3;
}

.bg-vk1:hover {
  background-color: #ff914d;
  color: white;
}

.bg-vk2:hover {
  background-color: #2262b3;
  color: white;
}

/* Pour cacher les flèches sur les navigateurs Webkit (Safari, Chrome) */
/* input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Pour cacher les flèches sur Firefox  */
/* input[type="number"] {
  -moz-appearance: textfield;
} */
.red-border {
  border: 1px solid red;
}
</style>
