<template>
    <div class="flex items-center">
        <div class="flex space-x-2">
            <!-- actif 
        <button class="btn-bread" :class="button">
            <div class="span" :class="span">
                <span class="material-icons-outlined" style="font-size: 16px">
                    notifications
                </span>
                <span class="m-auto px-2"> Actif </span>
            </div>
        </button> 
        -->
            <!-- preparation -->
            <button class="btn-bread" :class="buttonPreparation">
                <div class="span" :class="spanPreparation">
                    <span class="material-icons-outlined" style="font-size: 16px">
                        inventory
                    </span>
                    <span class="m-auto px-2"> En préparation </span>
                </div>
            </button>
            <!-- prete -->
            <button class="btn-bread" :class="buttonPrete">
                <div class="span" :class="spanPrete">
                    <span class="material-icons-outlined" style="font-size: 16px">
                        done_all
                    </span>
                    <span class="m-auto px-2"> Prête </span>
                </div>
            </button>
            <!-- valide -->
            <button class="btn-bread" :class="buttonValide">
                <div class="span" :class="spanValide">
                    <span class="material-icons-outlined" style="font-size: 16px">
                        local_shipping
                    </span>
                    <span class="m-auto px-2"> Validée</span>
                </div>
            </button>
        </div>

        <!-- attente 
        <button class="btn-bread" :class="buttonAttente">
            <div class="span" :class="spanAttente">
                <span class="material-icons-outlined" style="font-size: 16px">
                    hourglass_empty
                </span>
                <span class="m-auto px-2"> En attente</span>
            </div>
        </button>
        -->

        <!-- archived 
        <button class="btn-bread" :class="buttonArchived">
            <div class="span" :class="spanArchived">
                <span class="material-icons-outlined" style="font-size: 16px">
                    inventory_2
                </span>
                <span class="m-auto px-2"> Archivé</span>
            </div>
        </button>
        -->
        <div>
            <button class="ml-8 bg-slate-400 text-white font-bold py-3 px-6 rounded-md items-center" :class="buttonAttente">
                <span>En attente</span>
            </button>
        </div>

    </div>
</template>

<script>
export default {
    name: "breadCrumb",
    props: ["status", "archived"],
    data() {
        return {
            isActive: false,
            span1: "",
            btnbread1: ""
        }
    },
    computed: {
        // buttonActif() {
        //     if (this.status == ".") {
        //         return this.btnbread1
        //     }
        //     return {
        //         btnbread1: !this.isActive
        //     }
        // },
        // spanActif() {
        //     if (this.status == ".") {
        //         return this.span1
        //     }
        //     return {
        //         span1: !this.isActive
        //     }
        // },
        buttonPreparation() {
            if (this.status == "Commande validée" || this.status == "Commande prête" || this.status == "Commande et BL disponibles") {
                return {
                    btnbread1: !this.isActive
                }
            } else {
                return []
            }
        },
        spanPreparation() {
            if (this.status == "Commande validée" || this.status == "Commande prête" || this.status == "Commande et BL disponibles") {
                return {
                    span1: !this.isActive
                }
            } else {
                return []
            }
        },
        buttonPrete() {
            if (this.status == "Commande et BL disponibles" || this.status == "Commande prête") {
                return {
                    btnbread1: !this.isActive
                }
            } else {
                return []
            }
        },
        spanPrete() {
            if (this.status == "Commande et BL disponibles" || this.status == "Commande prête") {
                return {
                    span1: !this.isActive
                }
            } else {
                return []
            }
        },
        buttonValide() {
            if (this.status == "Commande et BL disponibles") {
                return {
                    btnbread1: !this.isActive
                }
            } else {
                return []
            }
        },
        spanValide() {
            if (this.status == "Commande et BL disponibles") {
                return {
                    span1: !this.isActive
                }
            } else {
                return []
            }
        },
        buttonAttente() {
            if (this.status == "Commande en attente") {
                return {
                    orange: !this.isActive
                }
            } else {
                return []
            }
        },

        // spanAttente() {
        //     if (this.status == "Commande en attente") {
        //         return this.span1
        //     }
        //     return {
        //         span1: !this.Active
        //     }
        // },
        // buttonArchived() {
        //     if (this.status == ".") {
        //         return this.btnbread1
        //     }
        //     return {
        //         btnbrea1: !this.Active
        //     }
        // },
        // spanArchived() {
        //     if (this.status == ".") {
        //         return this.span1
        //     }
        //     return {
        //         span1: !this.Active
        //     }
        // },
    },
}
</script>

<style scoped>
.modal {
    transition: opacity 0.25s ease;
}


/* COMMANDE NON VALIDEE */

.btn-bread .span {
    display: block;
    min-height: 20px;
    max-height: 20px;
    border-image: none;
    position: relative;
    margin-left: 20px;
    margin-right: 4px;
    padding: 11px 0 38.5px 10px;
    color: aliceblue;
    background: #93a4bb;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.btn-bread .span:before {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 25px solid #93a4bb;
    border-bottom: 25px solid transparent;
    z-index: -1;
}

.btn-bread .span:after {
    content: "";
    position: absolute;
    border-color: #93a4bb;
    left: -25px;
    top: 0px;
    width: 0px;
    height: 0px;
    border-width: 25px;
    border-style: solid;
    border-left: 25px solid transparent;
    border-image: none;
    color: #93a4bb !important;
    z-index: -1;
}


.btn-bread:first-child .span {
    margin-left: 0;
    padding-left: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.btn-bread:first-child .span:after {
    display: none;
}


.btn-bread:last-child .span {
    padding-right: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #93a4bb;
    color: aliceblue;
    text-decoration: none;
    cursor: pointer;
}

.btn-bread:last-child .span:after {
    color: #93a4bb;
}

.btn-bread:last-child .span:before {
    display: none;
}


/* COMMANDE VALIDEE */

.btnbread1 .span1 {
    display: block;
    min-height: 20px;
    max-height: 20px;
    border-image: none;
    position: relative;
    margin-left: 20px;
    margin-right: 4px;
    padding: 11px 0 38.5px 10px;
    color: aliceblue;
    background: #2262b3;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.btnbread1 .span1:before {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 25px solid #2262b3;
    border-bottom: 25px solid transparent;
    z-index: -1;
}

.btnbread1 .span1:after {
    content: "";
    position: absolute;
    border-color: #2262b3;
    left: -25px;
    top: 0px;
    width: 0px;
    height: 0px;
    border-width: 25px;
    border-style: solid;
    border-left: 25px solid transparent;
    border-image: none;
    color: #2262b3 !important;
    z-index: -1;
}


.btnbread1:first-child .span1 {
    margin-left: 0;
    padding-left: 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.btnbread1:first-child .span1:after {
    display: none;
}


.btnbread1:last-child .span1 {
    padding-right: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #2262b3;
    color: aliceblue;
    text-decoration: none;
    cursor: pointer;
}


.btnbread1:last-child .span1:after {
    color: #2262b3;
}

.btnbread1:last-child .span1:before {
    display: none;
}

.orange {
    background-color: orangered;
}

;
</style>

