<template>
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg my-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-slate-300 dark:text-slate-800">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Comptoir
                    </th>
                    <th>

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-slate-50 dark:border-slate-300">
                    <th scope="row" class="px-6 py-4 font-medium text-slate-600 whitespace-nowrap dark:text-slate-600">
                        Présentation du comptoir
                    </th>
                    <td class="px-24 py-4 float-right">
                        <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Voir la vidéo</a>
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-slate-50 dark:border-gray-300">
                    <th scope="row" class="px-6 py-4 font-medium text-slate-600 whitespace-nowrap dark:text-slate-600">
                        Bien utiliser le comptoir
                    </th>
                    <td class="px-24 py-4 float-right">
                        <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Voir la vidéo</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mb-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-slate-300 dark:text-slate-800">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Client
                    </th>
                    <th>

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-slate-50 dark:border-slate-300">
                    <th scope="row" class="px-6 py-4 font-medium text-slate-600 whitespace-nowrap dark:text-slate-600">
                        Comment modifier un client
                    </th>
                    <td class="px-24 py-4 float-right">
                        <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Voir la vidéo</a>
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-slate-50 dark:border-gray-300">
                    <th scope="row" class="px-6 py-4 font-medium text-slate-600 whitespace-nowrap dark:text-slate-600">
                        Bon de commande et bon de livraison
                    </th>
                    <td class="px-24 py-4 float-right">
                        <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Voir la vidéo</a>
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-slate-50 dark:border-gray-300">
                    <th scope="row" class="px-6 py-4 font-medium text-slate-600 whitespace-nowrap dark:text-slate-600">
                        Factures et factures pro
                    </th>
                    <td class="px-24 py-4 float-right">
                        <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Voir la vidéo</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="relative overflow-x-auto shadow-md sm:rounded-lg mb-10">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-slate-300 dark:text-slate-800">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Articles
                    </th>
                    <th>

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-slate-50 dark:border-slate-300">
                    <th scope="row" class="px-6 py-4 font-medium text-slate-600 whitespace-nowrap dark:text-slate-600">
                        Trouver un article
                    </th>
                    <td class="px-24 py-4 float-right">
                        <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Voir la vidéo</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

</script>

<style scoped></style>