<template>
  <div>
    <div v-if="tabStock" class="mt-2 flex justify-between">
      <!-- min max -->
      <div class="flex flex-col">
        <p class="text-xs">min : {{ tabStock.min }}</p>
        <p class="text-xs">max : {{ tabStock.max }}</p>
      </div>

      <!-- erreur stock si ça dépasse min ou max -->
      <div class="w-fit">
        <div
          v-if="
            parseInt(tabStock.stock) + parseInt(-quantity) >
            parseInt(tabStock.max)
          "
          class="flex text-md rounded-full bg-orange-500 px-1 py-0.25"
          :title="'stock : ' + tabStock.stock"
        >
          <p class="m-auto text-white material-icons-outlined">call_made</p>
        </div>
        <div
          v-if="
            parseInt(tabStock.stock) + parseInt(-quantity) <
            parseInt(tabStock.min)
          "
          class="flex text-md rounded-full bg-green-500 px-1 py-0.25"
          :title="'stock : ' + tabStock.stock"
        >
          <p class="m-auto text-white material-icons-outlined">call_received</p>
        </div>
        <div
          v-if="
            parseInt(tabStock.stock) + parseInt(-quantity) <=
              parseInt(tabStock.max) &&
            parseInt(tabStock.stock) + parseInt(-quantity) >=
              parseInt(tabStock.min)
          "
          class="flex text-md rounded-full bg-green-500 px-1 py-0.25"
          :title="'stock : ' + tabStock.stock"
        >
          <p class="m-auto text-white material-icons-outlined">call_received</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStock } from "@/hooks/stock/stock.ts";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      tabStock: null,
      showStock: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  props: ["reference", "ean", "marque", "quantity", "dispoArticle", "index"],
  methods: {
    async getStockArticle() {
      this.tabStock = [];
      const stock = await getStock(
        this.user.proprietaire,
        this.reference,
        this.ean,
        this.marque
      );
      this.tabStock = stock[0];
      console.log(this.tabStock);
    },
  },
  mounted() {
    this.getStockArticle();
  },
};
</script>
