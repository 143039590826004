<template>
  <div>
    <!-- Modal pour créer un utilisateur -->
    <div
      v-if="open"
      :class="`modal ${
        !open && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="open = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 60rem"
        class="z-50 h-3/9 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Ajouter un utilisateur</p>
            <div class="z-50 cursor-pointer modal-close" @click="open = false">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!--Body-->
          <div class="grid grid-cols-3 justify-between text-sm">
            <div id="left" class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Nom d'utilisateur</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">account_circle</span>
                </span>

                <input
                  v-model="username"
                  :placeholder="nom + '.' + prenom"
                  disabled
                  type="text"
                  class="lowercase focus:outline-2 outline-sky-300 w-full pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div
              id="center"
              class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
            >
              <label class="text-xs">Nom</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">account_box</span>
                </span>

                <input
                  v-model="nom"
                  type="text"
                  class="uppercase focus:outline-2 outline-sky-300 w-full pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div
              id="right"
              class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
            >
              <label class="text-xs">Prénom</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">account_box</span>
                </span>

                <input
                  v-model="prenom"
                  type="text"
                  class="uppercase focus:outline-2 outline-sky-300 w-full pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>

          <div class="grid grid-cols-3 justify-between text-sm">
            <div id="left" class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Email</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">email</span>
                </span>

                <input
                  v-model="email"
                  type="email"
                  class="lowercase focus:outline-2 outline-sky-300 w-full pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div
              id="center"
              class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
            >
              <label class="text-xs">Téléphone</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">phone</span>
                </span>

                <input
                  v-model="telephone"
                  type="tel"
                  class="w-full focus:outline-2 outline-sky-300 pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
              </div>
            </div>

            <div
              id="right"
              class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
            >
              <label class="text-xs">Type de compte</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round"
                    >settings_accessibility</span
                  >
                </span>

                <div
                  class="w-full focus:outline-2 outline-sky-300 pl-10 pr-3 py-2 border-transparent rounded-md appearance-none"
                >
                  <span>Utilisateur</span>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-3 justify-between text-sm">
            <div id="left" class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
              <label class="text-xs">Equipe</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">group</span>
                </span>

                <div
                  class="w-full focus:outline-2 outline-sky-300 pl-10 pr-3 py-2 border-transparent rounded-md appearance-none"
                >
                  <span>Client Pro</span>
                </div>
              </div>
            </div>

            <div
              id="center"
              class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
            >
              <label class="text-xs">Mot de passe</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">password</span>
                </span>

                <input
                  v-model="password"
                  :type="Typepassword"
                  class="w-full focus:outline-2 outline-sky-300 pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                />
                <span
                  class="absolute inset-y-0 left-0 flex items-center ml-56 text-gray-600 pl-2"
                >
                  <span
                    v-show="passShow"
                    class="absolute cursor-pointer material-icons-round"
                    @click="(Typepassword = 'text'), (passShow = !passShow)"
                    >visibility</span
                  >
                  <span
                    v-show="!passShow"
                    class="absolute cursor-pointer material-icons-round"
                    @click="(Typepassword = 'password'), (passShow = !passShow)"
                    >visibility_off</span
                  >
                </span>
              </div>
            </div>

            <div
              id="right"
              class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
            >
              <label class="text-xs">Propriétaire</label>

              <div class="relative mt-2 rounded-md shadow-sm">
                <span
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
                >
                  <span class="material-icons-round">business</span>
                </span>

                <div
                  class="w-full focus:outline-2 outline-sky-300 pl-10 pr-3 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                >
                  {{ clientChoosedTab.Name }}
                </div>
              </div>
            </div>
          </div>

          <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
            <label v-show="errorpassword" class="text-xs" style="color: red"
              >le mot de passe doit contenir 6 caractères minimum</label
            >
          </div>

          <div class="flex justify-end pt-2">
            <button
              @click="open = false"
              class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              @click="inscription()"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Ajouter
            </button>
          </div>
          <div v-if="loadingInscription" class="flex justify-end mt-2">
            <span>Compte en cours de creation...</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modal pour créer un utilisateur -->
    <div class="flex flex-row justify-between items-center">
      <div
        id="retour"
        class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit h-fit hover:bg-white border border-blue-400 cursor-pointer"
        @click="$router.back()"
      >
        <img src="../assets/RetourIcon.png" class="w-6" />
        <span style="color: #5976e8" class="m-auto">Retour</span>
      </div>
      <div>
        <button
          @click="open = true"
          class="p-2 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
        >
          Ajouter un utilisateur
        </button>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="text-2xl font-medium ml-2">
        <h2 class="space-x-2">
          <span>Utilisateurs</span>
          <span class="text-green-600"> {{ clientChoosedTab.Name }} </span>
          <span class="text-green-600"> </span>
        </h2>
      </div>
    </div>
    <div class="flex flex-col mt-8">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Nom d'utilisateur
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Propriétaire
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Type de compte
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Equipe
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Connexion
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in users"
                :key="index"
                class="hover:bg-gray-100 cursor-pointer"
                @click="updateUser(u)"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ u.username }}
                      </div>
                      <div class="text-sm leading-5 text-gray-500">
                        {{ u.email }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.proprietaire }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.accountType }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    >{{ u.team }}</span
                  >
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    v-if="u.connect"
                    class="bg-green-500 text-white rounded p-1"
                    >Oui</span
                  >
                  <span v-else class="bg-red-500 text-white rounded p-1"
                    >Non</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex flex-col items-center px-5 py-5 bg-white border-t xs:flex-row xs:justify-between"
          >
            <span class="text-xs text-gray-900 xs:text-sm"
              >1 à 4 sur 50 Articles</span
            >

            <div class="inline-flex mt-2 xs:mt-0">
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-l hover:bg-gray-400"
              >
                Précédent
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                1
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                2
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                3
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-400"
              >
                4
              </button>
              <button
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-r hover:bg-gray-400"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "UtilisateursClient",
  data() {
    return {
      users: [],
      open: false,
      username: "",
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      accountType: "",
      team: "",
      proprietaire: "",
      connect: true,

      Typepassword: "password",
      passShow: true,
      password: "",

      errorpassword: false,
      loadingInscription: false,
    };
  },
  computed: {
    ...mapGetters(["user", "clientChoosedTab", "plateform"]),
  },
  methods: {
    async getUsers() {
      await axios
        .post(`${process.env.VUE_APP_API}/user/getClientUsers`, {
          plateform: this.user.proprietaire,
          client: this.clientChoosedTab.Name,
        })
        .then((response) => {
          this.users = response.data;
        });
    },
    updateUser(user) {
      this.$router.push({
        name: "SetUtilisateur",
        params: { userID: user._id },
      });
    },
    async inscription() {
      if (this.password.length >= 6) {
        this.loadingInscription = true;
        await axios
          .post(`${process.env.VUE_APP_API}/user/createUser`, {
            data: {
              email: this.email,
              username: this.nom + "." + this.prenom,
              nom: this.nom,
              prenom: this.prenom,
              telephone: this.telephone,
              accountType: "Utilisateur",
              team: "Client Pro",
              connect: true,
              proprietaire: this.clientChoosedTab.Name,
              plateform: this.user.proprietaire,
            },
            firebaseData: {
              email: this.email,
              password: this.password,
            },
          })
          .then(() => {
            this.open = false;
            this.errorpassword = false;
            this.loadingInscription = false;
            this.getUsers();
          });
      } else {
        this.errorpassword = true;
      }
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
<style></style>
