<template>
  <div>
    <div @click="showTable = !showTable">
      <span class="p-2 bg-blue-500 text-white rounded shadow cursor-pointer">
        Exemple d'utilisation des informations article
      </span>
    </div>
    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      v-if="showTable"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="flex">
                  <span>Réf./Equipe.</span>
                </div>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Stock
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <span>Descr./Fourn.</span>
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                P.V.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Remise
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Px final
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="flex flex-row justify-between items-center">
                  <div class="uppercase">Total</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr>
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col space-y-1">
                  <span class="text-blue-500 p-1 w-fit">Ref_fournisseur</span>
                  <span class="text-blue-500 p-1 w-fit">Code_marque</span>
                </div>
              </td>
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span>Partie stock</span>
              </td>
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col space-y-1">
                  <span class="text-blue-500 p-1 w-fit">Description</span>
                  <span
                    ><span class="text-blue-500 p-1 w-fit">Prefixe_tarif</span>
                    (ex : Vhi => VAN HECK)</span
                  >
                </div>
              </td>
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span>1</span>
              </td>

              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span class="text-blue-500 p-1 w-fit">Prix_euro</span>
              </td>
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span>Condition</span>
              </td>
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col">
                  <div>
                    <span class="text-blue-500 p-1 w-fit">Prix_euro</span>
                    - (condition) %
                  </div>
                  <span>ou</span>
                  <div class="flex flex-row items-center space-x-2">
                    <span class="text-blue-500 p-1 w-fit">PNT1</span>
                    <span class="text-blue-500 p-1 w-fit">PNT2</span>
                    <span class="text-blue-500 p-1 w-fit">PNT3</span>
                  </div>
                </div>
              </td>
              <td
                class="px-2 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col">
                  <span
                    >(<span class="text-blue-500 p-1 w-fit">Prix_euro</span> -
                    (condition) %) x Quantité</span
                  >
                  <span>ou</span>
                  <span
                    ><span class="text-blue-500 p-1 w-fit">PNT1</span> x
                    Quantité</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <span class="text-blue-500 p-1 w-fit">Code_EAN</span> -> BARCODE
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showTable: true,
    };
  },
};
</script>
<style></style>
