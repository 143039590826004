import axios from "axios";
import { getStockFiliale } from "../stock/stock";

export async function regularSearchArticles(
  Ref_fournisseur: string,
  plateform: string
) {
  const search = await axios.post(
    `${process.env.VUE_APP_API}/searchEngine/regularSearchArticle`,
    {
      Ref_fournisseur: Ref_fournisseur,
      plateform: plateform,
    }
  );

  return search.data;
}

export async function searchArticles(
  Ref_fournisseur: string,
  plateform: string,
  Client: string,
  groupeClient: string
) {
  const search = await axios.post(
    `${process.env.VUE_APP_API}/searchEngine/searchArticle`,
    {
      Ref_fournisseur: Ref_fournisseur,
      plateform: plateform,
      Client: Client,
      groupeClient: groupeClient,
    }
  );

  return search.data;
}

export async function prepareArticle(
  articleData: any,
  plateform: string,
  client: string,
  groupeClient: string,
  quantity: number
) {
  let dataTab: any = {};
  const prepare = await axios.post(
    `${process.env.VUE_APP_API}/searchEngine/prepareArticleDirect`,
    {
      articleData: articleData,
      plateform: plateform,
      client: client,
      groupeClient: groupeClient,
      quantity: quantity,
    }
  );

  dataTab = prepare.data.data;
  dataTab.stockFiliale = [];
  dataTab.stockFilialeTotal = 0;
  const stockF = await getStockFiliale(
    plateform,
    dataTab.Ref_fournisseur,
    dataTab.Code_EAN,
    dataTab.Code_marque
  );

  for (const stF of stockF) {
    if (stF.stock.length > 0) {
      dataTab.stockFiliale.push({
        plateform: stF.stock[0].plateform,
        stock: stF.stock[0].stock,
        coef: stF.coef,
        transfertState: false,
      });
      dataTab.stockFilialeTotal += stF.stock[0].stock;
    }
  }

  if (dataTab.Prefixe_tarif == "Vhi") {
    axios
      .get(
        `${process.env.VUE_APP_API}/vanheck/getPrixLot/${dataTab.Ref_fournisseur}`
      )
      .then((response) => {
        if (response.data.length > 0) {
          dataTab.PrixLotVH = true;
        } else {
          dataTab.PrixLotVH = false;
        }
      })
      .catch((error) => {
        dataTab.PrixLotVH = false;
      });
  }

  if (dataTab.Qte_mini && parseInt(dataTab.Qte_mini) > quantity) {
    dataTab.quantity = dataTab.Qte_mini;
  } else {
    dataTab.quantity = quantity;
  }
  dataTab.commentaire = "";
  dataTab.prixtotal = dataTab.Prix_euro;
  dataTab.prixfinal = dataTab.Prix_euro;
  articleData.stockData.length > 0
    ? (dataTab.stockData = articleData.stockData[0].stock)
    : (dataTab.stockData = 0);
  articleData.stockData.length > 0 &&
  articleData.stockData[0].reservedClient &&
  articleData.stockData[0].reservedClient != ""
    ? (dataTab.reserveStockData = articleData.stockData[0].reservedClient)
    : (dataTab.reserveStockData = 0);

  return dataTab;
}
