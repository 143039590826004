<template>
  <div class="flex flex-col space-y-4 p-10" style="height: 75vh">
    <div class="flex justify-between">
      <div class="flex justify-between items-center">
        <div class="text-vk flex space-x-4">
          <p class="text-2xl font-bold italic">
            Marque :
            <span class="not-italic">{{ articleInfoData.Code_marque }}</span>
          </p>
          <p class="text-2xl font-bold italic">
            Ref. : {{ articleInfoData.Prefixe_tarif }} -
            {{ articleInfoData.Ref_fournisseur }}
          </p>
        </div>
      </div>
      <button
        class="px-2 py-1 flex justify-between item-center font-medium space-x-2 text-white bg-blue-500 rounded-md hover:bg-blue-400 outline-sky-300"
        @click="updateArticle(articleInfoData)"
      >
        <span class="m-auto">Voir le détail</span>
        <span class="material-icons-outlined m-auto" style="font-size: 20px">
          arrow_forward
        </span>
      </button>
    </div>
    <div class="flex flex-row items-center space-x-2">
      <p
        class="text-xl text-vk font-bold italic -mt-2"
        v-if="
          articleInfoData.Code_remise && user.accountType == 'Administrateur'
        "
      >
        Code remise : {{ articleInfoData.Code_remise }}
      </p>
      <span class="text-xl text-vk font-bold italic -mt-2"> / </span>
      <gen-art
        class="text-xl text-vk font-bold italic -mt-2"
        :Ref_fournisseur="articleInfoData.Ref_fournisseur"
        :Code_marque="articleInfoData.Code_marque"
      />
    </div>

    <div class="flex flex-row justify-between">
      <div class="w-6/12">
        <photo-article
          :Ref_fournisseur="articleInfoData.Ref_fournisseur"
          :Code_marque="articleInfoData.Code_marque"
        />
      </div>
      <div class="w-6/12 flex bg-gray-200 bg-white">
        <div class="w-8/12">
          <information-article
            :Ref_fournisseur="articleInfoData.Ref_fournisseur"
            :Code_marque="articleInfoData.Code_marque"
          />
        </div>
        <div
          class="w-4/12 max-h-28 py-1 shadow-lg h-2/12 bg-vk mt-4 mr-4 rounded border-gray-500"
        >
          <div class="text-white text-xl">
            <prixBrutArticleVue
              :article="articleInfoData.Ref_fournisseur"
              :marque="articleInfoData.Code_marque"
              :Prefixe_tarif="articleInfoData.Prefixe_tarif"
              :qty="1"
            />
          </div>
          <div class="float-right mr-4">
            <stockvue
              :quantity="1"
              :Article="articleInfoData"
              :key="articleInfoData.Code_EAN"
            />
          </div>
          <div>
            <ajoutPanier
              :Ref_fournisseur="articleInfoData.Ref_fournisseur"
              :Code_marque="articleInfoData.Code_marque"
              class="mt-16"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="w-12/12">
      <oecross-article
        :Ref_fournisseur="articleInfoData.Ref_fournisseur"
        :Code_marque="articleInfoData.Code_marque"
        @checkCross="checkCross"
      />
    </div>
    <div v-show="false" class="w-12/12">
      <assemblage-article
        :Ref_fournisseur="articleInfoData.Ref_fournisseur"
        :Code_marque="articleInfoData.Code_marque"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import photoArticle from "./photoArticle.vue";
import informationArticle from "./informationArticle.vue";
// import infoPrixArticle from "./infoPrixArticle.vue";
import assemblageArticle from "./assemblageArticle.vue";
import oecrossArticle from "./oecrossArticle.vue";
// import infoDispoArticle from "./infoDispoArticle.vue";
import ajoutPanier from "./ajoutPanier.vue";
import stockvue from "../Article/stock.vue";
import prixBrutArticleVue from "../Catalogue/prixBrutArticle.vue";
import genArt from "./genArt.vue";
export default {
  data() {
    return {};
  },
  components: {
    photoArticle,
    informationArticle,
    // infoPrixArticle,
    assemblageArticle,
    oecrossArticle,
    ajoutPanier,
    stockvue,
    prixBrutArticleVue,
    genArt,
  },
  computed: {
    ...mapGetters(["articleInfoData", "user"]),
  },
  methods: {
    back() {
      this.$router.back();
    },
    updateArticle(article) {
      if (article.Code_marque === "MEYLE") {
        const updatedArticle = {
          ...article,
          Ref_fournisseur: article.Ref_fournisseur_trim,
        };
        this.$store.dispatch("setArticle", updatedArticle);
      } else {
        this.$store.dispatch("setArticle", article);
      }
      this.$router.push({
        name: "SetArticles",
        // params: article,
      });
    },
    checkCross() {
      this.$emit("checkCross");
    },
  },
};
</script>

<style scoped>
.text-vk {
  color: #2262b3;
}
.bg-vk {
  background-color: #2262b3;
}
</style>
