import axios from "axios";

export async function createCP(plateform: string, username: string, Client: Array<object>, vehiculeData: Array<object>) {
  const response = await axios.post(
    `${process.env.VUE_APP_API}/controlepreliminaire/`,
    {
      plateform: plateform,
      username: username,
      Client: Client,
      vehiculeData: vehiculeData,
    }
  );

  return response.data;
}

export async function getCP(plateform: string) {
  const response = await axios.post(
    `${process.env.VUE_APP_API}/controlepreliminaire/getCP`,
    {
      plateform: plateform,
    }
  );

  return response.data;
}

export async function getCPbyId(id: string) {
  const response = await axios.post(
    `${process.env.VUE_APP_API}/controlepreliminaire/getCPbyId`,
    {
      id: id,
    }
  );

  return response.data;
}

export async function saveIndex(id: string, index: number) {
  const response = await axios.post(
    `${process.env.VUE_APP_API}/controlepreliminaire/saveIndex`,
    {
      id: id,
      index: index,
    }
  );

  return response.data;
}

export async function saveChange(id: null, data: object) {
  const response = await axios.put(
    `${process.env.VUE_APP_API}/controlepreliminaire/${id}`,
    data
  );

  return response.data;
}


