import axios from "axios";

export async function regrouperBdl(plateform: string, date: string) {
  const search = await axios.post(
    `${process.env.VUE_APP_API}/bdl/regrouperBdl`,
    {
      plateform: plateform,
      date: date
    }
  );

  return search.data;
}

export async function regrouperBdlClientNum(plateform: string, client: string, numero: string, date: string) {
  const search = await axios.post(
    `${process.env.VUE_APP_API}/bdl/regrouperBdl`,
    {
      plateform: plateform,
      numero: numero,
      client: client,
      date: date
    }
  );

  return search.data;
}

export async function regrouperBdlClient(plateform: string, client: string, date: string) {
  const search = await axios.post(
    `${process.env.VUE_APP_API}/bdl/regrouperBdl`,
    {
      plateform: plateform,
      client: client,
      date: date
    }
  );

  return search.data;
}

export async function regrouperBdlNum(plateform: string, numero: string, date: string) {
  const search = await axios.post(
    `${process.env.VUE_APP_API}/bdl/regrouperBdl`,
    {
      plateform: plateform,
      numero: numero,
      date: date
    }
  );

  return search.data;
}

export async function createFacture(
  plateform: string,
  tabBdls: string,
  dateEdition: string,
  username: string
) {
  const search = await axios.post(
    `${process.env.VUE_APP_API}/facture/createFacturation`,
    {
      plateform: plateform,
      data: tabBdls,
      Date: dateEdition,
      User: username,
    }
  );

  return search.data;
}
