<template>
  <div class="flex flex-col space-y-4">
    <div class="flex flex-row justify-between items-center">
      <div>
        <button
          class="flex flex-row space-x-2 items-center"
        >
          <backButton/>
        </button>
      </div>
      <div>
        <button
          @click="deleteUser()"
          class="flex flex-row space-x-2 items-center bg-red-500 text-white px-2 py-1 rounded shadow"
        >
          <span class="material-icons-outlined text-sm"> delete </span>
          <span>Supprimer</span>
        </button>
      </div>
    </div>
    <div class="flex flex-row space-x-2">
      <div class="w-1/5 bg-white p-4">
        <div class="flex flex-row items-center space-x-2">
          <span> Connexion </span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              v-model="userTab.connect"
              @change="setUserConnexion()"
              value=""
              class="sr-only peer"
            />
            <div
              class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
            ></div>
          </label>
        </div>
      </div>
      <div class="w-4/5 bg-white flex flex-col space-y-4 p-4">
        <div class="flex flex-row space-x-8 items-center">
          <div class="flex flex-col space-y-2 w-1/3">
            <span>Prénom :</span>
            <input
              type="text"
              class="bg-gray-100 rounded shadow p-2"
              v-model="userTab.prenom"
              @change="saveUserModif()"
            />
          </div>
          <div class="flex flex-col space-y-2 w-1/3">
            <span>Nom :</span>
            <input
              type="text"
              class="bg-gray-100 rounded shadow p-2"
              v-model="userTab.nom"
              @change="saveUserModif()"
            />
          </div>
        </div>
        <div class="flex flex-row space-x-8 items-center">
          <div class="flex flex-col space-y-2 w-1/3">
            <span>Email :</span>
            <input
              type="text"
              class="bg-gray-100 rounded shadow p-2"
              disabled
              v-model="userTab.email"
              @change="saveUserModif()"
            />
          </div>
          <div class="flex flex-col space-y-2 w-1/3">
            <span>Nom d'utilisateur :</span>
            <input
              type="text"
              class="bg-gray-100 rounded shadow p-2"
              v-model="userTab.username"
              @change="saveUserModif()"
            />
          </div>
        </div>
        <div class="flex flex-row space-x-8 items-center">
          <div class="flex flex-col space-y-2 w-1/3">
            <span>Téléphone :</span>
            <input
              type="text"
              class="bg-gray-100 rounded shadow p-2"
              v-model="userTab.telephone"
              @change="saveUserModif()"
            />
          </div>
        </div>
        <div
          class="flex flex-row space-x-8 items-center"
          v-if="userTab.team != 'Client Pro'"
        >
          <div class="flex flex-col space-y-2 w-1/3">
            <span>Type de compte :</span>
            <select
              v-model="userTab.accountType"
              class="bg-gray-100 rounded shadow p-2"
              @change="saveUserModif()"
            >
              <option :value="userTab.accountType" selected>
                {{ userTab.accountType }}
              </option>
              <option value="Super Administrateur">Super Administrateur</option>
              <option value="Administrateur">Administrateur</option>
              <option value="Utilisateur">Utilisateur</option>
            </select>
          </div>
          <div class="flex flex-col space-y-2 w-1/3">
            <span>Équipe :</span>
            <select
              v-model="userTab.team"
              class="bg-gray-100 rounded shadow p-2"
              @change="saveUserModif()"
            >
              <option :value="userTab.team" selected>
                {{ userTab.team }}
              </option>
              <option value="Administration">Administration</option>
              <option value="Client Pro">Client Pro</option>
              <option value="Magasinier">Magasinier</option>
              <option value="Responsable">Responsable</option>
              <option value="Commercial">Commercial</option>
              <option value="Comptable">Comptable</option>
              <option value="Préparateur">Préparateur</option>
              <option value="Livreur">Livreur</option>
              <option value="Réceptionnaire">Réceptionnaire</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import backButton from "@/components/Button/back.vue";
export default {
  name: "SetUtilisateur",
  data() {
    return {
      userTab: [],
    };
  },
  methods: {
    getUser() {
      axios
        .get(
          `${process.env.VUE_APP_API}/user/getUserToUpdate/${this.$route.params.userID}`
        )
        .then((response) => {
          this.userTab = response.data;
        });
    },
    saveUserModif() {
      axios.put(
        `${process.env.VUE_APP_API}/user/${this.$route.params.userID}`,
        this.userTab
      );
    },
    setUserConnexion() {
      axios.post(`${process.env.VUE_APP_API}/user/setUserConnexion`, {
        uid: this.$route.params.userID,
        state: this.userTab.connect,
      });
    },
    deleteUser() {
      axios
        .delete(`${process.env.VUE_APP_API}/user/${this.$route.params.userID}`)
        .then(() => {
          this.$router.back();
        });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  mounted() {
    this.getUser();
  },
  components: {
    backButton,
  },
};
</script>
<style></style>
