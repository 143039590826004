<template>
  <div class="flex flex-col mt-8">
    <div v-show="errorChamps">
      <span class="text-red-500">Veuillez remplir les champs</span>
    </div>
    <div
      class="flex flex-row items-center mt-2 space-x-4"
      v-show="!commandState"
    >
      <input
        type="text"
        v-model="Ref_fournisseur"
        placeholder="Référence article"
        class="p-2 focus:outline-2 outline-sky-300"
        @keydown.enter="focusNext"
      />
      <input
        type="Number"
        v-model="Quantity"
        placeholder="Quantité"
        class="p-2 focus:outline-2 outline-sky-300"
        ref="qty"
        @keydown.enter="checkArticle()"
      />
      <button
        @click="checkArticle()"
        class="p-2 bg-blue-500 text-white rounded shadow"
      >
        Rechercher
      </button>
      <PulseLoader color="#3b82f6" v-show="loading" />
    </div>
  </div>
  <div class="flex flex-col mt-8">
    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      v-show="!commandState"
    >
      <div
        v-if="a02Tab.B1_Artikelnummer"
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead class="">
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50"
              ></th>

              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50"
              >
                Demandé
              </th>

              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50"
              >
                <span class="">Equivalence</span>
              </th>

              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-center text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50"
              >
                <span class="">Equivalence</span>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <!-- Ref article -->
            <tr>
              <td
                class="px-6 py-4 text-sm leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                Référence article
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                {{ a02Tab.B1_Artikelnummer[0] }}
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                {{ a02Tab.B2_Artikelnummer[0] }}

                <span
                  style="font-size: 15px"
                  @click="changeReference(a02Tab.B2_Artikelnummer[0])"
                  class="material-icons-outlined cursor-pointer rounded-full bg-green-300 hover:shadow text-gray-800 p-0.5 ml-4"
                  >published_with_changes</span
                >
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                {{ a02Tab.B3_Artikelnummer[0] }}

                <span
                  style="font-size: 15px"
                  @click="changeReference(a02Tab.B3_Artikelnummer[0])"
                  class="material-icons-outlined cursor-pointer rounded-full bg-green-300 hover:shadow text-gray-800 p-0.5 ml-4"
                  >published_with_changes</span
                >
              </td>
            </tr>

            <!-- Livr A -->
            <tr>
              <td
                class="px-6 py-4 text-sm leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                Livraison A
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span
                  >{{ a02Tab.B1_LA_Aantal_response[0] }} pcs le
                  {{ a02Tab.B1_LA_DatumTijd[0] }}</span
                >
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span
                  >{{ a02Tab.B2_LA_Aantal_response[0] }} pcs le
                  {{ a02Tab.B2_LA_DatumTijd[0] }}</span
                >
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span
                  >{{ a02Tab.B3_LA_Aantal_response[0] }} pcs le
                  {{ a02Tab.B3_LA_DatumTijd[0] }}</span
                >
              </td>
            </tr>

            <!-- Livr B -->
            <tr>
              <td
                class="px-6 py-4 text-sm leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                Livraison B
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span v-if="parseInt(a02Tab.B1_LB_Aantal_response[0]) > 0"
                    >{{ a02Tab.B1_LB_Aantal_response[0] }} pcs le
                    {{ a02Tab.B1_LB_DatumTijd[0] }}</span
                  >
                  <span v-else>Non proposé</span>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span
                    >{{ a02Tab.B2_LB_Aantal_response[0] }} pcs le
                    {{ a02Tab.B2_LB_DatumTijd[0] }}</span
                  >
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span
                    >{{ a02Tab.B3_LB_Aantal_response[0] }} pcs le
                    {{ a02Tab.B3_LB_DatumTijd[0] }}</span
                  >
                </div>
              </td>
            </tr>

            <!-- Prix Brut -->
            <tr>
              <td
                class="px-6 py-4 text-sm leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                Prix brut
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span
                    >{{
                      parseFloat(a02Tab.B1_Brutoprijs[0]).toFixed(2)
                    }}
                    €</span
                  >
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span
                    >{{
                      parseFloat(a02Tab.B2_Brutoprijs[0]).toFixed(2)
                    }}
                    €</span
                  >
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span
                    >{{
                      parseFloat(a02Tab.B3_Brutoprijs[0]).toFixed(2)
                    }}
                    €</span
                  >
                </div>
              </td>
            </tr>

            <!-- Prix Net -->
            <tr>
              <td
                class="px-6 py-4 text-sm leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                Prix net
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span v-if="parseFloat(a02Tab.B1_Korting[0]) > 0"
                    >{{
                      parseFloat(
                        parseFloat(a02Tab.B1_Brutoprijs[0]) -
                          parseFloat(a02Tab.B1_Brutoprijs[0]) *
                            (parseFloat(a02Tab.B1_Korting[0]) / 100)
                      ).toFixed(2)
                    }}
                    €</span
                  >
                  <span v-else-if="a02Tab.B1_Nettoprijs[0]"
                    >{{ a02Tab.B1_Nettoprijs[0] }} €</span
                  >
                  <span v-else>Pas de remise</span>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span v-if="parseFloat(a02Tab.B2_Korting[0]) > 0"
                    >{{
                      parseFloat(
                        parseFloat(a02Tab.B2_Brutoprijs[0]) -
                          parseFloat(a02Tab.B2_Brutoprijs[0]) *
                            (parseFloat(a02Tab.B2_Korting[0]) / 100)
                      ).toFixed(2)
                    }}
                    €</span
                  >
                  <span v-else>Pas de remise</span>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span v-if="parseFloat(a02Tab.B3_Korting[0]) > 0"
                    >{{
                      parseFloat(
                        parseFloat(a02Tab.B3_Brutoprijs[0]) -
                          parseFloat(a02Tab.B3_Brutoprijs[0]) *
                            (parseFloat(a02Tab.B3_Korting[0]) / 100)
                      ).toFixed(2)
                    }}
                    €</span
                  >
                  <span v-else>Pas de remise</span>
                </div>
              </td>
            </tr>

            <!-- Prix Par -->
            <tr>
              <td
                class="px-6 py-4 text-sm leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                Prix par
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span>{{ a02Tab.B1_De_prijs_is_per[0] }}</span>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span>{{ a02Tab.B2_De_prijs_is_per[0] }}</span>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span>{{ a02Tab.B3_De_prijs_is_per[0] }}</span>
                </div>
              </td>
            </tr>

            <!-- Mitraille / Consignation -->
            <tr>
              <td
                class="px-6 py-4 text-sm leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                Mitraille / Consignation
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div><span>Mitraille / Consignation</span></div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div><span>Mitraille / Consignation</span></div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div><span>Mitraille / Consignation</span></div>
              </td>
            </tr>

            <!-- Popularité -->
            <tr>
              <td
                class="px-6 py-4 text-sm leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                Popularité
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span>{{ a02Tab.B1_Kourantheidskode[0] }}</span>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span>{{ a02Tab.B2_Kourantheidskode[0] }}</span>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div>
                  <span>{{ a02Tab.B3_Kourantheidskode[0] }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="mt-6 flex flex-row space-x-4 justify-center"
      v-if="a02Tab.B1_Artikelnummer"
      v-show="!loadingCommand"
    >
      <button
        class="p-2 bg-blue-500 text-white rounded shadow hover:shadow-lg"
        @click="commanderArticle()"
        v-show="!commandState"
      >
        Commander
      </button>
      <button
        v-show="!commandState"
        class="p-2 bg-gray-500 text-white rounded shadow"
      >
        Annuler
      </button>
    </div>
    <div
      class="mt-6 flex flex-row space-x-4 justify-center items-center"
      v-show="loadingCommand"
    >
      <span>Commande en cours...</span>
      <PulseLoader color="#3b82f6" v-show="true" />
    </div>

    <div class="w-1/2" v-show="commandState">
      <button
        class="flex flex-row items-center cursor-pointer w-54 h-8 bg-amber-400 rounded-full hover:bg-opacity-25 shadow px-2 text-white"
        @click="getInst()"
      >
        <span class="material-icons-round"> arrow_back </span>
        <span class="p-2">Retour</span>
      </button>
    </div>
    <div class="m-auto" v-show="commandState">
      <div class="flex flex-col rounded-md bg-white p-2">
        <div class="flex justify-center space-x-1">
          <span>Commande passée</span>
          <span class="material-icons-outlined text-green-600"> task_alt </span>
        </div>
        <div v-if="a04Tab.Regels">
          <div class="mt-6" v-if="a04Tab.Regels[0].A04Regel">
            <div class="flex justify-center m-auto space-x-1">
              <span class="text-sm">N° client : </span>
              <span
                class="text-sm"
                v-if="a04Tab.Regels[0].A04Regel[0].Klantnummer_bij_leverancier"
                >{{
                  a04Tab.Regels[0].A04Regel[0].Klantnummer_bij_leverancier[0]
                }}</span
              >
            </div>
            <div class="flex justify-center m-auto space-x-1">
              <span class="">N° commande : </span>
              <span
                class="text-xl"
                v-if="a04Tab.Regels[0].A04Regel[0].Partsnet_ordernummer"
                >{{
                  a04Tab.Regels[0].A04Regel[0].Partsnet_ordernummer[0]
                }}</span
              >
            </div>
            <div class="mt-8 flex justify-center">
              <table class="min-w-full" style="max-height: 18vh">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Réf./Descr.
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Livraison
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix brut
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix net
                    </th>
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr>
                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="flex flex-col">
                        <div v-if="a02Tab.B1_Artikelnummer">
                          <span>{{ a02Tab.B1_Artikelnummer[0] }}</span>
                        </div>
                        <div
                          v-if="
                            a04Tab.Regels[0].A04Regel[0].Artikelomschrijving
                          "
                        >
                          <span class="text-gray-700">{{
                            a04Tab.Regels[0].A04Regel[0].Artikelomschrijving[0]
                          }}</span>
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="space-y-1">
                        <div class="flex space-x-1">
                          <span
                            class="material-icons-outlined text-yellow-500 m-auto"
                            style="font-size: 15px"
                            >light_mode</span
                          >
                          <span
                            class="text-sm"
                            v-if="
                              a04Tab.Regels[0].A04Regel[0].LB_Aantal_te_leveren
                            "
                            >{{
                              a04Tab.Regels[0].A04Regel[0]
                                .LB_Aantal_te_leveren[0]
                            }}
                            pcs</span
                          >
                        </div>
                        <div class="flex space-x-1">
                          <span
                            class="material-icons-outlined text-blue-700 m-auto"
                            style="font-size: 15px"
                            >nightlight</span
                          >
                          <span
                            class="text-sm"
                            v-if="
                              a04Tab.Regels[0].A04Regel[0].LA_Aantal_te_leveren
                            "
                            >{{
                              a04Tab.Regels[0].A04Regel[0]
                                .LA_Aantal_te_leveren[0]
                            }}
                            pcs</span
                          >
                        </div>
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="space-y-1 flex flex-col">
                        <span v-if="a04Tab.Regels[0].A04Regel[0].LB_Brutoprijs"
                          >{{
                            a04Tab.Regels[0].A04Regel[0].LB_Brutoprijs[0]
                          }}
                          €</span
                        >
                        <span v-if="a04Tab.Regels[0].A04Regel[0].LA_Brutoprijs"
                          >{{
                            a04Tab.Regels[0].A04Regel[0].LA_Brutoprijs[0]
                          }}
                          €</span
                        >
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="space-y-1 flex flex-col">
                        <span v-if="a04Tab.Regels[0].A04Regel[0].LB_Nettoprijs"
                          >{{
                            a04Tab.Regels[0].A04Regel[0].LB_Nettoprijs[0]
                          }}
                          €</span
                        >
                        <span v-if="a04Tab.Regels[0].A04Regel[0].LA_Nettoprijs"
                          >{{
                            a04Tab.Regels[0].A04Regel[0].LA_Nettoprijs[0]
                          }}
                          €</span
                        >
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      Ref_fournisseur: "",
      Quantity: "",
      // logCode: "9980177",
      errorChamps: false,
      loading: false,
      a02Tab: [],
      a02FullTab: [],
      a04Tab: [],
      loadingCommand: false,
      commandState: false,
    };
  },
  components: {
    PulseLoader,
  },
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  methods: {
    checkArticle() {
      if (this.Ref_fournisseur && this.Quantity) {
        this.commandState = false;
        this.errorChamps = false;
        this.loading = true;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/prepareCommandArticle`,
            {
              Ref_fournisseur: this.Ref_fournisseur,
              Quantity: this.Quantity,
              logCode: this.plateform.grossierID,
              login: this.plateform.grossierID,
            }
          )
          .then((res) => {
            this.loading = false;
            this.a02Tab = res.data.Regels[0].A02Regel[0];
            this.a02FullTab = res.data;
          });
      } else {
        this.errorChamps = true;
      }
    },
    focusNext() {
      this.$refs.qty.focus();
    },
    changeReference(article) {
      if (article != "") {
        this.Ref_fournisseur = article;
        this.loading = true;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/prepareCommandArticle`,
            {
              Ref_fournisseur: article,
              Quantity: this.Quantity,
              logCode: this.plateform.grossierID,
              login: this.plateform.grossierID,
            }
          )
          .then((res) => {
            this.loading = false;
            this.a02Tab = res.data.Regels[0].A02Regel[0];
            this.a02FullTab = res.data;
          });
      } else {
        alert("vide");
      }
    },
    commanderArticle() {
      this.commandState = false;
      this.loadingCommand = true;
      axios
        .post(`${process.env.VUE_APP_API}/commandeFournisseur/commanderA03`, {
          infoCommande: this.a02FullTab,
          plateform: this.user.proprietaire,
          login: this.plateform.grossierID,
        })
        .then((res) => {
          this.loadingCommand = false;
          this.commandState = true;
          this.a04Tab = res.data;
        });
    },
    getInst() {
      window.location.reload();
    },
  },
};
</script>
<style></style>
