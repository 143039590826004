import axios from "axios";

export async function margeBdl(
  plateform: string,
  Ref_fournisseur: string,
  Prefixe_tarif: string,
  Code_EAN: string
) {
  const marge = await axios.post(
    `${process.env.VUE_APP_API}/venteStat/margeBdl`,
    {
      plateform: plateform,
      Ref_fournisseur: Ref_fournisseur,
      Prefixe_tarif: Prefixe_tarif,
      Code_EAN: Code_EAN,
    }
  );

  return marge.data;
}
