<template>
  <div id="body" class="bg-white p-2 rounded-md">
    <div class="flex justify-between">
      <div class="ml-2 flex items-center">
        <h2 class="text-2xl font-medium mr-5">
          Récapitulatif du fournisseur {{ fournisseurTab.CompanyName }}
        </h2>
      </div>
      <router-link to="/Fournisseurs">
        <div class="flex justify-end">
          <span
            style="font-size: 30px"
            class="material-icons-outlined cursor-pointer rounded-full text-red-700 hover:text-red-600"
            >close</span
          >
        </div>
      </router-link>
    </div>
    <div class="flex justify-center mt-8" id="someid">
      <div class="flex flex-col">
        <menuDetailFournisseur />
        <ul class="flex flex-col align-center space-y-20">
          <!-- INFORMATIONS GENERALES -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="showInformationsGenerales = !showInformationsGenerales"
            >
              <a class="text-xl"> Informations générales </a>
              <span
                v-show="!showInformationsGenerales"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showInformationsGenerales"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showInformationsGenerales"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="informationsgenerales"
              >
                <div class="grid grid-cols-4 justify-between">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Base tarif</label>
                    <div
                      class="mt-2 bg-white focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <div class="flex flex-row items-center space-x-2">
                        <span> Golda </span>
                        <label
                          class="relative inline-flex items-center cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            v-model.trim="fournisseurTab.golda"
                            @change="saveChange()"
                            value=""
                            class="sr-only peer"
                            checked
                          />
                          <div
                            class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                          ></div>
                        </label>
                        <span> Groupement </span>
                        <label
                          class="relative inline-flex items-center cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            v-model.trim="fournisseurTab.mere"
                            @change="saveChange()"
                            value=""
                            class="sr-only peer"
                            checked
                          />
                          <div
                            class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label for="typecondition" class="text-xs"
                      >Type de condition</label
                    >

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <select
                        name="typecondition"
                        v-model.trim="fournisseurTab.typeCondition"
                        ref="typecondition"
                        @keydown.enter="this.$refs.nomcommercial.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                      >
                        <option value="prixnet">Prix net</option>
                        <option value="remise">Remise</option>
                        <option value="coef1">Coef 1</option>
                        <option value="coef2">Coef 2 (sur le ppc)</option>
                      </select>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Gestion Prix d'Achat</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <select
                        name="typecondition"
                        v-model.trim="fournisseurTab.prixachatChoix"
                        ref="typecondition"
                        @keydown.enter="this.$refs.nomcommercial.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                      >
                        <option value="condition">Condition</option>
                        <option value="last">Dernier Prix d'achat</option>
                        <option value="pmp">Prix Moyen Pondéré</option>
                      </select>
                    </div>
                  </div>

                  <div
                    class="w-full px-5 py-2 text-gray-700 bg-gray-200 border-b"
                  >
                    <label class="text-xs">Nom</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.CompanyName"
                        type="text"
                        ref="CompanyName"
                        @keydown.enter="this.$refs.Number.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-4">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Statut juridique</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.statutjuridique"
                        type="text"
                        ref="statutjuridique"
                        @keydown.enter="this.$refs.CompanyName.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Numéro de compte</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.Number"
                        type="text"
                        ref="Number"
                        @keydown.enter="this.$refs.Prefix.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Préfixe</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.Prefix"
                        type="text"
                        ref="Prefix"
                        @keydown.enter="this.$refs.Prefix_tarif.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Préfixe tarif</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.Prefixe_tarif"
                        type="text"
                        ref="Prefix_tarif"
                        @keydown.enter="this.$refs.Adresse.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Nom de dépôt</label>

                  <div
                    class="relative mt-2 rounded-md shadow-sm"
                    v-if="fournisseurTab.depot_name"
                  >
                    <input
                      type="textfield"
                      v-model.trim="fournisseurTab.depot_name"
                      ref="Adresse"
                      @keydown.enter="this.$refs.Complement.focus()"
                      @change="saveChange()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Adresse</label>

                  <div
                    class="relative mt-2 rounded-md shadow-sm"
                    v-if="fournisseurTab.Address"
                  >
                    <input
                      type="textfield"
                      v-model.trim="fournisseurTab.Address.Name"
                      ref="Adresse"
                      @keydown.enter="this.$refs.Complement.focus()"
                      @change="saveChange()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Complément d'adresse</label>

                  <div
                    class="relative mt-2 rounded-md shadow-sm"
                    v-if="fournisseurTab.Address"
                  >
                    <input
                      type="textfield"
                      v-model.trim="fournisseurTab.Address.Complement"
                      ref="Complement"
                      @keydown.enter="this.$refs.PostalCode.focus()"
                      @change="saveChange()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="grid grid-cols-3">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Code Postal</label>

                    <div
                      class="relative mt-2 rounded-md shadow-sm"
                      v-if="fournisseurTab.Address"
                    >
                      <input
                        type="textfield"
                        v-model.trim="fournisseurTab.Address.PostalCode"
                        ref="PostalCode"
                        @keydown.enter="this.$refs.City.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Ville</label>

                    <div
                      class="relative mt-2 rounded-md shadow-sm"
                      v-if="fournisseurTab.Address"
                    >
                      <input
                        type="textfield"
                        v-model.trim="fournisseurTab.Address.City"
                        ref="City"
                        @keydown.enter="this.$refs.Country.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label for="Country" class="text-xs">Pays</label>

                    <div
                      class="relative mt-2 rounded-md shadow-sm"
                      v-if="fournisseurTab.Address"
                    >
                      <select
                        name="Country"
                        v-model.trim="fournisseurTab.Address.Country"
                        ref="Country"
                        @keydown.enter="this.$refs.telephone.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                      >
                        <option value="ALLEMAGNE">ALLEMAGNE</option>
                        <option value="AUTRICHE">AUTRICHE</option>
                        <option value="BELGIQUE">BELGIQUE</option>
                        <option value="ESPAGNE">ESPAGNE</option>
                        <option selected value="FRANCE">FRANCE</option>
                        <option value="ITALIE">ITALIE</option>
                        <option value="LUXEMBOURG">LUXEMBOURG</option>
                        <option value="MAROC">MAROC</option>
                        <option value="PAYS-BAS">PAYS-BAS</option>
                        <option value="SUISSE">SUISSE</option>
                        <option value="TURQUIE">TURQUIE</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-3">
                  <div
                    class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    v-if="fournisseurTab.Address"
                  >
                    <label class="text-xs">Tél n°1</label>

                    <div
                      class="relative mt-2 rounded-md shadow-sm"
                      v-if="fournisseurTab.Address.ContactDetail"
                    >
                      <input
                        type="tel"
                        v-model.trim="
                          fournisseurTab.Address.ContactDetail.Phone
                        "
                        ref="telephone"
                        @keydown.enter="this.$refs.telephone2.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Tél n°2</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.telephone2"
                        type="text"
                        ref="telephone2"
                        @keydown.enter="this.$refs.fax.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div
                    class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                    v-if="fournisseurTab.Address"
                  >
                    <label class="text-xs">Fax</label>

                    <div
                      class="relative mt-2 rounded-md shadow-sm"
                      v-if="fournisseurTab.Address.ContactDetail"
                    >
                      <input
                        v-model.trim="fournisseurTab.Address.ContactDetail.Fax"
                        type="text"
                        ref="fax"
                        @keydown.enter="this.$refs.email.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="px-5 py-2 text-gray-700 bg-gray-200 border-b"
                  v-if="fournisseurTab.Address"
                >
                  <label class="text-xs">Email</label>

                  <div
                    class="relative mt-2 rounded-md shadow-sm"
                    v-if="fournisseurTab.Address.ContactDetail"
                  >
                    <input
                      type="email"
                      v-model.trim="fournisseurTab.Address.ContactDetail.Email"
                      ref="email"
                      @keydown.enter="this.$refs.siret.focus()"
                      @change="saveChange()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="grid grid-cols-3">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Siret</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.siret"
                        type="text"
                        ref="siret"
                        @keydown.enter="this.$refs.nafape.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">NAF-APE</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.nafape"
                        type="text"
                        ref="nafape"
                        @keydown.enter="this.$refs.numerotva.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Numéro T.V.A.</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.numerotva"
                        type="text"
                        ref="numerotva"
                        @keydown.enter="this.$refs.rcsrm.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div class="grid grid-cols-3">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">RCS-RM</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.rcsrm"
                        type="text"
                        ref="rcsrm"
                        @keydown.enter="this.$refs.typecondition.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label for="typeArticle" class="text-xs"
                      >Type d'articles</label
                    >

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <select
                        name="typeArticle"
                        v-model.trim="fournisseurTab.typeArticle"
                        ref="typeArticle"
                        @keydown.enter="this.$refs.nomcommercial.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                      >
                        <option value="pieces">pieces</option>
                        <option value="pneu">pneu</option>
                        <option value="all">pieces et pneu</option>
                      </select>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label for="typecondition" class="text-xs"
                      >Priorité :</label
                    >

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="Number"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        v-model.trim="fournisseurTab.priority"
                        @change="saveChange()"
                      />
                    </div>
                  </div>
                </div>

                <h5 class="px-5 py-2 text-gray-700 bg-gray-200 underline">
                  Commercial
                </h5>
                <div
                  class="grid grid-cols-3 justify-between"
                  style="margin-top: -10px"
                >
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Nom/Prénom du commercial</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        v-model.trim="fournisseurTab.nomcommercial"
                        ref="nomcommercial"
                        @keydown.enter="this.$refs.telcommercial.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Tél. commercial</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        v-model.trim="fournisseurTab.telcommercial"
                        ref="telcommercial"
                        @keydown.enter="this.$refs.emailcommercial.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Email commercial</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        v-model.trim="fournisseurTab.emailcommercial"
                        ref="emailcommercial"
                        @keydown.enter="
                          (showAccesFournisseur = true),
                            (showInformationsGenerales = false)
                        "
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <!-- ACCES FOURNISSEUR -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="showAccesFournisseur = !showAccesFournisseur"
            >
              <a class="text-xl"> Accès fournisseur </a>
              <span
                v-show="!showAccesFournisseur"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showAccesFournisseur"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showAccesFournisseur"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="accesfournisseur"
              >
                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Type de commande :</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <select
                      v-model.trim="fournisseurTab.typeCommande"
                      name="typeCommande"
                      type="text"
                      ref="typeCommande"
                      @keydown.enter="this.$refs.codefournisseur.focus()"
                      @change="saveChange()"
                      class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <option value="teccom">teccom</option>
                      <option value="direct">direct</option>
                    </select>
                  </div>
                </div>
                <div class="grid grid-cols-2" v-if="fournisseurTab.credentialTecCom">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Code fournisseur TecCom</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="
                          fournisseurTab.credentialTecCom.SupplierNumber
                        "
                        type="text"
                        ref="codefournisseur"
                        @keydown.enter="this.$refs.fournissidentifiant.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Code client TecCom</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="
                          fournisseurTab.credentialTecCom.BuyerNumber
                        "
                        type="text"
                        ref="fournissidentifiant"
                        @keydown.enter="this.$refs.fournissmotdepasse.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="grid grid-cols-3"
                  v-if="fournisseurTab.Credential && fournisseurTab.Credential[0]"
                >
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Code fournisseur</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.Credential[0].code"
                        type="text"
                        ref="codefournisseur"
                        @keydown.enter="this.$refs.fournissidentifiant.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Identifiant</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.Credential[0].login"
                        type="text"
                        ref="fournissidentifiant"
                        @keydown.enter="this.$refs.fournissmotdepasse.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Mot de passe</label>

                    <div class="flex relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.Credential[0].password"
                        :type="Typepassword"
                        ref="fournissmotdepasse"
                        @keydown.enter="
                          (showAccesFournisseur = false), (showTransport = true)
                        "
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                      <div class="block flex justify-end text-gray-600 m-auto">
                        <span
                          v-show="passShow"
                          class="cursor-pointer material-icons-round"
                          @click="
                            (Typepassword = 'text'), (passShow = !passShow)
                          "
                          >visibility</span
                        >
                        <span
                          v-show="!passShow"
                          class="cursor-pointer material-icons-round"
                          @click="
                            (Typepassword = 'password'), (passShow = !passShow)
                          "
                          >visibility_off</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <!-- TRANSPORT -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="showTransport = !showTransport"
            >
              <a class="text-xl"> Transport </a>
              <span
                v-show="!showTransport"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showTransport"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showTransport"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="transport"
              >
                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Livré par transporteur ?</label>

                  <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                    <div
                      class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model.trim="fournisseurTab.livrepartransporteur"
                        id="livrepartransporteur"
                        name="livrepartransporteur"
                        value="true"
                        @change="saveChange()"
                      />
                      <label class="pl-2 checkbox">Oui</label>
                    </div>

                    <div
                      class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model.trim="fournisseurTab.livrepartransporteur"
                        id="livrepartransporteur"
                        name="livrepartransporteur"
                        value="false"
                        @change="saveChange()"
                      />
                      <label class="pl-2 checkbox">Non</label>
                    </div>
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Frais de port</label>

                  <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                    <div
                      class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model.trim="fournisseurTab.fraisdeportchoix"
                        id="fraisdeportchoix"
                        name="fraisdeportchoix"
                        value="true"
                        @change="saveChange()"
                      />
                      <label class="pl-2 checkbox">Oui</label>
                    </div>

                    <div
                      class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model.trim="fournisseurTab.fraisdeportchoix"
                        id="fraisdeportchoix"
                        name="fraisdeportchoix"
                        value="false"
                        @change="saveChange()"
                      />
                      <label class="pl-2 checkbox">Non</label>
                    </div>
                  </div>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model.trim="fournisseurTab.fraisdeport"
                      type="text"
                      ref="fraisdeport"
                      @keydown.enter="this.$refs.francodeport.focus()"
                      @change="saveChange()"
                      class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Frais de port Client</label>

                  <div class="flex relative mt-2 rounded-md shadow-sm bg-white">
                    <div
                      class="pl-4 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model.trim="fournisseurTab.fraisdeportclientstate"
                        id="fraisdeportclientstate"
                        name="fraisdeportchoix"
                        value="true"
                        @change="saveChange()"
                      />
                      <label class="pl-2 checkbox">Oui</label>
                    </div>

                    <div
                      class="pl-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    >
                      <input
                        type="radio"
                        v-model.trim="fournisseurTab.fraisdeportclientstate"
                        id="fraisdeportclientstate"
                        name="fraisdeportchoix"
                        value="false"
                        @change="saveChange()"
                      />
                      <label class="pl-2 checkbox">Non</label>
                    </div>
                  </div>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model.trim="fournisseurTab.fraisdeportclient"
                      type="Number"
                      ref="fraisdeport"
                      @keydown.enter="this.$refs.francodeport.focus()"
                      @change="saveChange()"
                      class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                  <label class="text-xs">Franco de port</label>

                  <div class="relative mt-2 rounded-md shadow-sm">
                    <input
                      v-model.trim="fournisseurTab.francodeport"
                      type="text"
                      ref="francodeport"
                      @keydown.enter="this.$refs.transporteur.focus()"
                      @change="saveChange()"
                      class="w-full focus:outline-2 outline-sky-300 p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                    />
                  </div>
                </div>

                <div class="grid grid-cols-2">
                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Transporteur</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.transporteur"
                        type="text"
                        ref="transporteur"
                        @keydown.enter="this.$refs.schematransport.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Schéma de transport</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.schematransport"
                        type="text"
                        ref="schematransport"
                        @keydown.enter="
                          (showTransport = false), (showFinance = true)
                        "
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <!-- FINANCE -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="showFinance = !showFinance"
            >
              <a class="text-xl"> Finance </a>
              <span
                v-show="!showFinance"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showFinance"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showFinance"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="finance"
              >
                <div class="container">
                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">IBAN</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="fournisseurTab.iban"
                          type="text"
                          ref="iban"
                          @keydown.enter="this.$refs.swift.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">SWIFT/BIC</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <input
                          v-model.trim="fournisseurTab.swift"
                          type="text"
                          ref="swift"
                          @keydown.enter="this.$refs.moyendepaiement.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-2">
                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Moyen de paiement</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="fournisseurTab.moyendepaiement"
                          ref="moyendepaiement"
                          @keydown.enter="this.$refs.delaidepaiement.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                        >
                          <option value="LCR">LCR</option>
                          <option value="Virement">Virement</option>
                          <option value="Chèque">Chèque</option>
                          <option value="CB">CB</option>
                          <option value="Espèce">Espèce</option>
                        </select>
                      </div>
                    </div>

                    <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                      <label class="text-xs">Délai de paiement</label>

                      <div class="relative mt-2 rounded-md shadow-sm">
                        <select
                          v-model.trim="fournisseurTab.delaidepaiement"
                          ref="delaidepaiement"
                          @keydown.enter="this.$refs.nomdelabanque.focus()"
                          @change="saveChange()"
                          class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md"
                        >
                          <option value="IMMEDIAT">IMMEDIAT</option>
                          <option value="30 JOURS FIN DE MOIS">
                            30 JOURS FIN DE MOIS
                          </option>
                          <option value="45 JOURS FIN DE MOIS">
                            45 JOURS FIN DE MOIS
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">Nom de la banque</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.nomdelabanque"
                        type="text"
                        ref="nomdelabanque"
                        @keydown.enter="this.$refs.ncomptecomptable.focus()"
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div class="px-5 py-2 text-gray-700 bg-gray-200 border-b">
                    <label class="text-xs">N° compte comptable</label>

                    <div class="relative mt-2 rounded-md shadow-sm">
                      <input
                        v-model.trim="fournisseurTab.ncomptecomptable"
                        type="text"
                        ref="ncomptecomptable"
                        @keydown.enter="
                          (showFinance = false), (showPersonnel = true)
                        "
                        @change="saveChange()"
                        class="focus:outline-2 outline-sky-300 w-full p-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>

          <!-- PERSONNEL -->
          <li
            style="width: 80rem"
            class="bg-slate-50 px-8 py-4 border rounded-md border-slate-200 shadow-lg cursor-pointer hover:bg-white hover:shadow-2xl"
          >
            <div
              class="flex justify-between hover:text-blue-400"
              @click="showPersonnel = !showPersonnel"
            >
              <a class="text-xl"> Personnel </a>
              <span
                v-show="!showPersonnel"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_more
              </span>
              <span
                v-show="showPersonnel"
                class="material-icons-outlined inset-y-0 right-0 flex items-center"
              >
                expand_less
              </span>
            </div>
            <transition>
              <div
                v-show="showPersonnel"
                class="border-b border-gray-500 bloc-couleur couleur-remplissage"
                id="personnel"
              >
                <div class="flex flex-col mt-8">
                  <div
                    class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                  >
                    <div
                      class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
                    >
                      <table class="min-w-full">
                        <thead>
                          <tr>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              <button class="">
                                <span
                                  class="w-6 h-6 material-icons-round rounded-full"
                                  style="color: orange"
                                >
                                  add_circle_outline
                                </span>
                              </button>
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Poste/service
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Prénom
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Nom
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Tél n°1
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Tél n°2
                            </th>
                            <th
                              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                            >
                              Email
                            </th>
                          </tr>
                        </thead>

                        <tbody class="bg-white">
                          <tr>
                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            ></td>

                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="fournisseurTab.personnelposte"
                                ref="personnelposte"
                                @keydown.enter="
                                  this.$refs.personnelprenom.focus()
                                "
                                @change="saveChange()"
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="fournisseurTab.personnelprenom"
                                ref="personnelprenom"
                                @keydown.enter="this.$refs.personnelnom.focus()"
                                @change="saveChange()"
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="fournisseurTab.personnelnom"
                                ref="personnelnom"
                                @keydown.enter="
                                  this.$refs.personneltel1.focus()
                                "
                                @change="saveChange()"
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="fournisseurTab.personneltel1"
                                ref="personneltel1"
                                @keydown.enter="
                                  this.$refs.personneltel2.focus()
                                "
                                @change="saveChange()"
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="fournisseurTab.personneltel2"
                                ref="personneltel2"
                                @keydown.enter="
                                  this.$refs.personnelemail.focus()
                                "
                                @change="saveChange()"
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                              />
                            </td>

                            <td
                              class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                            >
                              <input
                                v-model.trim="fournisseurTab.personnelemail"
                                ref="personnelemail"
                                class="focus:outline-2 outline-sky-300 rounded-sm border text-gray-800"
                                @change="saveChange()"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>
        </ul>
        <button
          class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 w-fit"
          @click="deleteFournisseur(fournisseurTab._id)"
        >
          supprimer fournisseur
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import menuDetailFournisseur from "../components/Fournisseur/menuDetailFournisseur.vue";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";

export default {
  data() {
    return {
      showInformationsGenerales: true,
      showAccesFournisseur: true,
      showTransport: true,
      showFinance: true,
      showPersonnel: true,

      Typepassword: "password",
      passShow: true,

      fournisseurTab: {},
    };
  },
  computed: {
    ...mapGetters(["fournisseurID", "user"]),
  },
  components: {
    menuDetailFournisseur,
  },
  methods: {
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
      console.log(this.fournisseurTab);
    },
    saveChange() {
      axios.put(
        `${process.env.VUE_APP_API}/fournisseur/modifyFournisseur/${this.fournisseurID}`,
        {
          data: this.fournisseurTab,
        }
      );
    },
    deleteFournisseur(id) {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/deleteFournisseur`, {
          id: id,
        })
        .then((res) => {
          this.$router.push("/fournisseurs");
        });
    },

    // disabledAllInput() {
    //   let elems = document
    //     .getElementById("someid")
    //     .getElementsByTagName("input", "select");

    //   for (let i = 0; i < elems.length; i++) {
    //     elems[i].disabled = true;
    //   }
    // },
    // enabledAllInput() {
    //   let elems = document
    //     .getElementById("someid")
    //     .getElementsByTagName("input", "select");

    //   for (let i = 0; i < elems.length; i++) {
    //     elems[i].disabled = false;
    //   }
    // },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  async mounted() {
    await this.getFournisseur();
  },
};
</script>

<style></style>
