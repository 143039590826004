<template>
  <div>
    <div v-for="(gp, indexGP) in configurationCatalogue" :key="indexGP">
      <!-- <div class="text-lg text-bluevk">{{ gp.name }}</div> -->
      <div
        class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 gap-3"
      >
        <div v-for="(famille, indexF) in gp.famille" :key="indexF">
          <div
            class="p-2 rounded shadow bg-white w-80 h-fit ml-4 mt-4"
            v-if="famille.actif"
          >
            <div class="flex flex-col">
              <div class="flex flex-row items-center">
                <div class="w-4/5">
                  <span
                    class="text-xl text-gray-600 hover:text-blue-600 hover:font-semibold cursor-pointer"
                    @click="
                      showSousFamille[indexGP][indexF] =
                        !showSousFamille[indexGP][indexF]
                    "
                    >{{ famille.name }}</span
                  >
                </div>
                <span class="w-1/5">
                  <img
                    @click="
                      showSousFamille[indexGP][indexF] =
                        !showSousFamille[indexGP][indexF]
                    "
                    class="h-12 w-17 cursor-pointer"
                    v-if="famille.stateLogo"
                    :src="famille.logo_url"
                  />
                </span>
              </div>

              <div
                class="flex flex-row items-center"
                v-show="!showSousFamille[indexGP][indexF]"
              >
                <span
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  @click="
                    showSousFamille[indexGP][indexF] =
                      !showSousFamille[indexGP][indexF]
                  "
                  >keyboard_double_arrow_down</span
                >
              </div>

              <div
                class="flex flex-row"
                v-show="showSousFamille[indexGP][indexF]"
                v-for="(sousFamille, indexSF) in famille.sousFamille"
                :key="indexSF"
              >
                <span
                  class="flex flex-row items-center hover:text-blue-400 hover:font-semibold"
                  v-if="sousFamille.actif"
                >
                  <span class="material-icons-round"> arrow_right </span>
                  <div>
                    <span
                      @click="getSousFamilleArticle(sousFamille)"
                      class="text-xs cursor-pointer"
                      >{{ sousFamille.name }}</span
                    >
                  </div>
                </span>
              </div>
              <div
                class="flex flex-row items-center"
                v-show="showSousFamille[indexGP][indexF]"
              >
                <span
                  class="text-sm text-blue-400 hover:text-blue-600 cursor-pointer"
                  @click="
                    showSousFamille[indexGP][indexF] =
                      !showSousFamille[indexGP][indexF]
                  "
                  >Voir moins</span
                >
                <span
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  @click="
                    showSousFamille[indexGP][indexF] =
                      !showSousFamille[indexGP][indexF]
                  "
                >
                  keyboard_double_arrow_up
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 flex justify-center">
      <img src="@/assets/tecdoc_logo.jpg" class="" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      showSousFamille: [[], [], [], [], [], [], [], [], [], [], [], [], [], []],
    };
  },
  props: {
    configurationCatalogue: {
      type: Array,
    },
    carId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setfamilleselected"]),
    async getArticles(famille) {
      this.setfamilleselected({ famille: famille, carId: this.carId });
      this.$router.push("/catalogueTecDocNew");
    },
    async getSousFamilleArticle(famille) {
      this.setfamilleselected({ famille: famille, carId: this.carId });
      this.$router.push("/catalogueTecDocSousFamille");
    },
  },
};
</script>
<style></style>
