<template>

  <div class="w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700" 
    >
    <div
      v-if="pourcentageStatus == 10"
      :class="['text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full h-4', isRed ? 'bg-red-600 text-red-100' : 'bg-orange-600 text-orange-100']"
      :style="'width:' + pourcentageStatus + '%'"
    >
    {{ pourcentageStatus }}%
    </div>  
    <div
      v-if="pourcentageStatus == 25"
      :class="['text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full h-4', isRed ? 'bg-red-600 text-red-100' : 'bg-amber-500 text-yellow-100']"
      :style="'width:' + pourcentageStatus + '%'"
    >
    {{ pourcentageStatus }}%
    </div> 
    <div
      v-if="pourcentageStatus == 75"
      :class="['text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full h-4', isRed ? 'bg-red-600 text-red-100' : 'bg-yellow-400 text-yellow-100']"
      :style="'width:' + pourcentageStatus + '%'"
    >
    {{ pourcentageStatus }}%
    </div> 
    <div
      v-if="pourcentageStatus == 100"
      :class="['text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full h-4', isRed ? 'bg-red-600 text-red-100' : 'bg-green-500 text-green-100']"
      :style="'width:' + pourcentageStatus + '%'"
    >
    {{ pourcentageStatus }}%
    </div> 
  </div>

</template>

<script>
export default {
  name: "progressBar",
  props: {
    pourcentageStatus: {
      type: Number,
      required: true,
    },
    isRed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
.bg-red-600 {
  background-color: #ef4444;
}
.text-red-100 {
  color: #fde8e8;
}</style>
