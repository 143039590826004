<template>
  <div class="flex justify-between mx-auto px-5">
    <div class="flex justify-between w-fit mx-auto px-5">
      <div class="inline-flex flex-col items-center">
        <button
          class="w-14 shadow-lg h-14 bg-bluevk border-2 border-bluevk rounded-full flex items-center justify-center pb-1 mb-2"
          v-if="state == 'identification'"
        >
          <img
            src="@/assets/VehiculeAnimation/identification-blc.png"
            alt="picto identification"
          />
        </button>
        <button
          @click="takeStep(0)"
          class="w-14 shadow-lg h-14 bg-white hover:bg-blue-50 border-2 border-bluevk rounded-full flex items-center justify-center pb-1 mb-2"
          v-else
        >
          <img
            src="@/assets/VehiculeAnimation/identification-bleu+orange.png"
            alt="picto identification"
          />
        </button>
        <p class="text-bluevk mt-2 text-center text-sm align-middle">
          <span class="font-bold">Identification </span>
        </p>
      </div>

      <div class="mt-8">
        <div v-if="indexState > 0" class="w-16 h-0.5 bg-orange-600"></div>
        <div v-else class="w-16 h-0.5 bg-gray-500"></div>
      </div>

      <div class="inline-flex flex-col items-center">
        <button
          class="w-14 shadow-lg h-14 border-2 border-bluevk bg-bluevk rounded-full flex items-center justify-center p-1 mb-2"
          v-if="state == 'verification'"
        >
          <img
            src="@/assets/VehiculeAnimation/Checkup-blc.png"
            alt="picto Vérification"
          />
        </button>
        <button
          @click="takeStep(1)"
          class="w-14 shadow-lg h-14 border-2 hover:bg-blue-50 border-bluevk bg-white rounded-full flex items-center justify-center p-1 mb-2"
          v-else
        >
          <img
            src="@/assets/VehiculeAnimation/Checkup-bleu+orange.png"
            alt="picto Vérification"
          />
        </button>
        <p class="text-bluevk mt-2 text-center text-sm align-middle">
          <span class="font-bold">Vérification </span>
        </p>
      </div>

      <div class="mt-8">
        <div v-if="indexState > 1" class="w-16 h-0.5 bg-orange-600"></div>
        <div v-else class="w-16 h-0.5 bg-gray-500"></div>
      </div>

      <div class="inline-flex flex-col items-center">
        <button
          class="w-14 shadow-lg h-14 border-2 border-bluevk bg-bluevk rounded-full flex items-center justify-center mb-2 pb-1"
          v-if="state == 'commande'"
        >
          <img
            src="@/assets/VehiculeAnimation/Commandes-blc.png"
            alt="picto Commandes"
          />
        </button>
        <button
          @click="takeStep(2)"
          class="w-14 shadow-lg h-14 border-2 hover:bg-blue-50 border-bluevk bg-white rounded-full flex items-center justify-center mb-2 pb-1"
          v-else
        >
          <img
            src="@/assets/VehiculeAnimation/Commandes-bleu-orange.png"
            alt="picto Commandes"
          />
        </button>
        <p class="text-bluevk mt-2 text-center text-sm align-middle">
          <span class="font-bold">Panier</span>
        </p>
      </div>

      <div class="mt-8">
        <div v-if="indexState > 2" class="w-16 h-0.5 bg-orange-600"></div>
        <div v-else class="w-16 h-0.5 bg-gray-500"></div>
      </div>

      <div class="inline-flex flex-col items-center">
        <button
          class="w-14 shadow-lg h-14 border-2 border-bluevk bg-bluevk rounded-full flex items-center justify-center mb-2"
          v-if="state == 'calendrier'"
        >
          <img
            src="@/assets/VehiculeAnimation/Calendrier-blc.png"
            alt="picto Calendrier"
          />
        </button>
        <button
          @click="takeStep(3)"
          class="w-14 shadow-lg h-14 border-2 hover:bg-blue-50 border-bluevk bg-white rounded-full flex items-center justify-center mb-2"
          v-else
        >
          <img
            src="@/assets/VehiculeAnimation/Calendrier-bleu-orange.png"
            alt="picto Calendrier"
          />
        </button>
        <p class="text-bluevk mt-2 text-center text-sm align-middle">
          <span class="font-bold">Calendrier</span>
        </p>
      </div>

      <div class="mt-8">
        <div v-if="indexState > 3" class="w-16 h-0.5 bg-orange-600"></div>
        <div v-else class="w-16 h-0.5 bg-gray-500"></div>
      </div>

      <div class="inline-flex flex-col items-center">
        <button
          class="w-14 shadow-lg h-14 border-2 border-bluevk bg-bluevk rounded-full flex items-center justify-center mb-2"
          v-if="state == 'reparation'"
        >
          <img
            src="@/assets/VehiculeAnimation/réparation-blc.png"
            alt="picto Réparation"
          />
        </button>
        <button
          @click="takeStep(4)"
          class="w-14 shadow-lg h-14 border-2 hover:bg-blue-50 border-bluevk bg-white rounded-full flex items-center justify-center mb-2"
          v-else
        >
          <img
            src="@/assets/VehiculeAnimation/réparation-bleu+orange.png"
            alt="picto Réparation"
          />
        </button>
        <p class="text-bluevk mt-2 text-center text-sm align-middle">
          <span class="font-bold">Réparation </span>
        </p>
      </div>

      <div class="mt-8">
        <div v-if="indexState > 4" class="w-16 h-0.5 bg-orange-600"></div>
        <div v-else class="w-16 h-0.5 bg-gray-500"></div>
      </div>

      <div class="inline-flex flex-col items-center">
        <button
          class="w-14 shadow-lg h-14 border-2 border-bluevk bg-bluevk rounded-full flex items-center justify-center 2 mb-2"
          v-if="state == 'validation'"
        >
          <img
            src="@/assets/VehiculeAnimation/Validation-blc.png"
            alt="picto Validation"
          />
        </button>
        <button
          @click="takeStep(5)"
          class="w-14 shadow-lg h-14 border-2 hover:bg-blue-50 border-bluevk bg-white rounded-full flex items-center justify-center 2 mb-2"
          v-else
        >
          <img
            src="@/assets/VehiculeAnimation/Validation-bleu+orange.png"
            alt="picto Validation"
          />
        </button>
        <p class="text-bluevk mt-2 text-center text-sm align-middle">
          <span class="font-bold">Validation </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { saveIndex } from "@/hooks/controlePreliminaire/controlePreliminaire.ts";
export default {
  props: {
    state: String,
    vehiculePosition: String,
    indexState: Number,
    tabState: Array,
    cpTab: Object,
  },
  methods: {
    async takeStep(index) {
      await saveIndex(this.cpTab._id, index);
      this.$emit("setState", index);
    },
  },
};
</script>
