import axios from "axios";

export async function sendCommandDSV(
  plateform: string,
  articles: Array<object>,
  user: string
) {
  const prepareCommande = await axios.post(
    `${process.env.VUE_APP_API}/dasilva/sendCommand`,
    {
      plateform: plateform,
      articles: articles,
      user: user,
    }
  );
  return prepareCommande.data;
}
