<template>
  <div class="flex flex-col space-y-4 w-full p-2">
    <div v-if="loadingBrandData" class="animate-pulse">
      Chargement des autres marques en cours...
    </div>
    <div
      v-for="(bd, indexBD) in brandData"
      :key="indexBD"
      class="flex flex-col"
    >
      <div
        class="flex flex-row items-center justify-between bg-blue-500 text-white w-full p-2 rounded-t"
      >
        <div>{{ bd.brandName }}</div>
        <div
          v-if="
            !loadingChangeBrand[indexBD] && user.accountType == 'Administrateur'
          "
        >
          <button
            class="border border-white rounded p-1 hover:bg-white hover:text-blue-500 transition-all"
            v-if="!bd.addState"
            @click="changeBrand(bd.brandName, indexBD)"
          >
            Ajouter au catalogue
          </button>
          <div
            v-else
            class="flex flex-row items-center space-x-1 p-1 bg-white rounded text-blue-500"
          >
            <span>Ajouté</span>
            <span class="material-icons-round"> check </span>
          </div>
        </div>
        <div v-else-if="user.accountType == 'Administrateur'">
          <div
            class="flex flex-row items-center space-x-1 p-1 bg-white rounded text-blue-500"
          >
            <span>Ajout</span>
            <span class="material-icons-round animate-spin"> cloud_sync </span>
          </div>
        </div>
      </div>
      <div
        v-for="(article, indexA) in bd.data"
        :key="indexA"
        class="flex flex-col w-full"
      >
        <article-catalogue
          v-if="article.type == 'db'"
          :article="article.dbArticle"
          :Ref_fournisseur="article.dbArticle.Ref_fournisseur"
          :Code_marque="article.dbArticle.Code_marque"
          :Fournisseur="article.dbArticle.Prefixe_tarif"
          :genericArticleId="
            article.tdArticle.genericArticles[0].genericArticleId
          "
          :Description="article.dbArticle.Description"
          :partsList="article.partsList"
          :Images="article.image"
          :Location="[]"
          :Systeme="[]"
          :Stock="article.stock"
          :Prix="article.prix"
          :quantity="article.quantity"
          :partsListTab="localpartsListTab"
        />
        <catalogueTD
          v-else-if="article.type == 'td'"
          :tdArticle="article.tdArticle"
          :Ref_fournisseur="article.tdArticle.articleNumber"
          :Code_marque="article.tdArticle.mfrName"
          :genericArticleId="
            article.tdArticle.genericArticles[0].genericArticleId
          "
          :Description="
            article.tdArticle.genericArticles[0].genericArticleDescription
          "
          :Images="article.tdArticle.images"
          :Location="[]"
          :Systeme="[]"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import articleCatalogue from "@/components/Article/articleCatalogue.vue";
import catalogueTD from "@/components/Article/catalogueTD.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      brandData: [],
      loadingBrandData: false,
      reloadkey: 0,
      loadingChangeBrand: [],
    };
  },
  components: {
    articleCatalogue,
    catalogueTD,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  props: {
    sousFamille: {
      type: Object,
      required: true,
    },
    genArtList: {
      type: Array,
      required: true,
    },
    carId: {
      type: Number,
      required: true,
    },
    brandList: {
      type: Array,
      required: true,
    },
    plateform: {
      type: String,
      required: true,
    },
  },
  methods: {
    async changeBrand(brand, index) {
      this.loadingChangeBrand[index] = true;
      const updatedSF = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: this.sousFamille._id,
          brandName: brand,
          type: "primary",
        }
      );
      this.brandData[index].addState = true;
      this.loadingChangeBrand[index] = false;
    },
  },
  async mounted() {
    this.loadingBrandData = true;
    const catSousFamilleProposition = await axios.post(
      `${process.env.VUE_APP_API}/searchEngine/catSousFamilleProposition`,
      {
        carId: this.carId,
        brandList: this.brandList,
        plateform: this.plateform,
        genArtList: this.genArtList,
      }
    );
    this.brandData = catSousFamilleProposition.data;
    this.loadingBrandData = false;
  },
};
</script>
<style></style>
