<template>
  <div class="flex flex-col space-y-2 h-80 md:h-85 w-full">
    <div
      v-if="openModalArticle"
      :class="`modal ${
        !openModalArticle && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="openModalArticle = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 68vw; max-height: 80vh"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 pt-4 text-left modal-content">
          <div class="flex items-center justify-end pb-3">
            <!-- <p class="text-2xl font-bold">Titre</p> -->
            <div
              class="z-50 cursor-pointer modal-close"
              @click="openModalArticle = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <article-info :key="articleInfo_componentKey" />
        </div>
      </div>
    </div>
    <div
      v-if="showCompareModal"
      :class="`modal ${
        !showCompareModal && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showCompareModal = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
        style="max-width: 72rem; max-height: 52rem"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <div class="flex items-center justify-end pb-3">
            <!-- <p class="text-2xl font-bold">Titre</p> -->
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showCompareModal = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="w-full">
            <compareStructure :compareTab="compareTab" />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <!-- <back /> -->
      <div class="md:hidden">
        <div
          class="w-full bg-white p-1 rounded-t shadow cursor-pointer"
          @click="showFiltre = !showFiltre"
        >
          Filtres
        </div>
        <div
          v-show="showFiltre"
          class="lg:flex lg:flex-row lg:space-x-2 lg:items-center grid grid-cols-2 gap-1 md:grid-cols-4 md:gap-2 p-2 bg-white rounded-b shadow"
        >
          <check text="Promotions" @updateCheckbox="filterPromotion" />
          <check text="Stock" @updateCheckbox="filterStock" />
          <filterButton
            text="Marque"
            @manageFilter="filterMarque"
            :filter_list="response.Marque"
          />
          <filterButton
            text="Famille"
            @manageFilter="filterFamille"
            :filter_list="response.Famille"
          />
          <filterButton
            text="Systeme"
            @manageFilter="filterSysteme"
            :filter_list="response.Systeme"
          />
          <filterButton
            text="Emplacement"
            @manageFilter="filterLocation"
            :filter_list="response.Location"
          />
          <filterButton
            text="Prix"
            @manageFilter="filterPrice"
            :filter_list="['Croissant', 'Decroissant']"
          />
          <compareButton
            class="hidden md:flex"
            v-if="compareTab.length >= 2"
            @click="compareArticles()"
          />
        </div>
      </div>
      <div
        class="lg:flex lg:flex-row lg:space-x-2 lg:items-center hidden md:grid md:grid-cols-4 md:gap-2 p-2 bg-white rounded shadow"
      >
        <compareButton
          class="hidden md:flex"
          v-if="compareTab.length >= 2"
          @click="compareArticles()"
        />
        <check text="Promotions" @updateCheckbox="filterPromotion" />
        <check text="Stock" @updateCheckbox="filterStock" />
        <filterButton
          text="Marque"
          @manageFilter="filterMarque"
          :filter_list="response.Marque"
        />
        <filterButton
          text="Famille"
          @manageFilter="filterFamille"
          :filter_list="response.Famille"
        />
        <filterButton
          text="Systeme"
          @manageFilter="filterSysteme"
          :filter_list="response.Systeme"
        />
        <filterButton
          text="Emplacement"
          @manageFilter="filterLocation"
          :filter_list="response.Location"
        />
        <filterButton
          text="Prix"
          @manageFilter="filterPrice"
          :filter_list="['Croissant', 'Decroissant']"
        />
      </div>
    </div>
    <div class="flex flex-row items-center space-x-2">
      <div
        v-for="(marque, indexM) in marqueTab"
        :key="indexM"
        class="p-1 border border-bluevk text-bluevk text-sm rounded flex flex-row items-center space-x-2"
      >
        {{ marque }}
      </div>
      <div
        v-for="(famille, indexF) in familleTab"
        :key="indexF"
        class="p-1 border border-bluevk text-bluevk text-sm rounded flex flex-row items-center space-x-2"
      >
        {{ famille }}
      </div>
      <div
        v-for="(systeme, indexS) in systemeTab"
        :key="indexS"
        class="p-1 border border-bluevk text-bluevk text-sm rounded flex flex-row items-center space-x-2"
      >
        {{ systeme }}
      </div>
      <div
        v-for="(location, indexL) in locationTab"
        :key="indexL"
        class="p-1 border border-bluevk text-bluevk text-sm rounded flex flex-row items-center space-x-2"
      >
        {{ location }}
      </div>
    </div>
    <div v-show="loadingSearch">Recherche en cours...</div>
    <div class="overflow-y-auto">
      <div
        class="space-y-2"
        v-for="(famille, indexFamille) in response.data"
        :key="indexFamille"
      >
        <section-title
          :titre="famille.designation"
          @click="famille.show = !famille.show"
        />
        <div
          v-for="(article, indexArticle) in famille.data"
          :key="article.dbArticle._id + reloadkey.toString()"
        >
          <article-catalogue
            v-show="famille.show"
            v-if="article.dbArticle.length > 0"
            :article="article.dbArticle"
            :Ref_fournisseur="article.dbArticle.Ref_fournisseur"
            :Code_marque="article.dbArticle.Code_marque"
            :Fournisseur="article.dbArticle.Prefixe_tarif"
            :genericArticleId="article.tdArticle.genericArticleId"
            :Description="article.dbArticle.Description"
            :partsList="article.partsList"
            :Images="article.image"
            :Location="article.location"
            :Systeme="article.systeme"
            :Stock="article.stock"
            :Prix="article.prix"
            :quantity="article.quantity"
            @checkedArticle="checkArticle(indexArticle, indexFamille)"
            :compareTabLength="compareTab.length"
            @infoArticle="plusInfo(article.dbArticle)"
          />
          <article-catalogue
            v-show="famille.show"
            v-else
            :article="article.dbArticle"
            :Ref_fournisseur="article.tdArticle.articleNumber"
            :Code_marque="article.tdArticle.mfrName"
            :Fournisseur="'99'"
            :genericArticleId="article.tdArticle.genericArticleId"
            :Description="
              article.tdArticle.genericArticles[0].genericArticleDescription
            "
            :partsList="article.partsList"
            :Images="article.image"
            :Location="article.location"
            :Systeme="article.systeme"
            :Stock="article.stock"
            :Prix="article.prix"
            :quantity="article.quantity"
            @checkedArticle="checkArticle(indexArticle, indexFamille)"
            :compareTabLength="compareTab.length"
            @infoArticle="plusInfo(article.dbArticle)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
// import back from "@/components/Button/back.vue";
import check from "@/components/Button/check.vue";
import filter from "@/components/Button/filter.vue";
import sectionTitle from "@/components/Text/SectionTitle.vue";
import articleCatalogue from "@/components/Article/articleCatalogue.vue";
import compareButton from "@/components/Button/compare.vue";
import compareStructure from "@/components/Article/compareStructure.vue";
import articleInfo from "@/components/articleInfo/articleInfo.vue";
export default {
  name: "searchArticleNew",
  data() {
    return {
      loadingSearch: false,
      response: [],
      response2: [],
      marqueTab: [],
      familleTab: [],
      systemeTab: [],
      locationTab: [],
      compareTab: [],
      reloadkey: 0,
      showCompareModal: false,
      showFiltre: false,
      openModalArticle: false,
      articleInfo_componentKey: 0,
    };
  },
  components: {
    // back,
    check,
    filterButton: filter,
    sectionTitle,
    articleCatalogue,
    compareButton,
    compareStructure,
    articleInfo,
  },
  computed: {
    ...mapGetters([
      "user",
      "Ref_fournisseur_search",
      "genArt_search",
      "familleSelected",
      "carIdSelected",
      "comptoirSessionActive",
      "brandSelected",
      "genericArticleSelected",
    ]),
  },
  methods: {
    ...mapActions(["setArticleInfo"]),
    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },
    async searchArticleSim() {
      this.loadingSearch = true;
      const article = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/catArticleMarque`,
        {
          plateform: this.user.proprietaire,
          Client: this.comptoirSessionActive[0].Client[0].Name,
          groupeClient: this.comptoirSessionActive[0].Client[0].Group.Name,
          genericArticleId: this.genericArticleSelected,
          dataSupplierId: this.brandSelected.dataSupplierId,
        }
      );
      this.loadingSearch = false;
      this.response = article.data;
      this.response2 = article.data;
    },
    async filterMarque(marque) {
      let oldTab = this.response2;
      let dataTab = [];
      if (!this.marqueTab.includes(marque)) {
        this.marqueTab.push(marque);
      } else {
        this.marqueTab.splice(this.marqueTab.indexOf(marque), 1);
      }
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterMarqueSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          marqueTab: this.marqueTab,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    async filterFamille(famille) {
      let oldTab = this.response2;
      let dataTab = [];
      if (!this.familleTab.includes(famille)) {
        this.familleTab.push(famille);
      } else {
        this.familleTab.splice(this.familleTab.indexOf(famille), 1);
      }
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterFamilleSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          familleTab: this.familleTab,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    async filterSysteme(systeme) {
      let oldTab = this.response2;
      let dataTab = [];
      if (!this.systemeTab.includes(systeme)) {
        this.systemeTab.push(systeme);
      } else {
        this.systemeTab.splice(this.systemeTab.indexOf(systeme), 1);
      }
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterSystemeSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          systemeTab: this.systemeTab,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    async filterLocation(location) {
      let oldTab = this.response2;
      let dataTab = [];
      if (!this.locationTab.includes(location)) {
        this.locationTab.push(location);
      } else {
        this.locationTab.splice(this.locationTab.indexOf(location), 1);
      }
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterLocationSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          locationTab: this.locationTab,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    async filterPromotion(promotion) {
      let oldTab = this.response2;
      let dataTab = [];
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterPromotionSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          promotion: promotion,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    async filterStock(stock) {
      let oldTab = this.response2;
      let dataTab = [];
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterStockSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          stock: stock,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    async filterPrice(prix) {
      let oldTab = this.response2;
      let dataTab = [];
      const filtre = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/filterPriceSearch`,
        {
          articleData: this.response,
          articleDataSave: this.response2,
          prix: prix,
          plateform: this.user.proprietaire,
        }
      );

      this.response = filtre.data;
      this.reloadkey++;
    },
    checkArticle(indexArticle, indexFamille) {
      //check if article is already in compareTab
      let article = this.response.data[indexFamille].data[indexArticle];
      let index = this.compareTab.findIndex(
        (element) => element.dbArticle._id === article.dbArticle._id
      );
      if (index === -1) {
        if (this.compareTab.length < 5) {
          this.compareTab.push(article);
        }
      } else {
        this.compareTab.splice(index, 1);
      }
    },
    compareArticles() {
      this.showCompareModal = true;
    },
  },
  mounted() {
    this.searchArticleSim();
  },
};
</script>
<style></style>
