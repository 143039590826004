import axios from "axios";

// Récupérer les clients qui ont passés des commandes dans la journée
export async function getClientsCmd(plateform: string) {
  const clients = await axios.post(
    `${process.env.VUE_APP_API}/commandesClient/getClientsInProgress`,
    {
      plateform: plateform,
    }
  );
  return clients.data;
}

// Regrouper les commandes client
export async function getCmdRegroupe(
  plateform: string,
  client: string,
  transporteur: string,
  cutoff: string
) {
  const cmd = await axios.post(
    `${process.env.VUE_APP_API}/commandesClient/getCmdRegroupe`,
    {
      plateform: plateform,
      client: client,
      transporteur: transporteur,
      cutoff: cutoff,
    }
  );
  return cmd.data;
}

// Regrouper les commandes client
export async function groupByCmdPro(
  plateform: string,
  user: string,
  commandes: null,
) {
  const cmd = await axios.post(
    `${process.env.VUE_APP_API}/groupedCmd/groupByCmdPro`,
    {
      plateform: plateform,
      user: user,
      commandes: commandes,
    }
  );
  return cmd.data;
}

// Regrouper les commandes client
export async function cancelGrouping(
  plateform: string,
  data: object
) {
  const cmd = await axios.post(
    `${process.env.VUE_APP_API}/groupedCmd/cancelGrouping`,
    {
      plateform: plateform,
      data: data
    }
  );
  return cmd.data;
}
