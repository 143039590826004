<template>
  <div
    v-if="openGenArtArticle"
    :class="`modal ${
      !openGenArtArticle && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openGenArtArticle = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 80vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openGenArtArticle = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <configurationBrandArticles />
      </div>
    </div>
  </div>
  <div class="flex cursor-pointer" @click="back()">
    <span class="material-icons-outlined"> arrow_back </span>
    <span>retour</span>
  </div>
  <div class="flex justify-center"><menuConfiguration /></div>
  <div class="flex justify-center mt-4">
    <div class="flex flex-row space-x-2">
      <span class="text-2xl text-bluevk">{{ brandSelected.mfrName }}</span>
      <button class="italic text-blue-500 text-sm" @click="changeAllBrand()">
        tout sélectionner / déselectionner
      </button>
      <button
        class="italic text-gray-500 text-sm"
        @click="changeAllSecondaryBrand()"
      >
        Ajouter cross
      </button>
    </div>
  </div>
  <div v-show="loadingSousFamille">Chargement en cours...</div>
  <div class="mt-4">
    <div class="flex flex-col space-y-2">
      <div
        v-for="(SF, index) in sousFamille"
        :key="index"
        class="flex flex-row bg-white rounded p-2 hover:bg-gray-100 w-full"
      >
        <div class="flex flex-col space-y-2 w-1/3 p-2">
          <div class="flex flex-row items-center space-x-4">
            <span class="text-lg"> {{ SF.name }} </span
            ><button
              v-if="!SF.brandPrimary.includes(brandSelected.mfrName)"
              class="bg-blue-500 text-white px-2 rounded-full shadow-md"
              @click="changeBrand(index, SF._id)"
            >
              Ajouter catalogue
            </button>
            <button
              v-if="!SF.brandSecondary.includes(brandSelected.mfrName)"
              class="bg-gray-500 text-white px-2 rounded-full shadow-md"
              @click="changeBrandScd(index, SF._id)"
            >
              Ajouter cross
            </button>
          </div>
          <div>Catalogue :</div>
          <div
            class="flex flex-row space-x-2 items-center w-full overflow-x-auto"
          >
            <div
              v-for="(BP, indexBP) in SF.brandPrimary"
              :key="indexBP"
              class="flex flex-row items-center justify-between text-xs p-2 border border-bluevk rounded text-bluevk"
            >
              <div>{{ BP }}</div>
              <div class="flex flex-col space-y-2">
                <button
                  @click="deleteBrand(SF._id, BP, index)"
                  class="material-icons-round text-sm text-red-500"
                >
                  clear
                </button>
              </div>
            </div>
          </div>
          <div>Cross :</div>
          <div
            class="flex flex-row space-x-2 items-center w-full overflow-x-auto"
          >
            <div
              v-for="(BS, indexBS) in SF.brandSecondary"
              :key="indexBS"
              class="flex flex-row items-center justify-between text-xs p-2 border border-bluevk rounded text-bluevk"
            >
              <div>{{ BS }}</div>
              <div class="flex flex-col space-y-2">
                <button
                  @click="deleteBrandScd(SF._id, BS, index)"
                  class="material-icons-round text-sm text-red-500"
                >
                  clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/3 p-2 flex flex-col space-y-1">
          <div class="flex flex-row items-center space-x-2">
            <div
              class="px-2 border border-gray-400 text-sm text-gray-500 rounded-full"
            >
              {{ SF.groupeName }}
            </div>
            <div
              class="px-2 border border-gray-400 text-sm text-gray-500 rounded-full"
            >
              {{ SF.familleName }}
            </div>
          </div>
          <div class="grid grid-cols-8 gap-2">
            <div
              v-for="(gaName, indexGAN) in SF.genericArticleId_name"
              :key="indexGAN"
              class="flex flex-row items-center justify-between px-1 text-xs border border-bluevk text-bluevk rounded"
            >
              <div>{{ gaName }} - {{ SF.genericArticleId[indexGAN] }}</div>
              <div class="flex flex-col space-y-2">
                <button
                  @click="
                    setGenArt(
                      SF._id,
                      SF.genericArticleId[indexGAN],
                      index,
                      indexGAN
                    )
                  "
                  class="material-icons-round text-sm text-red-500"
                >
                  clear
                </button>
                <button
                  @click="catArticleMarque(SF.genericArticleId[indexGAN])"
                  class="material-icons-round text-base text-red-500"
                >
                  manage_search
                </button>
              </div>
            </div>
            <div class="relative">
              <button
                @click="getRemainGenArt(SF._id, index)"
                class="flex flex-row items-center justify-center px-1 text-xs bg-bluevk text-white rounded w-full h-full"
              >
                Ajouter un GenArt
              </button>
              <div
                class="absolute bg-white p-1 rounded shadow z-20 p-1 text-sm flex flex-col max-h-64 overflow-y-auto"
                v-show="showGenArtRemain[index]"
              >
                <div
                  v-for="(RGA, indexRGA) in remainGATab"
                  :key="indexRGA"
                  class="flex flex-row items-center justify-between p-1 hover:bg-gray-100 hover:cursor-pointer"
                  @click="addDirectGA(SF._id, RGA, index)"
                >
                  <div>{{ RGA.designation }}</div>
                  <div>{{ RGA.genericArticleId }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="!loadingSousFamille"
    class="mt-4 text-xl text-bluevk flex justify-center"
    v-if="genArt.length > 0"
  >
    Familles manquantes
  </div>
  <div
    v-show="!loadingSousFamille"
    class="flex flex-row items-center space-x-4 justify-center mt-2"
  >
    <input
      class="p-2 w-1/4 border border-gray-200 rounded"
      type="Number"
      placeholder="Rechercher par GenArt"
      v-model="searchGenArt"
      v-on:keyup.enter="searchByGenArt"
    />
    <input
      class="p-2 w-1/4 border border-gray-200 rounded"
      type="Text"
      placeholder="Rechercher par terme"
      v-model="searchTerm"
      v-on:keyup.enter="searchByTerm"
    />
  </div>
  <div class="mt-4 grid grid-cols-4 gap-4">
    <div
      v-for="(GA, indexGA) in genArt"
      :key="indexGA"
      class="bg-white rounded shadow p-2 flex flex-col space-y-2"
    >
      <div class="flex flex-row justify-between items-center">
        <div
          class="flex flex-col"
          v-if="
            (GA.assemblyGroup && GA.assemblyGroup.endsWith(', universel')) ||
            (GA.assemblyGroup && GA.assemblyGroup.endsWith(', Universel'))
          "
        >
          <div class="text-orange-500">
            {{ GA.designation }} - {{ GA.genericArticleId }}
          </div>
          <div
            class="flex flex-row items-center space-x-2 text-xs text-gray-400"
          >
            <span
              class="border border-gray-400 rounded-full px-1"
              v-if="GA.assemblyGroup"
              >{{ GA.assemblyGroup }}</span
            >
            <span
              class="border border-gray-400 rounded-full px-1"
              v-if="GA.masterDesignation"
              >{{ GA.masterDesignation }}</span
            >
            <span
              class="border border-gray-400 rounded-full px-1"
              v-if="GA.usageDesignation"
              >{{ GA.usageDesignation }}</span
            >
          </div>
        </div>
        <div v-else class="flex flex-col">
          <div class="text-bluevk">
            {{ GA.designation }} - {{ GA.genericArticleId }}
          </div>
          <div
            class="flex flex-row items-center space-x-2 text-xs text-gray-400"
          >
            <span
              class="border border-gray-400 rounded-full px-1"
              v-if="GA.assemblyGroup"
              >{{ GA.assemblyGroup }}</span
            >
            <span
              class="border border-gray-400 rounded-full px-1"
              v-if="GA.masterDesignation"
              >{{ GA.masterDesignation }}</span
            >
            <span
              class="border border-gray-400 rounded-full px-1"
              v-if="GA.usageDesignation"
              >{{ GA.usageDesignation }}</span
            >
          </div>
        </div>
        <div class="flex flex-row items-center space-x-2">
          <button
            @click="catArticleMarque(GA.genericArticleId)"
            class="material-icons-round"
          >
            manage_search
          </button>
          <button
            class="material-icons-outlined text-bluevk"
            @click="prepareAddGA(indexGA)"
          >
            add_circle
          </button>
        </div>
      </div>
      <div v-show="showAddBrand[indexGA]" class="flex flex-col space-y-4">
        <div class="flex flex-row items-center justify-between w-full">
          <div class="text-sm text-gray-500 w-1/3">
            <select
              v-model="selectedGroup"
              @change="selectGroup('select', selectedGroup)"
              class="w-full"
            >
              <option
                v-for="(grp, indexGrp) in groupeList"
                :key="indexGrp"
                :value="grp"
              >
                {{ grp.name }}
              </option>
            </select>
          </div>
          <div class="text-sm text-gray-500">ou</div>
          <div class="text-sm text-gray-500 w-1/3">
            <input
              type="text"
              placeholder="Nouveau groupe"
              class="w-full"
              v-model="groupToCreate"
              v-on:keyup.enter="selectGroup('create', groupToCreate)"
            />
          </div>
        </div>

        <div
          v-show="showFamille"
          class="flex flex-row items-center justify-between w-full"
        >
          <div class="text-sm text-gray-500 w-1/3">
            <select
              v-model="familleSelected"
              @change="selectFamille('select', familleSelected, GA.designation)"
              class="w-full"
            >
              <option
                v-for="(fml, indexFml) in familleList"
                :key="indexFml"
                :value="fml"
              >
                {{ fml.name }}
              </option>
            </select>
          </div>
          <div class="text-sm text-gray-500">ou</div>
          <div class="text-sm text-gray-500 w-1/3">
            <input
              type="text"
              placeholder="Nouvelle famille"
              v-model="familleToCreate"
              class="w-full"
              v-on:keyup.enter="
                selectFamille('create', familleToCreate, GA.designation)
              "
            />
          </div>
        </div>

        <div
          v-show="showSousFamille"
          class="flex flex-row items-center justify-between w-full"
        >
          <div class="text-sm text-gray-500 w-1/3">
            <select
              v-model="sousFamilleSelected"
              @change="selectSousFamille('select', sousFamilleSelected)"
              class="w-full"
            >
              <option
                v-for="(sfm, indexSfm) in sousFamilleList"
                :key="indexSfm"
                :value="sfm"
              >
                {{ sfm.name }}
              </option>
            </select>
          </div>
          <div class="text-sm text-gray-500">ou</div>
          <div class="text-sm text-gray-500 w-1/3">
            <input
              type="text"
              placeholder="Nouvelle sous famille"
              class="w-full"
              v-model="sousFamilleToCreate"
              v-on:keyup.enter="
                selectSousFamille('create', sousFamilleToCreate)
              "
            />
          </div>
        </div>

        <div class="flex justify-end">
          <div class="flex flex-row items-center space-x-2">
            <button
              v-show="showAddButton"
              class="px-2 bg-bluevk text-white text-sm rounded shadow"
              @click="
                addGA(
                  indexGA,
                  GA.genericArticleId,
                  GA.designation,
                  GA.assemblyGroup
                )
              "
            >
              ajouter
            </button>
            <button
              class="px-2 bg-orangevk text-white text-sm rounded shadow"
              @click="cancelAddGroupe(indexGA)"
            >
              annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import menuConfiguration from "@/components/Menu/menuConfiguration.vue";
import configurationBrandArticles from "./configurationBrandArticles.vue";
export default {
  data() {
    return {
      sousFamille: [],
      genArt: [],
      genArt_2: [],
      saveGenArt: [],
      loadingSousFamille: false,
      showAddBrand: [],
      groupeList: [],
      groupeType: "",
      selectedGroup: {},
      groupToCreate: "",
      showFamille: false,
      familleList: [],
      familleType: "",
      familleSelected: {},
      familleToCreate: "",
      showSousFamille: false,
      sousFamilleList: [],
      sousFamilleType: "",
      sousFamilleSelected: {},
      sousFamilleToCreate: "",
      showAddButton: false,
      showGenArtRemain: [],
      remainGATab: [],
      openGenArtArticle: false,
      fournisseurList: [],
      searchGenArt: "",
      searchTerm: "",
    };
  },
  components: {
    menuConfiguration,
    configurationBrandArticles,
  },
  computed: {
    ...mapGetters(["brandSelected", "user", "comptoirSessionActive"]),
  },
  methods: {
    ...mapActions(["setbrandselected", "setgenericarticleselected"]),
    back() {
      this.setbrandselected({ name: "" });
      this.$router.push("/configurationFiltre");
    },
    async getFournisseur() {
      const res = await axios.post(
        `${process.env.VUE_APP_API}/fournisseur/getall`,
        {
          plateform: this.user.proprietaire,
        }
      );

      this.fournisseurList = res.data;
    },
    async setAssemblyGroup() {
      this.loadingSousFamille = true;
      const res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getLinkedBrand`,
        {
          plateform: this.user.proprietaire,
          brandName: this.brandSelected.mfrName,
        }
      );
      this.sousFamille = res.data.sf;
      this.genArt = res.data.ga;
      this.genArt_2 = res.data.ga;
      // this.genArt_2 = JSON.parse(JSON.stringify(this.genArt));
      this.loadingSousFamille = false;
    },
    async changeBrand(indexSousFamille, idSF) {
      const updatedSF = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: idSF,
          brandName: this.brandSelected.mfrName,
          type: "primary",
        }
      );
      this.sousFamille[indexSousFamille] = updatedSF.data;
    },
    async changeBrandScd(indexSousFamille, idSF) {
      const updatedSF = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
        {
          id: idSF,
          brandName: this.brandSelected.mfrName,
          type: "secondary",
        }
      );
      this.sousFamille[indexSousFamille] = updatedSF.data;
    },
    async changeAllBrand() {
      for (const [indexSF, SF] of this.sousFamille.entries()) {
        const updatedSF = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
          {
            id: SF._id,
            brandName: this.brandSelected.mfrName,
            type: "primary",
          }
        );
        this.sousFamille[indexSF] = updatedSF.data;
      }
    },
    async changeAllSecondaryBrand() {
      for (const [indexSF, SF] of this.sousFamille.entries()) {
        const updatedSF = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/setSousFamilleBrand`,
          {
            id: SF._id,
            brandName: this.brandSelected.mfrName,
            type: "secondary",
          }
        );
        this.sousFamille[indexSF] = updatedSF.data;
      }
    },
    cancelAddGroupe(indexGA) {
      this.showAddBrand[indexGA] = !this.showAddBrand[indexGA];
      this.showFamille = false;
      this.showSousFamille = false;
      this.showAddButton = false;
      this.groupeType = "";
      this.selectedGroup = {};
      this.groupToCreate = "";
      this.familleType = "";
      this.familleSelected = {};
      this.familleToCreate = "";
      this.sousFamilleType = "";
      this.sousFamilleSelected = {};
      this.sousFamilleToCreate = "";
    },
    async prepareAddGA(indexGA) {
      this.showAddBrand = [];
      this.showFamille = false;
      this.showSousFamille = false;
      this.showAddButton = false;
      this.groupeType = "";
      this.selectedGroup = {};
      this.groupToCreate = "";
      this.familleType = "";
      this.familleSelected = {};
      this.familleToCreate = "";
      this.sousFamilleType = "";
      this.sousFamilleSelected = {};
      this.sousFamilleToCreate = "";
      this.showAddBrand[indexGA] = !this.showAddBrand[indexGA];
      const getGroupe = await axios.get(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/getGroupe/${this.user.proprietaire}`
      );
      this.groupeList = getGroupe.data;
    },
    async selectGroup(type, group) {
      this.groupeType = type;
      if (type === "create") {
        this.groupToCreate = group;
        this.showFamille = true;
      } else {
        this.selectedGroup = group;
        const getFamille = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/getFamilleForBrand`,
          {
            plateform: this.user.proprietaire,
            groupeId: group._id,
          }
        );
        this.familleList = getFamille.data;
        this.showFamille = true;
      }
    },
    async selectFamille(type, famille, GA) {
      this.familleType = type;
      if (type === "create") {
        this.familleToCreate = famille;
        this.sousFamilleToCreate = GA;
        this.showSousFamille = true;
      } else {
        this.familleSelected = famille;
        const getSousFamille = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/getSousFamilleForBrand`,
          {
            plateform: this.user.proprietaire,
            groupeId: this.selectedGroup._id,
            familleId: famille._id,
          }
        );
        this.sousFamilleToCreate = GA;
        this.sousFamilleList = getSousFamille.data;
        this.showSousFamille = true;
      }
    },
    async selectSousFamille(type, sousFamille) {
      this.sousFamilleType = type;
      if (type === "create") {
        this.sousFamilleToCreate = sousFamille;
      } else {
        this.sousFamilleSelected = sousFamille;
      }
      this.showAddButton = true;
    },
    async addGA(
      indexGA,
      genericArticleId,
      genericArticleId_name,
      assemblyGroupGA
    ) {
      let univ = false;
      if (
        (assemblyGroupGA && assemblyGroupGA.endsWith(", universel")) ||
        (assemblyGroupGA && assemblyGroupGA.endsWith(", Universel"))
      ) {
        univ = true;
      }
      const addGA = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addGenericArticleIdBrand`,
        {
          plateform: this.user.proprietaire,
          username: this.user.username,
          brandName: this.brandSelected.mfrName,
          genericArticleId: genericArticleId,
          genericArticleId_name: genericArticleId_name,
          groupeType: this.groupeType,
          selectedGroup: this.selectedGroup,
          groupToCreate: this.groupToCreate,
          familleType: this.familleType,
          familleSelected: this.familleSelected,
          familleToCreate: this.familleToCreate,
          sousFamilleType: this.sousFamilleType,
          sousFamilleSelected: this.sousFamilleSelected,
          sousFamilleToCreate: this.sousFamilleToCreate,
          isUniversel: univ,
        }
      );
      this.sousFamille = addGA.data.sf;
      this.genArt.splice(indexGA, 1);
      this.cancelAddGroupe(indexGA);
    },
    async catArticleMarque(genericArticleId) {
      this.setgenericarticleselected(genericArticleId);
      this.openGenArtArticle = true;
      // this.$router.push("/configurationBrandArticles");
    },
    async setGenArt(sousFamilleId, genericArticleId, indexSF, indexGAN) {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/setGenArt`,
        {
          id: sousFamilleId,
          genericArticleId: parseInt(genericArticleId),
        }
      );
      this.sousFamille[indexSF].genericArticleId.splice(indexGAN, 1);
      this.sousFamille[indexSF].genericArticleId_name.splice(indexGAN, 1);
    },
    async deleteBrand(sousFamilleId, brand, indexSF) {
      const delBd = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteBrandSousFamille`,
        {
          id: sousFamilleId,
          brand: brand,
        }
      );
      this.sousFamille[indexSF] = delBd.data;
    },
    async deleteBrandScd(sousFamilleId, brand, indexSF) {
      const delBd = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/deleteBrandScdSousFamille`,
        {
          id: sousFamilleId,
          brand: brand,
        }
      );
      this.sousFamille[indexSF] = delBd.data;
    },
    async getRemainGenArt(sousFamilleId, indexSF) {
      if (!this.showGenArtRemain[indexSF]) {
        const res = await axios.post(
          `${process.env.VUE_APP_API}/configurationCatalogueV2/getRemainGenArt`,
          {
            id: sousFamilleId,
            brand: this.brandSelected.mfrName,
          }
        );
        this.remainGATab = res.data;
      }

      this.showGenArtRemain[indexSF] = !this.showGenArtRemain[indexSF];
    },
    async addDirectGA(sousFamilleId, genericArticleId, indexSF) {
      let res = await axios.post(
        `${process.env.VUE_APP_API}/configurationCatalogueV2/addGenArt`,
        {
          id: sousFamilleId,
          genArt: genericArticleId,
        }
      );
      this.sousFamille[indexSF] = res.data;
      this.showGenArtRemain[indexSF] = false;
    },
    searchByGenArt() {
      if (this.searchGenArt !== "") {
        this.genArt = this.genArt.filter((GA) => {
          return GA.genericArticleId
            .toString()
            .includes(this.searchGenArt.toString());
        });
      } else {
        this.genArt = this.genArt_2;
      }
    },
    searchByTerm() {
      //TODO search by term
      if (this.searchTerm !== "") {
        this.genArt = this.genArt.filter((GA) => {
          return (
            GA.assemblyGroup
              ?.toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            GA.masterDesignation
              ?.toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            GA.usageDesignation
              ?.toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            GA.designation
              ?.toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          );
        });
      } else {
        this.genArt = this.genArt_2;
      }
    },
  },
  beforeMount() {
    this.setAssemblyGroup();
  },
};
</script>
<style></style>
