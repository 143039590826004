<template>
  <div class="flex flex-col space-y-2 p-2 bg-white rounded shadow w-full h-fit">
    <div class="flex flex-row">
      <div class="hidden md:flex my-auto w-6">
        <span
          class="w-4 h-4 border border-gray-300 rounded bg-gray-100 animate-pulse"
        ></span>
      </div>
      <div class="w-1/12">
        <div
          class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-20 h-16"
        ></div>
      </div>
      <div class="flex flex-col space-y-2 w-2/5 md:w-2/12 text-sm md:text-base">
        <div class="flex flex-row items-center space-x-2 mx-auto">
          <div
            class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-24 h-8"
          ></div>
          <div
            class="border border-gray-200 rounded-full bg-gray-100 animate-pulse mx-auto w-6 h-6"
          ></div>
          <div
            class="border border-gray-200 rounded-full bg-gray-100 animate-pulse mx-auto w-6 h-6"
          ></div>
        </div>

        <div class="flex lg:hidden">
          <div
            class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-2/3 h-8"
          ></div>
        </div>
      </div>
      <div class="w-1/12 hidden lg:flex lg:flex-col">
        <div class="font-bold">
          <div
            class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-2/3 h-8"
          ></div>
        </div>
      </div>
      <div class="w-2/12 hidden lg:flex">
        <div
          class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-1/2 h-8"
        ></div>
      </div>
      <div
        class="w-2/12 lg:1/12 hidden md:flex md:flex-row md:space-x-2 mx-auto"
      >
        <span class="w-1/3"
          ><div
            class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-full h-8"
          ></div
        ></span>
        <div class="w-2/3">
          <div
            class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-1/2 h-8"
          ></div>
        </div>
      </div>
      <div class="w-2/12 hidden md:flex">
        <div
          class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-full h-16"
        ></div>
      </div>
      <div class="w-1/12 lg:1/12 hidden md:flex">
        <div
          class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-20 h-16"
        ></div>
      </div>
      <div class="flex flex-col w-2/5 md:w-1/12 text-sm md:text-base">
        <div
          class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-20 h-16"
        ></div>
        <div class="flex md:hidden">
          <div
            class="border border-gray-200 rounded bg-gray-100 animate-pulse mx-auto w-20 h-16"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "articleCatalogue",
};
</script>
<style></style>
