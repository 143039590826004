<template>
  <div v-if="fournisseurTab.Prefixe_tarif">
    <div>
      <button
        @click="$router.back()"
        class="flex flex-row space-x-2 items-center bg-blue-400 text-white px-2 py-1 rounded-md"
      >
        <span class="material-icons-outlined text-sm"> arrow_back_ios </span>
        <span>retour</span>
      </button>
    </div>

    <div class="flex justify-center">
      <div class="text-2xl font-medium ml-2">
        <h2 class="space-x-2">
          <span>Fournisseur </span>
          <span class="text-green-600"> {{ fournisseurTab.CompanyName }} </span>
          <span class="text-orange-600">
            {{ fournisseurTab.Prefixe_tarif }}
          </span>
        </h2>
      </div>
    </div>
    <div class="mb-10">
      <menuDetailFournisseur />
    </div>
    <div class="w-full mt-2">
      <articleImportExemple />
    </div>
    <div class="flex justify-end">
      <div class="flex flex-row space-x-2 items-center">
        <button
          class="p-2 bg-red-500 text-white rounded shadow"
          @click="deleteArticles()"
        >
          Supprimer les articles
        </button>
        <button class="p-2 bg-green-500 text-white rounded shadow">
          Exporter en Excel
        </button>
        <label for="dropzone-file">
          <span
            class="p-2 bg-blue-500 text-white rounded shadow cursor-pointer"
          >
            Importer les tarifs
          </span>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".xlsx, .xls, .csv"
            @change="readExcel()"
          />
        </label>
      </div>
    </div>
    {{ articleCounted }} articles
    <loading-import v-show="loadingFiles === true" />
    <div
      v-show="loadingFiles === false && showFile === true"
      class="py-2 my-2 overflow-y-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2 max-h-40"
    >
      <div class="flex justify-end mb-4" v-if="tabHeader.length > 5">
        <div
          class="p-2 bg-blue-500 text-white rounded shadow cursor-pointer"
          @click="importFile()"
        >
          Importer
        </div>
      </div>
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
              ></th>
              <th
                v-for="(header, index) in headers"
                :key="index"
                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
              >
                <div class="flex flex-row items-center space-x-1">
                  <select v-model="tabHeader[index]">
                    <option
                      v-for="(selectPrio, indexSelectPrio) in selectPrioValue"
                      :key="indexSelectPrio"
                      :value="selectPrio"
                    >
                      {{ selectPrio }}
                    </option>
                    <option
                      v-for="(select, indexSelect) in selectValue"
                      :key="indexSelect"
                      :value="select"
                    >
                      {{ select }}
                    </option>
                  </select>
                  <span
                    class="material-icons-round text-red-500 cursor-pointer"
                    @click="deleteCol(index)"
                  >
                    clear
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(row, index) in readFile" :key="index">
              <td>
                <span
                  class="material-icons-round text-red-500 cursor-pointer"
                  @click="deleteRow(index)"
                >
                  clear
                </span>
              </td>
              <td
                v-for="(cell, index) in row"
                :key="index"
                class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
              >
                {{ cell }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div v-else>
    <span class="text-red-500"
      >Veuillez renseigner le préfixe tarif du fournisseur</span
    >
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import menuDetailFournisseur from "../components/Fournisseur/menuDetailFournisseur.vue";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import loadingImport from "../components/general/importFiles/loadingImport.vue";
import articleImportExemple from "../components/importFournisseur/articleImportExemple.vue";
import { useToast } from "vue-toastification";
import { getFournisseurByID } from "@/hooks/fournisseur/fournisseur.ts";

export default {
  name: "ImportArticleFournisseur",
  data() {
    return {
      articleCounted: 0,
      readFile: null,
      file: null,
      showFile: false,
      loadingFiles: false,
      tabHeader: [],
      headers: [],
      selectPrioValue: [
        "Ref_fournisseur",
        "Ref_courte",
        "Code_EAN",
        "Description",
        "Prix_euro",
        "Code_remise",
        "Code_marque",
        "Prefixe_tarif",
        "Ref_complementaire",
        "Ref_complementaire_2",
        "Ref_complementaire_3",
        "Ref_similaire",
        "Ref_similaire_2",
        "Ref_similaire_3",
      ],
      selectValue: [
        "Qte_mini",
        "Udm_qte_mini",
        "Qte_cond",
        "Udm_qte_cond",
        "Prix_ppc",
        "Base_ppc",
        "PNF",
        "PNT1",
        "PNT2",
        "PNT3",
        "PNT4",
        "PNT5",
        "PNT6",
        "Poids",
        "Udm_poids",
        "Code_sousfamille_NU",
        "Famille",
        "Montant_consigne",
        "Code_classe_consigne",
        "Reference_consigne",
        "Code_metier",
        "Prix2",
        "Qte2",
        "Prix3",
        "Qte3",
        "Prix4",
        "Qte4",
        "Prix5",
        "Qte5",
        "Prix6",
        "Qte6",
        "Prix7",
        "Qte7",
        "Art_remplacement",
        "Qte_multiple",
        "Codes_constructeurs",
        "ValeurHT_DEEE",
        "Code_DEEE",
        "Coefficient_TICPE",
        "Mention_Coeff_TICPE",
        "Valeur_TICPE",
        "Coeff_TGAP",
        "Mention_Coeff_TGAP",
        "Valeur_TGAP",
        "Udm_prix2x_qte2x",
        "Description_courte",
        "Qte_contenant",
        "Udm_qte_contenant",
        "Hauteur",
        "Longueur",
        "Largeur",
        "Udm_dimensions",
        "Classe_ADR",
        "Code_ONU",
        "Grp_emballage",
        "Symbol_danger",
        "Point_eclair",
        "Description_ADR",
        "Statut",
        "Description_complementaire",
        "Date_application",
        "Code_famille_NU",
        "Date_fin_validite",
        "Num_jour_creation",
        "Ref_TecDoc",
        "Volume",
        "Udm_volume",
        "Code_pays",
        "GTIN_14",
        "Code_EAN_1",
        "Code_EAN_2",
        "Code_EAN_3",
        "Code_EAN_4",
        "Valeur_DDS",
        "DLC",
        "DLU",
        "Code_douane",
        "Code_famille_fournisseur",
        "Poids_net",
        "Qte_palette",
        "Surcharge_valeur",
        "Surcharge_type",
        "Surcharge_incluse",
        "URL",
      ],

      fournisseurTab: {},
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters(["user", "fournisseurID"]),
  },
  components: {
    menuDetailFournisseur,
    loadingImport,
    articleImportExemple,
  },
  methods: {
    async getFournisseur() {
      this.fournisseurTab = await getFournisseurByID(
        this.fournisseurID,
        this.user.proprietaire
      );
    },
    countArticle() {
      axios
        .post(`${process.env.VUE_APP_API}/article/countArticleFournisseur`, {
          plateform: this.user.proprietaire,
          Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        })
        .then((res) => {
          this.articleCounted = res.data;
        });
    },
    async readExcel() {
      var file = event.target.files ? event.target.files[0] : null;
      if (file.size < 1000000) {
        this.loadingFiles = true;
        this.showFile = true;
        this.readFile = await readXlsxFile(file);
        this.initHeader(this.readFile[0]);
        this.readFile.splice(0, 1);
        this.loadingFiles = false;
      } else {
        this.error("Le fichier est trop volumineux");
      }
    },
    initHeader(tab) {
      this.headers = [];
      tab.forEach((element) => {
        this.headers.push("test");
      });
    },

    importFile() {
      if (this.controlHeaderTab(this.tabHeader)) {
        this.loadingFiles = true;
        if (this.readFile.length > 0) {
          axios
            .post(`${process.env.VUE_APP_API}/article/importArticles`, {
              Data: this.readFile,
              plateform: this.user.proprietaire,
              headerTab: this.tabHeader,
              Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
            })
            .then((res) => {
              this.loadingFiles = false;
              this.showFile = false;
              this.success("Les articles ont été importés avec succès");
              this.countArticle();
            });
        } else {
          this.error("Le fichier est vide");
          this.loadingFiles = false;
        }
      } else {
        this.error("Les colonnes ne doivent pas être identiques");
      }
    },
    controlHeaderTab(arr) {
      for (let i = 0; i < arr.length - 1; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) {
            return false;
          }
        }
      }
      return true;
    },
    closeImport() {
      this.showFile = false;
      this.file = null;
      this.readFile = null;
    },
    deleteArticles() {
      this.loadingFiles = true;
      axios
        .post(`${process.env.VUE_APP_API}/article/deleteArticles`, {
          plateform: this.user.proprietaire,
          Prefixe_tarif: this.fournisseurTab.Prefixe_tarif,
        })
        .then(() => {
          this.loadingFiles = false;
          this.showFile = false;
          this.success("Les articles ont été supprimés avec succès");
          this.countArticle();
        });
    },
    deleteRow(index) {
      this.readFile.splice(index, 1);
    },
    deleteCol(index) {
      this.readFile.forEach((element) => {
        element.splice(index, 1);
      });
      this.headers.splice(index, 1);
    },
    success(message) {
      this.toast.success(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.warning(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  async mounted() {
    await this.getFournisseur();
    this.countArticle();
  },
};
</script>
<style></style>
