<template>
  <!-- <div class="flex justify-end">
    <button
      class="px-6 py-2 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-opacity-25 focus:outline-none"
      @click="getCommander()"
    >
      Commander
    </button>
  </div> -->

  <div
    v-if="openCreateReception"
    :class="`modal ${
      !openCreateReception && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openCreateReception = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 80rem; max-height: 45rem"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Création de réception</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openCreateReception = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->

        <div>
          <div class="flex flex-row items-center space-x-4">
            <div class="flex flex-col">
              <span>Numero de commande :</span>
              <input
                type="text"
                v-model="createName"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div class="flex flex-col">
              <span>Type de commande :</span>
              <input
                type="text"
                v-model="createType"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div class="flex flex-col">
              <span>Fournisseur :</span>
              <input
                type="text"
                v-model="createFournisseur"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <!-- Table -->
          <div
            class="py-2 my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Code EAN
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Marque
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Description
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      v-if="user.accountType == 'Administrateur'"
                    >
                      Prix d'achat
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Quantité réceptionnée
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Quantité commandée
                    </th>
                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr
                    v-for="(rec, index) in formattedFile"
                    :key="index"
                    class="cursor-pointer hover:bg-gray-100"
                  >
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        <span
                          class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                          >{{ rec.Ref_fournisseur }}</span
                        >
                      </div>
                      <div class="text-sm leading-5 text-gray-900">
                        <span
                          class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                          >{{ rec.Code_EAN }}</span
                        >
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ rec.Code_marque }}
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        {{ rec.Description }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                      v-if="user.accountType == 'Administrateur'"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        <input
                          class="p-1"
                          type="number"
                          v-model="rec.prix_net"
                        />
                        <span> €</span>
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ rec.quantity_received }}
                    </td>
                    <td
                      class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ rec.quantity_asked }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!--Footer-->
        <div class="flex space-x-2 justify-end pt-2">
          <button
            @click="createCommande()"
            class="p-3 px-6 py-3 text-white bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none"
          >
            Créer
          </button>
          <button
            @click="openCreateReception = false"
            class="p-3 px-6 py-3 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <button class="flex flex-row space-x-2 items-center">
      <backButton />
    </button>
  </div>

  <searchInput
    placeholder="Rechercher dans réception fournisseur"
    @searchWord="search(1, 10, $event)"
    @catchFalse="searchCatchError = $event"
    :searchStarted="searchInProgress"
    :catchError="searchCatchError"
    class="mt-2"
  />

  <div class="flex justify-between mt-4 items-center">
    <div class="flex space-x-2">
      <button
        class="text-white text-base p-1 rounded shadow-lg w-36"
        :class="state == 'received' ? 'bg-blue-800' : ' bg-blue-400'"
        @click="getAllCmd('received', 1, 10)"
      >
        Réceptionné
      </button>
      <button
        class="text-white text-base p-1 rounded shadow-lg w-36"
        :class="state == 'waiting' ? 'bg-blue-800' : ' bg-blue-400'"
        @click="getAllCmd('waiting', 1, 10)"
      >
        Non réceptionné
      </button>
      <button
        class="text-white text-base p-1 rounded shadow-lg w-36"
        :class="state == '' ? 'bg-blue-800' : ' bg-blue-400'"
        @click="getAllCmd('', 1, 10)"
      >
        Tous
      </button>
    </div>
    <div class="flex space-x-2">
      <button
        class="bg-gray-500 text-white text-base p-1 rounded shadow"
        @click="exportExcel()"
      >
        Template excel
      </button>
      <label for="dropzone-file">
        <span class="p-2 bg-green-500 text-white rounded shadow cursor-pointer">
          Importer excel
        </span>
        <input
          id="dropzone-file"
          type="file"
          class="hidden"
          accept=".xlsx, .xls, .csv"
          @change="readExcel()"
        />
      </label>
    </div>
  </div>

  <div
    class="py-2 -my-2 overflow-x-auto mt-4 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
  >
    <div
      class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
    >
      <table class="min-w-full">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              N° Commande
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Date
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Type
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Nombre d'articles
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Commandé par
            </th>
            <!-- <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Document
            </th> -->
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
            >
              Etat
            </th>
          </tr>
        </thead>

        <tbody class="bg-white" v-if="Commandes.length > 0">
          <tr
            v-for="(cmd, index) in Commandes"
            :key="index"
            style="cursor: pointer"
            class="hover:bg-gray-100"
            @click="setReception(cmd)"
          >
            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <span>{{ cmd.Name }}</span>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="text-sm leading-5 text-gray-900">
                {{ cmd.Date }}
              </div>
            </td>

            <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
              <div class="text-sm leading-5 text-gray-900">
                {{ cmd.Type }}
              </div>
            </td>

            <td
              class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
            >
              <div class="text-sm leading-5 text-gray-900">
                {{ cmd.Articles.length }}
              </div>
            </td>

            <td
              class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
            >
              <div class="text-sm leading-5 text-gray-900">
                {{ cmd.User }}
              </div>
            </td>

            <!-- <td
              class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
            >
              {{ cmd.Document[0].type }} N° {{ cmd.Document[0].Numero }} -
              {{ cmd.Document[0].Client }}
            </td> -->
            <td
              class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap"
            >
              <span class="bg-red-400 text-white rounded p-1" v-if="!cmd.etat"
                >Non réceptionné</span
              >
              <div v-else class="flex flex-col">
                <span class="bg-green-500 text-white rounded p-1"
                  >Réceptionné</span
                >
                <span class="text-xs">Par : {{ cmd.receptionneur }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <pageNumber
        :pageStart="pageStart"
        :pageNext="pageNext"
        :tabList="CommandesLength"
        :pageTab="pageTab"
        :pageTotal="Math.ceil(CommandesLength / 10)"
        :clickFunction="(arg1, arg2) => getAllCmd(state, arg1, arg2)"
        :currentPage="currentPage"
        :typeList="'commandes'"
        @update:start="pageStart = $event"
        @update:tab="pageTab = $event"
        @update:next="pageNext = $event"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import { createReception } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import searchInput from "@/components/searchInput.vue";
import pageNumber from "@/components/pageNumber.vue";
import { data } from "autoprefixer";

export default {
  name: "MyCommandes",
  data: () => ({
    Commandes: [],
    openCreateReception: false,
    readFile: null,
    file: null,
    loadingFiles: false,
    formattedFile: [],
    createName: "",
    createType: "",
    createFournisseur: "",
    state: "waiting",

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    currentPage: 1,
    CommandesLength: 0,
  }),
  components: {
    backButton,
    searchInput,
    pageNumber,
  },
  computed: {
    ...mapGetters(["user"]),
    setDate() {
      const json = new Date().toJSON();
      const date = new Date(json).toLocaleDateString();
      return date;
    },
  },
  methods: {
    ...mapActions(["addcommandereception"]),
    getAllCmd(state, skipValue, limitValue) {
      this.state = state;
      this.currentPage = skipValue;
      axios
        .post(`${process.env.VUE_APP_API}/commandeFournisseur/getByFilter`, {
          plateform: this.user.proprietaire,
          state: state,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
        })
        .then((response) => {
          console.log(response.data);
          this.Commandes = response.data.data;
          this.CommandesLength = response.data.length;
        });
    },
    getCommandes() {
      axios
        .get(
          `${process.env.VUE_APP_API}/commandeFournisseur/${this.user.proprietaire}`
        )
        .then((response) => {
          this.Commandes = response.data;
        });
    },
    getCommandesReceived() {
      axios
        .get(
          `${process.env.VUE_APP_API}/commandeFournisseur/receivedCommand/${this.user.proprietaire}`
        )
        .then((response) => {
          this.Commandes = response.data;
        });
    },
    getCommandesWaiting() {
      axios
        .get(
          `${process.env.VUE_APP_API}/commandeFournisseur/waitingCommand/${this.user.proprietaire}`
        )
        .then((response) => {
          this.Commandes = response.data;
        });
    },
    setReception(cmd) {
      this.addcommandereception(cmd);
      this.$router.push("/receptionFournisseur");
    },
    async exportExcel() {
      let infos = [
        {
          Ref_fournisseur: "",
          Code_marque: "",
          fournisseur: "",
          quantity_asked: "",
          prix_net: "",
        },
      ];
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(infos);
      XLSX.utils.book_append_sheet(wb, ws, "Reception");

      XLSX.writeFile(wb, "Reception_" + this.user.proprietaire + ".xlsx");
    },
    async readExcel() {
      var file = event.target.files ? event.target.files[0] : null;
      if (file.size < 1000000) {
        this.loadingFiles = true;
        this.readFile = await readXlsxFile(file);
        this.readFile.splice(0, 1);
        this.loadingFiles = false;
        this.openCreateReception = true;
        let res = await axios.post(
          `${process.env.VUE_APP_API}/commandeFournisseur/formatReceptionExcel`,
          { plateform: this.user.proprietaire, file: this.readFile }
        );
        this.formattedFile = res.data;
      } else {
        this.error("Le fichier est trop volumineux");
      }
    },
    async createCommande() {
      if (this.createName != "" && this.createType != "") {
        let recept = await createReception(
          this.user.username,
          this.user.proprietaire,
          this.createFournisseur,
          this.formattedFile,
          this.createName,
          this.createType,
          [],
          []
        );
        if (recept) {
          this.setReception(recept);
        }
      }
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getAllCmd(this.state, skipValue, limitValue);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/searchInPlateform`,
            {
              plateform: this.user.proprietaire,
              state: this.state,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 10,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.Commandes = response.data.results;
            this.CommandesLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  mounted() {
    this.getAllCmd("waiting", 1, 10);
    // this.getCommandesWaiting(this.index);
    this.pageSwitch();
  },
};
</script>
<style></style>
