<template>
  <!-- Breadcrumb -->
  <div>
    <div
      v-if="open"
      :class="`modal ${
        !open && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="open = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-5xl"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">References Cross</p>
            <div class="z-50 cursor-pointer modal-close" @click="open = false">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <!-- Body -->
          <!-- {{ crossoetab }} -->
          <div class="flex flex-col mt-8" style="height: 80vh">
            <div
              class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            >
              <div
                class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
              >
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Ref
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Marque
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Prix
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Stock
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      ></th>

                      <th
                        class="px-6 py-3 border-b border-gray-200 bg-gray-50"
                      ></th>
                    </tr>
                  </thead>

                  <tbody class="bg-white">
                    <tr
                      v-for="(u, index) in crossoetab"
                      :key="index"
                      class="hover:bg-gray-100"
                    >
                      <td
                        class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                      >
                        <div
                          class="text-sm font-medium leading-5 text-gray-500"
                        >
                          {{ u.articleNo }}
                        </div>
                        <div
                          class="text-sm font-medium leading-5 text-gray-900"
                        >
                          {{ u.articleName }}
                        </div>
                      </td>

                      <td
                        class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                      >
                        <div class="text-sm leading-5 text-gray-900">
                          {{ u.brandName }}
                        </div>
                      </td>

                      <td
                        class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                      >
                        <span v-if="prixtab[index]"
                          >{{
                            parseFloat(prixtab[index].prix).toFixed(2)
                          }}
                          €</span
                        >
                      </td>
                      <td
                        class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                      >
                        <div v-if="prixtab[index]">
                          <div
                            v-if="prixtab[index] && prixtab[index].stock > 0"
                            class="flex flex-row items-center mx-auto text-sm"
                          >
                            <span class="material-icons text-green-600">
                              fiber_manual_record
                            </span>
                            <span class="text-green-600">Dispo</span>
                          </div>
                          <div
                            v-else
                            class="flex flex-row items-center mx-auto text-sm"
                          >
                            <span
                              class="material-icons-outlined text-orange-600"
                            >
                              factory
                            </span>
                            <span class="text-orange-600">Sur commande</span>
                          </div>
                        </div>
                      </td>
                      <td
                        class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                        v-if="prixtab[index] != 'NOK'"
                      >
                        <span
                          class="material-icons-round cursor-pointer ml-4 text-blue-600"
                          @click="addPanier(u.articleNo, u.brandNo)"
                        >
                          add_shopping_cart
                        </span>
                      </td>
                      <td
                        class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                        v-else
                      >
                        --
                      </td>
                    </tr>
                  </tbody>
                </table>
                <RingLoader
                  color="#2d74da"
                  v-show="generalloading"
                  class="py-8 w-fit m-auto"
                />
              </div>
            </div>
          </div>

          <!--Body-->

          <!--Footer-->
          <div class="flex justify-end pt-2">
            <button
              @click="open = false"
              class="p-3 px-6 py-3 mr-2 text-indigo-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-indigo-400 focus:outline-none"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="flex justify-center">
      <div class="flex justify-center items-center space-x-4 bg-sky-500 bg-opacity-25 p-1 w-fit rounded -mt-6" v-if="vehiculeSet || vehiculeVin">
        <div class="flex space-x-1">
          <span class="text-gray-700">plaque : </span>
          <span class="font-medium uppercase">{{ vehicule[0].data.AWN_immat }}</span>
        </div>
        <div class="flex space-x-1">
          <span class="text-gray-700">marque : </span>
          <span class="font-medium">{{ vehicule[0].data.AWN_marque }}</span>
        </div>
        <div class="flex space-x-1">
          <span class="text-gray-700">modèle : </span>
          <span class="font-medium">{{ vehicule[0].data.AWN_modele }}</span>
        </div>
        <div class="flex space-x-1">
          <span class="text-gray-700">version : </span>
          <span class="font-medium">{{ vehicule[0].data.AWN_version }}</span>
        </div>
        <div class="flex space-x-1">
          <span class="text-gray-700">mise en circulation : </span>
          <span class="font-medium">{{ vehicule[0].data.AWN_date_mise_en_circulation }}</span>
        </div>
      </div>
    </div> -->

    <div
      class="flex flex-row"
      v-if="vehiculeSet || vehiculeVin"
      v-show="!assemblyGroupState"
    >
      <div class="w-1/2">
        <button
          v-show="!assemblyGroupState"
          class="w-54 h-8"
          @click="goAssemblyGroup"
        >
          <div
            id="retour"
            class="flex justify-start bg-slate-100 rounded-lg px-1 w-fit hover:bg-white border border-blue-400"
          >
            <img src="../../assets/RetourIcon.png" class="w-6" />
            <span style="color: #5976e8">Retour</span>
          </div>
        </button>
      </div>
      <div class="w-1/2">
        <span
          class="ml-2 p-2 text-blue-500 text-xl font-bold items-center mx-auto"
          v-show="!assemblyGroupState"
          >{{ tempAG }}</span
        >
      </div>

      <div
        class="flex flex-row w-1/2 p-2 h-fit"
        v-if="vehiculeSet || vehiculeVin"
        v-show="!assemblyGroupState"
      >
        <span
          v-show="isArticleState"
          class="p-2 rounded-sm bg-green-400 cursor-pointer text-white"
          @click="setDispo()"
          >Dispo</span
        >
        <span
          class="p-2 mx-4 rounded-sm bg-blue-400 cursor-pointer text-white"
          v-show="isArticleState"
          @click="setEnVente()"
          >En vente</span
        >
        <span
          class="p-2 rounded-sm bg-gray-400 cursor-pointer text-white"
          v-show="isArticleState"
          @click="setTous()"
          >Tous</span
        >
      </div>
    </div>

    <div class="flex" style="justify-content: space-between">
      <div
        class="flex flex-col space-y-2 overflow-y-auto"
        style="height: 73vh"
        v-if="vehiculeSet || vehiculeVin"
        v-show="!assemblyGroupState"
      >
        <!-- partie marque -->
        <span
          v-bind:class="{ white: !clicked[index], blue: clicked[index] }"
          v-on:click="clicked[index] = !clicked[index]"
          v-for="(brand, index) in articlesBrand"
          :key="index"
          class="items-center p-1 text-white border rounded shadow cursor-pointer"
          @click="brander(brand)"
          >{{ brand }}</span
        >
      </div>

      <!-- Partie droite avec les articles  -->
      <div class="flex flex-col w-full">
        <div
          class="flex flex-row w-full"
          v-if="vehiculeSet || vehiculeVin"
          v-show="!assemblyGroupState"
        ></div>
        <div
          class="relative overflow-y-auto overflow-x-hidden"
          style="height: 73vh"
          v-if="vehiculeSet || vehiculeVin"
          v-show="!assemblyGroupState"
        >
          <span class="mx-auto" v-show="!assemblyGroupState">
            <PulseLoader color="#2d74da" v-show="isLoading" />
          </span>
          <div
            v-for="(piece, index) in articles"
            :key="index"
            class="flex flex-wrap"
            style="flex-direction: row-reverse"
          >
            <div class="w-full px-6 sm:w-full xl:w-full">
              <div
                class="flex flex-col items-center px-1 py-1 bg-white rounded-md shadow-sm"
              >
                <div class="flex flex-row w-full">
                  <div class="pl-3 w-1/6">
                    <span class="text-m font-semibold">{{
                      piece.mfrName
                    }}</span>
                  </div>
                  <div class="w-3/6">
                    <div class="flex flex-row items-center">
                      <div>
                        <span class="text-m font-semibold text-blue-700">{{
                          piece.articleNumber
                        }}</span>
                      </div>
                      <div
                        class="flex flex-row items-center ml-2 p-1 cursor-pointer"
                        @click="
                          searchCross(piece.articleNumber), (open = !open)
                        "
                      >
                        <span class="material-icons-outlined text-orange-600">
                          cached
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="w-2/6"></div>
                </div>
                <div class="flex flex-row w-full">
                  <div class="w-1/6">
                    <img
                      class="p-3 h-20 w-25"
                      v-if="piece.images[0]"
                      :src="piece.images[0].imageURL200"
                    />
                    <img
                      class="p-3 h-20 w-25"
                      v-else
                      src="../../assets/not-found.png"
                    />
                  </div>

                  <div class="w-3/6">
                    <div class="flex flex-row items-center">
                      <h4 class="text-xl font-semibold text-gray-700">
                        {{ piece.genericArticles[0].genericArticleDescription }}
                      </h4>
                    </div>
                    <div class="flex flex-row">
                      <div>
                        <span
                          v-for="(attribut, indexe) in piece.articleCriteria"
                          :key="indexe"
                        >
                          <span class="text-xs text-gray-500"
                            >{{ attribut.criteriaDescription }} :
                            {{ attribut.rawValue }}
                            {{ attribut.criteriaUnitDescription }},
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      v-show="!extendInfo[index]"
                      class="flex flex-row items-center text-blue-500 text-sm cursor-pointer"
                      @click="extendInfo[index] = !extendInfo[index]"
                    >
                      <span>Ref constructeur </span>
                      <span class="material-icons-outlined ml-1 text-sm">
                        info
                      </span>
                    </div>
                    <div
                      v-for="(ref, indexes) in piece.oemNumbers"
                      :key="indexes"
                      v-show="extendInfo[index]"
                    >
                      <span
                        class="text-sm text-gray-500 hover:text-indigo-400 cursor-pointer"
                        @click="
                          searchCrossOe(ref.articleNumber), (open = !open)
                        "
                        >{{ ref.mfrName }} : {{ ref.articleNumber }}</span
                      >
                    </div>
                    <div
                      v-show="extendInfo[index]"
                      class="flex flex-row items-center text-red-500 text-sm cursor-pointer"
                      @click="extendInfo[index] = !extendInfo[index]"
                    >
                      <span>fermer </span>
                      <span class="material-icons-outlined text-sm ml-1">
                        arrow_upward
                      </span>
                    </div>
                  </div>
                  <!-- STOCK -->
                  <div class="w-2/6 flex flex-row p-2">
                    <div
                      class="mt-2 flex flex-col mx-auto w-1/3"
                      v-if="prixTabTD[index]"
                    >
                      <div
                        v-if="prixTabTD[index].stock != 'ND'"
                        class="flex flex-row items-center space-x-1 mx-auto mt-1"
                      >
                        <input
                          class="shadow bd-gray-400 w-15 rounded-sm border border-gray-400 focus:outline-2 outline-sky-300"
                          type="Number"
                          min="0"
                          step="1"
                          max="999"
                          v-model="qtyTabTD[index]"
                        />
                        <span
                          class="material-icons-round text-blue-500 cursor-pointer mx-auto"
                          @click="
                            addPanier(
                              piece.articleNumber,
                              piece.dataSupplierId,
                              qtyTabTD[index]
                            )
                          "
                        >
                          add_shopping_cart
                        </span>
                      </div>
                    </div>

                    <div
                      class="flex flex-col mx-auto w-1/3"
                      v-if="prixTabTD[index]"
                    >
                      <!-- <CardQuantity /> -->
                      <div
                        v-if="prixTabTD[index] && prixTabTD[index].stock > 0"
                        class="flex flex-row items-center mx-auto text-sm"
                      >
                        <span
                          @mouseover="showIconDispo[index] = true"
                          @mouseleave="showIconDispo[index] = false"
                          style="width: 1.75rem; height: 1.75rem"
                          class="material-icons-outlined cursor-pointer p-0.5 rounded-full bg-green-500 text-white"
                          >check_circle</span
                        >
                        <div
                          v-if="prixTabTD[index]"
                          class="flex flex-col absolute mt-16 bg-gray-200 p-2 rounded shadow-lg z-20"
                          v-show="showIconDispo[index]"
                        >
                          <span class="text-green-500 m-auto">Disponible</span>
                          <span class="mt-1 m-auto"
                            >{{ prixTabTD[index].stock }} pcs en stock</span
                          >
                        </div>
                      </div>
                      <div
                        v-else
                        class="flex flex-row items-center mx-auto text-sm"
                      >
                        <span
                          @mouseover="showIconCmd[index] = true"
                          @mouseleave="showIconCmd[index] = false"
                          style="width: 1.75rem; height: 1.75rem"
                          class="material-icons-outlined cursor-pointer p-0.5 rounded-full bg-orange-500 text-white"
                          >help_outline</span
                        >
                        <div
                          class="flex flex-col absolute mt-16 bg-gray-200 p-2 rounded shadow-lg z-20"
                          v-show="showIconCmd[index]"
                        >
                          <span class="text-orange-500 m-auto"
                            >Disponible sur commande</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-col w-1/3">
                      <RingLoader
                        class="mx-auto"
                        color="#2d74da"
                        size="3rem"
                        v-show="isLoadingPrice"
                      />
                      <div v-if="prixTabTD[index]" class="mx-auto">
                        <span class="text-sm">
                          Brut HT :
                          {{ parseFloat(prixTabTD[index].prix).toFixed(2) }}
                          €</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Assembly Groupe  -->
    <div v-show="assemblyGroupState">
      <div v-if="vehiculeSet || vehiculeVin">
        <!--Assembly Group place-->
        <div class="flex flex-wrap mx-6" v-if="plateform.typeCat == 'base'">
          <div
            class="p-2 rounded shadow bg-white w-80 h-fit ml-4 mt-4"
            v-for="(ag, index) in assemblyGroup"
            :key="index"
          >
            <div class="flex flex-col">
              <div class="flex flex-row items-center">
                <span
                  class="w-4/5 text-xl text-gray-600 hover:text-blue-600 hover:font-semibold cursor-pointer"
                  @click="getChildAssemblyGroup(ag.assemblyGroupNodeId, index)"
                  >{{ ag.assemblyGroupName }}</span
                >
                <span class="w-1/5"
                  ><img
                    class="h-12 w-17 cursor-pointer"
                    :src="
                      require('../../assets/Icons_TecDoc/' +
                        ag.assemblyGroupNodeId +
                        '.png')
                    "
                    @click="
                      showArticles(ag.assemblyGroupName, ag.assemblyGroupNodeId)
                    "
                  />
                </span>
              </div>
              <div
                class="flex flex-row items-center"
                v-if="childAssemblyGroup[index] == ag.assemblyGroupNodeId"
              >
                <!-- <span
                class="text-sm text-blue-400 hover:text-blue-600 cursor-pointer"
                @click="getChildAssemblyGroup(ag.assemblyGroupNodeId, index)"
                >Voir plus</span> -->
                <span
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  @click="getChildAssemblyGroup(ag.assemblyGroupNodeId, index)"
                  >keyboard_double_arrow_down</span
                >
              </div>

              <div v-if="childAssemblyGroup[index] != ag.assemblyGroupNodeId">
                <div
                  class="flex flex-row"
                  v-for="(child, indexC) in childAssemblyGroup[index]"
                  :key="indexC"
                >
                  <span
                    class="flex flex-row items-center hover:text-blue-400 hover:font-semibold"
                  >
                    <span class="material-icons-round"> arrow_right </span>
                    <span
                      class="text-xs cursor-pointer"
                      @click="
                        showArticles(
                          child.assemblyGroupName,
                          child.assemblyGroupNodeId
                        ),
                          hideChildAssemblyGroup(ag.assemblyGroupNodeId, index)
                      "
                      >{{ child.assemblyGroupName }}</span
                    >
                  </span>
                </div>
              </div>
              <div
                class="flex flex-row items-center"
                v-if="childAssemblyGroup[index] != ag.assemblyGroupNodeId"
              >
                <span
                  class="text-sm text-blue-400 hover:text-blue-600 cursor-pointer"
                  @click="hideChildAssemblyGroup(ag.assemblyGroupNodeId, index)"
                  >Voir moins</span
                >
                <span
                  class="material-icons-outlined cursor-pointer"
                  style="color: orange"
                  @click="hideChildAssemblyGroup(ag.assemblyGroupNodeId, index)"
                >
                  keyboard_double_arrow_up
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-wrap mx-6"
          v-else-if="
            plateform.typeCat == 'personnalise' && catalogueConfiguration[0]
          "
        >
          <div
            v-for="(ag, index) in catalogueConfiguration[0].configuration"
            :key="index"
          >
            <div
              class="p-2 rounded shadow bg-white w-80 h-fit ml-4 mt-4"
              v-if="ag.actif"
            >
              <div class="flex flex-col">
                <div class="flex flex-row items-center">
                  <div class="w-4/5">
                    <span
                      class="text-xl text-gray-600 hover:text-blue-600 hover:font-semibold cursor-pointer"
                      v-if="ag.named"
                      @click="
                        setPersoChildCatalogue(index, ag.assemblyGroupNodeId)
                      "
                      >{{ ag.newName }}</span
                    >
                    <span
                      v-else
                      class="text-xl text-gray-600 hover:text-blue-600 hover:font-semibold cursor-pointer"
                      @click="
                        setPersoChildCatalogue(index, ag.assemblyGroupNodeId)
                      "
                      >{{ ag.assemblyGroupName }}</span
                    >
                  </div>
                  <span class="w-1/5">
                    <!-- {{ ag.assemblyGroupNodeId }} -->
                    <img
                      class="h-12 w-17 cursor-pointer"
                      :src="
                        require('../../assets/Icons_TecDoc/' +
                          ag.assemblyGroupNodeId +
                          '.png')
                      "
                      @click="
                        showArticles(
                          ag.assemblyGroupName,
                          ag.assemblyGroupNodeId,
                          ag
                        )
                      "
                    />
                  </span>
                </div>

                <div
                  class="flex flex-row items-center"
                  v-show="!listChildState[index]"
                >
                  <!-- <span
                class="text-sm text-blue-400 hover:text-blue-600 cursor-pointer"
                @click="getChildAssemblyGroup(ag.assemblyGroupNodeId, index)"
                >Voir plus</span> -->
                  <span
                    class="material-icons-outlined cursor-pointer"
                    style="color: orange"
                    @click="
                      setPersoChildCatalogue(index, ag.assemblyGroupNodeId)
                    "
                    >keyboard_double_arrow_down</span
                  >
                </div>

                <div
                  class="flex flex-row"
                  v-show="listChildState[index]"
                  v-for="(child, indexC) in ag.child"
                  :key="indexC"
                >
                  <span
                    class="flex flex-row items-center hover:text-blue-400 hover:font-semibold"
                  >
                    <span class="material-icons-round"> arrow_right </span>
                    <div>
                      <span
                        v-if="child.named"
                        class="text-xs cursor-pointer"
                        @click="
                          showArticles(
                            child.assemblyGroupName,
                            child.assemblyGroupNodeId,
                            ag
                          ),
                            (indexChildAGPerso = indexC),
                            (listChildState[index] = !listChildState[index])
                        "
                        >{{ child.newName }}</span
                      >
                      <span
                        v-else
                        class="text-xs cursor-pointer"
                        @click="
                          showArticles(
                            child.assemblyGroupName,
                            child.assemblyGroupNodeId,
                            ag
                          ),
                            (indexChildAGPerso = indexC),
                            (listChildState[index] = !listChildState[index])
                        "
                        >{{ child.assemblyGroupName }}</span
                      >
                    </div>
                  </span>
                </div>
                <div
                  class="flex flex-row items-center"
                  v-show="listChildState[index]"
                >
                  <span
                    class="text-sm text-blue-400 hover:text-blue-600 cursor-pointer"
                    @click="listChildState[index] = !listChildState[index]"
                    >Voir moins</span
                  >
                  <span
                    class="material-icons-outlined cursor-pointer"
                    style="color: orange"
                    @click="listChildState[index] = !listChildState[index]"
                  >
                    keyboard_double_arrow_up
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flex justify-center mt-10 items-center">
          <div class="flex-row bg-white p-4 rounded shadow w-4/6 h-fit">
            <!-- PLAQUE -->
            <!-- <div class="flex justify-center">
              <div class="plate-immat">
                <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
                <input
                  type="text"
                  placeholder="AB-123-CD"
                  name="plate-number"
                  class="plate-number focus:outline-2 outline-sky-300"
                  v-model="plaque"
                  v-on:keyup.enter="getPlaque()"
                />
                <i class="plate-flag fx-center-center wlf wlf-plate-FR"></i>
              </div>
            </div> -->
            <!-- VIN -->
            <div class="flex justify-center items-center mt-4">
              <input
                class="w-2/6 p-3 focus:outline-2 outline-sky-300"
                type="text"
                v-model="VIN"
                placeholder="Rechercher par VIN"
                v-on:keyup.enter="getCarIDVIN()"
              />
            </div>
            <!-- type-mine -->
            <div class="flex justify-center mt-4">
              <input
                class="w-2/6 p-3 focus:outline-2 outline-sky-300"
                type="text"
                v-model="typeMine"
                placeholder="Rechercher par type-mine"
                v-on:keyup.enter="getCarIDTypeMine()"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex justify-center">
        <img src="../../assets/tecdoc_logo.jpg" class="" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
// import CardQuantity from "../../components/StockMaterialComponent/cardQuantity.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import RingLoader from "vue-spinner/src/RingLoader.vue";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyTecDoc",
  components: {
    // CardQuantity,
    PulseLoader,
    RingLoader,
  },
  computed: {
    ...mapGetters([
      "vehicule",
      "vehiculeSet",
      "actionArticle",
      "vehiculeVin",
      "crossoetab",
      "generalloading",
      "prixtab",
      "user",
      "plateform",
    ]),
    setTotal() {
      var total = 0.0;
      this.actionArticle.forEach((element) => {
        total = total + parseFloat(element.Prix_euro);
      });
      return total;
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  data() {
    return {
      open: false,
      isLoading: false,
      isLoadingPrice: false,
      isArticleState: false,
      carData: [],
      assemblyGroup: [],
      childAssemblyGroup: [],
      articles: [],
      articles_Save: [],
      articlesB: [],
      articlesC: [],
      assemblyGroupState: true,
      carID: 12345,
      docid: [],
      tempdoc: [],
      articlesFilter: [],
      articlesBrand: [],
      filtresTab: [],
      filtresLenA: 0,
      filtresLenB: 0,
      BrandTab: [],
      BrandLenA: 0,
      BrandLenB: 0,
      filterDone: false,
      brandDone: false,
      tempAG: "123456",
      tempAGN: 123456,
      extendInfo: [],
      VIN: "",
      typeMine: "",
      prixTabTD: [],
      prixTabTD_Save: [],
      prixTabTD_B: [],
      prixTabTD_C: [],
      tabRecherchePS: [],
      catalogueConfiguration: [],
      catalogueConfigurationTemp: [],
      listChildState: [],
      clicked: [],
      index: 0,
      showIconDispo: [],
      showIconCmd: [],
      agLinkedTab: [],
      qtyTabTD: [],
      statePersoCatalogue: false,
      assemblyGroupPersoSelected: [],
      indexChildAGPerso: null,
    };
  },
  methods: {
    ...mapActions([
      "addactionArticle",
      "suppactionArticle",
      "clearactionArticle",
      "clearvehicule",
      "vehiculetrue",
      "vehiculefalse",
      "searchCrossOe",
      "searchCross",
      "searchTranslatedDirectAdd",
    ]),
    deletevehicule() {
      this.carData = [];
      this.vehiculefalse();
      this.clearvehicule();
    },
    getCarID() {
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getcaridbytm`, {
          Ref_fournisseur: this.vehicule[0].data.AWN_type_mine,
        })
        .then((response) => {
          this.carData = response.data.data.array[0];
          this.carID = response.data.data.array[0].carId;

          this.getAssemblyGroup();
          this.getCatalogueConfiguration();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCarIDVIN() {
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getcarid`, {
          Ref_fournisseur: this.VIN,
        })
        .then((response) => {
          this.carData = response.data.data.matchingVehicles.array[0];
          this.carID = response.data.data.matchingVehicles.array[0].carId;
          this.getAssemblyGroup();
          this.vehiculetrue();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCarIDTypeMine() {
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getcaridbytm`, {
          Ref_fournisseur: this.typeMine,
        })
        .then((response) => {
          console.log(response.data);
          this.carData = response.data.data.array[0];
          this.carID = response.data.data.array[0].carId;
          this.getAssemblyGroup();
          this.vehiculetrue();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAssemblyGroup() {
      this.filtresTab = [];
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getassemblygroup`, {
          Ref_fournisseur: this.carID,
        })
        .then((response2) => {
          this.assemblyGroup = response2.data;
          response2.data.forEach((element) => {
            this.childAssemblyGroup.push(element.assemblyGroupNodeId);
          });
        });
    },
    getChildAssemblyGroup(parentID, index) {
      this.filtresTab = [];
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getchildassemblygroup`, {
          Ref_fournisseur: { car: this.carID, parent: parentID },
        })
        .then((response) => {
          this.childAssemblyGroup[index] = response.data;
        });
    },
    hideChildAssemblyGroup(parentID, index) {
      this.filtresTab = [];
      this.childAssemblyGroup[index] = parentID;
    },
    async showArticles(agname, assemblygroup, persoAG) {
      this.isLoading = true;
      this.articles = [];
      this.articlesFilter = [];
      this.articlesBrand = [];
      this.filterDone = false;
      this.brandDone = false;
      this.filtresTab = [];
      this.BrandTab = [];
      this.tempAG = agname;
      this.tempAGN = assemblygroup;
      this.assemblyGroupState = false;
      this.prixTabTD = [];
      this.tabRecherchePS = [];
      if (persoAG) {
        this.statePersoCatalogue = true;
        this.assemblyGroupPersoSelected = persoAG;
      } else {
        this.statePersoCatalogue = false;
        this.assemblyGroupPersoSelected = [];
      }
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/gettecdocarticle`, {
          AssemblyGroup: assemblygroup,
          LinkingTarget: this.carID,
        })
        .then((response) => {
          this.articles = response.data;
          this.articles_Save = response.data;
          this.isLoading = false;
        })
        .then(() => {
          this.setPrixTD();
        })
        .then(() => {
          this.getPrixTD();
        });
    },
    setPrixTD() {
      this.articles.forEach((article) => {
        this.tabRecherchePS.push({
          marque: article.mfrName,
          ref_f: article.articleNumber,
          plateform: this.user.proprietaire,
        });
      });
    },
    addPanier(article, marqueid, qty) {
      this.searchTranslatedDirectAdd({
        artNum: article,
        marque: marqueid,
        quantityAdded: qty,
        plateform: this.user.proprietaire,
      });
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    setDispo() {
      var Dispo = [];
      var DispoPTD = [];
      this.articles = this.articles_Save;
      this.prixTabTD = this.prixTabTD_Save;
      this.articles.forEach((article, index) => {
        if (
          this.prixTabTD[index].prix != "ND" &&
          this.prixTabTD[index].stock > 0
        ) {
          Dispo.push(article);
          DispoPTD.push(this.prixTabTD[index]);
        }
      });
      this.articles = Dispo;
      this.prixTabTD = DispoPTD;
      this.setBrand();
    },
    setEnVente() {
      var enVente = [];
      var enVentePTD = [];
      this.articles = this.articles_Save;
      this.prixTabTD = this.prixTabTD_Save;
      this.articles.forEach((article, index) => {
        this.qtyTabTD[index] = 1;
        if (
          this.prixTabTD[index].prix != "ND" &&
          this.prixTabTD[index].stock > 0
        ) {
          enVente.push(article);
          enVentePTD.push(this.prixTabTD[index]);
        }
      });
      this.articles.forEach((article, index) => {
        if (
          this.prixTabTD[index].prix != "ND" &&
          (this.prixTabTD[index].stock < 1 || !this.prixTabTD[index].stock)
        ) {
          enVente.push(article);
          enVentePTD.push(this.prixTabTD[index]);
        }
      });
      this.articles = enVente;
      this.prixTabTD = enVentePTD;

      this.getBrandFilter();
    },
    setTous() {
      this.articles = this.articles_Save;
      this.prixTabTD = this.prixTabTD_Save;
      this.setBrand();
    },
    getPrixTD() {
      this.isLoadingPrice = true;
      this.isArticleState = false;
      axios
        .post(`${process.env.VUE_APP_API}/articlecross/getps`, {
          Ref_fournisseur: this.tabRecherchePS,
        })
        .then((response) => {
          this.prixTabTD = response.data;
          this.prixTabTD_Save = response.data;
          this.isLoadingPrice = false;
          this.isArticleState = true;
        })
        .then(() => {
          this.setEnVente();
        });
    },
    getBrandFilter() {
      var brandList = [];
      var articleBrandTemp = [];

      if (this.indexChildAGPerso != null) {
        if (this.statePersoCatalogue) {
          if (this.assemblyGroupPersoSelected.brand.length > 0) {
            console.log("Il y a une config ET des marques");
            for (const brand of this.assemblyGroupPersoSelected.brand) {
              if (
                this.assemblyGroupPersoSelected.child[this.indexChildAGPerso]
                  .blacklistBrand
              ) {
                if (
                  !this.assemblyGroupPersoSelected.child[
                    this.indexChildAGPerso
                  ].blacklistBrand.includes(brand)
                ) {
                  this.articles.forEach((element) => {
                    console.log(element.mfrName + " <=> " + brand);
                    if (element.mfrName == brand) {
                      articleBrandTemp.push(element);
                    }
                  });
                }
              }
            }
            this.articles = articleBrandTemp;
            this.setBrand();
          }
        }
      } else {
        if (this.statePersoCatalogue) {
          if (this.assemblyGroupPersoSelected.brand.length > 0) {
            console.log("Il y a une config ET des marques");
            for (const brand of this.assemblyGroupPersoSelected.brand) {
              this.articles.forEach((element) => {
                console.log(element.mfrName + " <=> " + brand);
                if (element.mfrName == brand) {
                  articleBrandTemp.push(element);
                }
              });
            }
            this.articles = articleBrandTemp;
            this.setBrand();
          }
        }
      }
      // });
    },
    getGapArticleOe(numArticle) {
      var dataTab = numArticle.Gap;
      dataTab.quantity = 1;
      dataTab.remise = 0;
      dataTab.prixtotal = dataTab.Prix_euro;
      dataTab.prixfinal = dataTab.Prix_euro;
      this.addactionArticle(dataTab);
    },
    getGapArticle(numArticle) {
      var dataTab = numArticle.Gap[0];
      dataTab.quantity = 1;
      dataTab.remise = 0;
      dataTab.prixtotal = dataTab.Prix_euro;
      dataTab.prixfinal = dataTab.Prix_euro;
      this.addactionArticle(dataTab);
    },
    goAssemblyGroup() {
      this.assemblyGroupState = true;
      this.articles = [];
      this.articlesFilter = [];
      this.articlesBrand = [];
      this.filtresTab = [];
      this.BrandTab = [];
      this.prixTabTD = [];
      this.filterDone = false;
      this.brandDone = false;
      this.indexChildAGPerso = null;
    },
    setFilter() {
      this.articlesFilter = [];
      if (!this.brandDone) {
        this.articlesC = this.articles;
        this.prixTabTD_C = this.prixTabTD;
      }
      this.articles.forEach((element) => {
        if (
          !this.articlesFilter.includes(
            element.genericArticles[0].genericArticleDescription,
            0
          )
        ) {
          this.articlesFilter.push(
            element.genericArticles[0].genericArticleDescription
          );
        }
      });
    },
    filter(filtre) {
      this.filterDone = true;
      var enVentePTD = [];
      if (this.filtresTab.includes(filtre, 0)) {
        var index = this.filtresTab.indexOf(filtre, 0);
        this.filtresTab.splice(index, 1);
      } else {
        this.filtresTab.push(filtre);
      }
      this.filtresLenA = this.filtresTab.length;
      if (this.filtresTab.length > 0) {
        this.articlesB = this.articlesC;
        this.prixTabTD_B = this.prixTabTD_C;
        this.articles = [];
        this.filtresTab.forEach((element) => {
          this.articlesB.forEach((article, index) => {
            if (
              article.genericArticles[0].genericArticleDescription == element
            ) {
              this.articles.push(article);
              enVentePTD.push(this.prixTabTD_B[index]);
            }
          });
        });
      } else {
        this.showArticles(this.tempAG, this.tempAGN);
      }
      this.prixTabTD = enVentePTD;
      this.setBrand();
    },
    setBrand() {
      this.articlesBrand = [];
      if (!this.filterDone) {
        this.articlesC = this.articles;
        this.prixTabTD_C = this.prixTabTD;
      }
      this.articles.forEach((element) => {
        if (!this.articlesBrand.includes(element.mfrName, 0)) {
          this.articlesBrand.push(element.mfrName);
        }
      });
    },
    brander(brand) {
      this.brandDone = true;
      var enVentePTD = [];
      if (this.BrandTab.includes(brand, 0)) {
        var index = this.BrandTab.indexOf(brand, 0);
        this.BrandTab.splice(index, 1);
      } else {
        this.BrandTab.push(brand);
      }
      this.filtresLenA = this.BrandTab.length;
      if (this.BrandTab.length > 0) {
        this.articlesB = this.articlesC;
        this.prixTabTD_B = this.prixTabTD_C;
        this.articles = [];
        this.BrandTab.forEach((element) => {
          this.articlesB.forEach((article, index) => {
            if (article.mfrName == element) {
              this.articles.push(article);
              enVentePTD.push(this.prixTabTD_B[index]);
            }
          });
        });
      } else {
        this.showArticles(this.tempAG, this.tempAGN);
      }
      this.prixTabTD = enVentePTD;
      this.setFilter();
    },
    async setGapCatalogue() {
      this.articles.forEach((element, index) => {
        if (!element.Gap) {
          this.articles.splice(index, 1);
        }
      });
    },
    getCatalogueConfiguration() {
      if (this.plateform.typeCat == "personnalise") {
        axios
          .post(
            `${process.env.VUE_APP_API}/configurationCatalogue/getconfiguredcatalogue`,
            {
              plateform: this.user.proprietaire,
              TDCat: this.carID,
            }
          )
          .then((res) => {
            this.catalogueConfiguration = res.data;
            this.catalogueConfiguration[0].configuration.forEach(() => {
              this.listChildState.push(false);
            });
          });
        axios
          .post(`${process.env.VUE_APP_API}/articlecross/getAllAGChildLinked`, {
            infos: {
              car: this.carID,
            },
          })
          .then((res) => {
            this.agLinkedTab = res.data;
          });
      }
    },
    async setPersoChildCatalogue(indexAG, parentID) {
      if (!this.listChildState[indexAG]) {
        var childFound = [];
        for (const element of this.catalogueConfiguration[0].configuration[
          indexAG
        ].child) {
          for (const ag of this.agLinkedTab) {
            if (element.assemblyGroupNodeId === ag.assemblyGroupNodeId) {
              childFound.push(element);
            }
          }
        }
        this.catalogueConfiguration[0].configuration[indexAG].child =
          childFound;
      }
      this.listChildState[indexAG] = !this.listChildState[indexAG];
    },
  },
  mounted() {
    // this.getAssemblyGroup();
    if (this.vehiculeSet) {
      this.getCarID();
    }
  },
};
</script>

<style>
.white {
  background-color: #4dbaf0;
}
.white:hover {
  opacity: 0.5;
}
.blue {
  background-color: #0857b2;
  /* border-color: #a5f3fc; */
}
</style>
