<template>
  <div class="ml-2 flex flex-col space-y-2 p-2">
    <h2 class="text-vk text-xl font-bold underline-offset-4">
      Informations de l'article :
    </h2>
    <div class="flex flex-col leading-7 text-gray-600 mr-16">
      <div
        class="font-medium flex flex-row justify-between"
        v-for="(info, indexInfo) in information"
        :key="indexInfo"
      >
        {{ info.criteriaDescription }} :
        <div>
          <span class="font-thin">{{ info.rawValue }}</span>
          <span class="font-thin" v-if="info.criteriaUnitDescription">{{
            info.criteriaUnitDescription
          }}</span>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col"
      v-if="remplacementPar.length == 0 && remplacementDe.length == 0"
    >
      <h2 class="text-vk text-xl font-bold underline-offset-4">
        Aucun remplacement
      </h2>
      <div class="flex flex-col overflow-y-auto leading-6 ml-2 mr-4"></div>
    </div>
    <div class="flex flex-col" v-else-if="remplacementPar.length > 0">
      <h2 class="text-vk text-xl font-bold underline-offset-4">
        Remplacé par :
      </h2>
      <div
        class="flex flex-col overflow-y-auto leading-6 ml-2 mr-4 text-slate-700"
      >
        <div
          v-for="(rempPar, indexRempPar) in remplacementPar"
          :key="indexRempPar"
        >
          {{ rempPar.articleNumber }}
          <span class="text-slate-500 float-right font-medium"
            >({{ rempPar.mfrName }})</span
          >
        </div>
      </div>
    </div>
    <div class="flex flex-col" v-else-if="remplacementDe.length > 0">
      <h2 class="text-vk text-xl font-bold underline-offset-4">
        Remplace la référence :
      </h2>
      <div
        class="flex flex-col overflow-y-auto leading-6 ml-2 mr-4 text-slate-700"
      >
        <div v-for="(rempDe, indexRempDe) in remplacementDe" :key="indexRempDe">
          {{ rempDe.articleNumber }}
          <span class="text-slate-500 float-right font-medium"
            >({{ rempDe.mfrName }})</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      information: [],

      remplacementPar: [],
      remplacementDe: [],
    };
  },
  props: ["Ref_fournisseur", "Code_marque"],
  methods: {
    async getInformationArticle() {
      await axios
        .post(`${process.env.VUE_APP_API}/infoArticle/informationArticle`, {
          Ref_fournisseur: this.Ref_fournisseur,
          Code_marque: this.Code_marque,
        })
        .then((res) => {
          this.information = res.data;
        });
    },
    async getOEREPLACEArticle() {
      await axios
        .post(`${process.env.VUE_APP_API}/infoArticle/oereplaceArticle/`, {
          Ref_fournisseur: this.Ref_fournisseur,
          Code_marque: this.Code_marque,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.remplacementDe = res.data[0].replacesArticles;
            this.remplacementPar = res.data[0].replacedByArticles;
          }
        });
    },
  },
  beforeMount() {
    this.getInformationArticle();
    this.getOEREPLACEArticle();
  },
};
</script>
<style scoped>
.text-vk {
  color: #2262b3;
  text-decoration: underline;
  text-decoration-color: #ff914d;
}
</style>
