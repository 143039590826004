<template>
  <div
    v-if="openRefQty"
    :class="`modal ${
      !openRefQty && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openRefQty = false), focusInput()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 h-[26rem] w-[15rem] mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 50rem; max-width: 38rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div
        class="modal-content"
        v-if="
          inventoryInfos.ligne &&
          inventoryInfos.ligne.length > 0 &&
          inventoryInfos.ligne[dernierIndexTab]
        "
      >
        <div class="flex flex-col justify-center text-center pt-2">
          <button
            class="p-3 text-green-500 bg-transparent rounded-lg hover:bg-gray-100 focus:outline-none"
            @click="
              updateManualyQty(
                inventoryInfos.ligne[dernierIndexTab]._id,
                parseInt(inventoryInfos.ligne[dernierIndexTab].qty_inventory) +
                  1
              ),
                (inventoryInfos.ligne[dernierIndexTab].qty_inventory =
                  parseInt(
                    inventoryInfos.ligne[dernierIndexTab].qty_inventory
                  ) + 1)
            "
          >
            <span class="material-icons-outlined text-9xl"> expand_less </span>
          </button>

          <input
            class="w-20 justify-center items-center text-center content-center self-center text-5xl rounded-md"
            type="number"
            v-model="inventoryInfos.ligne[dernierIndexTab].qty_inventory"
            @input="
              updateManualyQty(
                inventoryInfos.ligne[dernierIndexTab]._id,
                inventoryInfos.ligne[dernierIndexTab].qty_inventory
              )
            "
          />
          <hr />
          <p class="text-1xl">
            {{ inventoryInfos.ligne[dernierIndexTab].Ref_fournisseur }}
          </p>
          <button
            class="p-3 text-red-500 bg-transparent rounded-lg hover:bg-gray-100 focus:outline-none"
            @click="
              updateManualyQty(
                inventoryInfos.ligne[dernierIndexTab]._id,
                parseInt(inventoryInfos.ligne[dernierIndexTab].qty_inventory) -
                  1
              ),
                (inventoryInfos.ligne[dernierIndexTab].qty_inventory =
                  parseInt(
                    inventoryInfos.ligne[dernierIndexTab].qty_inventory
                  ) - 1)
            "
          >
            <span class="material-icons-outlined text-9xl"> expand_more </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- BODY -->
  <div class="h-screen">
    <button
      class="flex flex-row items-center cursor-pointer w-54 h-8 bg-amber-400 rounded-full hover:bg-opacity-25 shadow px-2 text-white"
      @click="backToHub()"
    >
      <span class="material-icons-round"> arrow_back </span>
      <span class="p-2">Retour</span>
    </button>
    <div class="flex flex-row items-center space-x-2 mt-4">
      <span>Inventaire n° {{ inventaireProcessing.Numero }}</span>
      <button
        class="p-1 rounded shadow bg-blue-500 text-white"
        @click="valideInventaire()"
      >
        Valider l'inventaire
      </button>
    </div>
    <div class="flex flex-row space-x-1">
      <div
        class="rounded shadow uppercase w-fit mt-2 p-1"
        v-if="inventoryInfos.marque"
      >
        Marque: {{ inventoryInfos.marque }}
      </div>
      <div
        class="rounded shadow uppercase w-fit mt-2 p-1 flex flex-row items-center space-x-2"
        v-if="inventoryInfos.marqueTab && inventoryInfos.marqueTab.length > 0"
      >
        <div>Marque:</div>
        <div class="flex flex-row items-center space-x-2">
          <span
            class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
            v-for="(m, indexM) in inventoryInfos.marqueTab"
            :key="indexM"
            >{{ m }}</span
          >
        </div>
      </div>
      <div v-if="errorBrand">
        <span class="text-red-400"> Attention, mauvaise marque scanné </span>
      </div>
      <div v-show="loadingValideInventaire">
        <span class="text-blue-500 text-xl animate-pulse">
          Validation en cours...
        </span>
      </div>
      <div
        class="rounded shadow uppercase w-fit mt-2 p-1"
        v-if="inventoryInfos.adresse"
      >
        Adresse: {{ inventoryInfos.adresse }}
      </div>
    </div>
    <div class="relative mt-6 rounded-md shadow-sm">
      <span
        class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
      >
        <span class="material-icons-round">qr_code_scanner</span>
      </span>
      <div>
        <input
          type="text"
          ref="inputEAN"
          placeholder="Code-barres"
          v-model="articleSearchEAN"
          v-on:keyup.enter="getOneArticlebyEAN()"
          class="w-full focus:outline-2 outline-sky-300 px-9 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
        />
      </div>
    </div>
    <div class="h-4/6 overflow-y-auto mt-6">
      <div class="flex flex-col">
        <div class="py-2 my-2 overflow-x-auto sm:mx-6 sm:px-6 lg:mx-8 lg:px-8">
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Id
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Ref.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Quantité
                  </th>
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr v-for="ligne in tableauInverse" :key="ligne.id">
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ ligne.id }}
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ ligne.Ref_fournisseur }}
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <input
                      class="w-20 border border-gray-300 rounded-md"
                      type="number"
                      v-model="ligne.qty_inventory"
                      @change="updateManualyQty(ligne._id, ligne.qty_inventory)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <audio id="son1" controls class="hidden">
      <source src="../assets/sontest1.mp3" type="audio/mp3" />
    </audio>
    <audio id="son2" controls class="hidden">
      <source src="../assets/sontest2.mp3" type="audio/mp3" />
    </audio>
    <audio id="son3" controls class="hidden">
      <source src="../assets/sontest3.mp3" type="audio/mp3" />
    </audio>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import {
  getInventaireInfos,
  updateManualyQty,
} from "@/hooks/stock/inventaire.ts";
// import { updateStock } from "@/hooks/stock/stock.ts";
export default {
  data() {
    return {
      articleSearchEAN: "",
      scannedArticle: "",
      scanStarted: false,
      rescanned: false,
      art_qty_theo: 0,
      adresse_article: "",
      inputQuantity: 0,
      resultUpdatedInventory: 0,
      inventoryInfos: {},
      openRefQty: false,
      scannedRef: null,
      scannedProducts: [],
      loadingValideInventaire: false,
      compteurScan: 0,
      errorBrand: false,
    };
  },
  components: {
    // setInventoryArticle,
    // notExistInventory,
    // emptyInventory,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters(["user", "artInfosInventory", "inventaireProcessing"]),
    tableauInverse() {
      if (this.inventoryInfos.ligne && this.inventoryInfos.ligne.length > 0) {
        return this.inventoryInfos.ligne.slice().reverse();
      } else {
        return [];
      }
    },
    dernierIndexTab() {
      if (this.inventoryInfos.ligne && this.inventoryInfos.ligne.length > 0) {
        const foundIndex = this.inventoryInfos.ligne.findIndex(
          (element) => element.Ref_fournisseur === this.scannedRef
        );
        if (foundIndex !== -1) {
          return foundIndex;
        } else {
          return this.inventoryInfos.ligne[
            this.inventoryInfos.ligne.length - 1
          ];
        }
      } else {
        return null;
      }

      // if (this.inventoryInfos.ligne && this.inventoryInfos.ligne.length > 0) {
      //   const dernierIndex = this.inventoryInfos.ligne.length - 1;
      //   return dernierIndex;
      // } else {
      //   return null;
      // }
    },
  },
  methods: {
    ...mapActions([
      "setartinventory",
      "removeartinventory",
      "setinventoryprocessing",
      "removeinventoryprocessing",
    ]),
    async getOneArticlebyEAN() {
      if (this.articleSearchEAN != "") {
        this.errorBrand = false;
        this.scannedArticle = this.articleSearchEAN;
        const res = await axios.post(
          `${process.env.VUE_APP_API}/stock/getForInventory/`,
          {
            plateform: this.user.proprietaire,
            Code_EAN: this.articleSearchEAN,
          }
        );
        this.setartinventory(res.data);
        if (
          (!res.data[0].state && res.data[0].error == "doesn't exist!") ||
          !this.inventaireProcessing.marqueTab.includes(
            res.data[0].article.Code_marque
          )
        ) {
          if (
            !this.inventaireProcessing.marqueTab.includes(
              res.data[0].article.Code_marque
            )
          ) {
            this.errorBrand = true;
          }
          document.getElementById("son2").play();
          this.error("L'article recherché n'existe pas !");
          this.openRefQty = false;
          this.articleSearchEAN = "";
          this.focusInput();
        } else if (!res.data[0].state && res.data[0].error == "stock it") {
          //si l'element est deja dans tableauInverse alors on emet un son3 sinon son1
          const foundIndex = this.inventoryInfos.ligne.findIndex(
            (element) =>
              element.Ref_fournisseur === res.data[0].article.Ref_fournisseur
          );
          if (foundIndex !== -1) {
            document.getElementById("son3").play();
          } else {
            document.getElementById("son1").play();
          }

          this.art_qty_theo = 0;
          this.adresse_article = "";
          // await updateStock(
          //   this.articleSearchEAN,
          //   0,
          //   this.user.proprietaire,
          //   res.data[0].article.Ref_fournisseur,
          //   res.data[0].article.Prefixe_tarif
          // );

          this.scannedRef = res.data[0].article.Ref_fournisseur;

          await this.setInventaire();
          this.articleSearchEAN = "";
          this.inventoryInfos = await getInventaireInfos(
            this.inventaireProcessing._id
          );
          this.openRefQty = true;
          // this.inventoryInfos.ligne.reverse();
        } else {
          const foundIndex = this.inventoryInfos.ligne.findIndex(
            (element) =>
              element.Ref_fournisseur === res.data[0].article.Ref_fournisseur
          );
          if (foundIndex !== -1) {
            document.getElementById("son3").play();
          } else {
            document.getElementById("son1").play();
          }
          this.art_qty_theo = res.data[0].stock.stock;
          this.adresse_article = res.data[0].stock.adresse_article;
          this.scannedRef = res.data[0].article.Ref_fournisseur;
          await this.setInventaire();
          this.articleSearchEAN = "";
          this.inventoryInfos = await getInventaireInfos(
            this.inventaireProcessing._id
          );
          this.openRefQty = true;
          // this.inventoryInfos.ligne.reverse();
        }
      }
    },
    async setInventaire() {
      var todayupdate = new Date();
      var dd = String(todayupdate.getDate()).padStart(2, "0");
      var mm = String(todayupdate.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = todayupdate.getFullYear();
      var hour = todayupdate.getHours();
      var seconds = todayupdate.getSeconds();
      var minutes = todayupdate.getMinutes();
      todayupdate =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;

      if (this.inventoryInfos.ligne.length > 0) {
        const dernierIndex = this.inventoryInfos.ligne.length - 1;
        if (this.inventoryInfos.ligne[dernierIndex].id) {
          this.compteurScan = parseInt(
            this.inventoryInfos.ligne[dernierIndex].id
          );
        } else {
          this.compteurScan = 0;
        }
      } else {
        this.compteurScan = 0;
      }

      if (this.articleSearchEAN == this.scannedArticle && this.scanStarted) {
        await axios
          .post(`${process.env.VUE_APP_API}/inventaire/getInInventory`, {
            plateform: this.user.proprietaire,
            Numero: this.inventaireProcessing.Numero,
            Ref_fournisseur: this.artInfosInventory[0].article.Ref_fournisseur,
            rescanState: true,
            data: {
              Ref_fournisseur:
                this.artInfosInventory[0].article.Ref_fournisseur,
              Code_EAN: this.artInfosInventory[0].article.Code_EAN,
              Code_marque: this.artInfosInventory[0].article.Code_marque,
              username: this.user.username,
              qty_theo: parseInt(this.art_qty_theo),
              adresse_article: this.adresse_article,
              Prix_euro: this.artInfosInventory[0].article.Prix_euro,
              qty_inventory: 1,
              id: this.compteurScan + 1,
            },
          })
          .then((res) => {
            this.scanStarted = true;
          });
      } else {
        await axios
          .post(`${process.env.VUE_APP_API}/inventaire/getInInventory`, {
            plateform: this.user.proprietaire,
            Numero: this.inventaireProcessing.Numero,
            Ref_fournisseur: this.artInfosInventory[0].article.Ref_fournisseur,
            rescanState: false,
            data: {
              Ref_fournisseur:
                this.artInfosInventory[0].article.Ref_fournisseur,
              Code_EAN: this.artInfosInventory[0].article.Code_EAN,
              Code_marque: this.artInfosInventory[0].article.Code_marque,
              qty_theo: parseInt(this.art_qty_theo),
              adresse_article: this.adresse_article,
              username: this.user.username,
              Prix_euro: this.artInfosInventory[0].article.Prix_euro,
              qty_inventory: 1,
              id: this.compteurScan + 1,
            },
          })
          .then((res) => {
            this.scanStarted = true;
          });
      }
    },
    async updateManualyQty(id, qty) {
      await updateManualyQty(id, qty);
      this.focusInput();
    },
    async valideInventaire() {
      this.loadingValideInventaire = true;
      await axios
        .post(`${process.env.VUE_APP_API}/inventaire/valideInventaire`, {
          plateform: this.user.proprietaire,
          Numero: this.inventaireProcessing.Numero,
          marque: this.inventaireProcessing.marqueTab,
          famille: this.inventaireProcessing.famille,
          ligne: this.inventoryInfos.ligne,
          initZero: this.inventaireProcessing.initZero,
        })
        .then((res) => {
          this.loadingValideInventaire = false;
          this.backToHub();
        });
    },
    backToHub() {
      this.removeinventoryprocessing();
      this.$router.push("/hubInventaireStock");
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.error(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    openModalRefQty() {
      this.openRefQty = true;
    },
    focusInput() {
      this.$refs.inputEAN.focus();
    },
  },
  async mounted() {
    this.inventoryInfos = await getInventaireInfos(
      this.inventaireProcessing._id
    );
    // this.inventoryInfos.ligne.reverse();
  },
};
</script>
<style></style>
