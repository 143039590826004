<template>
  <router-link to="/setarticles">
    <div
      id="retour"
      class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    >
      <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
      <span>Retour</span>
    </div>
  </router-link>
  <div>
    <input
      type="text"
      v-model="searchMarque"
      placeholder="Search"
      class="focus:outline-2 outline-sky-300 border rounded-md shadow mb-1 px-1"
    />
    <div class="grid grid-cols-4 gap-1 h-20 overflow-y-auto">
      <div
        class="cursor-pointer bg-white rounded p-1 h-fit"
        v-for="(brand, indexBrand) in filteredMarque"
        :key="indexBrand"
        @click="addCross(brand.dataSupplierId, brand.mfrName)"
      >
        <span>{{ brand.mfrName }}</span>
      </div>
    </div>
  </div>
  <div v-show="errorSearch">
    <span class="text-red-500">Cross non trouvé !</span>
  </div>
  <div v-show="loadingCross">
    <span class="text-blue-500 animate-pulse">Recherche en cours...</span>
  </div>
  <div class="grid grid-cols-4 mt-6">
    <div
      class="flex flex-col space-y-2"
      v-for="(cross, indexCross) in crossList"
      :key="indexCross"
    >
      <div @click="exportExcel(cross)" class="text-blue-500 cursor-pointer">
        Extraire en excel
      </div>
      <div
        class="flex flex-col"
        v-for="(artCross, indexAC) in cross"
        :key="indexAC"
      >
        <div class="flex flex-col">
          <div v-if="!artCross.error">
            {{ artCross.Ref_fournisseur }} -
            {{ parseFloat(artCross.prix_achat).toFixed(2) }} €
          </div>
          <div v-else class="text-orange-500">
            {{ artCross.Ref_fournisseur }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import * as XLSX from "xlsx";
import { getPrixAchatSorted } from "@/hooks/articles/articles.ts";
export default {
  name: "StockArticle",
  computed: {
    ...mapGetters(["article", "user"]),
    filteredMarque() {
      if (this.searchMarque) {
        return this.brand.filter((item) => {
          return item.mfrName.startsWith(this.searchMarque.toUpperCase());
        });
      } else {
        return this.brand;
      }
    },
  },
  data() {
    return {
      brand: [],
      crossList: [],
      searchMarque: "",
      errorSearch: false,
      loadingCross: false,
      brandSelected: "",
    };
  },
  methods: {
    async initArticle() {
      this.crossList.push([this.article]);
      let PA = await getPrixAchatSorted(
        this.user.proprietaire,
        this.article.Ref_fournisseur,
        this.article.Code_EAN,
        this.article.Prefixe_tarif
      );
      this.crossList[0][0].prix_achat = PA;
    },
    async getBrand() {
      axios
        .get(`${process.env.VUE_APP_API}/articlecross/getambrand`)
        .then((res) => {
          this.brand = res.data;
        });
    },
    async addCross(dataSupplierId, brandName) {
      this.loadingCross = true;
      this.brandSelected = brandName;
      const searchCross = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/searchArticleSimByBrand`,
        {
          Ref_fournisseur: this.article.Ref_fournisseur,
          Code_marque: this.article.Code_marque,
          Prefixe_tarif: this.article.Prefixe_tarif,
          plateform: this.user.proprietaire,
          dataSupplierId: dataSupplierId,
        }
      );
      if (searchCross.data.length > 0) {
        this.crossList.push(searchCross.data);
        this.errorSearch = false;
      } else {
        this.errorSearch = true;
      }
      this.loadingCross = false;
    },
    exportExcel(tab) {
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(tab);
      XLSX.utils.book_append_sheet(wb, ws, "Cross");

      XLSX.writeFile(
        wb,
        "Cross_" + this.brandSelected + "_" + this.user.proprietaire + ".xlsx"
      );
    },
  },
  async mounted() {
    this.initArticle();
    this.getBrand();
  },
};
</script>

<style scoped></style>
