import axios from "axios";

export async function getInventaireInfos(id: any) {
  const inventaireInfos = await axios.get(
    `${process.env.VUE_APP_API}/inventaire/getInventoryInfos/${id}`
  );
  return inventaireInfos.data;
}

export async function updateManualyQty(id: string, qty: number) {
  const updateManualyQty = await axios.post(
    `${process.env.VUE_APP_API}/inventaire/updateManualyQty`,
    {
      id,
      qty,
    }
  );
  return updateManualyQty.data;
}
