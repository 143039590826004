<template>
  <button
    class="bg-bluevk hover:bg-bluevkdark text-white font-bold py-2 px-4 rounded"
  >
    Retour
  </button>
</template>

<script>
export default {
  name: "returnButton",
};
</script>

<style></style>
