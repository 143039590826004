<template>
  <div
    id="retour"
    class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
    @click="$router.push('/detailClient')"
  >
    <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
    <span>Retour</span>
  </div>
  
  <div>
    <!-- <searchInput
      placeholder="Rechercher dans paniers client"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
    /> -->

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Client / Utilisateur
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date du panier et cutoff
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Date de création
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Frais de transport / Franco
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT BC
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  Total HT BL
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in paniers"
                :key="index"
                class="cursor-pointer hover:bg-gray-100"
                @click="setPanier(u)"
              >
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u.client }}</span
                  >
                  <div class="text-sm leading-5 text-gray-900">
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                      >{{ u.user }}</span
                    >
                  </div>
                </td>

                <td
                  class="px-6 py-4 font-medium border-b border-gray-200 whitespace-nowrap"
                >
                  <p>{{ getFRDateSansH(u.cartDate) }}</p>
                  <p class="text-bluevk">{{ u.cutoff }}</p>
                </td>

                <td
                  class="px-6 py-4 font-medium border-b border-gray-200 whitespace-nowrap"
                >
                  <p>{{ getFRDate(u.date) }}</p>
                </td>

                <td
                  class="px-6 py-4 font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <p class="text-bluevk">
                    {{ u.shippingCosts }} € / {{ u.freeShippingAt }} €
                  </p>
                  <p class="text-gray-600 text-xs">
                    Frais de port du client : {{ u.savedShippingCosts }} €
                  </p>
                </td>

                <td
                  class="px-6 py-4 font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ financial(u.BCtotalPrice) }} €
                </td>

                <td
                  class="px-6 py-4 font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ financial(u.BLtotalPrice) }} €
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            :pageStart="pageStart"
            :pageNext="pageNext"
            :tabList="paniersLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(paniersLength / 10)"
            :clickFunction="getPaniers"
            :currentPage="currentPage"
            :typeList="'clients'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import pageNumber from "@/components/pageNumber.vue";
// import searchInput from "@/components/searchInput.vue";
import { getPanier } from "@/hooks/transport/panierTransport.ts";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  name: "MyPanierClient",
  data() {
    return {
      paniers: [],

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      paniersLength: 0,
      clientsArchivedLength: 0,
      clientsParLength: 0,
      clientsParArchivedLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,
    };
  },
  components: {
    pageNumber,
    // searchInput,
  },
  computed: {
    ...mapGetters(["user", "clientChoosedTab"]),
  },
  methods: {
    ...mapActions(["setDetailPanierClient"]),
    async getPaniers(skipValue, limitValue) {
      this.currentPage = skipValue;

      let dataCarts = await getPanier(
        this.user.proprietaire,
        this.clientChoosedTab._id,
        (skipValue - 1) * 10,
        limitValue
      );

      this.paniers = dataCarts.data;
      this.paniersLength = dataCarts.count;
    },
    setPanier(line) {
      this.setDetailPanierClient(line._id);
      this.$router.push("/detailPanierClient");
    },
    getFRDate(date) {
      return getFrDate(date);
    },
    getFRDateSansH(date) {
      return getFrDateSansH(date);
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  mounted() {
    this.getPaniers(1, 10);
    this.pageSwitch();
  },
};
</script>
