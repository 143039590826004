<template>
  <div>
    <div class="flex flex-row items-center">
      <button
        v-if="stateIndex != 0"
        class="w-32 h-10 flex justify-center space-x-2 p-1 bg-orange-600 hover:bg-orange-500 text-white rounded shadow-lg my-auto"
        @click="previous()"
      >
        <span class="material-icons-round my-auto">arrow_back</span>
        <h4 class="my-auto">Précédent</h4>
      </button>
      <div
        v-else
        class="w-32 h-10 flex justify-center space-x-2 p-1 bg-orange-600 hover:bg-orange-500 text-white rounded shadow-lg my-auto"
        @click="$router.back()"
      >
        <span class="material-icons-round my-auto">arrow_back</span>
        <h4 class="my-auto">Précédent</h4>
      </div>
      <VehiculeAnimation
        :state="stateTab[stateIndex].value"
        :vehiculePosition="stateTab[stateIndex].style"
        :indexState="stateIndex"
        :tabState="stateTab"
        :cpTab="cpTab"
        @setState="setIndexState"
      />
      <button
        v-if="stateIndex != 5"
        class="w-32 h-10 flex justify-center space-x-2 p-1 bg-orange-600 hover:bg-orange-500 text-white rounded shadow-lg my-auto"
        @click="next()"
      >
        <h4 class="my-auto">Suivant</h4>
        <span class="material-icons-round my-auto">arrow_forward</span>
      </button>
      <div v-else class="w-32"></div>
    </div>

    <div class="items-center h-3/4 px-2 overflow-y-auto mt-8">
      <identificationVehicule
        v-if="stateTab[stateIndex].value == 'identification'"
      />
      <verificationVehicule
        v-if="stateTab[stateIndex].value == 'verification'"
      />
      <commandesVehicule
        v-if="stateTab[stateIndex].value == 'commande'"
      />
      <calendrierVehicule
        v-if="stateTab[stateIndex].value == 'calendrier'"
      />
      <reparationVehicule
        v-if="stateTab[stateIndex].value == 'reparation'"
      />
      <validationVehicule
        v-if="stateTab[stateIndex].value == 'validation'"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import VehiculeAnimation from "@/components/ControlePreliminaire/VehiculeAnimation.vue";
import identificationVehicule from "@/components/ControlePreliminaire/identifiationVehicule.vue";
import verificationVehicule from "@/components/ControlePreliminaire/verificationVehicule.vue";
import commandesVehicule from "@/components/ControlePreliminaire/commandesVehicule.vue";
import calendrierVehicule from "@/components/ControlePreliminaire/calendrierVehicule.vue";
import reparationVehicule from "@/components/ControlePreliminaire/reparationVehicule.vue";
import validationVehicule from "@/components/ControlePreliminaire/validationVehicule.vue";
import {
  getCPbyId,
  saveIndex,
} from "@/hooks/controlePreliminaire/controlePreliminaire.ts";

export default {
  name: "MyControlePreliminaire",
  data() {
    return {
      stateIndex: 0,
      stateTab: [
        {
          value: "identification",
          style: "w-24 translate-x-0 transition duration-1000",
        },
        {
          value: "verification",
          style: "w-24 translate-x-44 transition duration-1000",
        },
        {
          value: "commande",
          style: "w-24 translate-x-[22.5rem] transition duration-1000",
        },
        {
          value: "calendrier",
          style: "w-24 translate-x-[33.5rem] transition duration-1000",
        },
        {
          value: "reparation",
          style: "w-24 translate-x-[44.5rem] transition duration-1000",
        },
        {
          value: "validation",
          style: "w-24 translate-x-[54.5rem] transition duration-1000",
        },
      ],
      cpTab: [],
    };
  },
  components: {
    VehiculeAnimation,
    identificationVehicule,
    verificationVehicule,
    commandesVehicule,
    calendrierVehicule,
    reparationVehicule,
    validationVehicule,
  },
  computed: {
    ...mapGetters(["user", "controlePreliminaireSelected"]),
  },
  methods: {
    async next() {
      if (this.stateIndex < this.stateTab.length - 1) {
        this.stateIndex++;
        await saveIndex(this.cpTab._id, this.stateIndex);
      }
    },
    async previous() {
      if (this.stateIndex > 0) {
        this.stateIndex--;
        await saveIndex(this.cpTab._id, this.stateIndex);
      }
    },
    setIndexState(index) {
      this.stateIndex = index;
      console.log(index);
    },
  },
  async beforeMount() {
    this.cpTab = await getCPbyId(this.controlePreliminaireSelected);
    console.log(this.cpTab);
    this.stateIndex = this.cpTab.stateIndex;
  },
};
</script>
