<template>
  <div
    class="flex flex-row items-center justify-between p-2 bg-white border border-gray-200 rounded w-36 md:w-44 cursor-pointer hover:bg-gray-100"
  >
    Comparer
  </div>
</template>
<script>
export default {
  name: "compareButton",
};
</script>
<style></style>
