function calculRemise(prix, remise) {
  return prix - prix * (remise / 100);
}

function calculPrixAchat(prix, remise, rfa1, rfa2, rfa3) {
  var prixremise = prix - prix * (remise / 100);
  var prixrfa1 = prixremise - prixremise * (rfa1 / 100);
  var prixrfa2 = prixrfa1 - prixrfa1 * (rfa2 / 100);
  var prixrfa3 = prixrfa2 - prixrfa2 * (rfa3 / 100);
  return prixrfa3;
}

module.exports = { calculRemise, calculPrixAchat };
