<template>
  <div v-if="tabInventaire">
    <button
      class="flex flex-row items-center cursor-pointer w-54 h-8 bg-amber-400 rounded-full hover:bg-opacity-25 shadow px-2 text-white"
      @click="backToHub()"
    >
      <span class="material-icons-round"> arrow_back </span>
      <span class="p-2">Retour</span>
    </button>
    <div class="flex flex-row items-center justify-between">
      <span>Inventaire n° {{ tabInventaire.Numero }}</span>
      <div class="flex flex-row items-center space-x-2">
        <button
          v-if="!tabInventaire.mise_a_niveau"
          class="p-1 rounded shadow bg-green-500 text-white"
          @click="miseANiveau()"
        >
          Mettre à niveaux les stocks
        </button>
        <button
          class="p-1 rounded shadow bg-blue-500 text-white"
          @click="exportExcel()"
        >
          Exporter l'inventaire en excel
        </button>
      </div>
    </div>
    <div
      class="rounded shadow uppercase w-fit mt-2 p-1"
      v-if="tabInventaire.adresse"
    >
      Adresse: {{ tabInventaire.adresse }} Marque:
      {{ tabInventaire.marque }}
    </div>
    <div>
      <div class="flex flex-col mt-2">
        <div
          class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Utilisateur
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Ref_fournisseur
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Adresse
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Quantité théorique
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Quantité scannée
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    <div class="flex flex-row items-center space-x-2">
                      <div>Mise à niveau du stock</div>
                      <div class="text-xs text-gray-500">
                        <span
                          v-if="tabInventaire.mise_a_niveau"
                          class="p-1 bg-green-500 text-white rounded shadow"
                        >
                          Faite
                        </span>
                        <span
                          v-else
                          class="p-1 bg-red-500 text-white rounded shadow"
                        >
                          Non
                        </span>
                      </div>
                    </div>
                  </th>

                  <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                </tr>
              </thead>

              <tbody class="bg-white">
                <tr v-for="(u, index) in tabInventaire.ligne" :key="index">
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div
                      v-if="u.username"
                      class="text-sm leading-5 text-gray-900"
                    >
                      {{ u.username }}
                    </div>
                    <div v-else class="text-sm leading-5 text-gray-900">
                      pas d'utilisateur répertorié
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ u.Ref_fournisseur }}
                  </td>
                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div v-if="u.adresse_article">
                      <div v-if="tabInventaire.adresse">
                        <div v-if="tabInventaire.adresse == u.adresse_article">
                          {{ u.adresse_article }}
                        </div>
                        <div v-else class="text-red-500">
                          {{ u.adresse_article }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td
                    class="bg-red-100 px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    v-if="u.qty_theo != u.qty_inventory"
                  >
                    {{ u.qty_theo }}
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                    v-else
                  >
                    {{ u.qty_theo }}
                  </td>
                  <td
                    v-if="u.qty_theo != u.qty_inventory"
                    class="bg-red-100 px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ u.qty_inventory }}
                  </td>
                  <td
                    v-else
                    class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                  >
                    {{ u.qty_inventory }}
                  </td>
                  <td
                    class="px-6 py-4 text-sm leading-5 border-b border-gray-200 whitespace-nowrap"
                  >
                    <div v-if="u.qty_theo != u.qty_inventory">
                      <span
                        v-if="u.qty_theo > u.qty_inventory"
                        class="text-red-500"
                      >
                        {{ u.qty_inventory - u.qty_theo }}</span
                      >
                      <span v-else class="text-green-500">
                        + {{ u.qty_inventory - u.qty_theo }}</span
                      >
                    </div>
                    <div v-else>Ok</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import * as XLSX from "xlsx";
import { stockMiseANiveau } from "@/hooks/stock/stock.ts";
export default {
  data() {
    return {
      articleSearchEAN: "",
      stateArticle: "none",
      scannedArticle: "",
      scanStarted: false,
      rescanned: false,
      inputQuantity: 0,
      resultUpdatedInventory: 0,
      tabInventaire: null,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapGetters(["user", "artInfosInventory", "inventaireProcessing"]),
  },
  methods: {
    ...mapActions([
      "setartinventory",
      "removeartinventory",
      "setinventoryprocessing",
      "removeinventoryprocessing",
    ]),
    async getInventaire() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/inventaire/getInventoryInfos/${this.inventaireProcessing}`
        )
        .then((res) => {
          console.log(res);
          this.tabInventaire = res.data;
        });
    },
    backToHub() {
      this.removeinventoryprocessing();
      this.$router.push("/hubInventaireStock");
    },
    success(message) {
      this.toast.success(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(message) {
      this.toast.warning(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(message) {
      this.toast.error(message, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    exportExcel() {
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.tabInventaire.ligne);
      XLSX.utils.book_append_sheet(wb, ws, "Inventaire");

      XLSX.writeFile(
        wb,
        "Inventaire_" +
          this.tabInventaire.Numero +
          "_" +
          this.user.proprietaire +
          ".xlsx"
      );

      this.success("Le fichier a été téléchargé");
    },
    async miseANiveau() {
      this.loadingMAN = true;
      await stockMiseANiveau(
        this.user.proprietaire,
        this.tabInventaire.ligne,
        this.tabInventaire._id,
        this.user.username,
        "Inventaire n° " +
          this.tabInventaire.Numero +
          " mise a niveau par : " +
          this.user.username,
        "NA",
        this.tabInventaire.Numero
      );
      this.success("Mise à niveau effectuée");
      this.backToHub();
    },
  },
  
  mounted() {
    this.getInventaire();
  },
};
</script>
<style></style>
