<template>
  <div>
    <span v-if="loading">Chargement...</span>
    <span class="material-icons-round text-red-500" v-if="updateTab.length == 0 && loading == false">
      warning
    </span>
    <img class="w-6 h-6" src="@/assets/ValidIcon.png" v-if="updateTab.length > 0" />
    <div class="text-xs m-auto" v-if="updateTab.length > 0">
      {{ updateTab[0].date }}
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "updateTarifState",
  props: ["Prefixe_tarif"],
  data() {
    return {
      updateTab: [],
      loading: true,
    };
  },
  async mounted() {
    await axios
      .post(`${process.env.VUE_APP_API}/updateArticle/getBrandPriceUpdate`, {
        plateform: "all_plateform",
        Prefixe_tarif: this.Prefixe_tarif,
      })
      .then((res) => {
        this.loading = false;
        this.updateTab = res.data;
      });
  },
};
</script>
<style></style>
