<template>
  <!-- modal search -->
  <div
  v-if="openModalSearch && openModalSearchVanHeck"
    :class="`modal ${
      !openModalSearch &&
      !openModalSearchVanHeck &&
      'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openModalSearch = false), (openModalSearchVanHeck = false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div class="flex space-x-6">
      <div
        style="max-width: 80rem; max-height: 50rem"
        class="z-50 w-11/12 mx-auto bg-gray-200 rounded shadow-lg modal-container"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Références</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="
                (openModalSearch = false), (openModalSearchVanHeck = false)
              "
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!-- Body -->
          <div class="flex flex-col mt-4" style="max-height: 42rem">
            <!-- <button
            @click="showCatRef = true"
            class="p-2 bg-blue-500 text-xs text-white rounded shadow"
          >
            Références Catalogue
          </button> -->
            <div
              class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            >
              <div
                class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
              >
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Réf.
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Descr.
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Prix
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      >
                        Stock
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      ></th>

                      <th
                        class="px-6 py-3 border-b border-gray-200 bg-gray-50"
                      ></th>
                    </tr>
                  </thead>

                  <tbody class="bg-white">
                    <tr
                      v-for="(u, index) in crossoetab"
                      :key="index"
                      class="hover:bg-gray-100"
                    >
                      <td
                        class="px-2 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="flex flex-col">
                          <div class="flex flex-row items-center">
                            <div
                              class="space-x-1 flex text-sm leading-5 text-gray-900 font-medium"
                            >
                              <span
                                class="material-icons-outlined m-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                                style="font-size: 11px"
                                @click="
                                  searchCross(u.articleNo),
                                    (open = !open),
                                    (searchInput = u.articleNo)
                                "
                              >
                                cached
                              </span>
                              <span>{{ u.articleNo }}</span>
                            </div>
                          </div>
                          <div class="text-sm leading-5 text-gray-500">
                            {{ u.brandName }}
                          </div>
                        </div>
                      </td>

                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="text-sm leading-5 text-gray-900">
                          {{ u.articleName }}
                        </div>
                      </td>

                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <span
                          v-if="prixtab[index]"
                          class="text-sm leading-5 text-blue-500"
                          >{{
                            parseFloat(prixtab[index].prix).toFixed(2)
                          }}
                          €</span
                        >
                      </td>

                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="flex space-x-1" v-if="prixtab[index]">
                          <!-- <img style="width:1rem" src="../assets/IconsDelivery/contrast.png"/>
                      <img style="width:1rem" src="../assets/IconsDelivery/day-and-night.png"/>
                      <img style="width:1rem" src="../assets/IconsDelivery/half-moon.png"/>
                      <img style="width:1rem" src="../assets/IconsDelivery/sun.png"/> -->
                          <div
                            v-if="prixtab[index] && prixtab[index].stock > 0"
                          >
                            <span
                              @mouseover="showIconDispoA[index] = true"
                              @mouseleave="showIconDispoA[index] = false"
                              style="width: 1.75rem; height: 1.75rem"
                              class="material-icons-outlined p-0.5 rounded-full bg-green-500 text-white"
                              >check_circle</span
                            >
                            <div
                              class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                              v-show="showIconDispoA[index]"
                            >
                              <span>Disponible</span>
                            </div>
                          </div>

                          <div>
                            <span
                              @mouseover="showIconFiliale1A[index] = true"
                              @mouseleave="showIconFiliale1A[index] = false"
                              style="width: 1.75rem; height: 1.75rem"
                              class="material-icons-outlined p-0.5 rounded-full bg-cyan-500 text-white"
                              >swap_horizontal_circle</span
                            >
                            <div
                              class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                              v-show="showIconFiliale1A[index]"
                            >
                              <span>Disponible filiale 1</span>
                            </div>
                          </div>

                          <div>
                            <span
                              @mouseover="showIconFiliale2A[index] = true"
                              @mouseleave="showIconFiliale2A[index] = false"
                              style="width: 1.75rem; height: 1.75rem"
                              class="material-icons-outlined p-0.5 rounded-full bg-blue-500 text-white"
                              >swap_horizontal_circle</span
                            >
                            <div
                              class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                              v-show="showIconFiliale2A[index]"
                            >
                              <span>Disponible filiale 2</span>
                            </div>
                          </div>

                          <div
                            v-if="
                              (prixtab[index] && prixtab[index].stock < 1) ||
                              !prixtab[index].stock
                            "
                          >
                            <span
                              @mouseover="showIconCmdA[index] = true"
                              @mouseleave="showIconCmdA[index] = false"
                              style="width: 1.75rem; height: 1.75rem"
                              class="material-icons-outlined p-0.5 rounded-full bg-orange-500 text-white"
                              >help_outline</span
                            >
                            <div
                              class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                              v-show="showIconCmdA[index]"
                            >
                              <span>Disponible sur commande</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      ></td>
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        v-if="prixtab[index] != 'NOK'"
                      >
                        <span
                          class="material-icons-round cursor-pointer ml-4 text-blue-600"
                          @click="
                            searchTranslatedDirectAdd({
                              artNum: u.articleNo,
                              marque: u.brandNo,
                              marqueName: u.brandName,
                              plateform: this.user.plateform,
                              quantityAdded: 1,
                            }),
                              (openModalSearch = false),
                              (openModalSearchVanHeck = false),
                              success()
                          "
                        >
                          add_shopping_cart
                        </span>
                      </td>
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        v-else
                      >
                        --
                      </td>
                    </tr>
                  </tbody>
                </table>
                <RingLoader
                  color="#2d74da"
                  v-show="generalloading"
                  class="py-8 w-fit m-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- modal CarCat -->
      <div
        style="max-width: 50rem; max-height: 50rem"
        class="z-50 w-11/12 mx-auto bg-gray-200 rounded shadow-lg modal-container"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Références CarCat</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="
                (openModalSearchVanHeck = false), (openModalSearch = false)
              "
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <!-- partie pour CarCat -->
          <div
            class="flex flex-col mt-4"
            style="height: 80vh"
            v-if="tabRefVH.length > 0"
          >
            <div
              class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
            >
              <div
                class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
              >
                <table class="min-w-full">
                  <thead>
                    <tr>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Réf.
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Descr.
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Prix
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      >
                        Stock
                      </th>
                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      ></th>

                      <th
                        class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                      ></th>
                    </tr>
                  </thead>

                  <tbody class="bg-white">
                    <tr
                      v-for="(u, index) in tabRefVH"
                      :key="index"
                      class="hover:bg-gray-100"
                    >
                      <td
                        class="px-2 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="flex flex-col">
                          <div class="flex flex-row items-center">
                            <div
                              class="space-x-1 flex text-sm leading-5 text-gray-900 font-medium"
                            >
                              <span
                                class="material-icons-outlined m-auto cursor-pointer text-orange-500 hover:bg-gray-100 rounded hover:shadow hover:animate-spin"
                                style="font-size: 11px"
                                @click="
                                  searchCross(u.article[0].Ref_fournisseur),
                                    (open = !open),
                                    (searchInput = u.article[0].Ref_fournisseur)
                                "
                              >
                                cached
                              </span>
                              <span>{{ u.article[0].Ref_fournisseur }}</span>
                            </div>
                          </div>
                          <div class="text-sm leading-5 text-gray-500">
                            {{ u.article[0].Code_marque }}
                          </div>
                        </div>
                      </td>

                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="text-sm leading-5 text-gray-900">
                          {{ u.article[0].Description }}
                        </div>
                      </td>

                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <span
                          v-if="u.article[0].Prix_euro"
                          class="text-sm leading-5 text-blue-500"
                          >{{
                            parseFloat(u.article[0].Prix_euro).toFixed(2)
                          }}
                          €</span
                        >
                      </td>

                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      >
                        <div class="flex space-x-1" v-if="prixtab[index]">
                          <!-- <img style="width:1rem" src="../assets/IconsDelivery/contrast.png"/>
                      <img style="width:1rem" src="../assets/IconsDelivery/day-and-night.png"/>
                      <img style="width:1rem" src="../assets/IconsDelivery/half-moon.png"/>
                      <img style="width:1rem" src="../assets/IconsDelivery/sun.png"/> -->
                          <div
                            v-if="prixtab[index] && prixtab[index].stock > 0"
                          >
                            <div v-if="u.stock[0]">
                              <span
                                @mouseover="showIconDispoB[index] = true"
                                @mouseleave="showIconDispoB[index] = false"
                                style="width: 1.75rem; height: 1.75rem"
                                class="material-icons-outlined p-0.5 rounded-full bg-green-500 text-white"
                                >check_circle</span
                              >
                              <div
                                class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                                v-show="showIconDispoB[index]"
                              >
                                <span>{{ u.stock[0].stock }}</span>
                                <span>pcs disponible</span>
                              </div>
                            </div>
                          </div>

                          <div>
                            <span
                              @mouseover="showIconFiliale1B[index] = true"
                              @mouseleave="showIconFiliale1B[index] = false"
                              style="width: 1.75rem; height: 1.75rem"
                              class="material-icons-outlined p-0.5 rounded-full bg-cyan-500 text-white"
                              >swap_horizontal_circle</span
                            >
                            <div
                              class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                              v-show="showIconFiliale1B[index]"
                            >
                              <span>Disponible filiale 1</span>
                            </div>
                          </div>

                          <div>
                            <span
                              @mouseover="showIconFiliale2B[index] = true"
                              @mouseleave="showIconFiliale2B[index] = false"
                              style="width: 1.75rem; height: 1.75rem"
                              class="material-icons-outlined p-0.5 rounded-full bg-blue-500 text-white"
                              >swap_horizontal_circle</span
                            >
                            <div
                              class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                              v-show="showIconFiliale2B[index]"
                            >
                              <span>Disponible filiale 2</span>
                            </div>
                          </div>

                          <div
                            v-if="
                              (prixtab[index] && prixtab[index].stock < 1) ||
                              !prixtab[index].stock
                            "
                          >
                            <span
                              @mouseover="showIconCmdB[index] = true"
                              @mouseleave="showIconCmdB[index] = false"
                              style="width: 1.75rem; height: 1.75rem"
                              class="material-icons-outlined p-0.5 rounded-full bg-orange-500 text-white"
                              >help_outline</span
                            >
                            <div
                              class="absolute bg-gray-200 p-2 rounded shadow-lg z-20"
                              v-show="showIconCmdB[index]"
                            >
                              <span>Disponible sur commande</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      ></td>
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        v-if="prixtab[index] != 'NOK'"
                      >
                        <span
                          class="material-icons-round cursor-pointer ml-4 text-blue-600"
                          @click="
                            searchTranslatedDirectAdd({
                              artNum: u.article[0].Ref_fournisseur,
                              marqueName: u.article[0].Code_marque,
                              plateform: this.user.proprietaire,
                              quantityAdded: 1,
                            }),
                              (openModalSearchVanHeck = false),
                              (openModalSearch = false),
                              success()
                          "
                        >
                          add_shopping_cart
                        </span>
                      </td>
                      <td
                        class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH"
                        v-else
                      >
                        --
                      </td>
                    </tr>
                  </tbody>
                </table>
                <RingLoader
                  color="#2d74da"
                  v-show="generalloading"
                  class="py-8 w-fit m-auto"
                />
              </div>
            </div>
          </div>
          <div v-else>Aucune référence trouvée</div>

          <!--Footer-->
          <!-- <div class="flex justify-end pt-2">
          <button
            @click="openModalSearchVanHeck = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Fin modal search -->

  <div
    class="flex flex-row justify-center h-full bg-stone-100 rounded shadow-lg border-red-500"
  >
    <div class="flex flex-col w-4/12 rounded h-full border-r border-white">
      <div class="text-vk font-bold underline-offset-4 mb-4 p-4 bg-stone-200">
        Numéros OE :
      </div>
      <div
        class="flex flex-col overflow-y-auto leading-6 ml-2 mr-2 max-h-[20vh]"
      >
        <div
          v-for="(o, indexOE) in oe"
          :key="indexOE"
          @click="
            searchRefFournisseur({ Ref_fournisseur: o.articleNumber }),
              $emit('checkCross')
          "
          class="flex flex-row items-center justify-between mr-2"
        >
          <span class="text-slate-500 font-medium"> {{ o.mfrName }}</span>
          <div
            class="cursor-pointer text-vk-blue flex flex-row items-center space-x-2"
          >
            <span class="material-icons-round"> sync </span>
            <span>{{ o.articleNumber }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-4/12 rounded h-full border-r border-white">
      <div class="text-vk font-bold underline-offset-4 mb-4 p-4 bg-stone-200">
        Références croisées :
      </div>
      <div
        class="flex flex-col overflow-y-auto leading-6 ml-2 mr-4 max-h-[20vh]"
      >
        <div
          v-for="(cro, indexCross) in cross"
          :key="indexCross"
          class="flex flex-row items-center justify-between mr-2"
          @click="
            searchRefFournisseur({ Ref_fournisseur: cro.articleNo }),
              $emit('checkCross')
          "
        >
          <span class="text-slate-500 float-right font-medium">
            {{ cro.brandName }}</span
          >
          <div
            class="cursor-pointer text-vk-blue flex flex-row items-center space-x-2"
          >
            <span class="material-icons-round"> sync </span>
            <span>{{ cro.articleNo }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-4/12 rounded h-full overflow-y-auto">
      <div class="text-vk font-bold underline-offset-4 mb-4 p-4 bg-stone-200">
        Véhicules associés :
      </div>
      <div class="px-2 flex flex-col space-y-1">
        <div v-for="(veh, indexVeh) in vehicules" :key="indexVeh">
          <div
            @click="veh.show = !veh.show"
            class="cursor-pointer text-vk-blue p-1"
          >
            {{ veh.mfrName }}
          </div>
          <div
            class="leading-6 ml-2 mr-4"
            v-for="(v, indexV) in veh.vehicules"
            :key="indexV"
            v-show="veh.show"
          >
            <div
              @click="v.show = !v.show"
              class="cursor-pointer text-vk-blue p-1"
            >
              {{ v.vehicleModelSeriesName }}
            </div>
            <div
              class="flex flex-col space-y-2 overflow-y-auto leading-6 ml-2 mr-4 max-h-[20vh]"
            >
              <div
                class="bg-white hover:bg-gray-50 rounded-lg pt-2 p-1 md:pt-1 h-16 md:h-14 cursor-pointer"
                v-for="(vInfos, indexVI) in v.vehicleInfos"
                :key="indexVI"
                v-show="v.show"
                @click="
                  setVehiculeCatalogue(
                    vInfos.linkageTargetId,
                    vInfos.mfrName,
                    vInfos.vehicleModelSeriesName,
                    vInfos.engineType,
                    vInfos.beginYearMonth
                  )
                "
              >
                <div class="flex items-center">
                  <img
                    v-if="vInfos.vehicleImages.length > 0"
                    :src="vInfos.vehicleImages[0].imageURL800"
                    alt="visuel"
                    class="w-1/3 h-10 md:h-10 bg-gray-300 rounded-sm ml-1 mr-2 text-xs text-gray-700 text-center"
                  />
                  <div class="w-2/3">
                    <div class="text-xs font-bold text-bluevk">
                      {{ vInfos.description }} |
                      {{ vInfos.mfrName }}
                    </div>
                    <div class="text-xs font-light text-gray-800">
                      {{ vInfos.beginYearMonth }} / {{ vInfos.endYearMonth }}
                    </div>
                    <div class="text-xs font-medium text-orange-600">
                      {{ vInfos.engineType }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import RingLoader from "vue-spinner/src/RingLoader.vue";
import { useToast } from "vue-toastification";
export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      oe: [],
      cross: [],
      vehicules: [],

      openModalSearchVanHeck: false,
      openModalSearch: false,
      tabRefVH: [],
      showIconDispoA: [],
      showIconDispoB: [],
      showIconFiliale1A: [],
      showIconFiliale1B: [],
      showIconFiliale2A: [],
      showIconFiliale2B: [],
      showIconCmdA: [],
      showIconCmdB: [],
    };
  },
  props: ["Ref_fournisseur", "Code_marque"],
  components: {
    RingLoader,
  },
  computed: {
    ...mapGetters([
      "user",
      "crossoetab",
      "prixtab",
      "plateform",
      "generalloading",
    ]),
  },
  methods: {
    ...mapActions([
      "searchTranslatedDirect",
      "searchTranslatedDirectAdd",
      "searchCross",
      "searchDirect",
      "searchRefFournisseur",
      "setSelectionCarId",
    ]),
    async getOEREPLACEArticle() {
      await axios
        .post(`${process.env.VUE_APP_API}/infoArticle/oereplaceArticle/`, {
          Ref_fournisseur: this.Ref_fournisseur,
          Code_marque: this.Code_marque,
        })
        .then((res) => {
          if (res.data.length > 0) {
            this.oe = res.data[0].oemNumbers;
          }
        });
    },
    async getLinkedVehicule() {
      if (this.Ref_fournisseur != "") {
        const getVehicule = await axios.post(
          `${process.env.VUE_APP_API}/infoArticle/getLinkedVehicule`,
          {
            Ref_fournisseur: this.Ref_fournisseur,
            Code_marque: this.Code_marque,
          }
        );

        this.vehicules = getVehicule.data;
      }
    },
    async getCrossArticle() {
      if (this.Ref_fournisseur != "") {
        await axios
          .post(`${process.env.VUE_APP_API}/infoArticle/crossArticle`, {
            Ref_fournisseur: this.Ref_fournisseur,
            Code_marque: this.Code_marque,
          })
          .then((res) => {
            this.cross = res.data;
          });
      }
    },
    getRefSimilaire(ref) {
      axios
        .post(`${process.env.VUE_APP_API}/vanheck/getRefSimilaire`, {
          Ref_fournisseur: ref,
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.tabRefVH = response.data;
          console.log(response.data);
          this.showCatRef = false;
        })
        .catch(() => {
          this.tabRefVH = [];
        });
    },
    setVehiculeCatalogue(carId, marque, modele, version, date) {
      this.setSelectionCarId({ carId: carId, marque, modele, version, date });
      this.$router.push("/catalogueHubNew");
    },
    success() {
      this.toast.success("Ajouté au panier !", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  beforeMount() {
    this.getOEREPLACEArticle();
    this.getCrossArticle();
    this.getLinkedVehicule();
  },
};
</script>
<style scoped>
.text-vk {
  color: #2262b3;
  text-decoration: underline;
  text-decoration-color: #ff914d;
}

.text-vk-blue {
  color: #2262b3;
}

.text-vk-blue1 {
  color: #2262b3;
}

.text-vk-blue:hover {
  color: #ff914d;
  font-weight: 500;
}
</style>
