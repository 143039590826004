<template>
  <div>
    {{ configuration }}
    <div v-if="configuration.length > 0">
      <span>configuration Globale ok !</span>
      <div v-if="configuration[0].configuration.length > 0">
        configuration titre ok
      </div>
      <div v-else>configuration titre nok</div>
    </div>
    <div v-else>conf globale NOK !</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      configuration: [],
    };
  },
  computed: {
    ...mapGetters(["plateform", "user"]),
  },
  methods: {
    getConfiguration() {
      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/getone`, {
          plateform: this.user.proprietaire,
        })
        .then((res) => {
          this.configuration = res.data;
        });
    },
    async saveChange(id) {
      await axios
        .put(`${process.env.VUE_APP_API}/configurationCatalogue/${id}`, {
          configuration: this.configuration.configuration,
        })
        .then((res) => {
          this.getConfiguration();
        });
    },
  },
  mounted() {
    this.getConfiguration();
  },
};
</script>
<style></style>
