<template>
  <div>
    <div @click="showTable = !showTable">
      <span class="p-2 bg-blue-500 text-white rounded shadow cursor-pointer">
        Exemple pneu
      </span>
    </div>
    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
      v-if="showTable"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg h-full"
      >
        <div class="flex items-center px-2 py-2 bg-white rounded-md shadow-sm">
          <div class="p-1 w-4/12">
            <div class="flex flex-row items-center space-x-6">
              <img class="h-24 w-18" src="../../assets/pneus.jpg" />
              <div class="flex flex-col space-y-1">
                <div class="flex flex-col space-y-1">
                  <div class="flex flex-row items-center">
                    <img src="../../assets/icon/ete.png" class="h-6 w-6" /><span
                      ><span class="text-blue-500 p-1 w-fit">saison_pneu</span>
                      : ETE</span
                    >
                  </div>
                  <div class="flex flex-row items-center">
                    <img
                      src="../../assets/icon/hiver.png"
                      class="h-6 w-6"
                    /><span
                      ><span class="text-blue-500 p-1 w-fit">saison_pneu</span>
                      : HIVER</span
                    >
                  </div>
                  <div class="flex flex-row items-center">
                    <img
                      src="../../assets/icon/saison.png"
                      class="h-6 w-6"
                    /><span
                      ><span class="text-blue-500 p-1 w-fit">saison_pneu</span>
                      : 4S</span
                    >
                  </div>
                </div>

                <div class="flex flex-col space-y-2">
                  <div class="flex flex-row items-center space-x-1">
                    <img src="../../assets/icon/gas.png" class="h-4 w-4" />
                    <span class="text-blue-500 p-1 w-fit">
                      resistence_roulement_pneu
                    </span>
                  </div>
                  <div class="flex flex-row items-center space-x-1">
                    <img src="../../assets/icon/pluie.png" class="h-4 w-4" />
                    <span class="text-blue-500 p-1 w-fit"
                      >freinage_mouille_pneu
                    </span>
                  </div>
                  <div class="flex flex-row items-center space-x-1">
                    <img src="../../assets/icon/sound.png" class="h-4 w-4" />
                    <span class="text-blue-500 p-1 w-fit">dB_pneu</span>
                  </div>
                </div>
                <div class="relative">
                  <span class="text-gray-500"
                    ><a
                      class="text-blue-600 flex flex-row items-center space-x-1"
                      target="_blank"
                    >
                      <span class="material-icons-round">
                        qr_code_scanner
                      </span>
                      <span>url_eprel_pneu</span>
                    </a></span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="w-3/12">
            <div class="text-lg font-bold">
              <span class="text-blue-500 p-1 w-fit">marque_pneu</span> &&
              <span class="text-blue-500 p-1 w-fit">Code_marque</span>
            </div>
            <div><span class="text-blue-500 p-1 w-fit">Description</span></div>
          </div>

          <div class="w-2/12">Stock</div>
          <div class="w-2/12 flex flex-col">
            <span class="text-blue-500 p-1 w-fit">Prix_euro</span>
            <span>ou</span>
            <span class="text-blue-500 p-1 w-fit">PNT1, PNT2, PNT3...</span>
          </div>
          <div class="w-3/12 flex flex-col space-y-2">
            <div class="flex flex-row items-center mx-auto">
              <span class="material-icons-round cursor-pointer">
                remove_circle_outline
              </span>
              <input
                value="2"
                type="number"
                class="w-16 appearance-none text-center"
              />
              <span class="material-icons-round cursor-pointer">
                add_circle_outline
              </span>
            </div>
            <div class="mx-auto">
              <button
                class="flex py-4 rounded-md shadow-sm py-1 px-2 content bg-blue-200"
              >
                <span class="material-icons-round cursor-pointer">
                  add_shopping_cart
                </span>
                Ajouter au panier
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="Recherche" class="w-1/2">
          <label
            for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only"
            >Search</label
          >
          <div class="relative">
            <div
              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="default-search"
              placeholder="largeur_pneu serie_pneu jante_pneu charge_pneu iv_pneu"
              class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-sky-300 outline-2"
              required
            />
            <button
              class="text-white absolute right-2.5 bottom-2.5 bg-sky-700 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
            >
              Rechercher
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <div>
          <span class="text-blue-500 p-1 w-fit">Code_EAN</span> -> BARCODE
        </div>
        <div>
          <span class="text-blue-500 p-1 w-fit">type_pneu</span> -> VL, PL, U,
          MOTO, AGRI, COMPETITION, COLLECTION, 4x4, SUV
        </div>
        <div>
          <span class="text-blue-500 p-1 w-fit">runflat_pneu</span> -> OUI ou
          NON
        </div>
        <div>
          <span class="text-blue-500 p-1 w-fit">url_img_pneu</span> ->
          http://....
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showTable: true,
    };
  },
};
</script>
<style></style>
