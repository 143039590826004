import axios from "axios";

export async function getGroupement(plateform: string) {
  const groupement = await axios.get(
    `${process.env.VUE_APP_API}/groupement/getGroupement/${plateform}`
  );

  return groupement.data;
}
export async function getAgences(plateform: string) {
  const agences = await axios.post(
    `${process.env.VUE_APP_API}/plateform/getAgences`,
    {
      plateform: plateform,
    }
  );

  return agences.data;
}
