<template>
  <div>
    <div
      class="p-6 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 gap-5"
    >
      <div v-for="(gp, indexGP) in configurationCatalogue" :key="indexGP">
        <div class="text-xl text-bluevk border-b border-bluevk">
          <span class="mx-auto">{{ gp.name }}</span>
        </div>
        <div v-for="(famille, indexF) in gp.famille" :key="indexF">
          <div
            class="rounded flex flex-col cursor-pointer"
            @click="getArticles(famille)"
            v-if="famille.actif"
          >
            <span class="text-lg text-gray-600 hover:text-bluevk">{{
              famille.name
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 flex justify-center">
      <img src="@/assets/tecdoc_logo.jpg" class="" />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      showSousFamille: [[], [], [], [], [], [], [], [], [], [], [], [], [], []],
    };
  },
  props: {
    configurationCatalogue: {
      type: Array,
    },
    carId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setfamilleselected"]),
    async getArticles(famille) {
      this.setfamilleselected({ famille: famille, carId: this.carId });
      this.$router.push("/catalogueTecDocNew");
    },
  },
};
</script>
<style></style>
