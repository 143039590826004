<template>
  <div class="flex-wrap">
    <nav class="space-x-2">
      <router-link to="/configurationCatalogueHub">
        <a
          href="#"
          name="Liste"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Menu
        </a>
      </router-link>
      <router-link to="/ConfigurationBrandPriority">
        <a
          href="#"
          name="Liste"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Priorité Marques
        </a>
      </router-link>
      <router-link to="/configurationFiltre">
        <a
          href="#"
          name="Liste"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Marques
        </a>
      </router-link>
      <router-link to="/configurationFamilleCatalogue">
        <a
          href="#"
          name="Conditions"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Catalogue
        </a>
      </router-link>
      <router-link to="/configurationFiltrePneu">
        <a
          href="#"
          name="Conditions"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Pneus
        </a>
      </router-link>
      <router-link to="/configurationConnexion">
        <a
          href="#"
          name="Conditions"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Connexions
        </a>
      </router-link>
      <router-link to="/ConfigurationCondition">
        <a
          href="#"
          name="Conditions"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Conditions
        </a>
      </router-link>
      <router-link
        to="/utilisateurs"
        v-if="$store.state.user.accountType == 'Administrateur'"
      >
        <a
          href="#"
          name="Conditions"
          class="px-3 py-2 border-b-2 border-orange-200 text-sky-700 hover:border-none hover:rounded-lg hover:bg-slate-100 hover:text-sky-900 hover:underline hover:underline-offset-2 hover:decoration-2 hover:decoration-orange-200"
        >
          Utilisateurs
        </a>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["plateform"]),
  },
};
</script>

<style></style>
