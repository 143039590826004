<template>
  <div>
    <backButton />
    <div
      class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-2"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Designation
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Code
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Famille
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Remise / Coef
              </th>
              <!-- <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                RFA 1
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                RFA 2
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                RFA 3
              </th> -->
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Pro 1
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Pro 2
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Pro 3
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Part 1
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Part 2
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-gray-50 bg-bluevk text-white"
              >
                Part 3
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <!-- <tr v-for="(mp, index) in matricePrix" :key="index"> -->
            <tr v-for="(condi, index) in conditions" :key="index">
              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <span
                  class="p-1 bg-red-500 text-white text-sm rounded shadow cursor-pointer"
                  @click="deleteCondition(condi._id)"
                  >supp</span
                >
                {{ condi.fournisseur }}
              </td>

              <td
                class="px-2 py-1 border-b border-r border-gray-200 whitespace-nowrap text-xs"
              >
                <input
                  type="text"
                  class="w-56"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.designation"
                />
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col">
                  <input
                    type="text"
                    class="w-20 border-2 px-1 rounded-md"
                    @change="updateCondition(condi._id, condi)"
                    v-model="condi.familleFournisseur"
                  />
                </div>
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="text"
                  v-model="condi.famille"
                  @change="updateCondition(condi._id, condi)"
                  class="w-28 block text-xs w-full focus:outline-2 outline-sky-300 px-2 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white rounded-l rounded-r appearance-none focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none hover:bg-gray-300"
                />
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.remiseFournisseur"
                />
                %
              </td>

              <!-- <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.rfa1"
                />
                %
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.rfa2"
                />
                %
              </td>

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-col" v-if="condi.rfa3">
                  <div class="flex">
                    <input
                      type="Number"
                      class="w-10"
                      @change="updateCondition(condi._id, condi)"
                      v-model="condi.rfa3.value"
                    />
                    %
                  </div>
                  <div class="" v-if="condi.rfa3">
                    <select
                      v-model="condi.rfa3.state"
                      @change="updateCondition(condi._id, condi)"
                    >
                      <option value="false">Inactif</option>
                      <option value="true">Actif</option>
                    </select>
                  </div>
                </div>
              </td> -->

              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.T1"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.T2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.T3"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.P1"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.P2"
                />
                %
              </td>
              <td
                class="px-6 py-4 border-b border-r border-gray-200 whitespace-nowrap"
                style="width: 8%"
              >
                <input
                  type="Number"
                  class="w-10"
                  @change="updateCondition(condi._id, condi)"
                  v-model="condi.P3"
                />
                %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import backButton from "@/components/Button/back.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      conditions: [],
    };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getConditions() {
      let getCond = await axios.post(
        `${process.env.VUE_APP_API}/conditionFournisseur/getConditionToConfigure`,
        {
          plateform: this.user.proprietaire,
        }
      );

      this.conditions = getCond.data;
    },
    async updateCondition(id, data) {
      await axios.put(
        `${process.env.VUE_APP_API}/conditionFournisseur/${id}`,
        data
      );
      this.getConditions();
    },
    async deleteCondition(id) {
      await axios.post(
        `${process.env.VUE_APP_API}/conditionFournisseur/deleteCondition`,
        {
          id: id,
        }
      );
      this.getConditions();
    },
  },
  mounted() {
    this.getConditions();
  },
};
</script>
<style></style>
