<template>
  <div class="flex cursor-pointer" @click="back()">
    <span class="material-icons-outlined"> arrow_back </span>
    <span>retour</span>
  </div>
  <div class="mt-2">
    <div v-if="assemblyGroupSelected.newName" class="text-xl">
      {{ assemblyGroupSelected.newName }}
    </div>
    <div v-else class="text-xl">
      {{ assemblyGroupSelected.assemblyGroupName }}
    </div>
  </div>
  <div class="mt-4 flex flex-row space-x-2">
    <div class="w-1/6 flex flex-col p-2 bg-white rounded shadow">
      <div v-if="selectedBrand" class="mx-auto my-2">
        <span class="p-1 rounded shadow">{{ selectedBrand }}</span>
      </div>
      <div
        v-if="selectedBrand"
        class="flex flex-row items-center justify-between mb-2"
      >
        <span
          class="text-xs p-1 bg-blue-500 text-white rounded shadow cursor-pointer"
          @click="checkAllChild()"
          >Tout selectionner</span
        >
        <span
          class="text-xs p-1 bg-orange-500 text-white rounded shadow cursor-pointer"
          @click="uncheckAllChild()"
          >Tout déselectionner</span
        >
      </div>
      <div
        v-for="(child, indexChild) in assemblyGroupSelected.child"
        :key="indexChild"
      >
        <div class="flex flex-row space-x-2">
          <div v-if="selectedBrand">
            <input
              v-model="checkedChildTMP[indexChild]"
              type="checkbox"
              class="w-4 h-4 text-green-500 bg-gray-100 rounded border-gray-300 dark:bg-gray-700 dark:border-gray-600"
              @change="checkChild(indexChild)"
            />
          </div>
          <span>
            <span v-if="!selectedBrand"> - </span>
            {{ child.assemblyGroupName }}</span
          >
        </div>
      </div>
    </div>
    <div class="w-5/6 flex flex-col">
      <span>Marques activées : </span>
      <div class="grid grid-cols-6 gap-1">
        <div
          v-for="(agBrand, indexAGBrand) in assemblyGroupSelected.brand"
          :key="indexAGBrand"
          draggable="true"
          @dragstart="startBrandDrag($event, indexAGBrand)"
          @drop="onBrandDrop($event, indexAGBrand)"
          @dragover.prevent
          @dragenter.prevent
        >
          <div
            class="flex flex-row p-1 bg-blue-500 text-white rounded shadow-xl justify-between"
            v-if="agBrand == selectedBrand"
          >
            <span @click="selectBrand(agBrand)" class="cursor-pointer"
              >{{ indexAGBrand + 1 }}. {{ agBrand }}</span
            >
            <span
              class="material-icons-round text-red-500 cursor-pointer"
              @click="removeBrand(agBrand)"
            >
              delete
            </span>
          </div>
          <div
            class="flex flex-row p-1 bg-white rounded justify-between"
            v-else
          >
            <span @click="selectBrand(agBrand)" class="cursor-pointer"
              >{{ indexAGBrand + 1 }}. {{ agBrand }}</span
            >
            <span
              class="material-icons-round text-red-500 cursor-pointer"
              @click="removeBrand(agBrand)"
            >
              delete
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-2 mt-2">
        <div>Marque secondaire</div>
        <div class="bg-white rounded p-1">
          <div class="grid grid-cols-6 gap-1">
            <div
              v-for="(
                agBrandSecondary, indexAGBrandSecondary
              ) in assemblyGroupSelected.brandSecondary"
              :key="indexAGBrandSecondary"
            >
              <div
                class="p-1 bg-blue-500 text-white rounded shadow flex flex-row justify-between"
                v-if="agBrandSecondary == selectedBrand"
              >
                <span
                  class="cursor-pointer"
                  @click="selectBrand(agBrandSecondary)"
                  >{{ agBrandSecondary }}</span
                >
                <span
                  class="material-icons-round text-red-500 cursor-pointer"
                  @click="removeBrandSecondary(agBrandSecondary)"
                >
                  delete
                </span>
              </div>
              <div
                class="p-1 bg-gray-200 rounded shadow flex flex-row justify-between"
                v-else
              >
                <span
                  class="cursor-pointer"
                  @click="selectBrand(agBrandSecondary)"
                  >{{ agBrandSecondary }}</span
                >
                <span
                  class="material-icons-round text-red-500 cursor-pointer"
                  @click="removeBrandSecondary(agBrandSecondary)"
                >
                  delete
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Recherche de marque à ajouter -->
      <div v-if="AGTab.error" class="mt-2">
        <div class="flex flex-col">
          <input
            type="text"
            v-model="searchMarque"
            placeholder="Search"
            class="focus:outline-2 outline-sky-300 border rounded-md shadow mb-1 px-1"
          />
          <div class="grid grid-cols-6 gap-1">
            <div
              class="flex flex-col space-y-1 bg-white p-1 rounded"
              v-for="(brandE, indexE) in filteredMarque"
              :key="indexE"
            >
              <div
                @click="
                  showBrandTypeChoice[indexE] = !showBrandTypeChoice[indexE]
                "
                class="cursor-pointer"
              >
                {{ brandE.mfrName }}
              </div>
              <div
                v-show="showBrandTypeChoice[indexE]"
                class="flex flex-row justify-between items-center"
              >
                <div
                  class="cursor-pointer px-4 bg-blue-500 text-white rounded"
                  @click="
                    addBrand(brandE.mfrName),
                      (showBrandTypeChoice[indexE] = false)
                  "
                >
                  Primaire
                </div>
                <div
                  class="cursor-pointer px-4 bg-blue-500 text-white rounded"
                  @click="
                    addBrandSecondary(brandE.mfrName),
                      (showBrandTypeChoice[indexE] = false)
                  "
                >
                  Secondaire
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      AGTab: [],
      searchMarque: "",
      searchMarqueF: "",
      dragBrandIndex: 0,
      dragBrandSecondaryIndex: 0,
      selectedBrand: "",
      selectedBrandSecondary: "",
      checkedChildTMP: [],
      showBrandTypeChoice: [],
    };
  },
  computed: {
    ...mapGetters(["assemblyGroupSelected", "user"]),
    filteredMarque() {
      if (this.searchMarque) {
        return this.AGTab.data.filter((item) => {
          return item.mfrName.startsWith(this.searchMarque.toUpperCase());
        });
      } else {
        return this.AGTab.data;
      }
    },
    filteredMarqueFound() {
      if (this.searchMarqueF) {
        return this.AGTab.data.filter((item) => {
          return item.startsWith(this.searchMarqueF.toUpperCase());
        });
      } else {
        return this.AGTab.data;
      }
    },
  },
  methods: {
    ...mapActions(["setagselected"]),
    back() {
      this.setagselected({});
      this.$router.push("/configurationcatalogue");
    },
    setAssemblyGroup() {
      axios
        .post(
          `${process.env.VUE_APP_API}/configurationCatalogue/getGenArtLinked`,
          {
            assemblyGroup: this.assemblyGroupSelected.assemblyGroupName,
          }
        )
        .then((res) => {
          this.AGTab = res.data;
        });
    },
    addBrand(brand) {
      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/addBrand`, {
          plateform: this.user.proprietaire,
          data: {
            brand: brand,
            assemblyGroupNodeId: this.assemblyGroupSelected.assemblyGroupNodeId,
          },
        })
        .then((res) => {
          //   this.setagselected(res.data);
          var indexAG = res.data[0].configuration.findIndex(
            (obj) =>
              obj.assemblyGroupNodeId ==
              this.assemblyGroupSelected.assemblyGroupNodeId
          );

          this.setagselected(res.data[0].configuration[indexAG]);
        });
    },
    addBrandSecondary(brandSecondary) {
      axios
        .post(
          `${process.env.VUE_APP_API}/configurationCatalogue/addBrandSecondary`,
          {
            plateform: this.user.proprietaire,
            data: {
              brandSecondary: brandSecondary,
              assemblyGroupNodeId:
                this.assemblyGroupSelected.assemblyGroupNodeId,
            },
          }
        )
        .then((res) => {
          //   this.setagselected(res.data);
          var indexAG = res.data[0].configuration.findIndex(
            (obj) =>
              obj.assemblyGroupNodeId ==
              this.assemblyGroupSelected.assemblyGroupNodeId
          );

          this.setagselected(res.data[0].configuration[indexAG]);
        });
    },
    removeBrand(brand) {
      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/removeBrand`, {
          plateform: this.user.proprietaire,
          data: {
            brand: brand,
            assemblyGroupNodeId: this.assemblyGroupSelected.assemblyGroupNodeId,
          },
        })
        .then((res) => {
          //   this.setagselected(res.data);
          var indexAG = res.data[0].configuration.findIndex(
            (obj) =>
              obj.assemblyGroupNodeId ==
              this.assemblyGroupSelected.assemblyGroupNodeId
          );

          this.setagselected(res.data[0].configuration[indexAG]);
        });
    },
    removeBrandSecondary(brandSecondary) {
      axios
        .post(
          `${process.env.VUE_APP_API}/configurationCatalogue/removeBrandSecondary`,
          {
            plateform: this.user.proprietaire,
            data: {
              brandSecondary: brandSecondary,
              assemblyGroupNodeId:
                this.assemblyGroupSelected.assemblyGroupNodeId,
            },
          }
        )
        .then((res) => {
          //   this.setagselected(res.data);
          var indexAG = res.data[0].configuration.findIndex(
            (obj) =>
              obj.assemblyGroupNodeId ==
              this.assemblyGroupSelected.assemblyGroupNodeId
          );

          this.setagselected(res.data[0].configuration[indexAG]);
        });
    },
    startBrandDrag(evt, index) {
      this.dragBrandIndex = index;
    },
    onBrandDrop(evt, index) {
      this.assemblyGroupSelected.brand[index] =
        this.assemblyGroupSelected.brand.splice(
          this.dragBrandIndex,
          1,
          this.assemblyGroupSelected.brand[index]
        )[0];

      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/saveBrand`, {
          plateform: this.user.proprietaire,
          data: {
            brand: this.assemblyGroupSelected.brand,
            assemblyGroupNodeId: this.assemblyGroupSelected.assemblyGroupNodeId,
          },
        })
        .then((res) => {
          //   this.setagselected(res.data);
          var indexAG = res.data[0].configuration.findIndex(
            (obj) =>
              obj.assemblyGroupNodeId ==
              this.assemblyGroupSelected.assemblyGroupNodeId
          );

          this.setagselected(res.data[0].configuration[indexAG]);
        });
    },
    selectBrand(agBrand) {
      this.selectedBrand = agBrand;
      for (const [
        index,
        childBrand,
      ] of this.assemblyGroupSelected.child.entries()) {
        if (childBrand.blacklistBrand.includes(this.selectedBrand)) {
          this.checkedChildTMP[index] = false;
        } else {
          this.checkedChildTMP[index] = true;
        }
      }
    },
    checkAllChild() {
      for (const [index, child] of this.assemblyGroupSelected.child.entries()) {
        this.checkedChildTMP[index] = true;
        if (
          this.assemblyGroupSelected.child[index].blacklistBrand.includes(
            this.selectedBrand
          )
        ) {
          var indexBlackListed = this.assemblyGroupSelected.child[
            index
          ].blacklistBrand.indexOf(this.selectedBrand);
          this.assemblyGroupSelected.child[index].blacklistBrand.splice(
            indexBlackListed,
            1
          );
        }
      }

      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/saveChild`, {
          plateform: this.user.proprietaire,
          data: {
            child: this.assemblyGroupSelected.child,
            assemblyGroupNodeId: this.assemblyGroupSelected.assemblyGroupNodeId,
          },
        })
        .then((res) => {
          //   this.setagselected(res.data);
          var indexAG = res.data[0].configuration.findIndex(
            (obj) =>
              obj.assemblyGroupNodeId ==
              this.assemblyGroupSelected.assemblyGroupNodeId
          );

          this.setagselected(res.data[0].configuration[indexAG]);
        });
    },
    uncheckAllChild() {
      for (const [index, child] of this.assemblyGroupSelected.child.entries()) {
        this.checkedChildTMP[index] = false;
        if (
          !this.assemblyGroupSelected.child[index].blacklistBrand.includes(
            this.selectedBrand
          )
        ) {
          this.assemblyGroupSelected.child[index].blacklistBrand.push(
            this.selectedBrand
          );
        }
      }

      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/saveChild`, {
          plateform: this.user.proprietaire,
          data: {
            child: this.assemblyGroupSelected.child,
            assemblyGroupNodeId: this.assemblyGroupSelected.assemblyGroupNodeId,
          },
        })
        .then((res) => {
          //   this.setagselected(res.data);
          var indexAG = res.data[0].configuration.findIndex(
            (obj) =>
              obj.assemblyGroupNodeId ==
              this.assemblyGroupSelected.assemblyGroupNodeId
          );

          this.setagselected(res.data[0].configuration[indexAG]);
        });
    },
    checkChild(indexChild) {
      if (
        this.assemblyGroupSelected.child[indexChild].blacklistBrand.includes(
          this.selectedBrand
        )
      ) {
        var indexBlackListed = this.assemblyGroupSelected.child[
          indexChild
        ].blacklistBrand.indexOf(this.selectedBrand);
        this.assemblyGroupSelected.child[indexChild].blacklistBrand.splice(
          indexBlackListed,
          1
        );
      } else {
        this.assemblyGroupSelected.child[indexChild].blacklistBrand.push(
          this.selectedBrand
        );
      }

      axios
        .post(`${process.env.VUE_APP_API}/configurationCatalogue/saveChild`, {
          plateform: this.user.proprietaire,
          data: {
            child: this.assemblyGroupSelected.child,
            assemblyGroupNodeId: this.assemblyGroupSelected.assemblyGroupNodeId,
          },
        })
        .then((res) => {
          //   this.setagselected(res.data);
          var indexAG = res.data[0].configuration.findIndex(
            (obj) =>
              obj.assemblyGroupNodeId ==
              this.assemblyGroupSelected.assemblyGroupNodeId
          );

          this.setagselected(res.data[0].configuration[indexAG]);
        });
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
    this.setAssemblyGroup();
  },
};
</script>
<style></style>
