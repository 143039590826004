<template>
  <div
    v-if="openModalSending"
    :class="`modal ${
      !openModalSending && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openModalSending = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 50rem; height: 50rem; max-height: 50rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Envoyer définitivement les factures</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openModalSending = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <div
          class="p-2 overflow-y-auto overflow-container border rounded-md shadow-lg"
          style="max-width: 47rem; height: 40rem; max-height: 40rem"
        >
          <div class="flex flex-wrap space-x-2">
            <div v-for="(line, index) in selectedLinks" :key="index">
              <a
                :href="line.link"
                target="_blank"
                rel="noopener noreferrer"
                class="flex space-x-2 hover:text-blue-500"
                >N° {{ line.numero }},
              </a>
            </div>
          </div>
        </div>

        <!--Footer-->
        <div class="flex justify-end mt-4">
          <button
            @click="openModalSending = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <button
            v-if="sending"
            class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
          >
            <PulseLoader color="#ffff" class="mr-2" />
            <span>En cours </span>
          </button>
          <button
            v-else
            @click="sendEmail(false, true)"
            class="px-6 py-3 mr-2 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white border border-bluevk hover:border-transparent rounded"
          >
            Envoyer définitivement
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="visible" class="print-dialog z-40">
    <div class="print-dialog-content">
      <h3 class="text-xl font-bold">Choisissez les documents à fusionner</h3>
      <div v-if="tabPdf && tabPdf.length > 0" class="p-5">
        <div class="flex justify-end">
          <input
            type="checkbox"
            v-model="isSelectAll"
            @change="toggleSelectAll"
            class="scale-125 mr-2"
          />
          <label>Tout sélectionner/désélectionner</label>
        </div>
        <ul
          class="p-2 border rounded-md shadow-lg overflow-container"
          style="
            height: 25rem;
            max-height: 25rem;
            width: 60rem;
            max-width: 60rem;
          "
        >
          <li
            v-for="(f, index) in tabPdf"
            :key="index"
            style="width: 59rem; max-width: 59rem"
          >
            <a
              :href="f.link"
              target="_blank"
              rel="noopener noreferrer"
              class="flex space-x-2 hover:text-blue-500"
            >
              <input
                type="checkbox"
                v-model="selectedLinks"
                :value="{
                  link: f.link,
                  numero: f.Numero,
                  clientId:
                    f.clientInfo && f.clientInfo.length > 0
                      ? f.clientInfo[0]._id
                      : null,
                }"
                class="scale-125 transition-transform duration-200"
              />
              <p>{{ f.Client }}, N° {{ f.Numero }}</p>
              <p v-if="f.printed" class="text-green-600">imprimé</p>
              <p v-if="f.sent" class="text-green-600">envoyé</p>
            </a>
          </li>
        </ul>
        <div style="width: 60rem; max-width: 60rem">
          <!-- Affichage du nombre d'éléments -->
          <div
            class="flex flex-col items-center px-5 py-5 bg-white border-t xs:flex-row xs:justify-between"
          >
            <span class="text-sm text-bluevk xs:text-sm">
              {{ nbrStart }} à {{ nbrEnd }} sur {{ tabPdfLength }} factures
            </span>
          </div>
          <!-- Boutons de pagination -->
          <div class="flex justify-center xs:mt-0">
            <!-- Bouton "Précédent" -->
            <button
              class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-l hover:bg-gray-400"
              @click="pageDown"
              :disabled="currentPage === 1"
            >
              Précédent
            </button>

            <!-- Boutons de pages -->
            <div v-for="page in pageNumbers" :key="page">
              <button
                :class="{ 'active-page': page === currentPage }"
                class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 hover:bg-gray-200"
                @click="getPdfLinkPagination(page, batchSize)"
              >
                {{ page }}
              </button>
            </div>

            <!-- Bouton "Suivant" -->
            <button
              class="px-4 py-2 text-sm font-semibold text-gray-800 bg-gray-300 rounded-r hover:bg-gray-400"
              @click="pageUp"
              :disabled="currentPage === pageTotal"
            >
              Suivant
            </button>
          </div>
        </div>
        <div class="flex justify-end">
          <div class="flex flex-col">
            <div class="">
              <label>Impression et envoie par : </label>
              <select
                v-model="batchSize"
                @change="getPdfLinkPagination(1, batchSize)"
                class="border ml-2 p-2 shadow-lg rounded-md"
              >
                <option :value="2">2</option>
                <option :value="4">4</option>
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="30">30</option>
                <option :value="40">40</option>
                <option :value="50">50</option>
              </select>
            </div>
            <p class="text-right">
              {{ selectedLinks.length }} factures sélectionnées
            </p>
          </div>
        </div>

        <div class="flex justify-end mt-6">
          <div class="flex flex-col space-y-4">
            <button
              v-if="printing && sending"
              class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
            >
              <PulseLoader color="#ffff" class="mr-2" />
              <span>En cours </span>
            </button>
            <button
              v-else
              @click="createPDF(true, true)"
              class="ml-auto px-6 py-3 mr-2 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white border border-bluevk hover:border-transparent rounded"
            >
              Imprimer et Envoyer
            </button>
            <div class="flex">
              <button
                @click="cancelPrint()"
                class="px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
              >
                Fermer
              </button>
              <button
                v-if="printing && !sending"
                class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
              >
                <PulseLoader color="#ffff" class="mr-2" />
                <span>En cours </span>
              </button>
              <button
                v-else
                @click="createPDF(true, false)"
                class="px-6 py-3 mr-2 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white border border-bluevk hover:border-transparent rounded"
              >
                Imprimer
              </button>
              <button
                v-if="!printing && sending"
                class="bg-bluevk text-white hover:bg-blue-900 border border-bluevk shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
              >
                <PulseLoader color="#ffff" class="mr-2" />
                <span>En cours </span>
              </button>
              <button
                v-else
                @click="createPDF(false, true)"
                class="px-6 py-3 mr-2 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white border border-bluevk hover:border-transparent rounded"
              >
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-else>Aucun document disponible.</div>
    </div>
    <div class="print-dialog-overlay" @click="cancelPrint()"></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { PDFDocument } from "pdf-lib";
import { success, warning, error } from "@/hooks/tools/toast";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    facturationSelected: {
      type: Object,
    },
  },
  data() {
    return {
      tabPdf: [],
      tabPdfLength: 0,
      selectedLinks: [],
      isSelectAll: true,
      batchSize: 10,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,

      openModalSending: false,
      printing: false,
      sending: false,
    };
  },
  computed: {
    ...mapGetters(["user", "plateform"]),

    pageTotal() {
      return Math.ceil(this.tabPdfLength / this.batchSize); // Nombre total de pages
    },

    pageNumbers() {
      const start = Math.max(1, this.currentPage - 5); // Début de la plage
      const end = Math.min(this.pageTotal, this.currentPage + 4); // Fin de la plage
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },

    nbrStart() {
      return (this.currentPage - 1) * this.batchSize + 1; // Début de l'affichage
    },

    nbrEnd() {
      return Math.min(this.currentPage * this.batchSize, this.tabPdfLength); // Fin de l'affichage
    },
  },
  components: {
    PulseLoader,
  },
  methods: {
    async getPdfLinkPagination(skipValue, limitValue) {
      this.currentPage = skipValue;
      const response = await axios.post(
        `${process.env.VUE_APP_API}/facture/getPdfLinkPagination`,
        {
          plateform: this.user.proprietaire,
          numeroF: this.facturationSelected.numeroFacturation,
          skip: (skipValue - 1) * parseInt(limitValue),
          limit: parseInt(limitValue),
        }
      );
      this.tabPdf = response.data.tab;
      this.tabPdfLength = response.data.count;
      this.selectedLinks = this.tabPdf.map((item) => ({
        link: item.link,
        numero: item.Numero,
        clientId:
          item.clientInfo && item.clientInfo.length > 0
            ? item.clientInfo[0]._id
            : null,
      }));
      console.log(this.selectedLinks);
    },
    cancelPrint() {
      this.$emit("cancel");
    },
    async createPDF(print, sent) {
      if (this.selectedLinks.length === 0) {
        warning("Aucune facture sélectionnée !");
        return;
      }

      try {
        // this.printing = print;
        // this.sending = sent;

        if (print) {
          const mergedPdf = await PDFDocument.create();

          // Fusionner les fichiers PDF sélectionnés
          for (const pdfUrl of this.selectedLinks) {
            const response = await fetch(pdfUrl.link);
            const arrayBuffer = await response.arrayBuffer();
            const sourcePdf = await PDFDocument.load(arrayBuffer);

            const copiedPages = await mergedPdf.copyPages(
              sourcePdf,
              sourcePdf.getPageIndices()
            );

            copiedPages.forEach((page) => {
              mergedPdf.addPage(page);
            });

            // Ajouter une page vierge si le nombre total de pages est impair
            const totalPages = sourcePdf.getPageCount();
            if (totalPages % 2 !== 0) {
              mergedPdf.addPage(); // Ajouter une page vierge
            }
          }

          const mergedPdfBytes = await mergedPdf.save();
          const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);

          const newWindow = window.open(url, "_blank");

          if (newWindow) {
            newWindow.addEventListener("load", () => {
              newWindow.print();
            });

            newWindow.addEventListener("beforeunload", () => {
              URL.revokeObjectURL(url);
            });
          } else {
            warning("Impossible d'ouvrir une nouvelle fenêtre !");
          }
          if (print && !sent) {
            this.sendEmail(true, false);
          }
        }
        if (sent) {
          console.log("seeeeeeeent :", sent);
          this.sending = false;
          this.openModalSending = true;
        } else {
          if (this.currentPage < this.pageNumbers.length) {
            this.getPdfLinkPagination(this.currentPage + 1, this.batchSize);
          } else if (this.currentPage == this.pageNumbers.length) {
            this.getPdfLinkPagination(this.currentPage, this.batchSize);
          }
        }
      } catch (er) {
        console.error("Erreur lors de la fusion des PDF :", er);
        error("Erreur lors de la fusion des PDF !");
      }
    },
    sendEmail(print, sent) {
      this.printing = print;
      this.sending = sent;
      axios
        .post(`${process.env.VUE_APP_API}/facture/sendEmail`, {
          plateform: this.plateform,
          tabLink: this.selectedLinks,
          printing: print,
          sending: sent,
        })
        .then((response) => {
          this.openModalSending = false;
          this.printing = false;
          this.sending = false;
          if (this.currentPage < this.pageNumbers.length) {
            this.getPdfLinkPagination(this.currentPage + 1, this.batchSize);
          } else if (this.currentPage == this.pageNumbers.length) {
            this.getPdfLinkPagination(this.currentPage, this.batchSize);
          }
        })
        .catch((error) => {
          this.openModalSending = false;
          this.printing = false;
          this.sending = false;
          this.getPdfLinkPagination(this.currentPage, this.batchSize);
        });
    },
    toggleSelectAll() {
      if (this.isSelectAll) {
        this.selectedLinks = this.tabPdf.map((item) => ({
          link: item.link,
          numero: item.Numero,
          clientId:
            item.clientInfo && item.clientInfo.length > 0
              ? item.clientInfo[0]._id
              : null,
        }));
      } else {
        this.selectedLinks = [];
      }
    },
    pageDown() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.getPdfLinkPagination(this.currentPage, this.batchSize);
      }
    },
    pageUp() {
      if (this.currentPage < this.pageTotal) {
        this.currentPage += 1;
        this.getPdfLinkPagination(this.currentPage, this.batchSize);
      }
    },
  },
  watch: {
    // Surveille le changement de selectedLinks pour ajuster isSelectAll
    selectedLinks() {
      this.isSelectAll = this.selectedLinks.length === this.tabPdf.length;
    },

    // Surveille le changement de batchSize pour ajuster la pagination
    batchSize(newSize) {
      this.getPdfLinkPagination(1, newSize); // Appeler la méthode pour ajuster la pagination
    },
  },
  beforeMount() {
    this.getPdfLinkPagination(1, this.batchSize);
  },
};
</script>

<style scoped>
.print-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print-dialog-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  z-index: 1000;
}

.print-dialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.print-dialog-actions {
  margin-top: 20px;
}

.overflow-container {
  overflow: auto;
}

.overflow-container::-webkit-scrollbar {
  width: 10px;
}

.overflow-container::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  margin-left: 20px;
}

.overflow-container::-webkit-scrollbar-thumb:hover {
  background-color: #888888;
}
</style>
