<template>
  <div class="flex flex-row items-center justify-between">
    <div
      id="retour"
      class="flex flex-row space-x-2 items-center w-fit p-2 bg-bluevk text-white rounded shadow cursor-pointer"
      @click="$router.push('/articlehub')"
    >
      <span class="material-icons-round text-sm"> arrow_back_ios_new </span>
      <span>Retour</span>
    </div>
    <div class="flex flex-row items-center space-x-2">
      <label>
        <span
          class="bg-blue-500 p-2 text-white rounded shadow cursor-pointer"
          @click="exportExcel()"
        >
          Exporter en Excel
        </span></label
      >
      <label for="dropzone-file">
        <span class="p-2 bg-green-500 text-white rounded shadow cursor-pointer">
          Importer excel
        </span>
        <input
          id="dropzone-file"
          type="file"
          class="hidden"
          accept=".xlsx, .xls, .csv"
          @change="readExcel()"
        />
      </label>
    </div>
  </div>
  <div>
    <input
      type="text"
      v-model="searchMarque"
      placeholder="Search"
      class="focus:outline-2 outline-sky-300 border rounded-md shadow mb-1 px-1"
    />
    <div class="grid grid-cols-4 gap-1 h-20 overflow-y-auto">
      <div
        class="cursor-pointer bg-white rounded p-1 h-fit"
        v-for="(brand, indexBrand) in filteredMarque"
        :key="indexBrand"
        @click="addCross(brand.dataSupplierId, brand.mfrName)"
      >
        <span>{{ brand.mfrName }}</span>
      </div>
    </div>
  </div>
  <div v-show="loadingCross" class="text-blue-500 animate-pulse">
    Recherche en cours...
  </div>
  <div class="flex flex-col space-y-2 mt-6">
    <div
      class="p-2 bg-white rounded shadow"
      v-for="(ref, indexRef) in crossList"
      :key="indexRef"
    >
      <div class="flex flex-col space-y-1">
        <div>
          {{ ref.Ref_fournisseur }} -
          {{ parseFloat(ref.prix_achat).toFixed(2) }} €
        </div>
        <div class="flex flex-col space-y-1">
          <div
            class="flex flex-row items-center space-x-1"
            v-for="(cross, indexCross) in ref.Cross"
            :key="indexCross"
          >
            <div>{{ cross.mfrName }} :</div>
            <div class="flex flex-row items-center space-x-1">
              <div
                v-for="(cArt, indexCArt) in cross.Articles"
                :key="indexCArt"
                class="flex flex-row w-fit p-1 items-center space-x-1 bg-blue-100 rounded"
              >
                <span>{{ cArt.article.Ref_fournisseur }}</span>
                <span>{{ cArt.article.stock }}</span>
                <span
                  >{{ parseFloat(cArt.article.prix_achat).toFixed(2) }} €</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
import commandeInstFournisseurVue from "./commandeInstFournisseur.vue";
import { getPAList } from "@/hooks/articles/articles.ts";
export default {
  name: "StockArticle",
  computed: {
    ...mapGetters(["user"]),
    filteredMarque() {
      if (this.searchMarque) {
        return this.brand.filter((item) => {
          return item.mfrName.startsWith(this.searchMarque.toUpperCase());
        });
      } else {
        return this.brand;
      }
    },
  },
  data() {
    return {
      brand: [],
      crossList: [],
      searchMarque: "",
      errorSearch: false,
      loadingCross: false,
    };
  },
  methods: {
    async getBrand() {
      axios
        .get(`${process.env.VUE_APP_API}/articlecross/getambrand`)
        .then((res) => {
          this.brand = res.data;
        });
    },
    async addCross(dataSupplierId, mfrName) {
      this.loadingCross = true;
      const searchCross = await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/searchManyArticleSimByBrand`,
        {
          crossList: this.crossList,
          plateform: this.user.proprietaire,
          dataSupplierId: dataSupplierId,
          mfrName: mfrName,
        }
      );
      this.crossList = searchCross.data;
      this.loadingCross = false;
    },
    async readExcel() {
      var file = event.target.files ? event.target.files[0] : null;
      console.log(file);
      if (file.size < 1000000) {
        this.loadingCross = true;
        let cross_xlsx = await readXlsxFile(file);
        cross_xlsx.splice(0, 1);
        for (const cross of cross_xlsx) {
          this.crossList.push({
            Ref_fournisseur: cross[0],
            Prefixe_tarif: cross[1],
            Cross: [],
          });
        }
        this.crossList = await getPAList(
          this.user.proprietaire,
          this.crossList
        );
        this.loadingCross = false;
      } else {
        this.error("Le fichier est trop volumineux");
      }
    },
    exportExcel() {
      let exportTab = [];
      let simpleExportTab = [];
      for (const cross of this.crossList) {
        let crossObj = {
          Ref_fournisseur: cross.Ref_fournisseur,
        };
        for (const brand of cross.Cross) {
          for (const [indexArt, art] of brand.Articles.entries()) {
            crossObj[`${art.article.Code_marque} ${indexArt + 1}`] =
              art.article.Ref_fournisseur;
            exportTab.push({
              Ref: cross.Ref_fournisseur,
              "Ref Prix Achat": cross.prix_achat,
              Marque: art.article.Code_marque,
              Cross: art.article.Ref_fournisseur,
              "Prix d'achat": art.article.prix_achat,
              Stock: art.article.stock,
              Existe: !art.article.error,
            });
          }
        }
        simpleExportTab.push(crossObj);
      }
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(simpleExportTab);
      let ws2 = XLSX.utils.json_to_sheet(exportTab);
      XLSX.utils.book_append_sheet(wb, ws, "Cross 1");
      XLSX.utils.book_append_sheet(wb, ws2, "Cross 2");

      XLSX.writeFile(wb, "Cross_" + this.user.proprietaire + ".xlsx");
    },
  },
  async mounted() {
    this.getBrand();
  },
};
</script>

<style scoped></style>
