<template>
  <div class="flex flex-row justify-end mb-4">
    <div class="flex flex-row">
      <select
        v-model="documentType"
        style="width: 10rem"
        class="cursor-pointer block w-full h-full pl-2 py-2 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l rounded-r focus:outline-2 outline-sky-300"
      >
        <option value="Devis">Devis</option>
        <option value="Ordre de réparation">Ordre de réparation</option>
      </select>
    </div>
    <button
      class="px-6 py-2 ml-3 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none"
      @click="saveDocument(documentType)"
    >
      Valider
    </button>
  </div>

  <div
    class="inline-block h-fit min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg overflow-y-auto"
  >
    <div v-if="tabCP.documentType && tabCP.documentType.type">
      {{ tabCP.documentType.type }} n°{{ tabCP.documentType.numero }}
    </div>
    <table class="min-w-full">
      <thead class="">
        <tr>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Réf./Equipe
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Stock
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Descr./Fourn.
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Qté
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            P.V.
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Remise
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Px final
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Total
          </th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr
          v-for="(articleAct, index) in tabCP.panier"
          :key="index"
          class="cursor-pointer hover:bg-orange-200 hover:bg-opacity-25 w-fit"
        >
          <td
            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
          >
            {{ articleAct.Ref_fournisseur }}
          </td>

          <td
            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
          >
            <div class="flex flex-col">
              <div class="flex flex-row items-center">
                <div class="text-2xs mx-auto">
                  {{ user.proprietaire }}
                </div>
                <div
                  class="text-green-600 mx-auto text-xs border border-gray-300 py-0.5 px-2 rounded-full"
                  v-if="articleAct.stockData"
                >
                  {{ articleAct.stockData }}
                </div>
                <div
                  class="text-red-600 mx-auto text-xs border border-gray-300 py-0.5 px-2 rounded-full"
                  v-else
                >
                  0
                </div>
              </div>

              <div
                v-for="(stF, indexStF) in articleAct.stockFiliale"
                :key="indexStF"
                class="flex flex-row items-center"
              >
                <div class="text-2xs mx-auto cursor-pointer">
                  {{ stF.plateform }}
                </div>
                <div class="flex mx-auto">
                  <input
                    v-if="articleAct.stockFiliale[indexStF].transfertState"
                    type="Number"
                    class="w-12 bg-green-600 text-white mx-auto text-xs border border-gray-300 p-0.5 rounded-full"
                    v-model="articleAct.stockFiliale[indexStF].stock"
                  />
                  <input
                    v-else
                    type="Number"
                    class="w-12 mx-auto text-xs border border-gray-300 p-0.5 rounded-full"
                    v-model="articleAct.stockFiliale[indexStF].stock"
                  />
                </div>
              </div>
            </div>
          </td>

          <td
            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
          >
            <div class="text-sm leading-5 whitespace-normal w-[24rem]">
              {{ articleAct.Description }}
            </div>
            <div class="flex flex-row items-center space-x-2">
              <div class="text-xs leading-5 text-gray-900">
                {{ articleAct.Code_marque }}
              </div>
              <div class="text-sm leading-5 text-gray-900">
                <span class="text-xs text-blue-500">{{
                  articleAct.FournisseurSelected
                }}</span>
              </div>
            </div>
          </td>
          <td
            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
          >
            <div class="flex flex-row items-center space-x-1">
              {{ articleAct.quantity }}
            </div>
          </td>

          <td
            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
          >
            {{ financial(articleAct.Prix_euro) }} €
          </td>

          <td
            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
          >
            <h4 v-if="articleAct.PrixFournisseurType != 'prixnet'">
              {{ articleAct.remise }} %
            </h4>
            <h4 v-else>{{ articleAct.PrixFournisseurType }} €</h4>
          </td>

          <td
            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
          >
            <h4 v-if="articleAct.PrixFournisseurType != 'prixnet'">
              {{
                financial(
                  articleAct.Prix_euro -
                    articleAct.Prix_euro * (tabCP.panier[index].remise / 100)
                )
              }}
              €
            </h4>
            <h4 v-else>
              <input
                class="w-3/5"
                type="Number"
                v-model="tabCP.panier[index].Prix_vente"
              />
              €
            </h4>
          </td>

          <td
            class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
          >
            <h4 v-if="articleAct.PrixFournisseurType != 'prixnet'">
              {{
                financial(
                  (articleAct.Prix_euro -
                    articleAct.Prix_euro * (tabCP.panier[index].remise / 100)) *
                    tabCP.panier[index].quantity
                )
              }}
              €
            </h4>
            <h4 v-else>
              {{
                financial(
                  tabCP.panier[index].Prix_vente * tabCP.panier[index].quantity
                )
              }}
              €
            </h4>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import {
  getCPbyId,
  saveChange,
} from "@/hooks/controlePreliminaire/controlePreliminaire.ts";
import { createdevis } from "@/hooks/pdf/devis";

export default {
  name: "identificationVehicule",
  data() {
    return {
      documentType: "Devis",
      tabCP: {},
      totalDebutFrais: "",
    };
  },
  computed: {
    ...mapGetters(["actionArticle", "user", "controlePreliminaireSelected"]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      var totaldebut = 0.0;

      if (this.tabCP.panier.length > 0) {
        this.tabCP.panier.forEach((element, index) => {
          if (element.PrixFournisseurType == "prixnet") {
            totalRemise = parseFloat(element.Prix_vente);
          } else {
            totalRemise =
              parseFloat(element.Prix_euro) -
              parseFloat(element.Prix_euro) *
                (this.tabCP.panier[index].remise / 100);
          }
          totaldebut =
            totaldebut + totalRemise * this.tabCP.panier[index].quantity;
          this.totalDebutFrais = totaldebut;
        });

        total = totaldebut;
      } else {
        total += totaldebut;
      }

      if (totaldebut >= 0) {
        return total;
      } else {
        return totaldebut;
      }
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  methods: {
    ...mapActions([
      "cleardataconsigne",
      "clearactionArticle",
      "cleardatanonconsigne",
    ]),
    save() {
      saveChange(this.tabCP._id, this.tabCP);
    },
    async saveDocument(docType) {
      let numDoc;
      var pdfinfo = [];

      var prixRemise = 0.0;

      await this.tabCP.panier.forEach((element, index) => {
        if (element.PrixFournisseurType == "prixnet") {
          prixRemise = parseFloat(element.Prix_vente);
        } else {
          prixRemise =
            element.Prix_euro -
            element.Prix_euro * (this.tabCP.panier[index].remise / 100);
        }
        // prixRemiseSupp =
        //   prixRemise - prixRemise * (this.remiseTabSCD[index] / 100);
        pdfinfo[index] = {
          description: element.Description,
          ref: element.Ref_fournisseur,
          quantite: this.tabCP.panier[index].quantity,
          remise: this.tabCP.panier[index].remise,
          commentaire: element.commentaire,
          remisegenerale: 0,
          // remiseSupp: this.remiseTabSCD[index],
          prixU: prixRemise,
          total: prixRemise * this.tabCP.panier[index].quantity,
          totalSansRemise: this.setTotalSansRemise,
        };
      });
      switch (docType) {
        case "Devis":
          await axios
            .post(`${process.env.VUE_APP_API}/devis/getlast`, {
              uid: this.user.proprietaire,
            })
            .then((response) => {
              numDoc = response.data + 1;
            });
          var dataDevis = {
            Name: this.user.username,
            Owner: this.user.proprietaire,
            // Date: today,
            Numero: numDoc,
            TotalPrice: this.setTotal,
            TotalPriceTTC: this.setTotalTTC,
            Client: this.tabCP.Client[0].Name,
            IsProcessed: false,
            Note: "",
            Articles: this.tabCP.panier,
            reglement: [],
            pdfData: pdfinfo,
            clientInfo: this.tabCP.Client,
            Archived: false,
            Transport: 0,
          };
          await axios
            .post(`${process.env.VUE_APP_API}/devis`, dataDevis)
            .then((res) => {
              // this.save();
              createdevis(res.data, false);
              // this.$router.push("/devis");
            });
          break;
      }
      this.cleardataconsigne();
      this.cleardatanonconsigne();
      this.clearactionArticle();
      this.tabCP = await getCPbyId(this.controlePreliminaireSelected);
      this.tabCP.documentType = {
        type: "Devis",
        numero: numDoc,
      };
      this.tabCP.panier = this.actionArticle;
      this.save();
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  async beforeMount() {
    this.tabCP = await getCPbyId(this.controlePreliminaireSelected);
    this.tabCP.panier = this.actionArticle;
    this.save();
  },
};
</script>
