<template>
    <div class="">
        <div > Attention ! Pas de session en cours.
            <br>
        </div>
        <button class="border bg-blue-500 border-blue-500 rounded-full"> Démarrer une session </button>
    </div>
</template>


