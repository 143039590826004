import axios from "axios";

export async function createClientPrixNet(
    data: object
) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/clientPrixNetFourn`,
        data
    );

    return response.data;
}

export async function getClientPrixNet(
    data: object
) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/clientPrixNetFourn/getAllByFournisseur`,
        data
    );

    return response.data;
}

export async function saveClientPrixNet(
    data: object
) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/clientPrixNetFourn/save`,
        data
    );

    return response.data;
}

export async function removeClientPrixNet(
    data: object
) {
    const response = await axios.post(
        `${process.env.VUE_APP_API}/clientPrixNetFourn/remove`,
        data
    );

    return response.data;
}