<template>
  <div
    v-if="showReservation"
    :class="`modal ${
      !showReservation && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="closeReservation()"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="closeReservation()"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <!-- Body -->
        <div>
          <div class="flex flex-col justify-center text-center pt-2">
            <button
              class="p-3 text-green-500 bg-transparent rounded-lg hover:bg-gray-100 focus:outline-none material-icons-outlined text-9xl"
              @click="addToReservation()"
            >
              expand_less
            </button>

            <input
              class="w-20 justify-center items-center text-center content-center self-center text-5xl rounded-md"
              type="number"
              v-if="Articles.stock.length > 0"
              v-model="Articles.stock[0].reservedClient"
            />

            <hr />
            <button
              class="p-3 text-red-500 bg-transparent rounded-lg hover:bg-gray-100 focus:outline-none material-icons-outlined text-9xl"
              @click="delFromReservation()"
            >
              expand_more
            </button>
          </div>
        </div>
        <!-- End Body -->
      </div>
    </div>
  </div>
  <router-link to="/articlehub">
    <div id="retour" class="flex justify-start w-fit">
      <backButton />
    </div>
  </router-link>
  <div>
    <div class="relative mt-2 rounded-md shadow-sm">
      <span
        class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
      >
        <span class="material-icons-round">qr_code_scanner</span>
      </span>
      <input
        type="text"
        ref="scan"
        placeholder="Code-barres"
        v-model="articleSearchEAN"
        v-on:keyup.enter="getOneArticlebyEAN()"
        class="w-full focus:outline-2 outline-sky-300 px-12 py-2 border-transparent rounded-md appearance-none focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
      />
    </div>
    <div v-show="errorScan" class="flex">
      <span class="text-orange-400">Veuillez scanner un code barre</span>
    </div>
    <div class="flex">
      <div
        class="w-full bg-white shadow rounded mt-2 p-2"
        v-if="Articles.article"
      >
        <div class="relative mt-4 text-sm lg:text-base">
          Ref : {{ Articles.article.Ref_fournisseur }} -
          {{ Articles.article.Code_marque }}
        </div>
        <div
          class="relative mt-4 text-sm lg:text-base"
          v-if="Articles.stock.length > 0"
        >
          Adresse :
          <input
            type="text"
            v-model="Articles.stock[0].adresse_article"
            @change="
              setAdr(Articles.article, Articles.stock[0].adresse_article)
            "
          />
        </div>
        <div class="relative mt-4 text-sm lg:text-base">
          Libellé : {{ Articles.article.Description }}
        </div>
        <div class="hidden lg:flex lg:flex-row lg:mt-6">
          <div class="w-1/3">
            <div class="flex flex-col items-center">
              <span v-if="Articles.stock.length > 0" class="text-6xl">{{
                Articles.stock[0].stock
              }}</span>
              <span v-else class="text-6xl">0</span>
              <span>Stock</span>
            </div>
          </div>
          <div class="w-1/3" @click="showReservedClient()">
            <div class="flex flex-col items-center">
              <span v-if="Articles.stock.length > 0" class="text-6xl">{{
                Articles.stock[0].reservedClient
              }}</span>
              <span v-else class="text-6xl">0</span>
              <span>Reservé client</span>
            </div>
          </div>
          <div class="w-1/3">
            <div class="flex flex-col items-center">
              <span class="text-6xl">0</span>
              <span>Relicat</span>
            </div>
          </div>
        </div>
        <div class="hidden lg:block">
          <div class="relative mt-4">
            <input
              type="text"
              v-model="inputStock"
              class="w-full focus:outline-2 outline-sky-300 p-4 text-white text-4xl bg-blue-400 shadow rounded"
            />
          </div>
          <div class="flex flex-row mt-8">
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('1')"
              >
                <span class="text-6xl">1</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('2')"
              >
                <span class="text-6xl">2</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('3')"
              >
                <span class="text-6xl">3</span>
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-4">
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('4')"
              >
                <span class="text-6xl">4</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('5')"
              >
                <span class="text-6xl">5</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('6')"
              >
                <span class="text-6xl">6</span>
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-4">
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('7')"
              >
                <span class="text-6xl">7</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('8')"
              >
                <span class="text-6xl">8</span>
              </div>
            </div>
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('9')"
              >
                <span class="text-6xl">9</span>
              </div>
            </div>
          </div>
          <div class="flex flex-row mt-4 justify-center">
            <div class="w-1/3">
              <div
                class="flex flex-col items-center shadow rounded cursor-pointer"
                @click="setNumberStock('0')"
              >
                <span class="text-6xl">0</span>
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-between mt-4">
            <div class="w-1/4">
              <div
                class="flex flex-col items-center shadow rounded text-white bg-red-500 cursor-pointer"
                @click="delNumberStock()"
              >
                <span class="text-2xl p-2">Supp</span>
              </div>
            </div>
            <div class="w-1/4">
              <div
                class="flex flex-col items-center shadow rounded text-white bg-orange-500 cursor-pointer"
                @click="modifyStock(Articles.article)"
              >
                <span class="text-2xl p-2">Modifier</span>
              </div>
            </div>
            <div class="w-1/4">
              <div
                class="flex flex-col items-center shadow rounded text-white bg-green-500 cursor-pointer"
                @click="addStock(Articles.article, Articles.stock)"
              >
                <span class="text-2xl p-2">Ajouter</span>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:hidden flex flex-row items-center space-x-2 mt-6">
          <div class="flex flex-col space-y-4 align-center">
            <div
              class="flex bg-green-500 w-full rounded shadow cursor-pointer"
              @click="upInput()"
            >
              <span
                class="material-symbols-rounded text-8xl text-white mx-auto"
              >
                keyboard_arrow_up
              </span>
            </div>
            <div>
              <input
                type="text"
                v-model="inputStock"
                class="w-full focus:outline-2 outline-sky-300 p-4 text-white text-4xl bg-blue-400 shadow rounded"
              />
            </div>
            <div
              class="flex bg-red-500 w-full rounded shadow cursor-pointer"
              @click="downInput()"
            >
              <span
                class="material-symbols-rounded text-8xl text-white mx-auto"
              >
                keyboard_arrow_down
              </span>
            </div>
          </div>
          <div>
            <button
              class="p-1 bg-blue-500 text-white rounded shadow"
              @click="modifyStock(Articles.article)"
            >
              Valider
            </button>
          </div>
        </div>
      </div>
    </div>

    <audio id="son1" controls class="hidden">
      <source src="../assets/sonTest1.ogg" type="audio/ogg" />
    </audio>
    <audio id="son2" controls class="hidden">
      <source src="../assets/sonTest2.ogg" type="audio/ogg" />
    </audio>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { setStock, setAdresse, reserveStock } from "@/hooks/stock/stock.ts";
import backButton from "@/components/Button/back.vue";
export default {
  name: "MyInventaire",
  data() {
    return {
      erreurAdresse: false,
      Articles: [],
      articleSearchEAN: "",
      errorScan: false,
      inputStock: "",
      scanStarted: false,
      adresseArt: "",
      showReservation: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    getOneArticlebyEAN() {
      var lastArticle = this.Articles;
      if (this.articleSearchEAN != "") {
        this.errorScan = false;
        axios
          .post(`${process.env.VUE_APP_API}/stock/getbyEAN`, {
            Code_EAN: this.articleSearchEAN,
            plateform: this.user.proprietaire,
          })
          .then((response) => {
            if (response.data.article) {
              this.Articles = response.data;
              this.articleSearchEAN = "";
              this.scanStarted = true;
              // o
              this.inputStock = response.data.stock[0].stock;
              document.getElementById("son1").play();
            } else {
              // m
              document.getElementById("son2").play();
              this.articleSearchEAN = "";
              this.Articles = [];
              this.inputStock = "0";
            }
          });
      } else {
        this.errorScan = true;
        this.Articles = [];
        // m
        document.getElementById("son2").play();
      }
      // if (
      //   this.scanStarted &&
      //   this.articleSearchEAN == lastArticle.article.Code_EAN
      // ) {
      //   this.inputStock = "1";
      //   this.addStock(lastArticle.article, lastArticle.stock);
      // } else if (
      //   this.scanStarted &&
      //   this.articleSearchEAN != lastArticle.article.Code_EAN
      // ) {
      //   this.inputStock = "1";
      //   this.addStockAfterDiff(lastArticle.article, lastArticle.stock);
      // }
    },
    getOneArticlebyEANSND(ean) {
      if (ean != "") {
        this.errorScan = false;
        axios
          .post(`${process.env.VUE_APP_API}/stock/getbyEAN`, {
            Code_EAN: ean,
            plateform: this.user.proprietaire,
          })
          .then((response) => {
            this.Articles = response.data;
            this.articleSearchEAN = "";
            this.scanStarted = true;
            document.getElementById("son1").play();
            console.log("SECOND");
          });
      } else {
        this.errorScan = true;
        this.Articles = [];
        document.getElementById("son2").play();
      }
    },
    upInput() {
      this.inputStock = (parseInt(this.inputStock) + 1).toString();
      this.focusScan();
    },
    downInput() {
      this.inputStock = (parseInt(this.inputStock) - 1).toString();
      this.focusScan();
    },
    setNumberStock(number) {
      var numTmp = this.inputStock;
      numTmp = numTmp + number;
      this.inputStock = numTmp;
      this.focusScan();
    },
    delNumberStock() {
      this.inputStock = "";
      this.focusScan();
    },
    async addStock(art, stock) {
      if (stock.length > 0) {
        await setStock(
          art.Code_EAN,
          parseInt(stock[0].stock) + parseInt(this.inputStock),
          this.user.proprietaire,
          art.Ref_fournisseur,
          art.Prefixe_tarif,
          art.Code_marque,
          this.user.username,
          "Regul de stock par : " + this.user.username,
          "NA",
          0
        );
        this.getOneArticlebyEANSND(art.Code_EAN);
        if (!this.adresseArt) {
          this.erreurAdresse = true;
          console.log("erreur");
        }
      } else {
        await setStock(
          art.Code_EAN,
          parseInt(stock[0].stock) + parseInt(this.inputStock),
          this.user.proprietaire,
          art.Ref_fournisseur,
          art.Prefixe_tarif,
          art.Code_marque,
          this.user.username,
          "Regul de stock par : " + this.user.username,
          "NA",
          0
        );
        this.getOneArticlebyEANSND(art.Code_EAN);
        console.log(this.adresseArt);
      }
      this.focusScan();
    },
    async addStockAfterDiff(art, stock) {
      if (stock.length > 0) {
        await setStock(
          art.Code_EAN,
          parseInt(stock[0].stock) + parseInt(this.inputStock),
          this.user.proprietaire,
          art.Ref_fournisseur,
          art.Prefixe_tarif,
          art.Code_marque,
          this.user.username,
          "Regul de stock par : " + this.user.username,
          "NA",
          0
        );
      } else {
        await setStock(
          art.Code_EAN,
          parseInt(this.inputStock),
          this.user.proprietaire,
          art.Ref_fournisseur,
          art.Prefixe_tarif,
          art.Code_marque,
          this.user.username,
          "Regul de stock par : " + this.user.username,
          "NA",
          0
        );
      }
    },
    async modifyStock(art) {
      if (this.inputStock != "") {
        await setStock(
          art.Code_EAN,
          parseInt(this.inputStock),
          this.user.proprietaire,
          art.Ref_fournisseur,
          art.Prefixe_tarif,
          art.Code_marque,
          this.user.username,
          "Regul de stock par : " + this.user.username,
          "NA",
          0
        );
        this.getOneArticlebyEANSND(art.Code_EAN);
      }
      this.focusScan();
    },
    async setAdr(art, adr) {
      await setAdresse(
        this.user.proprietaire,
        art.Ref_fournisseur,
        art.Code_EAN,
        adr
      );
    },
    focusScan() {
      this.$refs.scan.focus();
    },
    showReservedClient() {
      if (this.Articles.stock.length > 0) {
        this.showReservation = true;
      } else {
        this.showReservation = false;
        document.getElementById("son2").play();
      }
    },
    addToReservation() {
      //TODO : add to reservation
      if (this.Articles.stock.length > 0) {
        if (this.Articles.stock[0].stock > 0) {
          this.Articles.stock[0].reservedClient =
            parseInt(this.Articles.stock[0].reservedClient) + 1;
          this.Articles.stock[0].stock =
            parseInt(this.Articles.stock[0].stock) - 1;
        }
      }
    },
    delFromReservation() {
      //TODO : del from reservation
      if (this.Articles.stock.length > 0) {
        if (this.Articles.stock[0].reservedClient > 0) {
          this.Articles.stock[0].reservedClient =
            parseInt(this.Articles.stock[0].reservedClient) - 1;
          this.Articles.stock[0].stock =
            parseInt(this.Articles.stock[0].stock) + 1;
        }
      }
    },
    async closeReservation() {
      this.showReservation = false;
      let resStock = await reserveStock(
        this.Articles.stock[0]._id,
        this.Articles.article.Code_EAN,
        this.Articles.stock[0].stock,
        this.Articles.stock[0].reservedClient,
        this.user.proprietaire,
        this.Articles.article.Ref_fournisseur,
        this.Articles.article.Prefixe_tarif,
        this.Articles.article.Code_marque,
        this.user.username,
        "Réservation de stock par : " + this.user.username,
        "NA",
        0
      );
      document.getElementById("son1").play();
    },
  },
  components: {
    backButton,
  },
};
</script>
<style></style>
